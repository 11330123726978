import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useCurrentMessage } from './useCurrentMessage';
import { useAppDispatch } from 'src/store';
import { useSession } from '../useSession';
import { useConversationParams } from '../conversationHooks';
import {
  useCreateFeedbackMutation,
  useDeleteFeedbackMutation,
  useUpdateFeedbackMutation,
} from 'src/store/services';
import {
  replaceConversationMessage,
  overwriteConversationMessage,
} from 'src/store/updateQueries';
import { COMMON_ERROR_TEXT } from 'src/constants';
import {
  CreateFeedbackRequest,
  Feedback,
  FeedbackOperationType,
  FeedbackType,
  Message,
  PatchFeedbackRequest,
  ModelFeedback,
  MessageTopLevelFeedback,
  FeedbackV2Type,
} from 'src/types';
import { getErrorStatus } from 'src/utils';

interface FeedbackArgs {
  rating: number;
  operationType: FeedbackOperationType;
  modelId?: string;
}

interface ExtendedFeedbackArgs extends FeedbackArgs {
  details?: string;
}

const SUBMITTED_TEXT = 'Thank you for the feedback';
const CANCELLED_TEXT = 'Feedback cancelled';

interface UseFeedbackRatingArgs {
  messageId: string;
  modelId?: string;
  operationType: FeedbackOperationType;
}

export const useFeedbackRating = ({
  messageId,
  modelId,
  operationType,
}: UseFeedbackRatingArgs) => {
  const dispatch = useAppDispatch();

  const {
    appUser: { user_id },
  } = useSession();
  const { currentConversationId } = useConversationParams();

  const { currentMessage } = useCurrentMessage(messageId);

  const currentRating = useMemo(() => {
    if (
      !currentMessage ||
      !currentMessage.feedback?.feedbacks ||
      currentMessage.feedback?.feedbacks.length === 0
    ) {
      return;
    }

    if (operationType === 'models') {
      return (
        currentMessage.feedback.feedbacks.find(
          (item) => (item.data as ModelFeedback)?.model_id === modelId,
        )?.data as ModelFeedback
      )?.rating.rating;
    }

    if (operationType === 'message-level') {
      return (
        currentMessage.feedback.feedbacks.find(
          (item) =>
            item.data.feedback_type === FeedbackV2Type.MESSAGE_TOP_LEVEL,
        )?.data as MessageTopLevelFeedback
      )?.rating?.rating;
    }

    return;
  }, [currentMessage, operationType, modelId]);

  const currentFeedbackId = useMemo(() => {
    if (operationType === 'message-level') {
      return currentMessage?.feedback?.feedbacks?.find(
        (item) => item.data.feedback_type === FeedbackV2Type.MESSAGE_TOP_LEVEL,
      )?.feedback_id;
    }

    if (operationType === 'models') {
      return currentMessage?.feedback?.feedbacks?.find(
        (item) => (item.data as ModelFeedback).model_id === modelId,
      )?.feedback_id;
    }
    return;
  }, [operationType, currentMessage, modelId]);

  const [createFeedback, { isLoading: isCreateLoading }] =
    useCreateFeedbackMutation();
  const [deleteFeedback, { isLoading: isDeleteLoading }] =
    useDeleteFeedbackMutation();
  const [updateFeedback, { isLoading: isUpdateLoading }] =
    useUpdateFeedbackMutation();

  const isLoading = useMemo(
    () => isCreateLoading || isDeleteLoading || isUpdateLoading,
    [isCreateLoading, isDeleteLoading, isUpdateLoading],
  );

  const updateMessageWithFeedback = useCallback(
    ({
      message,
      feedback_id,
      rating,
      feedback_ids,
      updated_feedback,
    }: {
      message: Message;
      feedback_id?: string;
      rating: number | null;
      feedback_ids?: Array<string>;
      updated_feedback?: Feedback | null;
    }): Message => {
      if (updated_feedback && feedback_ids) {
        const updatedMessage = {
          ...message,
          feedback: updated_feedback,
          feedback_ids,
        };

        return updatedMessage;
      }

      // optimistic update handling

      if (rating === null) {
        const updatedMessage: Message = {
          ...message,
          feedback: {
            feedbacks: message.feedback?.feedbacks?.filter(
              (item) => item.feedback_id !== feedback_id,
            ),
          },
        };

        return updatedMessage;
      }

      const messageLevelFeedback =
        operationType === 'message-level'
          ? [
              {
                feedback_id: feedback_id || '',
                data: {
                  feedback_type: FeedbackV2Type.MESSAGE_TOP_LEVEL,
                  rating: {
                    rating,
                  },
                },
              },
            ]
          : [];

      const modelsFeedback =
        operationType === 'models' && !!modelId
          ? [
              {
                feedback_id: feedback_id || '',
                data: {
                  feedback_type: FeedbackV2Type.MODEL_FEEDBACK,
                  model_id: modelId || '',
                  rating: {
                    rating,
                  },
                },
              },
            ]
          : [];

      const updatedMessage: Message = {
        ...message,
        feedback: {
          feedbacks: [
            ...(message.feedback?.feedbacks || []),
            ...messageLevelFeedback,
            ...modelsFeedback,
          ],
        },
      };

      return updatedMessage;
    },
    [modelId, operationType],
  );

  const getFeedbackData = useCallback(
    ({
      operationType,
      rating,
      modelId,
    }: Omit<FeedbackArgs, 'messageId'>):
      | ModelFeedback
      | MessageTopLevelFeedback => {
      if (operationType === 'message-level') {
        return {
          feedback_type: FeedbackV2Type.MESSAGE_TOP_LEVEL,
          rating: {
            rating,
          },
        };
      }

      return {
        feedback_type: FeedbackV2Type.MODEL_FEEDBACK,
        model_id: modelId || '',
        rating: {
          rating,
        },
      };
    },
    [],
  );

  const handleDeleteFeedback = useCallback(
    async (messageId: string) => {
      if (!currentFeedbackId || !currentMessage) {
        return;
      }

      const temporaryReplacedMessage = updateMessageWithFeedback({
        message: currentMessage,
        feedback_id: currentFeedbackId,
        rating: null,
      });

      dispatch(replaceConversationMessage(temporaryReplacedMessage));

      try {
        const { feedback_ids, updated_feedback } = await deleteFeedback({
          conversation_id: currentConversationId,
          message_id: messageId,
          feedback_id: currentFeedbackId,
          user_id,
        }).unwrap();
        toast(CANCELLED_TEXT);

        const updatedMessage = updateMessageWithFeedback({
          message: currentMessage,
          feedback_id: currentFeedbackId,
          rating: null,
          feedback_ids,
          updated_feedback,
        });
        dispatch(overwriteConversationMessage(updatedMessage));
      } catch (error) {
        toast(COMMON_ERROR_TEXT, { type: 'error' });
        dispatch(overwriteConversationMessage(currentMessage));
      }
    },
    [
      currentFeedbackId,
      currentMessage,
      currentConversationId,
      user_id,
      deleteFeedback,
      dispatch,
      updateMessageWithFeedback,
    ],
  );

  const handleCreateFeedback = useCallback(
    async ({ rating, operationType, modelId }: FeedbackArgs) => {
      if (!currentMessage || !messageId) {
        return;
      }

      const body: CreateFeedbackRequest = {
        user_id,
        conversation_id: currentConversationId,
        message_id: messageId,
        feedback_type: FeedbackType.FEEDBACK_V2,
        feedback_details_v2: getFeedbackData({
          rating,
          operationType,
          modelId,
        }),
      };

      const temporaryReplacedMessage: Message = updateMessageWithFeedback({
        message: currentMessage,
        rating,
      });

      dispatch(replaceConversationMessage(temporaryReplacedMessage));

      try {
        const { updated_feedback, feedback_ids } =
          await createFeedback(body).unwrap();
        toast(SUBMITTED_TEXT, { type: 'success' });

        const updatedMessage = updateMessageWithFeedback({
          message: currentMessage,
          feedback_ids,
          updated_feedback,
          rating,
        });

        dispatch(overwriteConversationMessage(updatedMessage));
      } catch (error) {
        if (getErrorStatus(error) === 404) {
          toast(
            'Error saving feedback. Please wait until the response is fully streamed.',
            { type: 'error' },
          );
        } else {
          toast(COMMON_ERROR_TEXT, { type: 'error' });
        }

        dispatch(overwriteConversationMessage(currentMessage));
      }
    },
    [
      messageId,
      currentConversationId,
      currentMessage,
      user_id,
      dispatch,
      updateMessageWithFeedback,
      createFeedback,
      getFeedbackData,
    ],
  );

  const handleUpdateFeedback = useCallback(
    async ({
      messageId,
      feedbackId,
      rating,
      details,
    }: {
      messageId: string;
      rating: number;
      feedbackId?: string;
      details?: string;
    }) => {
      if (!currentMessage || !feedbackId) {
        return;
      }

      const body: PatchFeedbackRequest = {
        user_id,
        conversation_id: currentConversationId,
        message_id: messageId,
        feedback_id: feedbackId,
        feedback_details_v2:
          operationType === 'message-level'
            ? {
                feedback_type: FeedbackV2Type.MESSAGE_TOP_LEVEL,
                rating: {
                  rating,
                  reason: details,
                },
              }
            : {
                feedback_type: FeedbackV2Type.MODEL_FEEDBACK,
                model_id: modelId || '',
                rating: {
                  rating,
                  reason: details,
                },
              },
      };

      const temporaryReplacedMessage: Message = updateMessageWithFeedback({
        message: currentMessage,
        rating,
      });

      dispatch(replaceConversationMessage(temporaryReplacedMessage));

      try {
        const { feedback_ids, updated_feedback } =
          await updateFeedback(body).unwrap();
        toast(SUBMITTED_TEXT, { type: 'success' });

        const updatedMessage: Message = updateMessageWithFeedback({
          message: currentMessage,
          rating,
          feedback_ids,
          updated_feedback,
        });

        dispatch(overwriteConversationMessage(updatedMessage));
      } catch (error) {
        toast(COMMON_ERROR_TEXT, { type: 'error' });
        dispatch(overwriteConversationMessage(currentMessage));
      }
    },
    [
      currentConversationId,
      currentMessage,
      user_id,
      modelId,
      operationType,
      dispatch,
      updateMessageWithFeedback,
      updateFeedback,
    ],
  );

  const updateFeedbackRating = useCallback(
    async ({
      rating,
      operationType,
      modelId,
      details,
    }: ExtendedFeedbackArgs) => {
      if (!messageId || isLoading) {
        return;
      }

      if (currentRating === rating) {
        await handleDeleteFeedback(messageId);
        return;
      }

      if (currentFeedbackId) {
        await handleUpdateFeedback({
          messageId,
          rating,
          feedbackId: currentFeedbackId,
          details,
        });
        return;
      }

      await handleCreateFeedback({
        rating,
        operationType,
        modelId,
      });
    },
    [
      currentFeedbackId,
      messageId,
      currentRating,
      isLoading,
      handleDeleteFeedback,
      handleUpdateFeedback,
      handleCreateFeedback,
    ],
  );

  const addFeedbackDetails = useCallback(
    async (details: string) => {
      if (!messageId || !currentFeedbackId || !currentRating) {
        return;
      }

      await handleUpdateFeedback({
        messageId,
        rating: currentRating,
        feedbackId: currentFeedbackId,
        details,
      });
      return;
    },
    [handleUpdateFeedback, messageId, currentFeedbackId, currentRating],
  );

  return useMemo(
    () => ({
      currentRating,
      updateFeedbackRating,
      addFeedbackDetails,
      isLoading,
    }),
    [currentRating, updateFeedbackRating, addFeedbackDetails, isLoading],
  );
};
