import { useCallback, useMemo } from 'react';
import {
  useUpdateConversationByIdMutation,
  useArchiveAllConversationsMutation,
  useDeleteConversationByIdMutation,
} from 'src/store/services';
import {
  useAppSelector,
  sessionState,
  setPageToken,
  setTaskStatusFilter,
  setTaskSkillFilter,
  useAppDispatch,
} from 'src/store';
import {
  TaskStatusFilter,
  ConversationUpdateRequest,
  TaskSkill,
} from 'src/types';
import log from 'src/utils/logger';
import { toast } from 'react-toastify';

export const useConversationActions = () => {
  const dispatch = useAppDispatch();

  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);

  const [updateConversationById] = useUpdateConversationByIdMutation();
  const [deleteConversationById, { isLoading: isDeleteLoading }] =
    useDeleteConversationByIdMutation();

  const handleUpdateConversationById = useCallback(
    async (conversation: Omit<ConversationUpdateRequest, 'user_id'>) => {
      try {
        await updateConversationById({ user_id, ...conversation }).unwrap();
      } catch (error) {
        log.error(error);
      }
    },
    [updateConversationById, user_id],
  );

  const handleDeleteConversationById = useCallback(
    async (conversation: Omit<ConversationUpdateRequest, 'user_id'>) => {
      try {
        await deleteConversationById({ user_id, ...conversation }).unwrap();
      } catch (error) {
        toast.error('Something went wrong');
        log.error(error);
      }
    },
    [deleteConversationById, user_id],
  );

  const [archiveAllConversations, { isLoading: isArchiveAllLoading }] =
    useArchiveAllConversationsMutation();

  const handleArchiveAllConversations = useCallback(async () => {
    await archiveAllConversations({ user_id }).unwrap();
  }, [user_id, archiveAllConversations]);

  const handleUpdatePageToken = useCallback(
    (pageToken: string) => {
      dispatch(setPageToken(pageToken));
    },
    [dispatch],
  );

  const handleUpdateTaskStatusFilter = useCallback(
    (chatsFilter?: TaskStatusFilter) => {
      dispatch(setTaskStatusFilter(chatsFilter));
    },
    [dispatch],
  );

  const handleUpdateTaskSkillFilter = useCallback(
    (taskSkill?: TaskSkill | TaskSkill[]) => {
      dispatch(setTaskSkillFilter(taskSkill));
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      isArchiveAllLoading,
      isDeleteLoading,
      updateConversationById: handleUpdateConversationById,
      deleteConversationById: handleDeleteConversationById,
      archiveAllConversations: handleArchiveAllConversations,
      setPageToken: handleUpdatePageToken,
      setTaskStatusFilter: handleUpdateTaskStatusFilter,
      setTaskSkillFilter: handleUpdateTaskSkillFilter,
    }),
    [
      isArchiveAllLoading,
      isDeleteLoading,
      handleUpdateConversationById,
      handleDeleteConversationById,
      handleArchiveAllConversations,
      handleUpdatePageToken,
      handleUpdateTaskStatusFilter,
      handleUpdateTaskSkillFilter,
    ],
  );
};
