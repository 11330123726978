import styles from './ThreadErrorDeepResearchMessage.module.scss';
import { getRandomDeepResearcherVideos } from 'src/utils';
import { LazyReactPlayer } from 'src/components/LazyReactPlayer';
import { UpsellCard } from 'src/v2/commonComponents/UpsellCard';
import researcherLightIcon from 'src/images/icons/researcher-doc-light.svg';
import researcherDarkIcon from 'src/images/icons/researcher-doc-dark.svg';
import { useMemo } from 'react';
import { useSession, useUserMetadata } from 'src/hooks';
import { DotOutline } from '@phosphor-icons/react';
import { SUPER_AGENT_DEEP_RESEARCH_MODEL, SVG_SIZE_M } from 'src/constants';
import { QuickSkillCommand } from 'src/types';
import { isMobile } from 'react-device-detect';

type ThreadErrorDeepResearchMessageProps = {
  title?: string | null;
};

export const ThreadErrorDeepResearchMessage = ({
  title,
}: ThreadErrorDeepResearchMessageProps) => {
  const video = getRandomDeepResearcherVideos();
  const {
    isOpenTier,
    isFreeTier,
    isStandardTier,
    isProTier,
    isUltraTier,
    isTeamsUltraTier,
  } = useSession();
  const { metadata } = useUserMetadata();

  const titleText = useMemo(() => {
    if (isOpenTier || isFreeTier) {
      return 'Please upgrade for Deep Research';
    }
    if (isStandardTier || isProTier) {
      return 'Please upgrade to get unlimited Deep Research tasks';
    }
    return '';
  }, [isOpenTier, isFreeTier, isStandardTier, isProTier]);

  const modelName = useMemo(() => {
    if (metadata && metadata[SUPER_AGENT_DEEP_RESEARCH_MODEL]) {
      return metadata[SUPER_AGENT_DEEP_RESEARCH_MODEL]?.display_name || '';
    }
    return '';
  }, [metadata]);

  return (
    <div className={styles.root}>
      <div className={styles.headerRowWrapper}>
        {modelName.length > 0 && (
          <>
            <span className={styles.modelName}>{modelName}</span>
            <DotOutline size={SVG_SIZE_M} weight="fill" />
          </>
        )}
        <span className={styles.skillsLabel}>
          {/*todo replace in the future*/}
          {QuickSkillCommand.DEEP_RESEARCHER}
        </span>
      </div>
      <h4 className={styles.title}>{title || titleText}</h4>
      {!(isUltraTier || isTeamsUltraTier) && (
        <>
          <LazyReactPlayer
            url={video}
            controls
            playing={!isMobile}
            className={styles.videoWrapper}
          />

          <UpsellCard
            darkIcon={researcherDarkIcon}
            lightIcon={researcherLightIcon}
            description="Deep Research is now available for Ultra & Business users. See how Ninja can tackle your complex research tasks."
            dataGTM="Deep-Researcher-message"
            shouldShowUpgrade
          />
        </>
      )}
    </div>
  );
};
