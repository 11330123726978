import { useContext, useMemo, useRef } from 'react';
import {
  CodeBlock,
  Icon,
  Images,
  Info,
  ListMagnifyingGlass,
  PenNib,
  Video,
} from '@phosphor-icons/react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import {
  useCurrentMessage,
  useDeleteAttachment,
  useSubmitUserInput,
} from 'src/hooks';
import {
  DrawerTooltipContainer,
  DrawerTooltipContainerRef,
} from 'src/v2/commonComponents/DrawerTooltipContainer';
import { getSkillFromMessage, sanitizeHTMLId } from 'src/utils';
import { RegenerateToggle } from '../RegenerateToggle';
import { isMediaSkill, MessageSkill, MessageType } from 'src/types';
import { RedoBySkillSubmit } from '../RedoBySkillSubmit';
import styles from './RegenerateMessageControl.module.scss';
import { Tab, TabList, TabPanel, Tabs } from 'src/v2/commonComponents/Tabs';
import { useUserSettings } from 'src/hooks';
import { SettingKeys, SingleSelectSetting } from 'src/types/settings';
import { RedoWithDifferentModel } from 'src/v2/components/ThreadMessage/components/RedoWithDifferentModel';
import { Button } from 'src/v2/commonComponents/Button';
import { allSuperAgentModelsSet, SVG_SIZE_M } from 'src/constants';

export const REDO_OPTIONS: Array<{
  skill: MessageSkill;
  Icon: Icon;
  availableForMediaSkill: boolean;
  onlyForMediaSkill?: boolean;
}> = [
  {
    skill: MessageSkill.WRITER,
    Icon: PenNib,
    availableForMediaSkill: true,
  },
  {
    skill: MessageSkill.CODER,
    Icon: CodeBlock,
    availableForMediaSkill: false,
  },
  {
    skill: MessageSkill.RESEARCHER,
    Icon: ListMagnifyingGlass,
    availableForMediaSkill: false,
  },
  {
    skill: MessageSkill.IMAGE_GENERATION,
    Icon: Images,
    availableForMediaSkill: true,
  },
  {
    skill: MessageSkill.VIDEO_GENERATION,
    Icon: Video,
    availableForMediaSkill: true,
    onlyForMediaSkill: true,
  },
];

interface RegenerateMessageControlProps {
  messageId: string;
  disabled?: boolean;
  messageType?: MessageType;
}

enum TabsOptions {
  Skills = 'Skills',
  Models = 'Models',
}

const tabsData = [TabsOptions.Models, TabsOptions.Skills];

export const RegenerateMessageControl = ({
  messageId,
  disabled,
  messageType,
}: RegenerateMessageControlProps) => {
  const { onClearInputBox } = useContext(ThreadInputBoxContext);

  const { currentMessage } = useCurrentMessage(messageId);

  const { superAgentModels, otherLLMModels } =
    useUserSettings<SingleSelectSetting>(
      messageType === MessageType.RESEARCH_TASK_CREATION_CARD
        ? SettingKeys.RESEARCH_PRIMARY_MODEL
        : SettingKeys.WRITER_CODER_PRIMARY_MODEL,
    );

  const { onSubmitUserInput } = useSubmitUserInput();
  const { onDeleteAllAttachments } = useDeleteAttachment();

  const drawerRef = useRef<DrawerTooltipContainerRef>(null);

  const tooltipAnchorId = useMemo(
    () => `redo-trigger-${sanitizeHTMLId(messageId || '')}-tooltip`,
    [messageId],
  );

  const userInput = useMemo(
    () => currentMessage?.refined_query || currentMessage?.clean_query || '',
    [currentMessage?.refined_query, currentMessage?.clean_query],
  );

  const currentSkill = useMemo(
    () => getSkillFromMessage(currentMessage),
    [currentMessage],
  );

  const currentRedoOptions = useMemo(() => {
    return REDO_OPTIONS.filter(
      ({ skill, availableForMediaSkill, onlyForMediaSkill }) => {
        if (!currentSkill) {
          return false;
        }

        if (skill === currentSkill) {
          return false;
        }

        if (isMediaSkill(currentSkill)) {
          return availableForMediaSkill;
        }

        return skill !== currentSkill && !onlyForMediaSkill;
      },
    );
  }, [currentSkill]);

  const handleSubmit = (skill: MessageSkill) => {
    onDeleteAllAttachments();
    onClearInputBox();
    const preparedUserInput = `/${skill} ${userInput}`;
    onSubmitUserInput(preparedUserInput);
    drawerRef.current?.onVisibilityToggle();
  };

  const handleModelSelectClick = (model: string | null) => {
    onDeleteAllAttachments();
    onClearInputBox();
    const preparedUserInput = `/${currentSkill} ${userInput}`;
    onSubmitUserInput(preparedUserInput, {}, { overrideModelName: model });
    drawerRef.current?.onVisibilityToggle();
  };

  const showRedoWithDifferentModel =
    currentSkill === MessageSkill.CODER ||
    currentSkill === MessageSkill.WRITER ||
    currentSkill === MessageSkill.RESEARCHER;

  return (
    <DrawerTooltipContainer
      ref={drawerRef}
      id={tooltipAnchorId}
      place="right"
      offset={8}
      classNameTooltip={styles.tooltip}
      ToggleButton={RegenerateToggle}
      disabledToggle={disabled}
    >
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <p className={styles.title}>Redo answer with</p>
          <Button
            color="transparent"
            tooltipContent="Redo will submit this request again, selecting a different model or skill"
            className={styles.info}
          >
            <Info size={SVG_SIZE_M} />
          </Button>
        </div>

        {showRedoWithDifferentModel ? (
          <Tabs<TabsOptions>
            defaultActiveTab={TabsOptions.Models}
            className={styles.tabs}
          >
            <TabList className={styles.tabList}>
              {tabsData.map((item) => (
                <Tab key={item} tab={item}>
                  <>{item}</>
                </Tab>
              ))}
            </TabList>
            {tabsData.map((tabName) => (
              <TabPanel key={tabName} tab={tabName} className={styles.tabPanel}>
                {tabName === TabsOptions.Skills ? (
                  <div className={styles.tabPanelWrapper}>
                    {currentRedoOptions.map(({ Icon, skill }) => (
                      <RedoBySkillSubmit
                        key={skill}
                        Icon={Icon}
                        skill={skill}
                        onSubmit={handleSubmit}
                      />
                    ))}
                  </div>
                ) : (
                  <div className={styles.tabPanelWrapper}>
                    <RedoWithDifferentModel
                      models={superAgentModels}
                      externalLLMModels={otherLLMModels}
                      onClick={handleModelSelectClick}
                      modelsSet={
                        currentSkill !== MessageSkill.RESEARCHER
                          ? allSuperAgentModelsSet
                          : undefined
                      }
                    />
                  </div>
                )}
              </TabPanel>
            ))}
          </Tabs>
        ) : (
          <>
            {currentRedoOptions.map(({ Icon, skill }) => (
              <RedoBySkillSubmit
                key={skill}
                Icon={Icon}
                skill={skill}
                onSubmit={handleSubmit}
              />
            ))}
          </>
        )}
      </div>
    </DrawerTooltipContainer>
  );
};
