import { SVGProps } from 'react';
import { useColorTheme } from 'src/hooks';

export const Ninja404Logo = ({
  width = 268,
  height = 240,
}: SVGProps<SVGSVGElement>) => {
  const { isDarkTheme } = useColorTheme();

  if (isDarkTheme) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 268 240"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <path
          d="M220.961 226.601L90.9531 168.965C85.6799 166.628 83.0434 160.622 84.8641 155.105L103.297 99.2624C103.693 98.064 103.463 96.7411 102.684 95.7501L42.6376 19.1607C40.2361 16.0955 43.4995 11.8272 47.0442 13.399L176.793 70.9194C181.868 73.1687 184.533 78.8429 183.048 84.2312L167.552 140.562C167.243 141.682 167.469 142.886 168.16 143.817L225.427 220.918C227.718 224.001 224.459 228.15 220.965 226.601H220.961Z"
          fill="#0CC5FF"
          fillOpacity="0.12"
        />
        <path
          d="M27.3989 207.97L85.0346 77.9625C87.3715 72.6894 93.3775 70.0528 98.8949 71.8735L154.738 90.3064C155.936 90.7028 157.259 90.4724 158.25 89.6934L234.839 29.647C237.904 27.2455 242.173 30.509 240.601 34.0536L183.081 163.803C180.831 168.878 175.157 171.542 169.769 170.058L113.438 154.561C112.317 154.252 111.114 154.478 110.183 155.17L33.0823 212.436C29.9986 214.727 25.8502 211.468 27.3989 207.975V207.97Z"
          fill="#0CC5FF"
          fillOpacity="0.12"
        />
        <path
          d="M37.8125 164V141.125L38.5 140.188V100.75H38L20.9375 129.75H46.5625L48.25 129.188H68.375V146.25H0.75V130.438L40.3125 73H57.875V164H37.8125Z"
          fill="#8E9496"
        />
        <g clipPath="url(#clip0_3461_170880)">
          <path
            d="M134.667 92C123.029 92 112.617 95.2594 105.792 101.778C105.485 101.384 105.223 101.034 105.07 100.772C104.042 99.1313 102.839 97.2063 101.964 95.2594C101.81 94.9094 101.657 94.5594 101.548 94.2313C102.314 94.4281 103.21 94.5594 104.195 94.4938C105.114 94.4281 106.12 94.1219 107.017 93.4438C107.87 92.8094 108.373 92.0438 108.657 91.4313C109.182 90.3156 109.226 89.2 109.226 88.5219C109.226 87.0563 108.854 85.3281 108.46 83.7531C107.629 80.5156 105.967 68.7906 104.939 66.3188L97.5448 77.7375C98.3761 79.75 99.448 82.6375 100.192 85.1969C100.017 85.1531 99.8417 85.1313 99.6448 85.1094C98.9667 85.0219 97.9386 85 96.8011 85.4156C94.0448 86.4219 93.1479 88.85 92.8636 90.3594C92.2948 93.4656 93.3667 96.6156 94.3948 98.9125C95.4886 101.341 96.5823 102.259 96.5823 102.259L99.5792 105.716L101.395 107.334C99.9511 109.719 98.8573 112.366 98.1792 115.297C96.6479 114.509 94.8542 113.591 93.2792 112.563C92.9948 112.366 92.7104 112.191 92.4698 112.016C93.0823 111.906 93.7823 111.688 94.4386 111.338C95.0511 110.988 95.5979 110.463 95.8823 109.719C96.1667 109.019 96.123 108.319 96.0136 107.816C95.8167 106.897 95.3136 106.109 94.9636 105.65C94.2198 104.644 93.1261 103.594 92.0761 102.675C89.8886 100.75 82.9323 93.3344 81.0073 92L81.5104 102.128C83.0854 103.244 85.2511 104.841 87.0229 106.328C86.8917 106.35 86.7386 106.394 86.6073 106.438C86.0823 106.613 85.3604 106.919 84.7917 107.553C83.3698 109.106 83.9386 111.053 84.5073 112.169C85.6448 114.466 87.9417 116.259 89.8229 117.484C91.7698 118.775 92.9948 119.059 92.9948 119.059L96.8011 120.438L97.4792 120.613C97.4354 121.291 97.3698 121.947 97.3698 122.669C97.3698 143.122 114.082 164.341 134.71 164.341C155.339 164.341 172.051 143.122 172.051 122.669C172.051 102.216 155.339 92 134.71 92H134.667Z"
            fill="url(#paint0_linear_3461_170880)"
          />
          <path
            opacity="0.1"
            d="M108.723 146.841C104.042 140.213 101.242 132.119 101.242 124.134C101.242 105.65 116.292 96.4187 134.842 96.4187C153.392 96.4187 156.607 100.006 162.645 107.181C156.914 98.6281 145.889 94.3406 133.245 94.3406C114.673 94.3406 99.6448 103.572 99.6448 122.056C99.6448 140.541 103.079 139.884 108.701 146.841H108.723Z"
            fill="#E8EAEA"
          />
          <path
            d="M165.576 123.675C165.576 137.041 150.395 150.341 134.667 150.341C118.939 150.341 103.757 137.041 103.757 123.675C103.757 110.309 118.939 113.284 134.667 113.284C150.395 113.284 165.576 110.309 165.576 123.675Z"
            fill="url(#paint1_linear_3461_170880)"
            fillOpacity="0.4"
          />
          <path
            d="M159.954 131.868C161.267 128.543 158.51 124.562 153.807 122.987C149.104 121.412 144.226 122.856 142.914 126.181C141.601 129.506 144.248 130.906 148.951 132.459C153.654 134.034 158.642 135.193 159.954 131.846V131.868ZM109.379 131.868C108.067 128.543 110.823 124.562 115.526 122.987C120.229 121.412 125.107 122.856 126.42 126.181C127.732 129.506 125.085 130.906 120.382 132.459C115.679 134.034 110.692 135.193 109.379 131.846V131.868Z"
            fill="url(#paint2_linear_3461_170880)"
          />
        </g>
        <path
          d="M235.812 164V141.125L236.5 140.188V100.75H236L218.938 129.75H244.562L246.25 129.188H266.375V146.25H198.75V130.438L238.312 73H255.875V164H235.812Z"
          fill="#8E9496"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3461_170880"
            x1="163.717"
            y1="154.366"
            x2="91.9011"
            y2="82.55"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#11181B" />
            <stop offset="0.5" stopColor="#1E282D" />
            <stop offset="0.7" stopColor="#61696C" />
            <stop offset="0.9" stopColor="#8E9496" />
            <stop offset="1" stopColor="#BBBFC0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3461_170880"
            x1="132.195"
            y1="112.059"
            x2="140.157"
            y2="150.056"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2" stopColor="#7E9EA9" />
            <stop offset="1" stopColor="#919EA0" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_3461_170880"
            x1="109.511"
            y1="133.138"
            x2="159.339"
            y2="124.352"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0CC5FF" />
            <stop offset="1" stopColor="#0A74FF" />
          </linearGradient>
          <clipPath id="clip0_3461_170880">
            <rect
              width="112"
              height="112"
              fill="white"
              transform="translate(78 64)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 268 240"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M220.96 226.601L90.9531 168.965C85.6799 166.628 83.0434 160.622 84.8641 155.105L103.297 99.2624C103.693 98.064 103.463 96.7411 102.684 95.7501L42.6376 19.1607C40.2361 16.0955 43.4995 11.8272 47.0441 13.399L176.793 70.9194C181.868 73.1687 184.533 78.8429 183.048 84.2312L167.552 140.562C167.243 141.682 167.469 142.886 168.16 143.817L225.427 220.918C227.718 224.001 224.459 228.15 220.965 226.601H220.96Z"
        fill="#0A74FF"
        fillOpacity="0.12"
      />
      <path
        d="M27.399 207.97L85.0346 77.9625C87.3716 72.6894 93.3776 70.0528 98.895 71.8735L154.738 90.3064C155.936 90.7028 157.259 90.4724 158.25 89.6934L234.839 29.647C237.905 27.2455 242.173 30.509 240.601 34.0536L183.081 163.803C180.831 168.878 175.157 171.542 169.769 170.058L113.438 154.561C112.318 154.252 111.114 154.478 110.183 155.17L33.0824 212.436C29.9987 214.727 25.8502 211.468 27.399 207.975V207.97Z"
        fill="#0A74FF"
        fillOpacity="0.12"
      />
      <path
        d="M37.8125 164V141.125L38.5 140.188V100.75H38L20.9375 129.75H46.5625L48.25 129.188H68.375V146.25H0.75V130.438L40.3125 73H57.875V164H37.8125Z"
        fill="#61696C"
      />
      <g clipPath="url(#clip0_3461_170822)">
        <path
          d="M134.667 92C123.029 92 112.617 95.2594 105.792 101.778C105.485 101.384 105.223 101.034 105.07 100.772C104.042 99.1313 102.839 97.2063 101.964 95.2594C101.81 94.9094 101.657 94.5594 101.548 94.2313C102.314 94.4281 103.21 94.5594 104.195 94.4938C105.114 94.4281 106.12 94.1219 107.017 93.4438C107.87 92.8094 108.373 92.0438 108.657 91.4313C109.182 90.3156 109.226 89.2 109.226 88.5219C109.226 87.0563 108.854 85.3281 108.46 83.7531C107.629 80.5156 105.967 68.7906 104.939 66.3188L97.5448 77.7375C98.376 79.75 99.4479 82.6375 100.192 85.1969C100.017 85.1531 99.8417 85.1313 99.6448 85.1094C98.9667 85.0219 97.9385 85 96.801 85.4156C94.0448 86.4219 93.1479 88.85 92.8635 90.3594C92.2948 93.4656 93.3667 96.6156 94.3948 98.9125C95.4885 101.341 96.5823 102.259 96.5823 102.259L99.5792 105.716L101.395 107.334C99.951 109.719 98.8573 112.366 98.1792 115.297C96.6479 114.509 94.8542 113.591 93.2792 112.563C92.9948 112.366 92.7104 112.191 92.4698 112.016C93.0823 111.906 93.7823 111.688 94.4385 111.338C95.051 110.988 95.5979 110.463 95.8823 109.719C96.1667 109.019 96.1229 108.319 96.0135 107.816C95.8167 106.897 95.3135 106.109 94.9635 105.65C94.2198 104.644 93.126 103.594 92.076 102.675C89.8885 100.75 82.9323 93.3344 81.0073 92L81.5104 102.128C83.0854 103.244 85.251 104.841 87.0229 106.328C86.8917 106.35 86.7385 106.394 86.6073 106.438C86.0823 106.613 85.3604 106.919 84.7917 107.553C83.3698 109.106 83.9385 111.053 84.5073 112.169C85.6448 114.466 87.9417 116.259 89.8229 117.484C91.7698 118.775 92.9948 119.059 92.9948 119.059L96.801 120.438L97.4792 120.613C97.4354 121.291 97.3698 121.947 97.3698 122.669C97.3698 143.122 114.082 164.341 134.71 164.341C155.339 164.341 172.051 143.122 172.051 122.669C172.051 102.216 155.339 92 134.71 92H134.667Z"
          fill="url(#paint0_linear_3461_170822)"
        />
        <path
          opacity="0.1"
          d="M108.723 146.841C104.042 140.213 101.242 132.119 101.242 124.134C101.242 105.65 116.292 96.4187 134.842 96.4187C153.392 96.4187 156.607 100.006 162.645 107.181C156.914 98.6281 145.889 94.3406 133.245 94.3406C114.673 94.3406 99.6448 103.572 99.6448 122.056C99.6448 140.541 103.079 139.884 108.701 146.841H108.723Z"
          fill="#E8EAEA"
        />
        <path
          d="M165.576 123.675C165.576 137.041 150.395 150.341 134.667 150.341C118.939 150.341 103.757 137.041 103.757 123.675C103.757 110.309 118.939 113.284 134.667 113.284C150.395 113.284 165.576 110.309 165.576 123.675Z"
          fill="url(#paint1_linear_3461_170822)"
          fillOpacity="0.4"
        />
        <path
          d="M159.954 131.868C161.267 128.543 158.51 124.562 153.807 122.987C149.104 121.412 144.226 122.856 142.914 126.181C141.601 129.506 144.248 130.906 148.951 132.459C153.654 134.034 158.642 135.193 159.954 131.846V131.868ZM109.379 131.868C108.067 128.543 110.823 124.562 115.526 122.987C120.229 121.412 125.107 122.856 126.42 126.181C127.732 129.506 125.085 130.906 120.382 132.459C115.679 134.034 110.692 135.193 109.379 131.846V131.868Z"
          fill="url(#paint2_linear_3461_170822)"
        />
      </g>
      <path
        d="M235.812 164V141.125L236.5 140.188V100.75H236L218.938 129.75H244.562L246.25 129.188H266.375V146.25H198.75V130.438L238.312 73H255.875V164H235.812Z"
        fill="#61696C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3461_170822"
          x1="163.717"
          y1="154.366"
          x2="91.901"
          y2="82.55"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#11181B" />
          <stop offset="0.5" stopColor="#1E282D" />
          <stop offset="0.7" stopColor="#61696C" />
          <stop offset="0.9" stopColor="#8E9496" />
          <stop offset="1" stopColor="#BBBFC0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3461_170822"
          x1="132.195"
          y1="112.059"
          x2="140.157"
          y2="150.056"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2" stopColor="#7E9EA9" />
          <stop offset="1" stopColor="#919EA0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3461_170822"
          x1="109.511"
          y1="133.138"
          x2="159.339"
          y2="124.352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0CC5FF" />
          <stop offset="1" stopColor="#0A74FF" />
        </linearGradient>
        <clipPath id="clip0_3461_170822">
          <rect
            width="112"
            height="112"
            fill="white"
            transform="translate(78 64)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
