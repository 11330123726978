import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import {
  DrawerTooltipContainer,
  DrawerTooltipContainerRef,
} from 'src/v2/commonComponents/DrawerTooltipContainer';
import { CodeLanguageToggle } from '../CodeLanguageToggle';
import { Button } from 'src/v2/commonComponents/Button';
import { ProgrammingLanguageOptions } from '../../plugins/NinjaCanvasPlugin/shared/types';
import styles from './CodeLanguageControl.module.scss';
import { useGraphContext } from '../../plugins/NinjaCanvasPlugin/contexts/GraphContext';
import { useEditorActions, useEditorState } from 'src/hooks';
import { PROGRAMMING_LANGUAGES } from '../../plugins/NinjaCanvasPlugin/shared/constants';
import { HumanMessage } from '@langchain/core/messages';
import { convertToOpenAIFormat } from '../../plugins/NinjaCanvasPlugin/lib/convert_messages';
import {
  canConvertLanguages,
  getCodeEditorChangeLanguagePrompt,
} from 'src/utils';
import { useThreadContext } from '../../plugins/NinjaCanvasPlugin/contexts/ThreadProvider';

const tooltipAnchorId = 'code-language-button-tooltip-anchor';

export const CodeLanguageControl = () => {
  const drawerRef = useRef<DrawerTooltipContainerRef>(null);

  const { codeEditorData } = useEditorState();
  const { setCodeEditorData } = useEditorActions();

  const { getUserThreads } = useThreadContext();
  const { graphData } = useGraphContext();
  const { streamMessage, setMessages } = graphData;

  const handleClick = async (language: ProgrammingLanguageOptions) => {
    drawerRef.current?.onVisibilityToggle();

    setCodeEditorData({
      language,
    });

    if (codeEditorData?.language) {
      if (canConvertLanguages(codeEditorData?.language, language)) {
        const humanMessage = new HumanMessage({
          content: getCodeEditorChangeLanguagePrompt(
            codeEditorData?.language,
            language,
          ),
          id: uuidv4(),
        });

        setMessages((prev) => [...prev, humanMessage]);

        await streamMessage({
          messages: [convertToOpenAIFormat(humanMessage)],
          portLanguage: language,
        });

        await getUserThreads();
      } else {
        toast.error('Code translation between these languages is not possible');
      }
    }
  };

  return (
    <DrawerTooltipContainer
      ref={drawerRef}
      id={tooltipAnchorId}
      place="bottom"
      offset={8}
      classNameTooltip={styles.tooltip}
      ToggleButton={CodeLanguageToggle}
    >
      <div className={styles.container}>
        {PROGRAMMING_LANGUAGES.map(({ label, language }) => (
          <Button
            key={language}
            onClick={() => handleClick(language)}
            className={styles.item}
            color="transparent"
          >
            <div className={styles.labelWrapper}>
              <span>{label}</span>
            </div>
          </Button>
        ))}
      </div>
    </DrawerTooltipContainer>
  );
};
