import { File } from '@phosphor-icons/react';
import { Message, RequestPayloadType } from 'src/types';
import { FileImageThumbnail } from 'src/components/FlatAppearance/components/FileImageThumbnail';
import { UserMessageContent } from 'src/v2/components/UserMessage/components/UserMessageContent';
import {
  CONVERTED_TEXT_TO_FILE_NAME,
  FILE_REGEX,
  IMAGE_FILE_EXTENSION_LIST,
  CONVERTED_PASTED_TEXT_TO_FILE_NAME,
  SVG_SIZE_M,
} from 'src/constants';
import { FileRequestPayload } from 'src/types/models/FileRequestPayload';
import { ConvertedTextFile } from 'src/components/UserMessage/components/ConvertedTextFile';

interface UserContentWithFileProps {
  message: Message;
}

export const UserContentWithFile = ({ message }: UserContentWithFileProps) => {
  const filename =
    message.content.match(FILE_REGEX) !== null
      ? (message.content.match(FILE_REGEX) || [])[2].replaceAll('"', '')
      : '';
  const originalFilename =
    message.content.match(FILE_REGEX) !== null
      ? (message.content.match(FILE_REGEX) || [])[1].replaceAll('"', '')
      : '';

  const extention = originalFilename.split('.').pop()?.toLowerCase();
  const isImage = extention
    ? IMAGE_FILE_EXTENSION_LIST.includes(extention)
    : false;

  const newContent = message.content.replace(FILE_REGEX, '');

  const filesFromPayloadList = (message.payload_list || []).filter(
    (item) => item.payload_type === RequestPayloadType.FILE,
  );

  const handleFileClick = () => {
    // todo
  };

  const fileComponent = (
    isImage: boolean,
    filename: string,
    originalFilename: string,
  ) => {
    if (
      originalFilename === CONVERTED_TEXT_TO_FILE_NAME ||
      originalFilename.includes(CONVERTED_PASTED_TEXT_TO_FILE_NAME)
    ) {
      return (
        <ConvertedTextFile
          filename={originalFilename}
          convertedFilename={filename}
          key={filename}
          isLoading={false}
          hasError={false}
        />
      );
    }

    return isImage ? (
      <FileImageThumbnail
        fileName={filename}
        originalFileName={originalFilename}
        key={filename}
      />
    ) : (
      !!originalFilename && (
        <button
          type="button"
          onClick={handleFileClick}
          className="nj-thread-message--content-with-file-wrapper--file-label"
          key={filename}
        >
          <div className="nj-thread-message--content-with-file-wrapper--file-label-text">
            <File size={SVG_SIZE_M} />
            <span className="nj-thread-message--content-with-file-wrapper--file-name ellipsis">
              {originalFilename}
            </span>
          </div>
        </button>
      )
    );
  };

  return (
    <div className="nj-thread-message--content-with-file-wrapper">
      <UserMessageContent content={newContent} />

      <div className="nj-thread-message--content-with-file-wrapper--files-row">
        {fileComponent(isImage, filename, originalFilename)}
        {filesFromPayloadList.map((item) => {
          const payloadFileExtention = (
            item as FileRequestPayload
          ).original_filename
            .split('.')
            .pop()
            ?.toLowerCase();
          const isPayloadFileImage = payloadFileExtention
            ? IMAGE_FILE_EXTENSION_LIST.includes(payloadFileExtention)
            : false;

          return fileComponent(
            isPayloadFileImage,
            (item as FileRequestPayload).converted_filename,
            (item as FileRequestPayload).original_filename,
          );
        })}
      </div>
    </div>
  );
};
