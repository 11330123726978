import { TextModelList } from './components/TextModelList';
import styles from './OtherModelsSelector.module.scss';
import { useActiveModel } from 'src/hooks/userSettingsHooks/useActiveModel';
import { SettingLLMModels } from 'src/types/settings';
import cn from 'classnames';

interface OtherModelsSelectorProps {
  onClose: () => void;
  isOpen: boolean;
  modelsList?: SettingLLMModels;
  showRegenerate?: boolean;
  onRegenerateClick?: (value: string) => void;
}

export const OtherModelsSelector = ({
  isOpen,
  onClose,
  modelsList,
  showRegenerate,
  onRegenerateClick,
}: OtherModelsSelectorProps) => {
  const { changeCurrentModel } = useActiveModel();

  const handleChangeValue = (item: string) => {
    if (showRegenerate && !!onRegenerateClick) {
      onRegenerateClick(item);
    } else {
      changeCurrentModel(item);
    }
    onClose();
  };

  if (!isOpen || !modelsList || modelsList?.length === 0) {
    return null;
  }

  return (
    <div
      className={cn(styles.tooltip, {
        [styles.regenerateTooltip]: showRegenerate,
      })}
    >
      <TextModelList
        onClick={handleChangeValue}
        modelsList={modelsList}
        showRegenerate={showRegenerate}
      />
    </div>
  );
};
