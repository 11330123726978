import styles from './SettingsSettings.module.scss';
import { Tab, TabList, TabPanel, Tabs } from 'src/v2/commonComponents/Tabs';
import { SVG_SIZE_M } from 'src/constants';
import { Sliders } from '@phosphor-icons/react';
import { ReactNode } from 'react';
import { Icon } from 'src/components/Icon';
import { NinjaModeSettings } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/NinjaModeSettings';
import { PreferencesSettings } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/PreferencesSettings';

enum SettingsTabList {
  NINJA_MODE = 'ninja_mode',
  PREFERENCES = 'preferences',
}

interface SkillTab {
  tabName: SettingsTabList;
  tabTitle: string;
  iconComponent?: ReactNode;
  TabPanelComponent: ReactNode;
}

export const SettingsSection = () => {
  const tabsData: SkillTab[] = [
    {
      tabName: SettingsTabList.NINJA_MODE,
      tabTitle: 'Ninja Mode',
      iconComponent: <Icon type="ninjaOutlined" size={SVG_SIZE_M} />,
      TabPanelComponent: <NinjaModeSettings />,
    },
    {
      tabName: SettingsTabList.PREFERENCES,
      tabTitle: 'Preferences',
      iconComponent: <Sliders size={SVG_SIZE_M} />,
      TabPanelComponent: <PreferencesSettings />,
    },
  ];

  return (
    <div className={styles.root}>
      <Tabs<SettingsTabList>
        defaultActiveTab={SettingsTabList.NINJA_MODE}
        className={styles.root}
      >
        <TabList className={styles.tabList}>
          {tabsData.map(({ tabName, tabTitle, iconComponent }) => (
            <Tab key={tabName} tab={tabName}>
              <>
                {iconComponent}
                {tabTitle}
              </>
            </Tab>
          ))}
        </TabList>

        {tabsData.map(({ tabName, TabPanelComponent }) => (
          <TabPanel key={tabName} tab={tabName} className={styles.tabPanel}>
            {TabPanelComponent}
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};
