import { useContext } from 'react';
import { TaskSkill } from 'src/types';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { SkillLabel } from 'src/components/SkillLabel';
import { useBreakpoint, useSession, useSubmitUserInput } from 'src/hooks';
import { SkillLabelIcon } from 'src/components/SkillLabelIcon';
import styles from './PillarsBlendTile.module.scss';
import { BetaLabel } from 'src/v2/commonComponents/BetaLabel';
import { RecommendedSkillExample } from 'src/types/recommendedSkills';

type PillarsBlendTileProps = {
  skill: TaskSkill | string;
  template?: RecommendedSkillExample;
  onClick: () => void;
  onShowUpsell: () => void;
};

export const PillarsBlendTile = ({
  skill,
  template,
  onClick,
  onShowUpsell,
}: PillarsBlendTileProps) => {
  const { setThreadInputBoxValue, onCheckInputTagReplacement } = useContext(
    ThreadInputBoxContext,
  );

  const { isDesktopAndUp } = useBreakpoint();
  const { onSubmitUserInput } = useSubmitUserInput();
  // TODO(olha): waiting for flag from BE
  const {
    isTeamsUltraTier,
    isEnterpriseTier,
    isUltraTier,
    isEnterpriseTrialTier,
  } = useSession();

  const isVideoCapable =
    isUltraTier ||
    isEnterpriseTrialTier ||
    isEnterpriseTier ||
    isTeamsUltraTier;

  const isVideoSkill = skill === 'video';

  const handleTileClick = () => {
    if (!template) {
      return;
    }

    if (isVideoSkill && !isVideoCapable) {
      onShowUpsell();
      return;
    }

    const inputValue = `/${template.command} ${template.inputText}`;

    setThreadInputBoxValue(inputValue);

    const shouldShowReplaceTooltip = onCheckInputTagReplacement(inputValue);

    if (!shouldShowReplaceTooltip) {
      onSubmitUserInput(inputValue);
    }

    onClick();
  };

  return (
    <div
      className="nj-random-pillars--blend-tile"
      onClick={handleTileClick}
      data-e2e={`skill-example-button-${skill}`}
    >
      <div className={styles.wrapper}>
        {isDesktopAndUp && <SkillLabelIcon skill={skill} />}
        <SkillLabel skill={skill} />
      </div>

      <span className="nj-random-pillars--blend-tile--content">
        {template?.displayMessage}
      </span>

      {isVideoSkill && <BetaLabel className={styles.label} />}
    </div>
  );
};
