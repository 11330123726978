import classNames from 'classnames';
import { Check, CheckCircle, Circle } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { EXTERNAL_MODELS_LINK } from 'src/constants/externalLinks';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent, UserTier } from 'src/types';
import { PlanCardWrapper } from '../PlanCardWrapper';
import styles from './PlanCard.module.scss';

interface PlanCardProps {
  id: UserTier;
  title: string;
  price: number;
  features: { id: string; title: JSX.Element }[];
  models: { id: string; icon: JSX.Element; text: JSX.Element }[];
  description: React.ReactNode;
  onClick: (plan: UserTier) => void;
  isSelected: boolean;
}

export const PlanCard = ({
  id,
  title,
  price,
  features,
  models,
  description,
  isSelected,
  onClick,
}: PlanCardProps) => {
  const isBasic = id === UserTier.FREE;

  const handleViewAllModelsClick = () => {
    sendGTMEvent(GTMEvent.PAYMENT_VIEW_ALL_MODELS_CLICK);
  };

  return (
    <PlanCardWrapper id={id} onClick={onClick} isSelected={isSelected}>
      {!isBasic ? (
        <div
          className={classNames(styles.selectButton, {
            [styles.selected]: isSelected,
          })}
        >
          {isSelected ? (
            <CheckCircle size={SVG_SIZE_M} weight="fill" />
          ) : (
            <Circle size={SVG_SIZE_M} />
          )}
        </div>
      ) : (
        <span className={styles.caption}>Current Plan</span>
      )}

      <div className={styles.header}>
        <p className={styles.title}>{title}</p>
        <p className={styles.price}>{price ? `$${price} USD/Month` : 'Free'}</p>
        <p className={styles.description}>{description}</p>
      </div>

      <div className={styles.features}>
        {features.map(({ id, title }) => (
          <p key={id} className={styles.feature}>
            <Check size={SVG_SIZE_M} />
            {title}
          </p>
        ))}
      </div>

      <div className={styles.models}>
        {models.map(({ id, icon, text }) => (
          <p key={id} className={styles.model}>
            {icon}
            {text}
          </p>
        ))}

        {id === UserTier.ULTRA && (
          <a
            href={EXTERNAL_MODELS_LINK}
            target="_blank"
            rel="noreferrer"
            className={styles.viewAllModels}
            onClick={handleViewAllModelsClick}
          >
            +16 more models
          </a>
        )}
      </div>
    </PlanCardWrapper>
  );
};
