import {
  MAX_NUMBER_OF_PASTED_WORDS,
  REG_EXP_FOR_WORD_SEPARATORS,
} from 'src/constants';

export const validatePrompt = (value: string) => {
  const valueSplittedToWords = value.split(REG_EXP_FOR_WORD_SEPARATORS);

  if (valueSplittedToWords.length <= MAX_NUMBER_OF_PASTED_WORDS) {
    return {
      validatedValue: value,
      isError: false,
      wordLimit: MAX_NUMBER_OF_PASTED_WORDS,
    };
  }

  const newValue = valueSplittedToWords
    .slice(0, MAX_NUMBER_OF_PASTED_WORDS)
    .join(' ');

  return {
    validatedValue: newValue,
    isError: true,
    wordLimit: MAX_NUMBER_OF_PASTED_WORDS,
  };
};
