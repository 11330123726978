import { SingleSelectSetting, SettingKeys } from 'src/types/settings';
import { useUserSettings } from 'src/hooks';
import { useCallback, useMemo, useRef } from 'react';
import { newExternalModelsList, SVG_SIZE_M } from 'src/constants';
import {
  DrawerTooltipContainer,
  DrawerTooltipContainerRef,
} from 'src/v2/commonComponents/DrawerTooltipContainer';
import styles from './SettingsSelect.module.scss';
import { SelectorButton } from 'src/v2/components/LeftSidePanel/components/LLMSelectorSection/components/SelectorButton';
import { TaskSkill } from 'src/types';
import { Icon } from 'src/components/Icon';
import { ExternalModelItem } from 'src/v2/components/OtherModelsSelector/components/TextModelList/components/ExternalModelItem';
import { prepareModelsList } from 'src/utils';
import { useUserMetadata } from 'src/hooks';

interface SettingsSelectProps {
  settingKey: SettingKeys;
  skill?: TaskSkill;
}

export const SettingsSelect = ({ settingKey, skill }: SettingsSelectProps) => {
  const drawerRef = useRef<DrawerTooltipContainerRef>(null);

  const { setting, updateUserSetting } =
    useUserSettings<SingleSelectSetting>(settingKey);
  const { metadata } = useUserMetadata();

  const tooltipAnchorId = useMemo(() => `settings-${skill}-tooltip`, [skill]);

  const handleSelectClick = useCallback(
    (id: string) => {
      updateUserSetting(id);
      drawerRef.current?.onVisibilityToggle();
    },
    [updateUserSetting],
  );

  const modelItems = useMemo(() => {
    return prepareModelsList(setting?.options || {}, metadata).map((item) => {
      return {
        id: item.id,
        icon: item.iconName ? (
          <Icon type={item.iconName} size={SVG_SIZE_M} />
        ) : (
          <></>
        ),
        title: item.title,
        selected: item.id === setting?.value,
        availableIn: item.availableIn,
        description:
          skill === TaskSkill.RESEARCH ? item.description : undefined,
        onClick: () => handleSelectClick(item.id),
      };
    });
  }, [setting?.options, setting?.value, handleSelectClick, metadata, skill]);

  const nameFromFE =
    newExternalModelsList[setting?.value || '']?.display_name || '';

  return (
    <DrawerTooltipContainer
      ref={drawerRef}
      id={tooltipAnchorId}
      place="bottom"
      offset={8}
      classNameTooltip={styles.tooltip}
      ToggleButton={SelectorButton}
      data-e2e="xxxxxxx"
      toggleButtonProps={{
        label: metadata
          ? metadata[setting?.value || '']?.display_name || nameFromFE
          : nameFromFE,
        icon: newExternalModelsList[setting?.value || '']?.icon_name || '',
        dataE2E: 'xxxxxxx',
      }}
      disabledToggle={!setting?.user_editable}
    >
      <div
        className={styles.container}
        data-e2e={`model-settings-${settingKey}`}
      >
        {modelItems.map((item) => (
          <ExternalModelItem key={item.id} {...item} />
        ))}
      </div>
    </DrawerTooltipContainer>
  );
};
