/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TaskChannel {
  EMAIL = 'email',
  TEST = 'test',
  WEB_APP = 'web_app',
}
