import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ArrowCircleUpRight } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import styles from './EditorContainer.module.scss';
import { useGraphContext } from '../../plugins/NinjaCanvasPlugin/contexts/GraphContext';
import classNames from 'classnames';

const Placeholder = (
  <span className={styles.placeholderContent}>
    <span>Write anything or use</span>
    <ArrowCircleUpRight weight="fill" size={SVG_SIZE_M} />
    <span>in the chat thread to import content</span>
  </span>
);

interface EditorContainerProps {
  onRef: (_floatingAnchorElem: HTMLDivElement) => void;
}

export const EditorContainer = ({ onRef }: EditorContainerProps) => {
  const { graphData } = useGraphContext();
  const { isStreaming } = graphData;

  return (
    <RichTextPlugin
      contentEditable={
        <div
          ref={onRef}
          className={classNames(styles.container, {
            [styles.withOpacity]: isStreaming,
          })}
        >
          <ContentEditable
            className={styles.editorInput}
            aria-placeholder="Write anything or use icon in the chat thread to import content"
            placeholder={
              <div className={styles.placeholder}>{Placeholder}</div>
            }
          />
        </div>
      }
      ErrorBoundary={LexicalErrorBoundary}
    />
  );
};
