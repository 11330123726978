import { PortalWrapper } from 'src/components/PortalWrapper';
import { SpinnerSimple } from 'src/v2/commonComponents/SpinnerSimple';
import styles from './LoadingOverlay.module.scss';

interface LoadingOverlayProps {
  label: string;
}

export const LoadingOverlay = ({ label }: LoadingOverlayProps) => {
  return (
    <PortalWrapper>
      <div className={styles.root} data-e2e="loading-overlay">
        <div className={styles.loadingContainer}>
          <SpinnerSimple />
          <span className={styles.loadingTitle}>{label}</span>
        </div>
      </div>
    </PortalWrapper>
  );
};
