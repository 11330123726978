import { Check, Copy } from '@phosphor-icons/react';
import { toast } from 'react-toastify';
import { SVG_SIZE_M, CITATION_REGEX } from 'src/constants';
import { useCurrentMessage } from 'src/hooks';
import { Message, MessageType } from 'src/types';
import {
  getStringUrlListFromReferences,
  getTextContentFromMessage,
  getWebPageReferencesFromMessage,
} from 'src/utils';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './CopyMessageButton.module.scss';

type CopyMessageButtonProps = {
  messageId: string;
  disabled?: boolean;
  activeTab: string;
};

export const CopyMessageButton = ({
  messageId,
  disabled,
  activeTab,
}: CopyMessageButtonProps) => {
  const { currentMessage } = useCurrentMessage(messageId);

  const getContent = (message: Message) => {
    switch (message.message_type) {
      case MessageType.CODE_TASK_CREATION_CARD:
      case MessageType.RESEARCH_TASK_CREATION_CARD:
      case MessageType.CHAT_CARD:
        return getTextContentFromMessage({ message, activeTab });

      default:
        return message.content;
    }
  };

  const handleCopyClick = () => {
    if (!currentMessage) {
      return null;
    }

    const content = getContent(currentMessage);
    const updatedContent = content.replace(
      CITATION_REGEX,
      (_, number) => `[${number}]`,
    );

    const references = getWebPageReferencesFromMessage({
      message: currentMessage,
      activeTab,
    });

    const copiedContent = references
      ? `${updatedContent}${getStringUrlListFromReferences(references)}`
      : updatedContent;

    navigator.clipboard.writeText(copiedContent);

    toast(
      <div className={styles.content}>
        <Check size={SVG_SIZE_M} />
        <span>Copied</span>
      </div>,
    );
  };

  return (
    <Button
      color="transparent"
      onClick={handleCopyClick}
      disabled={disabled || !currentMessage}
      data-e2e="copy-button"
      tooltipContent="Copy"
      tooltipPlace="left"
    >
      <Copy size={SVG_SIZE_M} color="currentColor" />
    </Button>
  );
};
