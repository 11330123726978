/**
 * @deprecated
 */
export function trimInput(value: string): string {
  const newValue = value
    .replace(/<br\/>/gi, '')
    .replace(/<br>/gi, '')
    .replace(/\n/gi, '')
    .replace(/\r/gi, '')
    .replace(/\s{2, }/gi, ' ')
    .replace(/<div>/gi, '')
    .replace(/<\/div>/gi, '');
  return newValue === ' ' ? '' : newValue;
}

/**
 * getCaretPosition() gets caret position inside nested HTML.
 * @param contentEditable HTMLDivElement
 * @returns number
 */
export function getCaretPosition(contentEditable: HTMLDivElement): number {
  const selection = window.getSelection();

  if (!contentEditable || !selection || !selection.anchorNode) {
    return -1;
  }

  const anchorNode = selection.anchorNode;
  let caretPosition = selection.anchorOffset;

  function traverse(node: Node) {
    if (node === anchorNode) {
      return;
    }
    if (node.nodeType === Node.TEXT_NODE) {
      const textContent = node.textContent || '';
      if (caretPosition <= textContent.length) {
        return;
      }
      caretPosition -= textContent.length;
    } else {
      for (const childNode of Array.from(node.childNodes)) {
        traverse(childNode);
      }
    }
  }

  traverse(contentEditable);

  return caretPosition;
}
