import { isObject } from './general.guard';
import { CodeValuesType, DocumentValuesType } from '../fileList';

export function isCodeValuesType(data: unknown): data is CodeValuesType {
  const codeData = data as CodeValuesType;

  if (!isObject(codeData)) {
    return false;
  }

  if (
    codeData?.artifact?.contents?.[0] &&
    'language' in codeData?.artifact?.contents?.[0]
  ) {
    return true;
  }

  return false;
}

export function isDocumentValuesType(
  data: unknown,
): data is DocumentValuesType {
  const codeData = data as DocumentValuesType;

  if (!isObject(codeData)) {
    return false;
  }

  if (
    codeData?.artifact?.contents?.[0] &&
    'markdown' in codeData?.artifact?.contents?.[0]
  ) {
    return true;
  }

  return false;
}
