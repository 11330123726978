import { ImageGenStyles } from 'src/types';
import { useSession, useUserSettings } from 'src/hooks';
import { imageStyleOptions } from 'src/constants';
import styles from './ImageStyleSelect.module.scss';
import classNames from 'classnames';
import { LabelPro } from 'src/components/LabelPro';
import { Button } from 'src/v2/commonComponents/Button';

import { SettingKeys, SingleSelectSetting } from 'src/types/settings';

interface ImageStyleSelectProps {
  selectedValue?: string;
  onChange?: (value: string) => void;
  maxMenuHeight?: number;
}

export const ImageStyleSelect = ({
  selectedValue,
  onChange,
}: ImageStyleSelectProps) => {
  const { isOpenTier, isFreeTier } = useSession();

  const { setting, updateUserSetting } = useUserSettings<SingleSelectSetting>(
    SettingKeys.IMAGE_STYLES,
  );

  const isDisabled = isOpenTier || isFreeTier;

  const value = selectedValue
    ? selectedValue
    : setting?.value || ImageGenStyles.NONE;

  const handleChange = (newValue: ImageGenStyles) => {
    if (newValue !== value) {
      if (!!onChange) {
        onChange(newValue);
      } else {
        updateUserSetting(newValue);
      }
    }
  };

  return (
    <div className={classNames(styles.root, { [styles.disabled]: isDisabled })}>
      <label className={styles.label}>
        <span>Style</span>
        {isDisabled && <LabelPro />}
      </label>

      <div className={styles.options}>
        {imageStyleOptions.map(({ value: key, label }) => {
          const isOptionDisabled = !setting?.options[key]?.selectable;
          return (
            <Button
              key={key}
              disabled={isOptionDisabled}
              onClick={() => handleChange(key as ImageGenStyles)}
              className={classNames(styles.button, {
                [styles.active]: key === value,
              })}
            >
              <span className={styles.optionTitle}>{label}</span>
            </Button>
          );
        })}
      </div>
    </div>
  );
};
