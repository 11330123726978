import {
  useEffect,
  useRef,
  useState,
  RefObject,
  useCallback,
  useContext,
} from 'react';
import cn from 'classnames';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import styles from './CanvasEditor.module.scss';
import { AiGenerationStatus, ImageGenRenderData } from 'src/types';
import {
  useBreakpoint,
  useCurrentImagesList,
  useRightSidePanelActions,
  useSelectedImageIndex,
  useImageMaskingPanelActions,
  useVisible,
  useAttachedImageTooltipState,
  useAttachedImageTooltipActions,
} from 'src/hooks';
import { MobileModalImagesList } from '../MobileModalImagesList';
import { NavigationButtons } from '../NavigationButtons';
import { Canvas } from '../Canvas';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { MediaModalError } from 'src/v2/components/MediaModalError';
import { ImageGenData } from 'src/types/models/ImageGenData';
import { DisabledImageOverlay } from '../DisabledImageOverlay';
import { SpinnerSimple } from 'src/v2/commonComponents/SpinnerSimple';
import { useHotkeys } from 'react-hotkeys-hook';

type CanvasEditorProps = {
  brushSize: number;
  currentMaskingImageData: ImageGenRenderData | null;
  pushToUndoStack: (state: { mainCanvas: string; maskCanvas: string }) => void;
  isEditingMode: boolean;
  maskCanvasRef: RefObject<HTMLCanvasElement>;
  mainCanvasRef: RefObject<HTMLCanvasElement>;
  incrementSelectionsCount: () => void;
  addBlackBgAndSaveMask: () => void;
  scale: number;
  setScale: (scale: number) => void;
};

export const CanvasEditor = ({
  brushSize,
  currentMaskingImageData,
  pushToUndoStack,
  isEditingMode,
  maskCanvasRef,
  mainCanvasRef,
  incrementSelectionsCount,
  addBlackBgAndSaveMask,
  scale,
  setScale,
}: CanvasEditorProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const customCursorRef = useRef<HTMLDivElement | null>(null);
  const swiperRef = useRef<SwiperRef | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const [displayedWidth, setDisplayedWidth] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [displayedHeight, setDisplayedHeight] = useState(0);
  const [isCanvasVisible, setCanvasVisible] = useState(false);
  const { isMobile, isMobileOrTablet, isDesktop } = useBreakpoint();
  const { currentImagesList } = useCurrentImagesList();
  const { selectedImageIndex } = useSelectedImageIndex();
  const { resetAndCloseRightSidePanelData } = useRightSidePanelActions();
  const {
    onChangeSelectedImageIndex,
    onChangeImageMaskingRightPanelData,
    onToggleIsOpenModalImagesList,
    resetImageMaskingPanelInfo,
  } = useImageMaskingPanelActions();
  const {
    isVisible: isOpenMobileListPanel,
    onVisibilityRemove: closeMobileListPanel,
    onVisibilityToggle: toggleIsOpenMobileListPanel,
  } = useVisible();

  const { isAttachedImageEditingMode } = useAttachedImageTooltipState();
  const {
    onToggleImageEditTooltipVisible,
    onChangeIsAttachedImageEditingMode,
  } = useAttachedImageTooltipActions();

  const { onClearAttachments } = useContext(ThreadInputBoxContext);

  useEffect(() => {
    setCanvasVisible(isEditingMode);
  }, [isEditingMode]);

  // useEffect(() => {
  //   return () => {
  //     resetAndCloseRightSidePanelData();
  //   };
  // }, [resetAndCloseRightSidePanelData]);

  const handleToggleModalImagesList = useCallback(() => {
    toggleIsOpenMobileListPanel();
    onToggleIsOpenModalImagesList();
  }, [onToggleIsOpenModalImagesList, toggleIsOpenMobileListPanel]);

  const handleSliderNavigate = useCallback(
    (index: number) => {
      onChangeSelectedImageIndex(index);
      onChangeImageMaskingRightPanelData(currentImagesList[index]);
      resetImageMaskingPanelInfo();
      onClearAttachments();
    },
    [
      currentImagesList,
      onChangeImageMaskingRightPanelData,
      onChangeSelectedImageIndex,
      onClearAttachments,
      resetImageMaskingPanelInfo,
    ],
  );

  const updateLayout = useCallback(() => {
    if (
      !containerRef.current ||
      !imgRef.current ||
      !mainCanvasRef.current ||
      !maskCanvasRef.current
    ) {
      return;
    }

    const containerRect = containerRef.current.getBoundingClientRect();
    const W = containerRect.width;
    let H = containerRect.height;

    if (isMobileOrTablet) {
      H -= 370;
    } else {
      H -= 100;
    }

    const naturalW = imgRef.current.naturalWidth;
    const naturalH = imgRef.current.naturalHeight;

    if (!naturalW || !naturalH) {
      return;
    }

    const scaleValue = Math.min(W / naturalW, H / naturalH);
    const newWidth = naturalW * scaleValue;
    const newHeight = naturalH * scaleValue;

    let mainCanvasWidth = newWidth;
    const navigationButtonsWidth = 144;
    const isHorizontal = naturalW > naturalH && !isMobileOrTablet;

    if (isHorizontal && isDesktop) {
      mainCanvasWidth -= navigationButtonsWidth;
    }

    const mainCanvasHeight = newHeight;
    const offsetLeftCanvas = (W - mainCanvasWidth) / 2;

    setDisplayedWidth(mainCanvasWidth);
    setDisplayedHeight(mainCanvasHeight);
    setOffsetLeft(offsetLeftCanvas);

    const mainCanvasScale =
      (mainCanvasWidth / (naturalW * scaleValue)) * scaleValue;
    setScale(mainCanvasScale);

    mainCanvasRef.current.width = mainCanvasWidth;
    mainCanvasRef.current.height = mainCanvasHeight;

    maskCanvasRef.current.width = naturalW;
    maskCanvasRef.current.height = naturalH;
  }, [isMobileOrTablet, mainCanvasRef, maskCanvasRef, isDesktop, setScale]);

  useEffect(() => {
    const handleResize = () => {
      resetAndCloseRightSidePanelData();

      if (isAttachedImageEditingMode) {
        onToggleImageEditTooltipVisible(true);
        onChangeIsAttachedImageEditingMode(false);
      }
    };

    window.addEventListener('resize', handleResize);

    const onImgLoad = () => {
      updateLayout();
    };

    imgRef?.current?.addEventListener('load', onImgLoad);

    updateLayout();

    return () => {
      window.removeEventListener('resize', handleResize);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      imgRef?.current?.removeEventListener('load', onImgLoad);
    };
  }, [
    resetAndCloseRightSidePanelData,
    updateLayout,
    currentMaskingImageData?.url,
    isAttachedImageEditingMode,
    onToggleImageEditTooltipVisible,
    onChangeIsAttachedImageEditingMode,
  ]);

  const handleSlideChange = useCallback(() => {
    const direction = swiperRef?.current?.swiper.swipeDirection;

    if (direction === 'next') {
      if (selectedImageIndex + 1 !== currentImagesList.length) {
        handleSliderNavigate(selectedImageIndex + 1);
      }
    } else if (direction === 'prev') {
      if (selectedImageIndex !== 0) {
        handleSliderNavigate(selectedImageIndex - 1);
      }
    }
  }, [currentImagesList, handleSliderNavigate, selectedImageIndex]);

  useHotkeys('esc', () => {
    if (!isEditingMode) {
      handleSliderNavigate(0);
      resetAndCloseRightSidePanelData();
    }
  });

  useHotkeys('left', () => {
    if (selectedImageIndex !== 0 && !isEditingMode) {
      handleSliderNavigate(selectedImageIndex - 1);
    }
  });

  useHotkeys('right', () => {
    if (selectedImageIndex + 1 !== currentImagesList.length && !isEditingMode) {
      handleSliderNavigate(selectedImageIndex + 1);
    }
  });

  return (
    <div ref={containerRef} className={styles.root}>
      <div>
        <div className={styles.canvasContainer}>
          <Canvas
            currentMaskingImageData={currentMaskingImageData}
            imgRef={imgRef}
            mainCanvasRef={mainCanvasRef}
            maskCanvasRef={maskCanvasRef}
            customCursorRef={customCursorRef}
            isEditingMode={isEditingMode}
            scale={scale}
            brushSize={brushSize}
            addBlackBgAndSaveMask={addBlackBgAndSaveMask}
            pushToUndoStack={pushToUndoStack}
            incrementSelectionsCount={incrementSelectionsCount}
            displayedHeight={displayedHeight}
            displayedWidth={displayedWidth}
            offsetLeft={offsetLeft}
            isCanvasVisible={isCanvasVisible}
          />

          {!currentMaskingImageData?.url &&
            currentMaskingImageData?.status === AiGenerationStatus.SUCCESS && (
              <div className={styles.spinner}>
                <SpinnerSimple />
              </div>
            )}

          {!isEditingMode && (
            <Swiper
              centeredSlides={true}
              spaceBetween={50}
              slidesPerView={1}
              initialSlide={selectedImageIndex}
              simulateTouch={isMobileOrTablet}
              direction="horizontal"
              ref={swiperRef}
              onSlideChange={handleSlideChange}
              allowSlideNext
              allowSlidePrev
              allowTouchMove
              style={{
                width: '100%',
                height: displayedHeight,
                zIndex: 3,
                pointerEvents: isEditingMode ? 'none' : 'auto',
              }}
            >
              {currentImagesList.map((item, i) => (
                <SwiperSlide
                  key={`${item.url}_${i}`}
                  className={isEditingMode ? styles.editingModeSlide : ''}
                >
                  {currentMaskingImageData?.isDisabled && (
                    <DisabledImageOverlay />
                  )}

                  {currentMaskingImageData?.status ===
                    AiGenerationStatus.SUCCESS && (
                    <img
                      src={currentMaskingImageData?.url || ''}
                      alt="background-image"
                      className={cn(styles.swiperImage, {
                        [styles.nonVisible]:
                          currentMaskingImageData?.status !==
                          AiGenerationStatus.SUCCESS,
                      })}
                      style={{
                        left: 0,
                        top: 0,
                        width: displayedWidth || '100%',
                        height: displayedHeight || '100%',
                      }}
                    />
                  )}

                  {(currentMaskingImageData?.status ===
                    AiGenerationStatus.CONTENT_POLICY_VIOLATION ||
                    currentMaskingImageData?.status ===
                      AiGenerationStatus.FAILURE) && (
                    <MediaModalError
                      status={
                        (currentMaskingImageData as ImageGenData)?.status ||
                        undefined
                      }
                      mediaType="image"
                      width="100%"
                      height={isMobile ? '50%' : '80%'}
                    />
                  )}
                </SwiperSlide>
              ))}

              {!isAttachedImageEditingMode && !isMobileOrTablet && (
                <NavigationButtons
                  handleSliderNavigate={handleSliderNavigate}
                  handleToggleModalImagesList={handleToggleModalImagesList}
                />
              )}
            </Swiper>
          )}
        </div>
      </div>

      {!isAttachedImageEditingMode && isMobileOrTablet && (
        <div className={styles.navWrapper}>
          <NavigationButtons
            handleSliderNavigate={handleSliderNavigate}
            handleToggleModalImagesList={handleToggleModalImagesList}
          />
        </div>
      )}

      {isMobileOrTablet && (
        <MobileModalImagesList
          isOpenMobileListPanel={isOpenMobileListPanel}
          closeMobileListPanel={closeMobileListPanel}
        />
      )}
    </div>
  );
};
