import { LexicalCommand } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Icon as PhosphorIcon } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './ToolbarButton.module.scss';

interface ToolbarButtonProps<T> {
  command: LexicalCommand<T>;
  payload: T;
  Icon: PhosphorIcon;
  isActive?: boolean;
  disabled?: boolean;
  bold?: boolean;
  onClick?: () => void;
  tooltipContent?: string;
}

export const ToolbarButton = <T,>({
  command,
  payload,
  Icon,
  isActive,
  disabled,
  tooltipContent,
  bold,
  onClick,
}: ToolbarButtonProps<T>) => {
  const [editor] = useLexicalComposerContext();

  const handleClick = () => {
    editor.dispatchCommand(command, payload);
    onClick?.();
  };

  return (
    <Button
      shape="round"
      color={isActive ? 'tertiary' : 'transparent'}
      onClick={handleClick}
      disabled={disabled}
      className={styles.root}
      tooltipContent={tooltipContent}
    >
      <Icon size={SVG_SIZE_M} weight={bold ? 'bold' : 'regular'} />
    </Button>
  );
};
