import { useMemo } from 'react';
import { useAppSelector, interfaceControlState } from 'src/store';
import { RightPanelSections } from 'src/types';

// TODO(olha): rename to useRightPanelState
export const useRightSidePanelState = () => {
  const { isRightPanelExpanded, rightPanelSections, isRightPanelFullWidth } =
    useAppSelector(interfaceControlState);

  return useMemo(
    () => ({
      isRightPanelExpanded,
      isImageEditSectionExpanded:
        isRightPanelExpanded && rightPanelSections === RightPanelSections.IMAGE,
      isEditorPanelExpanded:
        isRightPanelExpanded &&
        rightPanelSections === RightPanelSections.EDITOR,
      rightPanelSections,
      isRightPanelFullWidth,
    }),
    [isRightPanelExpanded, rightPanelSections, isRightPanelFullWidth],
  );
};
