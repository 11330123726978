import { useMemo, useRef } from 'react';
import {
  useCurrentConversationTasks,
  useCurrentMessage,
  useCustomNinjaModelName,
  useLeftPanelActions,
  useUserMetadata,
} from 'src/hooks';
import {
  TaskState,
  ResearchTaskCreationCard,
  SchedulerCreationCard,
  ChitChatCard,
  ImageCard,
  CodeTaskCreationCard,
  VideoCard,
  MessageType,
  MessageSkill,
  LeftPanelSections,
  isChitChatCard,
  TaskSkill,
  isResearchTaskCreationCard,
  EditorType,
} from 'src/types';
import { getSkillFromMessage, isBetaEnv } from 'src/utils';
// import { LatestProgressAction } from '../LatestProgressAction';
import { ThreadMessageActions } from 'src/components/FlatAppearance/components/ThreadMessageActions';
import styles from './MessageWithTaskProgress.module.scss';
import { DotOutline, Sliders } from '@phosphor-icons/react';
import {
  newExternalModelsList,
  SUPER_AGENT_DEEP_RESEARCH_MODEL,
  SVG_SIZE_M,
} from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import { MoveToEditorTool } from '../MoveToEditorTool';

interface MessageWithTaskProgressProps {
  messageId: string;
  payload:
    | ResearchTaskCreationCard
    | SchedulerCreationCard
    | CodeTaskCreationCard
    | ChitChatCard
    | ImageCard
    | VideoCard;
  activeTab: string;
  isFinalMessageChunk?: boolean;
}

const MessagesWithSettings = [
  MessageType.RESEARCH_TASK_CREATION_CARD,
  MessageType.CHAT_CARD,
  MessageType.IMAGE_CARD,
];

export const MessageWithTaskProgress = ({
  messageId,
  payload,
  activeTab,
  isFinalMessageChunk,
}: MessageWithTaskProgressProps) => {
  const containerRef = useRef<HTMLParagraphElement>(null);

  const { currentMessage } = useCurrentMessage(messageId);
  const { getTaskFromConversationById } = useCurrentConversationTasks();
  const { metadata } = useUserMetadata();
  const { setLeftPanelData } = useLeftPanelActions();
  const { customModelName } = useCustomNinjaModelName(messageId);

  const {
    task_id,
    // progress
  } = payload;

  const taskState = useMemo(() => {
    return getTaskFromConversationById(task_id)?.state || TaskState.IN_PROGRESS;
  }, [getTaskFromConversationById, task_id]);

  const skill = useMemo(
    () => getSkillFromMessage(currentMessage),
    [currentMessage],
  );

  const responseAgent = useMemo(() => {
    switch (currentMessage?.message_type) {
      case MessageType.RESEARCH_TASK_CREATION_CARD:
      case MessageType.CHAT_CARD:
      case MessageType.IMAGE_CARD:
        const modelName = currentMessage?.model_ids
          ? currentMessage?.model_ids[0]
          : '';
        return modelName
          ? metadata[modelName]?.display_name || ''
          : newExternalModelsList[modelName]?.display_name || '';
      default:
        return '';
    }
  }, [currentMessage, metadata]);

  const agentTitle = useMemo(
    () => customModelName || responseAgent,
    [customModelName, responseAgent],
  );

  const handleGoToSettingsClick = () => {
    setLeftPanelData({
      panelType: LeftPanelSections.SETTINGS,
      isExpanded: true,
    });
  };

  const shouldShowSettingsButton = useMemo(
    () =>
      !!currentMessage?.message_type &&
      MessagesWithSettings.includes(currentMessage?.message_type) &&
      !(currentMessage?.payload as ImageCard)?.watermarked_images &&
      !(
        (currentMessage?.payload as ResearchTaskCreationCard)?.research_card
          ?.data?.deep_research_result?.model ===
        SUPER_AGENT_DEEP_RESEARCH_MODEL
      ),
    [currentMessage?.message_type, currentMessage?.payload],
  );

  const isEditorToolHidden =
    (isChitChatCard(currentMessage?.payload) &&
      currentMessage?.payload.data?.skill !== TaskSkill.CHITCHAT) ||
    (!isResearchTaskCreationCard(currentMessage?.payload) &&
      !isChitChatCard(currentMessage?.payload));

  if (!task_id) {
    return null;
  }

  return (
    <div className={styles.rootProgress}>
      <div className={styles.root}>
        <div className={styles.infoRowWrapper} ref={containerRef}>
          {agentTitle && (
            <>
              <span className={styles.modelName}>{agentTitle}</span>
              <DotOutline size={SVG_SIZE_M} weight="fill" />
            </>
          )}
          <span className={styles.skillsLabel}>/{skill}</span>
          {shouldShowSettingsButton && (
            <Button
              color="transparent"
              shape="round"
              onClick={handleGoToSettingsClick}
              className={styles.settingsButton}
            >
              <Sliders size={SVG_SIZE_M} />
            </Button>
          )}
        </div>

        <ThreadMessageActions
          taskId={task_id}
          taskState={taskState}
          showStopButton={skill === MessageSkill.SCHEDULER}
        />
      </div>
      {/*<LatestProgressAction*/}
      {/*  messageId={messageId}*/}
      {/*  actions={progress?.actions}*/}
      {/*  skill={skill}*/}
      {/*  taskState={taskState}*/}
      {/*/>*/}

      {isBetaEnv && !isEditorToolHidden && (
        <MoveToEditorTool
          messageId={messageId}
          disabled={!isFinalMessageChunk}
          activeTab={activeTab}
          componentType={EditorType.DOCUMENT}
        />
      )}
    </div>
  );
};
