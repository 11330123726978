import dayjs from 'dayjs';
import { useMemo } from 'react';
import type { Thread } from '@langchain/langgraph-sdk';
import { DEFAULT_DATE_FORMAT } from 'src/constants';
import type { CodeValuesType, DocumentValuesType } from 'src/types';
import { FileListGroupedByDate } from './components/FileListGroupedByDate';
import styles from './FileList.module.scss';
import { EmptyState } from '../EmptyState';
import { useThreadContext } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/contexts/ThreadProvider';
import { SpinnerSimple } from 'src/v2/commonComponents/SpinnerSimple';

export const FileList = () => {
  const { userThreads, isUserThreadsLoading } = useThreadContext();

  const groupedDocumentListByDate: Record<
    string,
    Thread<DocumentValuesType | CodeValuesType>[]
  > = useMemo(() => {
    const groupedData: Record<
      string,
      Thread<DocumentValuesType | CodeValuesType>[]
    > = {};

    if (userThreads.length === 0) {
      return groupedData;
    }

    for (const documentListItem of userThreads as Thread<
      DocumentValuesType | CodeValuesType
    >[]) {
      const groupedDate = dayjs(documentListItem.updated_at).format(
        DEFAULT_DATE_FORMAT,
      );

      if (!groupedData[groupedDate]) {
        groupedData[groupedDate] = [documentListItem];
      } else {
        groupedData[groupedDate] = [
          ...groupedData[groupedDate],
          documentListItem,
        ];
      }
    }

    return groupedData;
  }, [userThreads]);

  const documentList = Object.entries(groupedDocumentListByDate);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {!documentList.length && !isUserThreadsLoading && <EmptyState />}

        {isUserThreadsLoading && (
          <div className={styles.spinnerWrapper}>
            <SpinnerSimple />
          </div>
        )}

        {!isUserThreadsLoading && documentList.length ? (
          <div className={styles.documents}>
            {documentList.map(([date, group]) => (
              <FileListGroupedByDate
                key={date}
                date={date}
                groupedDocuments={group}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};
