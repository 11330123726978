import classNames from 'classnames';
import { TaskSkill } from 'src/types';
import { useMemo } from 'react';
import { getReadableTaskSkillName } from 'src/utils';

interface SkillLabelProps {
  skill?: TaskSkill | string;
  size?: 'small' | 'medium';
}

export const SkillLabel = ({ skill, size = 'small' }: SkillLabelProps) => {
  const labelText = useMemo(() => getReadableTaskSkillName(skill), [skill]);

  if (!skill) {
    return null;
  }

  if (
    skill !== TaskSkill.RESEARCH &&
    skill !== TaskSkill.SCHEDULING &&
    skill !== TaskSkill.CODING &&
    skill !== TaskSkill.CHITCHAT &&
    skill !== TaskSkill.IMAGE &&
    skill !== TaskSkill.VIDEO &&
    skill !== TaskSkill.DOCUMENT_QA &&
    skill !== TaskSkill.DEEP_RESEARCH
  ) {
    return null;
  }

  return (
    <span
      className={classNames('nj-skill-label', size, {
        research: skill === TaskSkill.RESEARCH,
        scheduling: skill === TaskSkill.SCHEDULING,
        coding: skill === TaskSkill.CODING,
        chitchat: skill === TaskSkill.CHITCHAT,
        image: skill === TaskSkill.IMAGE,
        video: skill === TaskSkill.VIDEO,
        file: skill === TaskSkill.DOCUMENT_QA,
        deepResearch: skill === TaskSkill.DEEP_RESEARCH,
      })}
    >
      /{labelText}
    </span>
  );
};
