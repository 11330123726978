import {
  activeModelState,
  sessionState,
  setActiveModel,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { useCallback, useEffect, useMemo } from 'react';
import { useCurrentConversation } from 'src/hooks';
import { useUpdateConversationByIdMutation } from 'src/store/services';
import { ConversationUpdateRequest } from 'src/types';
import log from 'src/utils/logger';
import { NINJA_AGENT_TEXT_AUTO_MODEL } from 'src/constants';

export const useActiveModel = () => {
  const { activeModel } = useAppSelector(activeModelState);
  const dispatch = useAppDispatch();
  const { currentConversation } = useCurrentConversation();

  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);

  const [updateConversationById] = useUpdateConversationByIdMutation();

  const updateConversation = useCallback(
    async (conversation: Omit<ConversationUpdateRequest, 'user_id'>) => {
      try {
        await updateConversationById({ user_id, ...conversation }).unwrap();
      } catch (error) {
        log.error(error);
      }
    },
    [updateConversationById, user_id],
  );

  const changeActiveModel = useCallback(
    (value?: string) => {
      dispatch(setActiveModel(value));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!!currentConversation) {
      if (!!currentConversation.model_ids) {
        changeActiveModel(
          currentConversation.model_ids[0] !== NINJA_AGENT_TEXT_AUTO_MODEL
            ? currentConversation.model_ids[0]
            : undefined,
        );
      } else {
        changeActiveModel(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConversation?.model_ids]);

  const changeCurrentModel = useCallback(
    (value?: string) => {
      changeActiveModel(value);
      if (!!currentConversation) {
        updateConversation({
          conversation_id: currentConversation?.conversation_id || '',
          model_ids: value ? [value] : undefined,
        });
      }
    },
    [currentConversation, changeActiveModel, updateConversation],
  );

  return useMemo(
    () => ({
      activeModel,
      changeActiveModel,
      changeCurrentModel,
    }),
    [changeCurrentModel, activeModel, changeActiveModel],
  );
};
