import { useMemo } from 'react';
import { useAppSelector, editorState } from 'src/store';

export const useEditorState = () => {
  const {
    editorType,
    codeEditorData,
    isWordWrapEnabled,
    isEditorDarkTheme,
    shouldResetEditorContent,
  } = useAppSelector(editorState);

  return useMemo(
    () => ({
      isEditorDarkTheme,
      editorType,
      codeEditorData,
      isWordWrapEnabled,
      shouldResetEditorContent,
    }),
    [
      editorType,
      codeEditorData,
      isWordWrapEnabled,
      isEditorDarkTheme,
      shouldResetEditorContent,
    ],
  );
};
