import { useCallback } from 'react';
import {
  useAppDispatch,
  setThreadStatus,
  setMessageTabPersistRegistry,
} from 'src/store';
import { ThreadsStatusRegistryPayload } from 'src/store/slices/threadSlice';
import { MessageTabPersistRegistryPayload } from 'src/store/slices/threadPersistSlice';

export const useThreadActions = () => {
  const dispatch = useAppDispatch();

  const updateThreadStatus = useCallback(
    ({ threadId, statusRegistry }: ThreadsStatusRegistryPayload) => {
      dispatch(
        setThreadStatus({
          threadId,
          statusRegistry,
        }),
      );
    },
    [dispatch],
  );

  const updateMessageTabRegistry = useCallback(
    ({ messageId, tabName, isRead }: MessageTabPersistRegistryPayload) => {
      dispatch(
        setMessageTabPersistRegistry({
          messageId,
          tabName,
          isRead,
        }),
      );
    },
    [dispatch],
  );

  return {
    updateThreadStatus,
    updateMessageTabRegistry,
  };
};
