import { useMemo, useState } from 'react';
import { TextareaSimple } from 'src/v2/commonComponents/TextareaSimple';
import { Button } from 'src/v2/commonComponents/Button';
import { MagicWand } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import styles from './PromptForm.module.scss';
import classNames from 'classnames';
import { useRightSidePanelActions, useRightSidePanelState } from 'src/hooks';
import { validatePrompt } from '../../utils';
import { useGraphContext } from '../../plugins/NinjaCanvasPlugin/contexts/GraphContext';

interface PromptFormProps {
  placeholder: string;
  onSubmit: (userPrompt: string) => Promise<void>;
}

// TODO(olha): WIP Editor create a common component with PromptInputBox for input
export const PromptForm = ({ placeholder, onSubmit }: PromptFormProps) => {
  const { graphData } = useGraphContext();
  const { isStreaming } = graphData;

  const [value, setValue] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isFocused, setFocused] = useState<boolean>(false);

  const { isRightPanelFullWidth } = useRightSidePanelState();
  const { toggleRightSidePanelFullWidth } = useRightSidePanelActions();

  const clearedValue = useMemo(() => value.trim(), [value]);

  const isSubmitDisabled = useMemo(
    () => !clearedValue || isLoading || isStreaming,
    [clearedValue, isLoading, isStreaming],
  );

  const handleChange = (value: string) => {
    setErrorText('');
    const { validatedValue, isError, wordLimit } = validatePrompt(value);
    setValue(validatedValue);

    if (isError) {
      const errorText = `Word limit: ${new Intl.NumberFormat('en-US').format(wordLimit)}.`;
      setErrorText(errorText);
    }
  };

  const handleSubmit = async () => {
    if (isSubmitDisabled) {
      return;
    }

    setLoading(true);
    setValue('');

    if (!isRightPanelFullWidth) {
      toggleRightSidePanelFullWidth();
    }

    await onSubmit(value);
    setLoading(false);
  };

  return (
    <div
      className={classNames(styles.root, {
        [styles.focused]: isFocused,
        [styles.error]: !!errorText,
      })}
    >
      <TextareaSimple
        className={styles.textarea}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        maxRows={4}
        onFocus={() => setFocused(true)}
        onSubmit={handleSubmit}
      />

      <Button
        disabled={isSubmitDisabled}
        shape="round"
        color={isFocused ? 'primary' : 'gray'}
        onClick={handleSubmit}
      >
        <MagicWand size={SVG_SIZE_M} />
      </Button>

      {errorText && <p className={styles.errorText}>{errorText}</p>}
    </div>
  );
};
