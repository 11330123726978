import {
  RANDOM_DEEP_RESEARCHER_VIDEOS,
  RANDOM_VIDEOS,
} from 'src/constants/externalLinks';

export const getRandomVideo = () => {
  return RANDOM_VIDEOS[Math.floor(Math.random() * RANDOM_VIDEOS.length)];
};

export const getRandomDeepResearcherVideos = () => {
  return RANDOM_DEEP_RESEARCHER_VIDEOS[
    Math.floor(Math.random() * RANDOM_DEEP_RESEARCHER_VIDEOS.length)
  ];
};
