import { NINJA_AGENT_TITLE, NINJA_AUTO_MODELS } from 'src/constants';
import { useCurrentMessage } from './useCurrentMessage';
import { useMemo } from 'react';
import { getSkillFromMessage } from 'src/utils';
import { MessageSkill } from 'src/types';

const SKILL_EXCEPTIONS = [MessageSkill.FILE];

interface CustomModelName {
  customModelName: string | null;
}

export const useCustomNinjaModelName = (messageId: string): CustomModelName => {
  const { currentMessage } = useCurrentMessage(messageId);

  const customModelName = useMemo(() => {
    if (!currentMessage) {
      return null;
    }

    const currentModelId = currentMessage.model_ids?.[0];

    const skill = getSkillFromMessage(currentMessage);

    if (!currentModelId && skill && SKILL_EXCEPTIONS.includes(skill)) {
      return NINJA_AGENT_TITLE;
    }

    return currentModelId && NINJA_AUTO_MODELS.includes(currentModelId)
      ? NINJA_AGENT_TITLE
      : null;
  }, [currentMessage]);

  return { customModelName };
};
