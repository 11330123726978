import { MoonStars } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import { useEditorActions, useEditorState } from 'src/hooks';
import styles from './DarkThemeButton.module.scss';

export const DarkThemeButton = () => {
  const { setEditorIsDarkTheme } = useEditorActions();
  const { isEditorDarkTheme } = useEditorState();

  const toggleDarkTheme = () => {
    setEditorIsDarkTheme(!isEditorDarkTheme);
  };

  return (
    <Button
      color={isEditorDarkTheme ? 'tertiary' : 'transparent'}
      onClick={toggleDarkTheme}
      className={styles.root}
    >
      <MoonStars size={SVG_SIZE_M} />
    </Button>
  );
};
