import { useMemo } from 'react';
import { useCurrentConversationMessages } from '../conversationHooks/useCurrentConversationMessages';

export const useCurrentMessage = (messageId: string) => {
  const { conversationMessagesMap } = useCurrentConversationMessages();

  const currentMessage = useMemo(
    () =>
      messageId ? conversationMessagesMap[messageId] || undefined : undefined,
    [conversationMessagesMap, messageId],
  );

  return useMemo(
    () => ({
      currentMessage,
    }),
    [currentMessage],
  );
};
