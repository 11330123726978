import styles from './ResolutionSlider.module.scss';
import { SegmentedControl } from 'src/v2/components/SegmentedControl';
import { useUserSettings } from 'src/hooks';
import { SettingKeys, SingleSelectSetting } from 'src/types/settings';
import { availableInLabel } from 'src/constants';

interface ResolutionSliderProps {
  isVisible?: boolean;
  onChange?: (value: string) => void;
  selectedValue?: string;
}

export const ResolutionSlider = ({
  isVisible,
  selectedValue,
  onChange,
}: ResolutionSliderProps) => {
  const { setting, updateUserSetting } = useUserSettings<SingleSelectSetting>(
    SettingKeys.IMAGE_SIZES,
  );

  const value = selectedValue ? selectedValue : setting?.value || '';

  const options = Object.entries(setting?.options || {}).map(([key, data]) => ({
    label: key.toUpperCase()[0],
    value: key,
    disabled: !data.selectable,
    availableIn: data.requires,
    availableLabel: data.requires ? availableInLabel[data.requires] : null,
  }));

  const handleSegmentedChange = (newValue: string) => {
    if (newValue !== value) {
      if (!!onChange) {
        onChange(newValue);
      } else {
        updateUserSetting(newValue);
      }
    }
  };

  return (
    <div className={styles.root}>
      <SegmentedControl
        options={options}
        isVisible={isVisible}
        activeValue={value}
        onChange={handleSegmentedChange}
      />

      <div className={styles.sizeLabels}>
        {options.map(({ availableLabel, value }) => {
          return (
            <div key={value} className={styles.sizeLabel}>
              {availableLabel}
            </div>
          );
        })}
      </div>
    </div>
  );
};
