import dayjs from 'dayjs';
import { NinjaSubscriptionPlan, WelcomeUpsellModalType } from 'src/types';
import { UserTier } from 'src/types/models/UserTier';

/**
 * @param amount in cents
 * @param discount in dollars
 * @returns price with discount
 */
export const getNewAmountWithDiscount = (amount: number, discount: number) => {
  return amount / 100 - discount;
};

export const getCalculatedSale = (amount: number, discount: number) => {
  return Math.ceil((discount * 100) / (amount / 100));
};

export const getNextUpsellModalTimestamp = (delay: number) => {
  return dayjs().valueOf() + delay;
};

/**
 * @deprecated
 */
export const getUpsellModalType = (
  userTier?: UserTier | null,
): WelcomeUpsellModalType | null => {
  // if (userTier === UserTier.OPEN) {
  //   return 'open-tier-upsell-modal';
  // }
  // if (userTier === UserTier.FREE) {
  //   return 'free-tier-upsell-modal';
  // }
  // if (userTier === UserTier.PRO_TRIAL) {
  //   return 'pro-trial-upsell-modal';
  // }

  return null;
};

export const defineIsUpgrade = (
  currentTier: UserTier,
  newTier: NinjaSubscriptionPlan,
) => {
  if (currentTier === UserTier.ULTRA) {
    // check for empty string as newTier could be undefined with new approach to get plan info by seats change
    return (newTier || '').includes('team');
  }
  if (currentTier === UserTier.TEAMS_ULTRA) {
    return false;
  }
  if (
    currentTier === UserTier.STANDARD ||
    currentTier === UserTier.TEAMS_STANDARD
  ) {
    return true;
  }

  if (currentTier === UserTier.PAID || currentTier === UserTier.PRO) {
    return newTier !== NinjaSubscriptionPlan.STANDARD;
  }

  if (currentTier === UserTier.TEAMS_PRO) {
    return newTier === NinjaSubscriptionPlan.TEAMS_ULTRA;
  }
};

export const isAppleOrAmpSource = (source?: string) => {
  return (
    source === 'apple' || source === 'amp' || source === 'google_play_store'
  );
};

export const getNextBillingCycleDate = (date?: string | null) => {
  return `, starting next billing cycle${!!date ? ` - ${dayjs(date).format('MMM D, YYYY')}` : ''}`;
};
