import type { BaseMessage } from '@langchain/core/messages';
import { ConversationRole } from 'src/types';
import { useGraphContext } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/contexts/GraphContext';
import { PromptListItem } from '../PromptListItem';
import styles from './PromptList.module.scss';

export const PromptList = () => {
  const { graphData } = useGraphContext();
  const messages = graphData.messages;

  return (
    <div className={styles.root}>
      {[...messages].reverse().map((message: BaseMessage) => {
        if (message?.constructor?.name === 'Object') {
          return (
            <PromptListItem
              key={message.id}
              role={
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                message.type === 'human'
                  ? ConversationRole.USER
                  : ConversationRole.AGENT
              }
              content={message.content as string}
              model={message.additional_kwargs?.model as string}
            />
          );
        }
        return (
          typeof message.content === 'string' && (
            <PromptListItem
              key={message.id}
              role={
                message.getType() === 'human'
                  ? ConversationRole.USER
                  : ConversationRole.AGENT
              }
              content={message.content}
              model={message.additional_kwargs?.model as string}
            />
          )
        );
      })}
    </div>
  );
};
