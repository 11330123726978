import { useMemo } from 'react';
import {
  useMessageTab,
  useSession,
  useExternalModels,
  useMessageSwitchTabs,
  useTeamInfo,
  useCurrentMessage,
  useUserMetadata,
} from 'src/hooks';
import {
  ChitChatCard,
  TaskSkill,
  MessageTabs,
  MessageTabData,
  GTMEvent,
  AiGenerationStatus,
} from 'src/types';
import { prepareExternalModelsTabContent, sendGTMEvent } from 'src/utils';
import { Tabs, TabPanel } from 'src/v2/commonComponents/Tabs';
import { renderMessageTabList } from '../MessageTabList';
import styles from './ThreadChitChatCard.module.scss';

// TODO(olha): needs refactoring
import { ThreadMessageContent } from 'src/components/FlatAppearance/components/ThreadMessageContent';
import { UpgradeButton } from 'src/components/UpgradeButton';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';
import { SuperAgentUpsell } from '../SuperAgentUpsell';
import {
  writerCoderModelsContainsThinking,
  NINJA_AGENT_TITLE,
  SUPER_AGENT_AS_EXTERNAL_MODEL,
} from 'src/constants';
import { FeedbackRating } from 'src/v2/components/ThreadMessage/components/FeedbackRating';
import { SeeSuperAgentAnswerButton } from '../SeeSuperAgentAnswerButton';
import { IconName } from 'src/components/Icon';

interface ThreadChatCardProps {
  messageId: string;
  chitChatCard: ChitChatCard;
  isStreaming?: boolean;
  taskId?: string;
  onSetActiveTab: (activeTab: string) => void;
}

const TEXT_MAX_SIZE = 150;

// TODO(olha): Tabs feature needs deep refactoring
export const ThreadChitChatCard = ({
  messageId,
  chitChatCard,
  isStreaming = false,
  taskId,
  onSetActiveTab,
}: ThreadChatCardProps) => {
  const { isLimitedTier, isTeamsStandardTier } = useSession();

  const { currentMessage } = useCurrentMessage(messageId);
  const { metadata } = useUserMetadata();

  const { isOwner } = useTeamInfo();

  const { textExternalModels } = useExternalModels();

  const { data } = chitChatCard;
  const { ninja_model_output, referenced_model_summary, skill } = data || {};

  const { references: external_models_result } = referenced_model_summary || {};

  const tabContent = useMemo(
    () =>
      external_models_result
        ? prepareExternalModelsTabContent(
            external_models_result,
            textExternalModels,
          )
        : [],
    [external_models_result, textExternalModels],
  );

  const superAgentTabData = useMemo(
    () =>
      tabContent.find(({ tabName }) =>
        tabName.includes(SUPER_AGENT_AS_EXTERNAL_MODEL),
      ),
    [tabContent],
  );

  const withSuperAgent = useMemo(
    () => !!superAgentTabData,
    [superAgentTabData],
  );

  const tabsData: MessageTabData[] = useMemo(
    () => [
      ...(ninja_model_output
        ? [
            {
              tabName: MessageTabs.NINJA_AGENT,
              iconName: 'ninjaOutlined' as IconName,
              tabTitle:
                !!currentMessage?.model_ids && !!metadata
                  ? metadata[currentMessage?.model_ids[0] || '']
                      ?.display_name || ''
                  : NINJA_AGENT_TITLE,
              content: ninja_model_output || '',
              status:
                tabContent.some(
                  ({ status }) => status === AiGenerationStatus.SUCCESS,
                ) || isStreaming === false
                  ? AiGenerationStatus.SUCCESS
                  : undefined,
            },
          ]
        : []),
      // todo remove if not needed in the future
      // ...(!withSuperAgent && !settings?.super_agent?.is_capable
      //   ? [
      //       {
      //         tabName: MessageTabs.SUPER_AGENT,
      //         iconName: 'ninjaPixProLogo' as IconName,
      //         tabTitle:
      //           !!currentMessage?.model_ids && !!metadata
      //             ? metadata[currentMessage?.model_ids[0] || '']
      //                 ?.display_name || ''
      //             : 'Ninja SuperAgent',
      //         content: '',
      //       },
      //     ]
      //   : []),
      ...tabContent,
    ],
    [ninja_model_output, tabContent, isStreaming, currentMessage, metadata],
  );

  const { getTabSRegistry, onTabClick } = useMessageTab({
    messageId,
    tabsData,
  });

  const withoutSuperAgentNewStructure = useMemo(
    () => !withSuperAgent && tabsData.length === 1,
    [withSuperAgent, tabsData.length],
  );

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    onSetActiveTab(tabName);
    onTabClick(tabName);

    if (tabName === MessageTabs.SUPER_AGENT) {
      sendGTMEvent(GTMEvent.SUPERAGENT_UPSELL_TAB_CLICK);
      return;
    }

    if (withSuperAgent && tabName === superAgentTabData?.tabName) {
      tabContent[0]?.content
        ? sendGTMEvent(GTMEvent.SUPERAGENT_COMPLETE_CLICK)
        : sendGTMEvent(GTMEvent.SUPERAGENT_IN_PROGRESS_CLICK);
      return;
    }
  };

  const { activeTab, setActiveTab, handleSeeSuperAgentAnswerButton } =
    useMessageSwitchTabs({
      taskId,
      handleTabClick,
      defaultTabModel: tabsData[0]?.tabName,
      onSetActiveTab,
    });

  if (skill === TaskSkill.DOCUMENT_QA && isLimitedTier) {
    if (ninja_model_output) {
      const trimmedContent = ninja_model_output.replace(/^\n+|\n+$/g, '');
      const isLongText = trimmedContent.length > TEXT_MAX_SIZE;
      const slicedContent = `${trimmedContent.slice(0, TEXT_MAX_SIZE).trim()}...`;
      return (
        <div>
          <div className={styles.contentWrapper}>
            <ThreadMessageContent
              content={slicedContent}
              messageId={messageId}
              isStreaming={isStreaming}
            />
            {isLongText && <div className={styles.blurOverlay} />}
          </div>
          {isLongText &&
            (!isTeamsStandardTier ? (
              <div className={styles.upsellBlockWrapper}>
                <span className={styles.upsellBlockText}>
                  Upgrade to Pro to reveal the answer and include your file(s)
                  in follow-up conversations.
                </span>
                <UpgradeButton dataGTM="File-agent-response-upgrade" />
              </div>
            ) : (
              <div className={styles.upgradeButtonWrapper}>
                <UpgradeButton
                  dataGTM="File-agent-response-upgrade"
                  title={
                    isOwner ? 'Upgrade to Unlock' : 'Request Upgrade to Unlock'
                  }
                />
              </div>
            ))}
        </div>
      );
    }

    return null;
  }

  const withThinking =
    currentMessage?.model_ids && currentMessage?.model_ids[0]
      ? writerCoderModelsContainsThinking.includes(currentMessage?.model_ids[0])
      : false;

  return (
    <div className={styles.root}>
      <Tabs<string> defaultActiveTab={activeTab} onTabClick={handleTabClick}>
        {tabsData.length > 1 &&
          renderMessageTabList({
            data: tabsData,
            withSuperAgent,
            tabRegistry: getTabSRegistry(),
          })}

        {tabsData.map(({ tabName, content, tabTitle, status, reasoning }) => {
          const isSuperAgentTab =
            tabName.includes(SUPER_AGENT_AS_EXTERNAL_MODEL) ||
            tabName === MessageTabs.SUPER_AGENT;

          const isTabStreaming =
            tabName === MessageTabs.NINJA_AGENT
              ? isStreaming
              : status !== AiGenerationStatus.SUCCESS;

          return (
            <TabPanel key={tabName} tab={tabName}>
              {content.length === 0 && !reasoning ? (
                // TODO(olha): create a common component
                <>
                  {(!isSuperAgentTab || withSuperAgent) && (
                    <>
                      <div className={styles.blinkingProgressText}>
                        Generating the response...
                      </div>
                      {!withThinking && <ProgressBar count={3} withColor />}
                    </>
                  )}

                  {isSuperAgentTab && (
                    <SuperAgentUpsell description="An advanced agent that dives deep into your questions, leveraging relevant sources to deliver more accurate and richer answers." />
                  )}
                </>
              ) : (
                <div className={styles.contentWrapper}>
                  <ThreadMessageContent
                    messageId={messageId}
                    content={content}
                    isStreaming={isTabStreaming}
                    reasoning={reasoning}
                  />

                  {((tabName === MessageTabs.NINJA_AGENT &&
                    withSuperAgent &&
                    tabsData.length > 1) ||
                    withoutSuperAgentNewStructure) &&
                    false && ( // todo temporary hide
                      <SeeSuperAgentAnswerButton
                        onClick={handleSeeSuperAgentAnswerButton}
                        isWithoutSuperAgent={withoutSuperAgentNewStructure}
                        messageId={messageId}
                      />
                    )}

                  <FeedbackRating
                    tabTitle={tabTitle}
                    messageId={messageId}
                    isStreaming={status !== AiGenerationStatus.SUCCESS}
                    operationType="models"
                    modelId={tabName}
                  />
                </div>
              )}
            </TabPanel>
          );
        })}
      </Tabs>
    </div>
  );
};
