import { useEffect, useState } from 'react';
import { Modal } from 'src/components/Modal';
import { UpgradeButton } from 'src/components/UpgradeButton';
import { SignupForFreeButton } from 'src/components/SignupForFreeButton';
import { LazyReactPlayer } from 'src/components/LazyReactPlayer';
import { useSession } from 'src/hooks';
import { getRandomVideo } from 'src/utils';
import styles from './VideoGenUpsellModal.module.scss';
import { X } from '@phosphor-icons/react';
import { SVG_SIZE_L } from 'src/constants';
import { isMobile } from 'react-device-detect';

interface VideoGenUpsellModalProps {
  open: boolean;
  onClose: () => void;
}

export const VideoGenUpsellModal = ({
  open,
  onClose,
}: VideoGenUpsellModalProps) => {
  const [video, setVideo] = useState<string>(getRandomVideo());
  const { isOpenTier } = useSession();

  useEffect(() => {
    if (open) {
      setVideo(getRandomVideo());
    }

    return () => {
      setVideo(getRandomVideo());
    };
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={styles.root}
      closeIcon={<X size={SVG_SIZE_L} color="var(--nj-background)" />}
    >
      <div className={styles.container}>
        <LazyReactPlayer
          className={styles.videoWrapper}
          url={video}
          playing={!isMobile}
          controls
        />

        <div className={styles.content}>
          <p className={styles.title}>
            Video Generation is now available with Ultra
          </p>
          <p className={styles.caption}>Generate with Amazon Nova Reel</p>

          {isOpenTier ? (
            <SignupForFreeButton
              className={styles.button}
              dataGTM="video-generate-signup"
              title="Sign Up"
            />
          ) : (
            <UpgradeButton
              className={styles.button}
              dataGTM="video-generate-upgrade"
              title="Upgrade to Ultra"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
