import { KeyboardEvent, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { FormProvider } from 'src/components/FormProvider';
import {
  Attendee,
  CreateMeetingCard as CreateMeetingCardType,
  KeyCodes,
  Message,
  Span,
} from 'src/types';
import { Frequency } from 'rrule';
import { useTaskById, useSession, useUpdateTaskMessageByPDU } from 'src/hooks';
import { convertRruleStringToObject } from 'src/utils';
import { prepareMessageForSubmit } from './utils/prepareMessageForSubmit';
import { ThreadMessageContent } from 'src/components/FlatAppearance/components/ThreadMessageContent';
import {
  DEFAULT_RECURRENT_INTERVAL,
  DEFAULT_RECURRENT_FREQUENCY,
  DEFAULT_RECURRENT_OCCURRENCES,
  DEFAULT_ORGANIZER_AVAILABILITY,
} from './constants/defaultValues';
import { NO_VIDEO_CALL_VALUE } from './constants/defaultValues';
import { getDefaultValueFromOptions } from './utils/selectOptions';
import { EventInfoField } from './components/EventInfoField';
import { ScheduleBetweenField } from './components/ScheduleBetweenField';
import { GuestsNotesField } from './components/GuestsNotesField';
import { CMCActionButtons } from './components/CMCActionButtons';

enum CreateMeetingNotifications {
  SUBMISSION_FAILED = 'Create meeting submission failed.',
  SUBMISSION_SUCCESS = 'Create meeting was submitted.',
}

export interface MeetingCardFormData {
  event_title: string;
  location?: string;
  conference?: string;
  minutes?: number | null;
  all_day?: boolean;
  description?: string;
  is_recurrent?: boolean;
  recurrent_interval: number;
  recurrent_frequency: Frequency;
  recurrent_total_occurrences: number;
  confirm_guest_availability?: boolean;
  organizer_availability?: string;
  event_description?: string;
  meeting_windows: Array<Span>;
  attendees: Array<Attendee>;
  executed?: boolean;
  new_attendee?: string;
}

interface CreateMeetingCardProps {
  message: Message;
  meetingCard: CreateMeetingCardType;
}

export const CreateMeetingCard = ({
  meetingCard,
  message,
}: CreateMeetingCardProps) => {
  const { appUser } = useSession();
  const { currentTaskData } = useTaskById();

  const { isSubmitted, onSubmit } = useUpdateTaskMessageByPDU({
    successMessage: CreateMeetingNotifications.SUBMISSION_SUCCESS,
    errorMessage: CreateMeetingNotifications.SUBMISSION_FAILED,
  });

  const {
    description,
    event_title,
    location_and_conference,
    duration,
    recurrence,
    event_description,
    meeting_windows,
    attendees,
    executed,
  } = meetingCard;
  const { location, conference } = location_and_conference;
  const { minutes, all_day } = duration;

  const rruleValue = useMemo(
    () =>
      recurrence.value && recurrence.value[0]
        ? convertRruleStringToObject(recurrence.value[0])
        : null,
    [recurrence.value],
  );

  // TODO(olha): temporary workaround. check only num_of_invitees when BE will handle it
  const maxAttendeesLength = useMemo(
    () => appUser.settings?.scheduler?.num_of_invitees || 0,
    [appUser],
  );

  const defaultValues: MeetingCardFormData = {
    event_title: event_title.value || '',
    location: location.value || undefined,
    conference:
      getDefaultValueFromOptions(conference.options) || NO_VIDEO_CALL_VALUE,
    minutes,
    all_day,
    is_recurrent: !!recurrence.value,
    recurrent_interval:
      rruleValue?.options.interval || DEFAULT_RECURRENT_INTERVAL,
    recurrent_frequency:
      rruleValue?.options.freq || DEFAULT_RECURRENT_FREQUENCY,
    recurrent_total_occurrences:
      rruleValue?.options.count || DEFAULT_RECURRENT_OCCURRENCES,
    confirm_guest_availability: true,
    organizer_availability: DEFAULT_ORGANIZER_AVAILABILITY,
    event_description: event_description.value || '',
    meeting_windows: meeting_windows.values,
    attendees: attendees.values || [],
    executed: !!executed,
    new_attendee: '',
  };

  const methods = useForm<MeetingCardFormData>({
    defaultValues,
  });

  const { handleSubmit, setValue, setError } = methods;

  const handleFormSubmit = (data: MeetingCardFormData) => {
    if (data.attendees.length > maxAttendeesLength) {
      setError('attendees', {
        type: 'manual',
        message: `Maximum number of items: ${maxAttendeesLength}`,
      });
      return;
    }

    const preparedMessage = prepareMessageForSubmit({
      data,
      message,
      meetingCard,
    });

    onSubmit(preparedMessage);
    setValue('executed', true);
  };

  if (!currentTaskData || !currentTaskData.task_id) {
    return null;
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === KeyCodes.ENTER && !e.shiftKey) {
      e.preventDefault();
    }
  };

  return (
    <FormProvider<MeetingCardFormData> methods={methods}>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className={classNames('nj-create-meeting-card', { disabled: executed })}
        onKeyDown={handleKeyDown}
      >
        <ThreadMessageContent content={description} />

        <div className="nj-create-meeting-card--form">
          <h5 className="nj-create-meeting-card--title">Review detail</h5>

          <EventInfoField conferenceOptions={conference.options} />
          <hr className="divider" />

          <ScheduleBetweenField />
          <hr className="divider" />

          <GuestsNotesField maxAttendeesLength={maxAttendeesLength} />
        </div>

        <CMCActionButtons message={message} isSubmitted={isSubmitted} />
      </form>
    </FormProvider>
  );
};
