import { useCallback, useContext, useMemo } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import {
  useClearEditor,
} from 'src/v2/components/Editor/hooks';
import {
  RightPanelSections,
  EditorType,
  FileListType,
  DocumentValuesType,
  CodeValuesType,
} from 'src/types';
import {
  getDocumentTypeFromArtifactValues,
  getLanguageFromArtifactValues,
  getLanguageKey,
} from 'src/utils';
import { useEditorActions, useInsertContentToDocumentEditor, useRightSidePanelActions } from 'src/hooks';
import { Thread } from '@langchain/langgraph-sdk';

export const useAddDefaultContentToEditor = () => {
  const { setRightSidePanelData } = useRightSidePanelActions();
  const { clearEditor } = useClearEditor();
  const { setCodeEditorData } = useEditorActions();
  const { insertContentToDocumentEditor } = useInsertContentToDocumentEditor();
  const { codeEditorHandlerRef } = useContext(ForwardRefContext);

  const handleAddDefaultContentToEditor = useCallback(
    (data: Thread<DocumentValuesType | CodeValuesType>) => {
      const { values } = data;

      const languageForCodeEditor = getLanguageFromArtifactValues(values);
      const documentType = getDocumentTypeFromArtifactValues(values);

      clearEditor();
      setRightSidePanelData({
        panelType: RightPanelSections.EDITOR,
        isExpanded: true,
        editorType:
          documentType === FileListType.CODE
            ? EditorType.CODE
            : EditorType.DOCUMENT,
      });

      if (documentType === FileListType.DOCUMENT) {
        const documentValues = values as DocumentValuesType;
        const lastContentIndex = documentValues?.artifact?.contents?.length - 1;
        const content =
          documentValues?.artifact?.contents?.[lastContentIndex]?.fullMarkdown;

        codeEditorHandlerRef?.current?.createDocumentArtifact(content);

        if (content) {
          insertContentToDocumentEditor({
            markdown: content,
          });
        }
      }

      if (documentType === FileListType.CODE) {
        const codeValues = values as CodeValuesType;
        const lastContentIndex = codeValues?.artifact?.contents?.length - 1;
        const content =
          codeValues?.artifact?.contents?.[lastContentIndex]?.code;
        const language = getLanguageKey(languageForCodeEditor);

        codeEditorHandlerRef?.current?.createCodeArtifact(language, content);

        setCodeEditorData({ language, content });
      }
    },
    [clearEditor, codeEditorHandlerRef, insertContentToDocumentEditor, setCodeEditorData, setRightSidePanelData],
  );

  return useMemo(
    () => ({
      onAddDefaultContentToEditor: handleAddDefaultContentToEditor,
    }),
    [handleAddDefaultContentToEditor],
  );
};
