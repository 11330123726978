import styles from './TranslatePromptLanguageSelector.module.scss';
import { TextList } from 'src/v2/commonComponents/TextList';
import { Button } from 'src/v2/commonComponents/Button';
import { X } from '@phosphor-icons/react';
import { Spinner } from 'src/components/Loading';
import { SVG_SIZE_M, SVG_SIZE_S } from 'src/constants';
import { SubmitTranslation } from '../SubmitTranslation';

interface TranslatePromptLanguageSelectorProps {
  isModalOpen: boolean;
  isLoading: boolean;
  languages: Record<string, string>;
  selectedLanguage: string;
  defaultLanguage: string;
  handleLanguageSelect: (id: string) => void;
  handleSendPrompt: (language?: string) => Promise<void>;
  removeModalVisibility: () => void;
}

export const TranslatePromptLanguageSelector = ({
  isModalOpen,
  isLoading,
  languages,
  selectedLanguage,
  defaultLanguage,
  handleLanguageSelect,
  handleSendPrompt,
  removeModalVisibility,
}: TranslatePromptLanguageSelectorProps) => {
  const handleModalClose = () => {
    removeModalVisibility();
  };

  const handleSubmitTranslation = () => {
    handleModalClose();
    handleSendPrompt(selectedLanguage);
  };

  if (!isModalOpen) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <p className={styles.title}>Translation options</p>
        <p className={styles.subtitle}>Choose a language for your text</p>

        <Button
          className={styles.closeButton}
          shape="round"
          color="transparent"
          onClick={handleModalClose}
        >
          <X size={SVG_SIZE_S} />
        </Button>
      </div>
      <hr className="divider" />

      <div className={styles.scrollContainer}>
        {isLoading ? (
          <Spinner size={SVG_SIZE_M} inline />
        ) : (
          <TextList
            values={languages}
            selectedLanguage={selectedLanguage}
            defaultValue={defaultLanguage}
            onValueSelect={handleLanguageSelect}
          />
        )}
      </div>

      <div>
        <hr className="divider" />
        <SubmitTranslation
          isSubmitDisabled={false}
          onReset={handleModalClose}
          onSubmit={handleSubmitTranslation}
        />
      </div>
    </div>
  );
};
