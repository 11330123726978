import { useCallback, useMemo } from 'react';
import { Thread } from '@langchain/langgraph-sdk';
import { CodeValuesType, DocumentValuesType, EditorType } from 'src/types';
import { useThreadContext } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/contexts/ThreadProvider';
import { CodeArtifactType, DocumentArtifactType } from '../../types/fileList';
import { useEditorState } from './useEditorState';

export const useCurrentEditorThread = () => {
  const { editorType } = useEditorState();
  const { userThreads, threadId } = useThreadContext();

  const currentEditorThread = useMemo(() => {
    return userThreads.find(
      (thread) => thread.thread_id === threadId,
    ) as Thread<DocumentValuesType | CodeValuesType>;
  }, [userThreads, threadId]);

  const currentEditorThreadTitle = useMemo(() => {
    return (
      (currentEditorThread?.metadata?.thread_title as string) ||
      'Untitled document'
    );
  }, [currentEditorThread?.metadata?.thread_title]);

  const currentEditorArtifacts = useMemo(() => {
    return currentEditorThread?.values?.artifact?.contents || [];
  }, [currentEditorThread?.values?.artifact?.contents]);

  const getArtifactContentByIndex = useCallback(
    ({ index }: { index: number }) => {
      if (editorType === EditorType.DOCUMENT) {
        const artifacts = currentEditorArtifacts as DocumentArtifactType[];
        const content = artifacts?.[index]?.fullMarkdown;

        return content;
      }

      if (editorType === EditorType.CODE) {
        const artifacts = currentEditorArtifacts as CodeArtifactType[];
        const content = artifacts?.[index]?.code;

        return content;
      }

      return '';
    },
    [currentEditorArtifacts, editorType],
  );

  return useMemo(
    () => ({
      currentEditorThread,
      currentEditorThreadTitle,
      currentEditorArtifacts,
      getArtifactContentByIndex,
    }),
    [
      currentEditorThread,
      currentEditorThreadTitle,
      currentEditorArtifacts,
      getArtifactContentByIndex,
    ],
  );
};
