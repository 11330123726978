import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import {
  Message,
  AppUser,
  ConversationRole,
  TaskSkill,
  ApiContact,
  isString,
  isError,
  SingleSelectOption,
  ReleaseAsset,
  WelcomeUpsellModalType,
} from 'src/types';
import {
  BETA_ENVIRONMENT,
  DEFAULT_CHAT_ID,
  DEFAULT_DATE_FORMAT,
  ENTERPRISE_ENVIRONMENT,
  GAMMA_ENVIRONMENT,
  LOCALHOST_ENVIRONMENT,
  PRODUCTION_ENVIRONMENT,
} from 'src/constants';
import { env } from 'src/env';
import { OsTypes, osName, isMacOs, isWindows } from 'react-device-detect';
import {
  DESKTOP_APP_DOWNLOAD_LATEST,
  DESKTOP_APP_RELEASES_LATEST,
} from 'src/constants/externalLinks';
import { UserTier } from 'src/types/models/UserTier';

// Extends DayJS library:
dayjs.extend(isToday);

/**
 * Return true if in beta environment.
 */
export const isBetaEnv: boolean =
  env.REACT_APP_ENVIRONMENT === BETA_ENVIRONMENT ||
  env.REACT_APP_ENVIRONMENT === LOCALHOST_ENVIRONMENT;

/**
 * Return true if in gamma environment.
 */
export const isGammaEnv: boolean =
  env.REACT_APP_ENVIRONMENT === GAMMA_ENVIRONMENT;

/**
 * Return true if in prod environment.
 */
export const isProdEnv: boolean =
  env.REACT_APP_ENVIRONMENT === PRODUCTION_ENVIRONMENT;

/**
 * Return true if in enterprise environment.
 */
export const isEnterpriseEnv: boolean =
  env.REACT_APP_ENVIRONMENT === ENTERPRISE_ENVIRONMENT;

/**
 * We might have several "enterprise environments. E.g. enterprise, enterprise-gamma, enterprise-beta".
 */
export const isEnterprise: boolean = [ENTERPRISE_ENVIRONMENT].includes(
  env.REACT_APP_ENVIRONMENT,
);

export const isEnabledForGTMEnv = isProdEnv || isEnterpriseEnv;

/**
 * Get full user name
 * @param user AppUser
 * @returns string
 */
export function getUserFirstName(user: AppUser): string {
  if (!user.first_name) {
    return '';
  }
  return user.first_name;
}

/**
 * Get initials from the user.
 * @param user AppUser
 * @returns string
 */
export function getInitials(user: AppUser | ApiContact): string {
  const { first_name = '', last_name = '' } = user;
  const names = [first_name, last_name];
  const initials: string = names.reduce((acc, name) => {
    return isString(name) ? `${acc}${name.charAt(0).toUpperCase()}` : acc;
  }, '');
  return initials;
}

/**
 * Get initials from user name.
 * @param name string
 * @returns string
 */
export function getInitialsFromName(name: string): string {
  const names = name.split(' ').slice(0, 2);
  const initials: string = names.reduce((acc, name) => {
    return isString(name) ? `${acc}${name.charAt(0).toUpperCase()}` : acc;
  }, '');
  return initials;
}

/**
 * getFullName() gets full name for a user or name for a robot.
 * @param user AppUser
 * @returns string
 */
export function getFullName(user?: AppUser): string {
  if (!user) {
    return '';
  }
  return user.role === ConversationRole.USER
    ? `${user.first_name} ${user.last_name}`
    : `${user.first_name}`;
}

/**
 * Get random item from an array.
 * @param arr string[]
 * @returns string
 */
export function getRandomText(arr: string[]): string {
  return arr[Math.floor(Math.random() * arr.length)];
}

/**
 * Pauses execution for a given number of milliseconds.
 * @param delay number
 * @returns promise
 */
export function pause(delay: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
}

/**
 * Generates color & returns back circle color classname.
 * @returns string
 */
export function getCircleColor(): string {
  const colors = [
    'green-circle',
    'blue-circle',
    'purple-circle',
    'pink-circle',
    'orange-circle',
    'marine-circle',
  ];
  return getRandomText(colors);
}

// TODO(olha): deprecated
export function formatMonthDay(timestamp: number | string): string {
  const diffInDays = getDiffInDays(timestamp);

  if (diffInDays === 0) {
    return 'Today';
  }

  if (diffInDays === 1) {
    return 'Yesterday';
  }

  const date = new Date(timestamp).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
  });

  return date;
}

// TODO(olha): deprecated
export function formatMonthDayExtended(
  timestamp: number | string,
  locale = 'en-US',
): string {
  const diffInDays = getDiffInDays(timestamp);
  if (diffInDays === 1) {
    return 'Yesterday';
  }
  if (diffInDays === -1) {
    return 'Tomorrow';
  }

  return formatDay(timestamp, locale);
}

// TODO(olha): deprecated
export function formatDate(
  timestamp: number | string,
  locale = 'en-US',
): string {
  return new Date(timestamp).toLocaleString(locale, {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

// deprecated
export function formatDay(
  timestamp: number | string,
  locale = 'en-US',
): string {
  return new Date(timestamp).toLocaleString(locale, {
    month: 'short',
    day: 'numeric',
  });
}

// Deprecated
export function getDiffInDays(
  firstTimestamp: number | string,
  secondTimestamp?: number | string,
): number {
  const firstDate = new Date(firstTimestamp);
  const secondDate = secondTimestamp ? new Date(secondTimestamp) : new Date();
  firstDate.setHours(0, 0, 0, 0);
  secondDate.setHours(0, 0, 0, 0);
  const diffInMs = secondDate.getTime() - firstDate.getTime();
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  return diffInDays;
}

/**
 * Uppercase first letter of the word
 * @param str string
 * @returns string
 */
export function uppercaseFirstLetter(str: string): string {
  if (!str) {
    return '';
  }
  return [...str][0].toUpperCase() + str.slice(1);
}

/**
 * Uppercase first letter of each word in the string
 * @param str string
 * @returns string
 */
export function capitalizeEachFirstLetter(str: string): string {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function convertTaskHashToTitle(str: string): string {
  return uppercaseFirstLetter(str.replaceAll('-', ' '));
}

/**
 * Takes a string and returns alphanumeric version of it.
 * @param str string
 * @returns string
 */
export function toReadableString(str: string): string {
  return str.replace(/[\W_]+/g, ' ').trim();
}

// Deprecated
export function isMoreThanOneDayOld(timestamp: string | undefined) {
  if (!timestamp) return;

  const stamp = new Date(timestamp).getTime();
  const now = Date.now();
  const oneDay = 24 * 60 * 60 * 1000;
  return now - stamp > oneDay;
}

/**
 * Find out elapsed time in human readable format.
 * @param dateOne string
 * @param dateTwo string
 * @returns string
 */
export function getElapsedTime(dateOne?: string, dateTwo?: string): string {
  if (!dateOne || !dateTwo) {
    return 'n/a';
  }

  const diff = Math.abs(
    new Date(dateOne).getTime() - new Date(dateTwo).getTime(),
  );

  const diffDate = new Date(diff);
  const minutes = diffDate.getMinutes();
  const seconds = diffDate.getSeconds();
  const milliseconds = diffDate.getMilliseconds();

  const template =
    `${minutes > 0 ? minutes + 'min ' : ''}` +
    `${seconds > 0 ? seconds + 's ' : ''}` +
    `${milliseconds > 0 ? milliseconds + 'ms' : ''}`;
  return template.trim();
}

/**
 * getTaskDisplayDate() returns
 * @param date string
 * @returns string
 */
export const getTaskDisplayDate = (date: string): string => {
  const currentDate = dayjs().format(DEFAULT_DATE_FORMAT);

  if (currentDate === date) {
    return 'Today';
  } else if (dayjs().diff(dayjs(date), 'day') === 1) {
    return 'Yesterday';
  } else {
    return dayjs(date).format('MMMM D, YYYY');
  }
};

/**
 * stripHtmlTags() removes html tags from strings.
 * @param text string
 * @returns string
 */
export function stripHtmlTags(text: string): string {
  if (text === null || text === '') return '';
  return text
    .toString()
    .replace(/(<([^>]+)>)/gi, '')
    .replace('&nbsp;', ' ');
}

// Deprecated
export function isMessageFromToday(message?: Message): boolean {
  if (!message) return false;

  const timestamp = message?.timestamp;
  if (!timestamp) return false;

  return dayjs(timestamp).isToday();
}

// Deprecated
export function haveConversedToday(messages: Message[] = []): boolean {
  const lastMessage = messages.length > 0 ? messages.at(-1) : undefined;

  return isMessageFromToday(lastMessage);
}

/**
 * displayGreeting() will say "Good morning" etc.
 * based on time of the day measured in user timezone.
 * Good Morning! 5:00 AM — 11:59 AM
 * Good Afternoon! 12:00 PM — 4:59 PM
 * Good Evening! 5:00 PM — 4:59 AM
 * @returns
 */
export const displayGreeting = (): string => {
  const today = new Date();
  const hrs = today.getHours();

  if (hrs >= 5 && hrs < 12) {
    return 'Good Morning';
  }

  if (hrs >= 12 && hrs < 17) {
    return 'Good Afternoon';
  }

  return 'Good Evening';
};

/**
 * checkSkillCancellability() checks the ability to cancel the task by skill.
 * @param name TaskSkill
 * @returns boolean
 */
export const checkSkillCancellability = (skill?: TaskSkill): boolean => {
  if (!skill) {
    return false;
  }
  return skill === TaskSkill.SCHEDULING || skill === TaskSkill.RESERVATION;
};

/**
 * toHoursAndMinutes() creates out of seconds hours and minutes.
 * @param totalSeconds number
 * @returns string
 */
export function toHoursAndMinutes(totalSeconds: number): string {
  const totalMinutes = Math.floor(totalSeconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const hourString = hours > 0 ? `${hours} hrs` : ``;
  const minString = minutes > 0 ? `${minutes} min` : ``;

  return hourString === '' && minString === ''
    ? `0`
    : `${hourString} ${minString}`;
}

/**
 * escapeRegExp() goes over a string and escapes reg exp.
 * @param string
 * @returns string
 */
export function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/**
 * Checks whether email belongs to the employee of NinjaTech AI.
 * @param email string
 * @returns boolean
 */
export const defineIfUserNinjaEmployee = (email: string): boolean => {
  return email ? email.split('@')[1] === 'ninjatech.ai' : false;
};

/**
 * Extracts the error message if the param is an error.
 * @param error
 * @returns string
 */
export function getErrorMessage(error: unknown): string {
  if (isError(error)) {
    return error.message;
  }
  return String(error);
}

/**
 * checkIfImageExists() returns promise whether images has loaded or not.
 * @param url string
 * @returns Promise<boolean>
 */
export const checkIfImageExists = async (url: string): Promise<boolean> => {
  const img = new Image();

  return new Promise<boolean>((resolve) => {
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
};

/**
 * Removes invalid characters from a string intended to be used as an HTML ID.
 * Retains only alphanumeric characters, dashes, and underscores, making the string safe for use
 * as HTML IDs and CSS selectors. This simplifies interaction with the DOM via JavaScript and CSS.
 *
 * @param id The original identifier string that may contain invalid characters.
 * @returns A sanitized identifier string with only valid characters.
 */
export const sanitizeHTMLId = (id: string) => {
  return id.replace(/[^a-zA-Z0-9\-_]/g, '');
};

export const handleOpenUserCalendar = () => {
  window.open('https://calendar.google.com/calendar/u/0/r', '_blank');
};

/**
 * `mergeArraysWithSortUniqueElementsByKey` merges `currentArray` and `newArray` of objects into one, sorting by `sortingKey`.
 * It de-duplicates based on a key, prioritizing `newArray` objects when keys match.
 *
 * @param currentArray Original array of objects.
 * @param newArray Array of new objects to merge with `currentArray`.
 * @param uniqKey Property name used for object uniqueness.
 * @param sortingKey Property name used for sorting arrays.
 * @returns Merged and de-duplicated array, ordered by `newArray` then unmatched `currentArray` objects.
 */
export const mergeArraysWithSortUniqueElementsByKey = <
  T,
  K extends keyof T,
  N extends keyof T,
>(
  currentArray: T[],
  newArray: T[],
  uniqKey: K,
  sortingKey: N,
): T[] => {
  const combinedArray = [...currentArray, ...newArray];
  const uniqueMap = new Map();

  combinedArray.forEach((item) => {
    uniqueMap.set(item[uniqKey], item);
  });

  const uniqueArray = Array.from(uniqueMap.values());

  uniqueArray.sort((a, b) => {
    if (a[sortingKey] < b[sortingKey]) return -1;
    if (a[sortingKey] > b[sortingKey]) return 1;
    return 0;
  });

  return uniqueArray;
};

/**
 * `updateSelectOptionsSelection` marks an option as selected in an array of `SelectOption` objects based on the `selectedValue`.
 * It iterates through each `SelectOption` in the provided array, setting the `selected` property to `true` if the option's value matches `selectedValue`, otherwise sets to `false`.
 *
 * @param array Array of `SelectOption` objects to process.
 * @param selectedValue (Optional) The value of the option to mark as selected. If not provided, all options are marked as not selected.
 * @returns An array of `SelectOption` objects with updated `selected` property based on `selectedValue`.
 */
export const processSelectionInSelectOptions = (
  array: Array<SingleSelectOption>,
  selectedValue?: string,
) => {
  return array.map((item) => ({
    ...item,
    selected: item.value === selectedValue,
  }));
};

/**
 * isJsonString() validates string for JSON parsing.
 * @param str string
 * @returns boolean
 */
export const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Determines if the scroll position is within a specified gap from the bottom of an element.
 * @param ref A React ref object targeting an HTMLElement.
 * @param gap The distance from the bottom of the element within which the function returns true.
 * @returns Returns true if the current scroll position is within the specified gap from the bottom, otherwise returns false.
 */
export const isScrollNearBottom = (
  ref: React.RefObject<HTMLElement>,
  gap: number,
): boolean => {
  const element = ref.current;

  if (!element) {
    return false;
  }

  const { scrollTop, clientHeight, scrollHeight } = element;

  const scrollPosition = scrollTop + clientHeight;
  const isNearBottom = scrollHeight - scrollPosition <= gap;

  return isNearBottom;
};

export const convertToValueWithoutDots = (value: string) => {
  const tempString = '_____';
  return value.replaceAll('.', tempString);
};

/**
 * Converts a conversationId to a Slug format by removing 'conv::' and colons.
 *
 * @param conversationId string.
 * @returns The converted string in the format 'YYYYMMDD-HHMMSS-xyz'.
 */
export const convertConversationIdToSlug = (conversationId: string) => {
  return conversationId
    .replace('conv::', '')
    .replace(/-/g, '')
    .replace('::', '-')
    .replace(/:(.+)/, '-$1')
    .replace(/:/g, '');
};

export const convertSlugToConversationId = (slug?: string) => {
  if (!slug || slug === DEFAULT_CHAT_ID) {
    return null;
  }
  const [date, time, code] = slug.split('-');

  if (date.length !== 8 || time.length !== 6) {
    return null;
  }

  const formattedDate = `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`;
  const formattedTime = `${time.slice(0, 2)}:${time.slice(2, 4)}:${time.slice(4, 6)}`;

  return `conv::${formattedDate}:${formattedTime}::${code}`;
};

export const getPriceWithCurrency = (amount: number, currency?: string) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    maximumFractionDigits: 3,
  }).format(amount < 0.001 && amount !== 0 ? 0.001 : amount);
};

export const getRoundedPriceWithCurrency = (
  amount: number,
  currency?: string,
) => {
  return getPriceWithCurrency(amount / 100, currency);
};

export const getDownloadLinkForOperatingSystem = async () => {
  const os = osName;
  const validOS = isMacOs || isWindows;

  if (validOS) {
    try {
      const fileExtensions = {
        [OsTypes.Windows]: '.exe',
        [OsTypes.MAC_OS]: '.dmg',
      };
      const response = await fetch(DESKTOP_APP_DOWNLOAD_LATEST);
      const data = await response.json();

      const assets = data.assets;

      const asset = assets.find((asset: ReleaseAsset) =>
        asset.name.includes(fileExtensions[os]),
      );

      if (asset) {
        return asset.browser_download_url;
      }
    } catch (error) {
      console.error('Error fetching download link:', error);
    }
  }
  return DESKTOP_APP_RELEASES_LATEST;
};

export const DownloadApp = async () => {
  const link = await getDownloadLinkForOperatingSystem();

  if (link) {
    window.open(link, '_blank')?.focus();
  }
};

export const getRandomFileId = () => {
  return Math.floor(Math.random() * 1e16).toString();
};

export const formatFileSize = (sizeInBytes: number) => {
  const units = [
    { name: 'bytes', threshold: 1 },
    { name: 'KB', threshold: 1024 },
    { name: 'MB', threshold: 1024 ** 2 },
    { name: 'GB', threshold: 1024 ** 3 },
    { name: 'TB', threshold: 1024 ** 4 },
  ];

  const appropriateUnit = units.find((unit) => sizeInBytes >= unit.threshold);
  const index = units.indexOf(
    appropriateUnit as { name: string; threshold: number },
  );

  if (sizeInBytes === 0) return '0 bytes';

  const size = sizeInBytes / (index > 0 ? 1024 ** index : 1);
  const formattedSize = size.toFixed(size >= 10 ? 0 : 1);

  return `${formattedSize} ${appropriateUnit?.name}`;
};

export const getWelcomeModalType = (
  tier_id: string,
): WelcomeUpsellModalType | null => {
  switch (tier_id) {
    case UserTier.STANDARD:
      return 'standard-welcome-modal';
    case UserTier.PRO:
      return 'pro-welcome-modal';
    case UserTier.ULTRA:
      return 'ultra-welcome-modal';
    case UserTier.TEAMS_STANDARD:
      return 'teams-standard-welcome-modal';
    case UserTier.TEAMS_PRO:
      return 'teams-pro-welcome-modal';
    case UserTier.TEAMS_ULTRA:
      return 'teams-ultra-welcome-modal';
    default:
      return null;
  }
};
