import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { X } from '@phosphor-icons/react';
import {
  APPLE_PAYMENT_UPGRADE_ERROR_TEXT,
  BASIC_TIER_MODELS_AND_SKILLS,
  BASIC_WELCOME_UPSELL_MODAL_PLANS,
  SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE,
  SVG_SIZE_L,
} from 'src/constants';
import {
  useBasicTierWelcomeModal,
  useBreakpoint,
  useSubscriptionInfo,
} from 'src/hooks';
import { AppRoutes, TeamsTabs, UserTier } from 'src/types';
import { Modal } from 'src/components/Modal';
import { Button } from 'src/v2/commonComponents/Button';
import { ModelAndSkillItem } from './components/ModelAndSkillItem';
import styles from './BasicTierUpsellModal.module.scss';
import { useState } from 'react';
import { MobilePlanCard } from './components/MobilePlanCard';
import { PlanCard } from './components/PlanCard';

export const BasicTierUpsellModal = () => {
  const navigate = useNavigate();
  const { subscriptionInfo, isAppleOrAmpPayment } = useSubscriptionInfo();
  const { isMobile } = useBreakpoint();
  const { isOpen, handleClose } = useBasicTierWelcomeModal();

  const [selectedPlan, setSelectedPlan] = useState<UserTier | null>(
    UserTier.ULTRA,
  );

  const handleUpgradeClick = async () => {
    if (
      !!subscriptionInfo?.is_cancelled ||
      !!subscriptionInfo?.has_scheduled_downgrade ||
      !!subscriptionInfo?.has_scheduled_plan_change ||
      !!subscriptionInfo?.processing_plan_change ||
      isAppleOrAmpPayment
    ) {
      toast.error(
        isAppleOrAmpPayment
          ? APPLE_PAYMENT_UPGRADE_ERROR_TEXT
          : SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE,
      );
      handleClose();
    } else {
      handleClose();
      navigate(
        `${AppRoutes.PAYMENT}?payment_tab=${TeamsTabs.INDIVIDUAL}${selectedPlan ? `&plan=${selectedPlan}` : ''}`,
      );
    }
  };

  return (
    <div className={styles.root}>
      <Modal
        open={isOpen}
        onClose={handleClose}
        className={styles.modal}
        overlayClassName={styles.overlay}
        showCloseIcon={false}
        blockScroll={false}
      >
        <div className={styles.content} data-e2e="basic-tier-welcome-modal">
          {!isMobile && (
            <button
              type="button"
              className={styles.closeButton}
              onClick={handleClose}
              data-e2e="update-modal-close-button"
            >
              <X size={SVG_SIZE_L} />
            </button>
          )}

          <div className={styles.titleWrapper}>
            <h2 className={styles.title}>Choose Your Plan</h2>

            <h4 className={styles.description}>
              Unlimited Tasks, Premium AI Models, One Subscription
            </h4>
          </div>

          <div className={styles.modelsAndSkills}>
            <div className={styles.withLeftMargin}>
              {BASIC_TIER_MODELS_AND_SKILLS.slice(0, 6).map(
                ({ title, icon }) => (
                  <ModelAndSkillItem title={title} icon={icon} key={title} />
                ),
              )}
            </div>

            <div>
              {BASIC_TIER_MODELS_AND_SKILLS.slice(6, 12).map(
                ({ title, icon }) => (
                  <ModelAndSkillItem title={title} icon={icon} key={title} />
                ),
              )}
            </div>
          </div>

          <div className={styles.plans}>
            {BASIC_WELCOME_UPSELL_MODAL_PLANS.map(
              ({ id, title, price, features, models, description }) =>
                isMobile ? (
                  <MobilePlanCard
                    key={title}
                    id={id}
                    title={title}
                    price={price}
                    features={features}
                    models={models}
                    description={description}
                    onClick={setSelectedPlan}
                    isSelected={selectedPlan === id}
                  />
                ) : (
                  <PlanCard
                    key={title}
                    id={id}
                    title={title}
                    price={price}
                    features={features}
                    models={models}
                    description={description}
                    onClick={setSelectedPlan}
                    isSelected={selectedPlan === id}
                  />
                ),
            )}
          </div>

          <div className={styles.footer}>
            <Button
              className={styles.submit}
              onClick={handleUpgradeClick}
              fullWidth={isMobile}
            >
              Subscribe
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
