import styles from './DummySettingsAutoSelect.module.scss';
import {
  DrawerTooltipContainer,
  DrawerTooltipContainerRef,
} from 'src/v2/commonComponents/DrawerTooltipContainer';
import { SelectorButton } from 'src/v2/components/LeftSidePanel/components/LLMSelectorSection/components/SelectorButton';
import { useMemo, useRef, ReactNode } from 'react';
import { SVG_SIZE_M } from 'src/constants';
import { Check } from '@phosphor-icons/react';
import { Button } from 'src/v2/commonComponents/Button';
import { TaskSkill } from 'src/types';
import { Icon, IconName } from 'src/components/Icon';

const label = 'Ninja Auto-selects AI model';

type DummySettingsAutoSelectProps = {
  title?: string;
  skill: TaskSkill;
  iconName?: IconName;
  labelComponent?: ReactNode;
  disabled?: boolean;
};

export const DummySettingsAutoSelect = ({
  title,
  skill,
  iconName,
  labelComponent,
  disabled,
}: DummySettingsAutoSelectProps) => {
  const drawerRef = useRef<DrawerTooltipContainerRef>(null);

  const tooltipAnchorId = useMemo(() => `settings-${skill}-tooltip`, [skill]);

  const handleClick = () => {
    drawerRef.current?.onVisibilityToggle();
  };

  return (
    <DrawerTooltipContainer
      ref={drawerRef}
      id={tooltipAnchorId}
      place="bottom"
      offset={8}
      classNameTooltip={styles.tooltip}
      ToggleButton={SelectorButton}
      toggleButtonProps={{
        label: title || label,
        icon: iconName,
      }}
    >
      <div className={styles.container}>
        <Button
          onClick={handleClick}
          className={styles.item}
          color="transparent"
          disabled={disabled}
        >
          <div className={styles.labelWrapper}>
            {!!iconName && <Icon type={iconName} size={SVG_SIZE_M} />}
            <span>{title || label}</span>
          </div>

          {!!labelComponent && labelComponent}
          {!disabled && <Check size={SVG_SIZE_M} />}
        </Button>
      </div>
    </DrawerTooltipContainer>
  );
};
