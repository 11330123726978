import { ArrowsClockwise, CheckCircle } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import styles from './SuperAgentSelectItem.module.scss';
import { Button } from 'src/v2/commonComponents/Button';
import { UserTier } from 'src/types';
import { LabelPro } from 'src/components/LabelPro';
import { LabelUltra } from 'src/components/LabelUltra';
import { LabelNew } from 'src/components/LabelNew';
import LabelStandard from 'src/components/LabelStandard/LabelStandard';
import { useMemo } from 'react';
import { useUserMetadata } from 'src/hooks';

type SuperAgentSelectItemProps = {
  onClick: () => void;
  item: string;
  activeSuperAgentModel?: string;
  availableIn?: UserTier;
  showRegenerate?: boolean;
  isNew?: boolean;
};

export const SuperAgentSelectItem = ({
  onClick,
  item,
  activeSuperAgentModel,
  availableIn,
  showRegenerate,
  isNew,
}: SuperAgentSelectItemProps) => {
  const { metadata } = useUserMetadata();
  const availableLabel = useMemo(() => {
    if (!!availableIn) {
      switch (availableIn) {
        case UserTier.STANDARD:
          return <LabelStandard />;
        case UserTier.PRO:
          return <LabelPro />;
        case UserTier.ULTRA:
          return <LabelUltra />;
        default:
          return null;
      }
    }
    return null;
  }, [availableIn]);

  return (
    <Button
      className={styles.rowWrapper}
      color="transparent"
      onClick={onClick}
      key={item}
      disabled={!!availableIn}
    >
      <div className={styles.labelWrapper}>
        <span className={styles.title}>{metadata[item]?.display_name}</span>
        <span className={styles.description}>
          {metadata[item]?.description}
        </span>
      </div>
      <div className={styles.rightSideWrapper}>
        {showRegenerate && !availableIn && (
          <ArrowsClockwise size={SVG_SIZE_M} />
        )}
        {isNew && !showRegenerate && !availableIn && <LabelNew />}
        {activeSuperAgentModel === item && !availableIn && !showRegenerate && (
          <CheckCircle size={SVG_SIZE_M} weight="fill" />
        )}
        {availableLabel}
      </div>
    </Button>
  );
};
