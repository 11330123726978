import {
  Atom,
  CodeBlock,
  Images,
  ListMagnifyingGlass,
  PencilLine,
  Video,
} from '@phosphor-icons/react';
import { SortOrder, QuickSkillCommandType, QuickSkillCommand } from 'src/types';

export const DEFAULT_NUMBER_OF_REFERENCES = 3;
export const DEFAULT_REFERENCE_WIDTH = 200;
export const DEFAULT_REFERENCE_HEIGHT = 112;
export const RESEARCH_STREAMING_SPEED = 5;
export const SCROLLER_PAUSE_TIME = 350;
export const DEFAULT_SCROLLER_SPEED = 50;
export const DEFAULT_STREAMING_SPEED = 2;
export const DEFAULT_CHAT_NAME = 'new-chat';
export const DEFAULT_CHAT_ID = 'default';
export const DEFAULT_PAGE_LIMIT = 100;
export const DEFAULT_SORT_ORDER = SortOrder.DESC;

export const TEMPORARY_CONVERSATION_ID = 'new-chat';

export const DEFAULT_GUEST_TAG = '[@guest]';
export const TAGS_TO_REPLACE = [DEFAULT_GUEST_TAG];

/**
 * @deprecated
 */
export const CommunicationStyle: Record<number, string> = {
  1: 'Concise',
  2: 'Brief',
  3: 'Moderate',
  4: 'Detailed',
  5: 'Verbose',
};

export const CITATION_REGEX = / ?\[citation:(\d+)\]/g;
export const CITATION_REGEX_WITHOUT_SPACE = /\[citation:(\d+)\]/g;
export const CITATION_TITLE_REGEX = /^citation:(\d+)$/;

export const FILE_REGEX =
  /---\[original-filename="([^"]+)" converted-filename="([^"]+)"\]---/;
export const EDIT_FILE_REGEX = /---\[edit-image-url="([^"]+)"\]---/;

export const AttachmentFileInputId = 'attachFile';

export const MAX_NUMBER_OF_WORDS = 16000;
export const MAX_NUMBER_OF_PASTED_WORDS = 16000;
export const MAX_NUMBER_OF_WORDS_OPEN_TIER = 2000;

export const CONVERTED_TEXT_TO_FILE_NAME = 'InputPrompt.txt';
export const CONVERTED_PASTED_TEXT_TO_FILE_NAME = 'Pasted';
export const CONVERTED_TEXT_TO_FILE_EXTENSION = '.txt';

export enum PromptLoadingReason {
  UNDEFINED = 'undefined',
  IMPROVING = 'improving',
  TRANSLATING = 'translating',
}

/**
 * @deprecated
 */
export const CODE_WITHOUT_NEWLINE_REGEX = /(\\n)?\s*```/g;
/**
 * @deprecated
 */
export const CODE_LANGUAGE_REGEX = /```(\w+)?\n([\s\S]*?)```/g;

export const QUICK_SKILL_COMMANDS: QuickSkillCommandType[] = [
  {
    skill: QuickSkillCommand.IMAGE,
    description: 'Create & edit images',
    Icon: Images,
  },
  {
    skill: QuickSkillCommand.CODER,
    description: 'Create & debug code',
    Icon: CodeBlock,
  },
  {
    skill: QuickSkillCommand.RESEARCHER,
    description: 'Online fast research',
    Icon: ListMagnifyingGlass,
  },
  {
    skill: QuickSkillCommand.DEEP_RESEARCHER,
    description: 'Online deep research',
    Icon: Atom,
    isLimited: true,
  },
  {
    skill: QuickSkillCommand.WRITER,
    description: 'Write, brainstorm, get advice',
    Icon: PencilLine,
  },
  {
    skill: QuickSkillCommand.FILE,
    description: 'Analyze file attachments for insights',
    Icon: PencilLine,
  },
  {
    skill: QuickSkillCommand.VIDEO,
    description: 'Create video',
    Icon: Video,
  },
];
