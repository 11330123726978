import {
  LexicalEditor,
  $getSelection,
  $isRangeSelection,
  $isParagraphNode,
  LexicalNode,
  ElementNode,
} from 'lexical';
import { $isListItemNode } from '@lexical/list';
import { $isHeadingNode, $isQuoteNode } from '@lexical/rich-text';

// TODO(olha): WIP Editor The function needs extension. We might need to consider first char selection and last one from the selection, and return all blocks related to selection
const getClosestBlock = (node: LexicalNode): ElementNode | null => {
  let current: LexicalNode | null = node;

  while (
    current !== null &&
    !(
      $isParagraphNode(current) ||
      $isListItemNode(current) ||
      $isHeadingNode(current) ||
      $isQuoteNode(current)
    )
  ) {
    current = current.getParent();
  }
  return current;
};

// TODO(olha): WIP Editor It doesn't work for us. We need to get content block in the Markdown.
export const getTextBlock = async (editor: LexicalEditor) => {
  let blockText = '';

  editor.update(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();

      const blockNode = getClosestBlock(anchorNode);

      if (blockNode) {
        blockText = blockNode.getTextContent();
      }
    }
  });

  return blockText;
};
