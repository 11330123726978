import { SVG_SIZE_M } from 'src/constants';
import { ResearcherSettingsMediaItem } from 'src/types';
import styles from './MediaSettingsItem.module.scss';
import { SettingsSwitch } from 'src/v2/components/LeftSidePanel/components/SettingsSwitch';

export const MediaSettingsItem = ({
  value,
  label,
  icon: Icon,
  settingKey,
}: ResearcherSettingsMediaItem) => {
  return (
    <div key={value} className={styles.root}>
      <p className={styles.label}>
        <Icon size={SVG_SIZE_M} />
        <span>{label}</span>
      </p>
      <SettingsSwitch settingKey={settingKey} />
    </div>
  );
};
