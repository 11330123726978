import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSession } from 'src/hooks';
import {
  sessionState,
  useAppDispatch,
  useAppSelector,
  setBasicTierWelcomeLastShown,
} from 'src/store';
import { INVITATION_TOKEN } from 'src/constants';

export const useBasicTierWelcomeModal = () => {
  const dispatch = useAppDispatch();
  const invitationToken = localStorage.getItem(INVITATION_TOKEN);
  const { isFreeTier } = useSession();
  const { basicTierWelcomeLastShown } = useAppSelector(sessionState);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const updateLastShown = useCallback(() => {
    dispatch(setBasicTierWelcomeLastShown(Date.now().toString()));
  }, [dispatch]);

  useEffect(() => {
    if (!isFreeTier || !!invitationToken) {
      if (isOpen) {
        setIsOpen(false);
      }
      return;
    }

    const lastShownTime = basicTierWelcomeLastShown
      ? parseInt(basicTierWelcomeLastShown, 10)
      : 0;

    const now = Date.now();
    const hoursPassed = (now - lastShownTime) / (1000 * 60 * 60);

    if (hoursPassed >= 24 || lastShownTime === 0) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFreeTier]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    updateLastShown();
  }, [updateLastShown]);

  return useMemo(
    () => ({
      isOpen,
      handleClose,
    }),
    [isOpen, handleClose],
  );
};
