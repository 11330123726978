import styles from './NinjaModeSettings.module.scss';
import { TaskSkill } from 'src/types';
import { SkillLabel } from 'src/components/SkillLabel';
import { SettingsSelect } from 'src/v2/components/LeftSidePanel/components/SettingsSelect';
import { SettingKeys } from 'src/types/settings';
import { DummySettingsAutoSelect } from 'src/v2/components/LeftSidePanel/components/DummySettingsAutoSelect';
import { useSession } from 'src/hooks';
import { LabelUltra } from 'src/components/LabelUltra';
import {
  newExternalModelsList,
  SUPER_AGENT_DEEP_RESEARCH_MODEL,
} from 'src/constants';

export const NinjaModeSettings = () => {
  const {
    isUltraTier,
    isTeamsUltraTier,
    isEnterpriseTier,
    isEnterpriseTrialTier,
  } = useSession();

  const isVideoDisabled = !(
    isUltraTier ||
    isTeamsUltraTier ||
    isEnterpriseTier ||
    isEnterpriseTrialTier
  );

  const data = [
    {
      skill: TaskSkill.CHITCHAT,
      description: 'Writing, Coding & Brainstorming',
      label: (
        <span className={styles.skillsWrapper}>
          <SkillLabel skill={TaskSkill.CHITCHAT} />
          <SkillLabel skill={TaskSkill.CODING} />
        </span>
      ),
      selector: (
        <SettingsSelect
          settingKey={SettingKeys.WRITER_CODER_PRIMARY_MODEL}
          skill={TaskSkill.CHITCHAT}
        />
      ),
    },
    {
      skill: TaskSkill.IMAGE,
      description: 'Image Generation and Editing',
      label: <SkillLabel skill={TaskSkill.IMAGE} />,
      selector: (
        <SettingsSelect
          settingKey={SettingKeys.IMAGE_PRIMARY_MODEL}
          skill={TaskSkill.IMAGE}
        />
      ),
    },
    {
      skill: TaskSkill.RESEARCH,
      description: 'Real-Time Online Research',
      label: <SkillLabel skill={TaskSkill.RESEARCH} />,
      selector: (
        <SettingsSelect
          settingKey={SettingKeys.RESEARCH_PRIMARY_MODEL}
          skill={TaskSkill.RESEARCH}
        />
      ),
    },
    {
      skill: TaskSkill.DEEP_RESEARCH,
      description: 'Online deep research',
      label: <SkillLabel skill={TaskSkill.DEEP_RESEARCH} />,
      selector: (
        <DummySettingsAutoSelect
          skill={TaskSkill.DEEP_RESEARCH}
          iconName={
            newExternalModelsList[SUPER_AGENT_DEEP_RESEARCH_MODEL].icon_name
          }
          title={
            newExternalModelsList[SUPER_AGENT_DEEP_RESEARCH_MODEL].display_name
          }
        />
      ),
    },
    {
      skill: TaskSkill.DOCUMENT_QA,
      description: 'Analyze Attachments',
      label: <SkillLabel skill={TaskSkill.DOCUMENT_QA} />,
      selector: (
        <DummySettingsAutoSelect
          skill={TaskSkill.DOCUMENT_QA}
          iconName="ninjaOutlined"
        />
      ),
    },
    {
      skill: TaskSkill.VIDEO,
      description: 'Video Generation',
      label: <SkillLabel skill={TaskSkill.VIDEO} />,
      selector: (
        <DummySettingsAutoSelect
          title="Amazon Nova Reel"
          skill={TaskSkill.VIDEO}
          iconName="nova"
          disabled={isVideoDisabled}
          labelComponent={isVideoDisabled ? <LabelUltra /> : undefined}
        />
      ),
    },
    // {
    //   skill: TaskSkill.SCHEDULING,
    //   description: 'Set Reminders',
    //   label: <SkillLabel skill={TaskSkill.SCHEDULING} />,
    //   selector: (
    //     <DummySettingsAutoSelect
    //       skill={TaskSkill.SCHEDULING}
    //       iconName="ninjaOutlined"
    //     />
    //   ),
    // },
  ];

  return (
    <div className={styles.root}>
      <p className={styles.descriptionTitleText}>
        Select your preferred AI models for each skill
      </p>
      {data.map((skill) => (
        <div key={skill.skill}>
          <div className={styles.headerTextWrapper}>
            <p className={styles.title}>{skill.label}</p>
            <span className={styles.description}>{skill.description}</span>
            {skill.selector}
          </div>
          <hr className={styles.divider} />
        </div>
      ))}
    </div>
  );
};
