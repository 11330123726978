import { baseUsersV2Query } from './config';
import {
  Conversation,
  ConversationList,
  ConversationRequestArgs,
  ConversationUpdateRequest,
  ConversationsByPageRequest,
} from 'src/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import log from 'src/utils/logger';
import { DEFAULT_PAGE_LIMIT, DEFAULT_SORT_ORDER } from 'src/constants';
import {
  updateQueryGetConversationById,
  updateQueryGetConversations,
  updateQueryGetConversationsWithArchived,
} from '../updateQueries';
import { tasksApi } from './tasksApi';
import { TaskSkill } from 'src/types';

export enum ConversationsTags {
  Conversations = 'Conversations',
  ConversationById = 'ConversationById',
}

export const conversationApi = createApi({
  reducerPath: 'conversationApi',
  tagTypes: [
    ConversationsTags.Conversations,
    ConversationsTags.ConversationById,
  ],
  baseQuery: baseUsersV2Query,
  endpoints: (builder) => ({
    getConversations: builder.query<
      ConversationList,
      ConversationsByPageRequest
    >({
      query: ({
        user_id,
        page_token = '',
        include_archived = false,
        filter_task_skills,
        filter_task_states,
      }) => ({
        url: `${user_id}/top_conversations`,
        params: {
          sort_order: DEFAULT_SORT_ORDER,
          limit: DEFAULT_PAGE_LIMIT,
          include_archived,
          page_token,
          filter_task_skills,
          filter_task_states,
        },
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { include_archived, filter_task_skills, filter_task_states } =
          queryArgs;
        return { include_archived, filter_task_skills, filter_task_states };
      },
      merge: (currentCache, newData) => {
        currentCache?.data?.push(...newData.data);
        currentCache.page_token = newData.page_token;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          !!currentArg?.page_token &&
          currentArg?.page_token !== previousArg?.page_token
        );
      },
      providesTags: [ConversationsTags.Conversations],
    }),

    archiveAllConversations: builder.mutation<void, { user_id: string }>({
      query: ({ user_id }) => ({
        url: `${user_id}/archive_all`,
        method: 'POST',
      }),
      async onQueryStarted({ user_id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          log.error(error);
        } finally {
          dispatch(conversationApi.util.resetApiState());
          dispatch(tasksApi.util.resetApiState());

          dispatch(
            conversationApi.endpoints.getConversations.initiate({
              user_id,
              page_token: '',
              include_archived: false,
            }),
          );
        }
      },
    }),

    getConversationById: builder.query<Conversation, ConversationRequestArgs>({
      query: ({ user_id, conversation_id }) => ({
        url: `${user_id}/conversations/${conversation_id}?show_tasks=true&show_messages=true`,
      }),
      providesTags: (_, __, arg) => {
        return [
          { type: ConversationsTags.ConversationById, id: arg.conversation_id },
        ];
      },
    }),

    updateConversationById: builder.mutation<
      Conversation,
      ConversationUpdateRequest
    >({
      query: ({
        user_id,
        conversation_id,
        conversation_hash,
        state,
        model_ids,
      }) => ({
        url: `${user_id}/conversations/${conversation_id}`,
        method: 'PATCH',
        body: {
          user_id,
          conversation_id,
          conversation_hash,
          state,
          model_ids,
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const conversationByIdResult = dispatch(
          updateQueryGetConversationById(arg),
        );
        const conversationsResult = dispatch(updateQueryGetConversations(arg));
        const conversationsWithArchivedResult = dispatch(
          updateQueryGetConversationsWithArchived(arg),
        );

        const filteredConversationsResults = Object.values(TaskSkill).map(
          (filter) => dispatch(updateQueryGetConversations(arg, filter)),
        );

        try {
          await queryFulfilled;
        } catch {
          conversationByIdResult.undo();
          conversationsResult.undo();
          conversationsWithArchivedResult.undo();

          filteredConversationsResults.forEach((result) => result.undo());
        }
      },
    }),

    deleteConversationById: builder.mutation<
      Conversation,
      ConversationUpdateRequest
    >({
      query: ({ user_id, conversation_id }) => ({
        url: `${user_id}/conversations/${conversation_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, arg) => [
        { type: ConversationsTags.ConversationById, id: arg.conversation_id },
      ],
    }),
  }),
});

export const {
  useGetConversationsQuery,
  useGetConversationByIdQuery,
  useUpdateConversationByIdMutation,
  useDeleteConversationByIdMutation,
  useArchiveAllConversationsMutation,
} = conversationApi;
