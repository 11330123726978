import { HumanMessage } from '@langchain/core/messages';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useContext, useMemo, useState } from 'react';
import { TextareaSimple } from 'src/v2/commonComponents/TextareaSimple';

import { PaperPlaneRight } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import { useGraphContext } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/contexts/GraphContext';
import { convertToOpenAIFormat } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/lib/convert_messages';
import { v4 as uuidv4 } from 'uuid';
import { getEditorMarkdown, validatePrompt } from '../../utils';
import styles from './PromptInputBox.module.scss';

import type { ArtifactV3 } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/shared/types';
import classNames from 'classnames';
import { EditorType } from 'src/types';
import { useEditorState } from 'src/hooks';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import {
  getArtifactContentForCode,
  getArtifactContentForDocument,
} from 'src/utils';

export const PromptInputBox = () => {
  const { codeEditorRef } = useContext(ForwardRefContext);

  const [value, setValue] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const { editorType } = useEditorState();
  const [editor] = useLexicalComposerContext();
  const { graphData } = useGraphContext();
  const {
    artifact,
    setMessages,
    streamMessage,
    setArtifact,
    setArtifactContent,
  } = graphData;

  const clearedValue = useMemo(() => value.trim(), [value]);
  const isSubmitDisabled = useMemo(
    () => !clearedValue || isLoading,
    [clearedValue, isLoading],
  );

  const handleChange = (value: string) => {
    setErrorText('');
    const { validatedValue, isError, wordLimit } = validatePrompt(value);
    setValue(validatedValue);

    if (isError) {
      const errorText = `Word limit exceeded. The maximum allowed is ${new Intl.NumberFormat('en-US').format(wordLimit)}.`;
      setErrorText(errorText);
    }
  };

  const handleSubmit = async () => {
    if (isSubmitDisabled) {
      return;
    }

    setLoading(true);
    setValue('');

    const view = codeEditorRef.current;

    const fullDocumentContent = getEditorMarkdown(editor);
    const fullCodeContent = view?.state?.doc?.toString() || '';

    const humanMessage = new HumanMessage({
      content: value,
      id: uuidv4(),
    });

    setMessages((prevMessages) => [...prevMessages, humanMessage]);

    const temp_artifact: ArtifactV3 | undefined = !artifact
      ? editorType === EditorType.DOCUMENT
        ? getArtifactContentForDocument(fullDocumentContent)
        : getArtifactContentForCode(undefined, '')
      : undefined;

    if (!artifact) {
      setArtifact(() => temp_artifact); // we need to set the artifact in UI before streaming
    } else {
      if (editorType === EditorType.DOCUMENT) {
        setArtifactContent(artifact.currentIndex, fullDocumentContent);
      } else {
        setArtifactContent(artifact.currentIndex, fullCodeContent);
      }
    }

    await streamMessage({
      messages: [convertToOpenAIFormat(humanMessage)],
      ...(temp_artifact && { artifact: { ...temp_artifact } }),
    });

    setLoading(false);
  };

  return (
    <div className={styles.root}>
      <div
        className={classNames(styles.container, {
          [styles.error]: !!errorText,
        })}
      >
        <TextareaSimple
          className={styles.textarea}
          value={value}
          onChange={handleChange}
          placeholder="Prompt to edit the document"
          maxRows={4}
          minRows={2}
          onSubmit={handleSubmit}
        />

        <Button
          disabled={isSubmitDisabled}
          shape="round"
          color={clearedValue ? 'primary' : 'transparent'}
          onClick={handleSubmit}
        >
          <PaperPlaneRight
            weight={clearedValue ? 'fill' : 'regular'}
            size={SVG_SIZE_M}
          />
        </Button>
      </div>

      {errorText && <p className={styles.errorText}>{errorText}</p>}
    </div>
  );
};
