import { useContext, useMemo } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { escapeRegExp } from 'src/utils';
import { QUICK_SKILL_COMMANDS } from 'src/constants';
import { useSession } from './useSession';

type CommandsParams = { shouldSearch: boolean };

export const useCommands = ({ shouldSearch }: CommandsParams) => {
  const { threadInputBoxValue } = useContext(ThreadInputBoxContext);
  const { isSubscribedTier } = useSession();

  const matchingCommands = useMemo(() => {
    if (!shouldSearch) {
      return [];
    }

    if (!threadInputBoxValue) {
      return QUICK_SKILL_COMMANDS;
    }

    const regExp = new RegExp(`^${escapeRegExp(threadInputBoxValue)}`, 'gi');

    return (
      isSubscribedTier
        ? QUICK_SKILL_COMMANDS
        : QUICK_SKILL_COMMANDS.filter(({ isLimited }) => !isLimited)
    ).filter((item) => item.skill.match(regExp));
  }, [threadInputBoxValue, shouldSearch, isSubscribedTier]);

  return {
    matchingCommands,
  };
};
