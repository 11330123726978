import { Button } from 'src/v2/commonComponents/Button';
import { PaymentsSubscriptionInfo } from 'src/types';
import { useSession } from 'src/hooks';
import { toast } from 'react-toastify';
import log from 'src/utils/logger';
import {
  useResumeSubscriptionMutation,
  useUpgradeSubscriptionMutation,
} from 'src/store/services';

type RenewSubscriptionButtonProps = {
  subscriptionInfo?: PaymentsSubscriptionInfo;
  isSubscriptionInfoLoading?: boolean;
  isAppleOrAmpPayment?: boolean;
};

export const RenewSubscriptionButton = ({
  subscriptionInfo,
  isSubscriptionInfoLoading,
  isAppleOrAmpPayment,
}: RenewSubscriptionButtonProps) => {
  const { appUser } = useSession();

  const [upgradeSubscription, { isLoading: isUpgradeSubscriptionLoading }] =
    useUpgradeSubscriptionMutation();

  const [resumeSubscription, { isLoading: isResumeSubscriptionLoading }] =
    useResumeSubscriptionMutation();

  const isRenewDisabled =
    isResumeSubscriptionLoading ||
    isSubscriptionInfoLoading ||
    isUpgradeSubscriptionLoading ||
    subscriptionInfo?.processing_plan_change ||
    isAppleOrAmpPayment ||
    !!appUser?.stripe_info?.renew_early_pending_subscription;

  const handleRenewClick = async () => {
    if (!subscriptionInfo || !subscriptionInfo?.price_info?.lookup_key) {
      return;
    }

    if (!!subscriptionInfo && subscriptionInfo?.source === 'stripe') {
      try {
        const result = await upgradeSubscription({
          lookup_key: subscriptionInfo?.price_info?.lookup_key,
          user_id: appUser.user_id,
        }).unwrap();

        const redirectUrl =
          result.checkout_session?.url || result.billing_portal_session?.url;

        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      } catch (error) {
        toast.error('Failed to renew subscription.');
        log.error(error);
      }
    } else {
      try {
        await resumeSubscription(appUser.user_id).unwrap();
        toast.success('Subscription renewed successfully.');
      } catch (error) {
        toast.error('Failed to renew subscription.');
        log.error(error);
      }
    }
  };

  return (
    <Button
      color="secondary"
      disabled={isRenewDisabled}
      onClick={handleRenewClick}
    >
      Renew subscription
    </Button>
  );
};
