import { MessageSkill, TaskSkill } from 'src/types';
import { PillarBlend, PillarBlendSet } from 'src/types/recommendedSkills';
import PillarsBlendData from 'src/data/skill-examples.json';

const PILLARS_BLEND_DATA: PillarBlend = JSON.parse(
  JSON.stringify(PillarsBlendData),
);

const agentToTaskSkill = (key: string): TaskSkill => {
  switch (key) {
    case 'coder':
      return TaskSkill.CODING;
    case 'writer':
      return TaskSkill.CHITCHAT;
    case 'scheduler':
      return TaskSkill.SCHEDULING;
    case 'researcher':
      return TaskSkill.RESEARCH;
    case 'image':
      return TaskSkill.IMAGE;
    case 'video':
      return TaskSkill.VIDEO;
    default:
      throw new Error(`Unknown key: ${key}`);
  }
};

export const createRandomPillarSet = () => {
  const randomSet = {} as PillarBlendSet;

  for (const [key, value] of Object.entries(PILLARS_BLEND_DATA)) {
    const randomValue = value[Math.floor(Math.random() * value.length)];
    randomValue.agent = agentToTaskSkill(key);
    randomValue.command = key;
    randomSet[key as MessageSkill] = randomValue;
  }

  return randomSet;
};
