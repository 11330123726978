import { useCallback, useContext } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { useCommands, useSession } from 'src/hooks';
import { useQuickPickerControls } from 'src/hooks';
import { PortalWrapper } from 'src/components/PortalWrapper';
import styles from './QuickCommandPicker.module.scss';
import { CommandPickerButton } from '../CommandPickerButton';

const anchorId = '#input-box-anchor-tooltip';

interface QuickCommandPickerProps {
  isOpen: boolean;
  onClose: () => void;
  setCursorPosition: (position: number) => void;
}

export const QuickCommandPicker = ({
  isOpen,
  onClose,
  setCursorPosition,
}: QuickCommandPickerProps) => {
  const { setThreadInputBoxValue } = useContext(ThreadInputBoxContext);
  const { isSubscribedTier } = useSession();

  const { matchingCommands } = useCommands({
    shouldSearch: isOpen,
  });

  const handleCommandClick = useCallback(
    (index: number) => {
      const command = matchingCommands[index].skill;
      const insertValue = `${command} `;
      setThreadInputBoxValue(insertValue);
      setCursorPosition(insertValue.length);
    },
    [matchingCommands, setThreadInputBoxValue, setCursorPosition],
  );

  const { selectedIndex } = useQuickPickerControls({
    options: matchingCommands,
    isOpen,
    shouldFocusInputBox: true,
    onClose,
    onItemClick: handleCommandClick,
  });

  if (!isOpen || matchingCommands.length === 0) {
    return null;
  }

  return (
    <PortalWrapper>
      <div className={styles.root}>
        <ReactTooltip
          anchorSelect={anchorId}
          isOpen={true}
          clickable
          noArrow
          openOnClick
          opacity={1}
          place="top-start"
          offset={48}
          className={styles.tooltip}
        >
          <div className={styles.container}>
            {matchingCommands.map(
              ({ skill, description, Icon, isLimited }, index) =>
                isLimited && !isSubscribedTier ? (
                  <></>
                ) : (
                  <CommandPickerButton
                    key={skill}
                    skill={skill}
                    description={description}
                    icon={Icon}
                    onClick={() => handleCommandClick(index)}
                    onClose={onClose}
                    isSelected={index === selectedIndex}
                  />
                ),
            )}
          </div>
        </ReactTooltip>
      </div>
    </PortalWrapper>
  );
};
