import styles from './CancelRenewSection.module.scss';
import { useSubscriptionInfo, useVisible } from 'src/hooks';
import {
  DrawerTooltipContainer,
  DrawerTooltipContainerRef,
} from 'src/v2/commonComponents/DrawerTooltipContainer';
import { useMemo, useRef } from 'react';
import { CancelSubscriptionButton } from 'src/v2/pages/subscription/components/CancelSubscriptionButton';
import { MoreOptionsToggle } from 'src/v2/pages/subscription/components/MoreOptionsToggle';
import { RenewSubscriptionButton } from 'src/v2/pages/subscription/components/RenewSubscriptionButton';
import { CancelSubscriptionModal } from 'src/pages/PaymentBetaPage/components/CancelSubscriptionModal';
import dayjs from 'dayjs';
import { PAYMENTS_DATE_FORMAT } from 'src/constants';

const tooltipAnchorId = 'cancel-subscription-button-tooltip-anchor';

export const CancelRenewSection = () => {
  const { subscriptionInfo, isAppleOrAmpPayment, isSubscriptionInfoLoading } =
    useSubscriptionInfo();

  const drawerRef = useRef<DrawerTooltipContainerRef>(null);

  const { isVisible, onVisibilitySet, onVisibilityRemove } = useVisible();

  const cancelledDate = useMemo(() => {
    return dayjs(subscriptionInfo?.curr_period_end_timestamp).format(
      PAYMENTS_DATE_FORMAT,
    );
  }, [subscriptionInfo?.curr_period_end_timestamp]);

  const handleClick = () => {
    drawerRef.current?.onVisibilityToggle();
    if (subscriptionInfo?.source === 'paypal') {
      onVisibilitySet();
    }
  };

  return (
    <div className={styles.root}>
      {!subscriptionInfo?.is_cancelled && (
        <DrawerTooltipContainer
          ref={drawerRef}
          id={tooltipAnchorId}
          place="bottom-start"
          ToggleButton={MoreOptionsToggle}
          onlyTooltip
          classNameDrawer={styles.root}
        >
          <div className={styles.container}>
            <CancelSubscriptionButton
              onClick={handleClick}
              subscriptionInfo={subscriptionInfo}
              isSubscriptionInfoLoading={isSubscriptionInfoLoading}
              isAppleOrAmpPayment={isAppleOrAmpPayment}
            />
          </div>
        </DrawerTooltipContainer>
      )}
      {subscriptionInfo?.is_cancelled && (
        <RenewSubscriptionButton
          subscriptionInfo={subscriptionInfo}
          isAppleOrAmpPayment={isAppleOrAmpPayment}
          isSubscriptionInfoLoading={isSubscriptionInfoLoading}
        />
      )}
      <CancelSubscriptionModal
        cancellationDate={cancelledDate}
        isOpen={isVisible}
        onClose={onVisibilityRemove}
      />
    </div>
  );
};
