import classNames from 'classnames';
import avatarAtlas from 'src/images/avatars/AiAvatarAtlas.png';
import attention from 'src/images/icons/AttentionDot.svg';
import company from 'src/images/icons/Windows.png';
import defaultAvatar from 'src/images/icons/defaultAvatar.svg';
import waveform from 'src/images/icons/waveform.svg';
import ninjaIconPNG from 'src/images/loader/ninja-head-new.png';
import openaiIcon from 'src/images/logos/openai-icon.svg';
import gmailLogo from 'src/images/logos/gmail-logo.svg';
import slackLogo from 'src/images/logos/slack-logo.svg';
import calendar from 'src/images/icons/calendar.png';
import contact from 'src/images/icons/contact.png';
import googleWhite from 'src/images/logos/google-white-logo.svg';
import chatGPTLogo from 'src/images/logos/chat-gpt.svg';
import googleGeminiProLogo from 'src/images/logos/google-gemini-pro-logo.svg';
import claudeLogo from 'src/images/logos/anthropic-claude-logo.svg';
import metaLogo from 'src/images/logos/meta-logo.svg';
import googlePalmLogo from 'src/images/logos/google-palm-logo.svg';
import envelope from 'src/images/icons/envelope.svg';
import listGradient from 'src/images/icons/list-gradient.svg';
import googleCalendarLogo from 'src/images/logos/google-calendar-logo.svg';
import outlookLogo from 'src/images/logos/outlook-logo.svg';
import awsLogo from 'src/images/logos/aws-logo.png';
import cohereLogo from 'src/images/logos/cohere-logo.svg';
import jurassicLogo from 'src/images/logos/jurassic-logo.png';
import mistralLogo from 'src/images/logos/mistral-logo.png';
import bedrockStableLogo from 'src/images/logos/bedrock-stable-logo.svg';
import ninjaPixProLogo from 'src/images/logos/ninja-pix-pro-logo.svg';
import googleColorShortLogo from 'src/images/logos/google-logo.svg';
import wolframAlfaLogo from 'src/images/logos/wolfram-alfa-logo.svg';
import nova from 'src/images/logos/nova.svg';

import { getCircleColor } from 'src/utils';
import { useMemo } from 'react';
import { ChatGPTLogo } from 'src/images/logos/chat-gpt';
import { AWSLogo } from 'src/images/logos/aws-logo';
import { AnthropicLogo } from 'src/images/logos/anthropic-logo';
import { NinjaAgentsOutlinedIcon } from 'src/images/icons/ninjaAgentsOutlined';
import { HeadCircuit } from '@phosphor-icons/react';
import { SentimentVeryDissatisfied } from 'src/images/icons/emoji/SentimentVeryDissatisfied';
import { SentimentDissatisfied } from 'src/images/icons/emoji/SentimentDissatisfied';
import { SentimentNeutral } from 'src/images/icons/emoji/SentimentNeutral';
import { SentimentSatisfied } from 'src/images/icons/emoji/SentimentSatisfied';
import { SentimentVerySatisfied } from 'src/images/icons/emoji/SentimentVerySatisfied';
import { NinjaMainIcon } from 'src/images/logos/NinjaMainIcon';
import { DeepSeekLogo } from 'src/images/logos/deepSeekLogo';
import { Ios } from 'src/images/icons/ios';
import { Android } from 'src/images/icons/android';
import { Landscape } from 'src/images/icons/landscape';
import { Portrait } from 'src/images/icons/portrait';
import { Square } from 'src/images/icons/square';
import { DownloadAppsQr } from 'src/images/icons/downloadAppsQr';
import { Flux } from 'src/images/icons/flux';
import { Ninja404Logo } from 'src/images/icons/ninja404Logo';
import { DocumentsFolder } from 'src/images/icons/documentsFolder';

const iconMap = {
  company,
  attention,
  ninjaIconPNG,
  openaiIcon,
  avatarAtlas,
  defaultAvatar,
  waveform,
  gmailLogo,
  slackLogo,
  calendar,
  contact,
  googleWhite,
  chatGPTLogo,
  claudeLogo,
  metaLogo,
  googlePalmLogo,
  googleGeminiProLogo,
  envelope,
  googleCalendarLogo,
  outlookLogo,
  listGradient,
  awsLogo,
  cohereLogo,
  jurassicLogo,
  mistralLogo,
  bedrockStableLogo,
  ninjaPixProLogo,
  googleColorShortLogo,
  wolframAlfaLogo,
  nova,
  ios: 'ios',
  android: 'android',
  deepSeekLogo: 'deepSeekLogo',
  ninjaOutlined: 'ninjaOutlined',
  superAgent: 'superAgent',
  ninjaMainIcon: 'ninjaMainIcon',
  'sentiment-very-dissatisfied': 'sentiment-very-dissatisfied',
  'sentiment-dissatisfied': 'sentiment-dissatisfied',
  'sentiment-neutral': 'sentiment-neutral',
  'sentiment-satisfied': 'sentiment-satisfied',
  'sentiment-very-satisfied': 'sentiment-very-satisfied',
  square: 'square',
  downloadAppsQr: 'downloadAppsQr',
  flux: 'flux',
  ninja404Logo: 'ninja404Logo',
  documentsFolder: 'documentsFolder',
  portrait: 'portrait',
  landscape: 'landscape',
} as const;

export type IconName = keyof typeof iconMap;

type IconProps = {
  type?: IconName | null;
  alt?: string;
  height?: number;
  width?: number;
  size?: number;
  masterColor?: string;
  weight?: 'fill' | 'regular';
  rest?: { [key: string]: React.ReactNode };
};

/**
 * Icon component displays icon with an image based
 * on the props provided, or a color circle instead.
 */
// TODO(olha): needs refactoring. We should use SVG for icons
export const Icon = ({
  type,
  size = 32,
  height,
  width,
  alt = '',
  masterColor = '',
  weight = 'regular',
  ...rest
}: IconProps) => {
  const custom = useMemo(() => {
    switch (type) {
      case 'awsLogo':
        return <AWSLogo />;
      case 'chatGPTLogo':
        return <ChatGPTLogo />;
      case 'claudeLogo':
        return <AnthropicLogo />;
      case 'ninjaOutlined':
        return <NinjaAgentsOutlinedIcon width={size} height={size} />;
      case 'superAgent':
        return <HeadCircuit size={size} />;
      case 'ninjaMainIcon':
        return <NinjaMainIcon width={size} height={size} />;
      case 'deepSeekLogo':
        return <DeepSeekLogo width={size} height={size} />;
      case 'ios':
        return <Ios width={size} height={size} />;
      case 'android':
        return <Android width={size} height={size} />;

      case 'sentiment-very-dissatisfied':
        return (
          <SentimentVeryDissatisfied
            weight={weight}
            width={size}
            height={size}
          />
        );

      case 'sentiment-dissatisfied':
        return (
          <SentimentDissatisfied weight={weight} width={size} height={size} />
        );

      case 'sentiment-neutral':
        return <SentimentNeutral weight={weight} width={size} height={size} />;

      case 'sentiment-satisfied':
        return (
          <SentimentSatisfied weight={weight} width={size} height={size} />
        );

      case 'sentiment-very-satisfied':
        return (
          <SentimentVerySatisfied weight={weight} width={size} height={size} />
        );

      case 'square':
        return <Square width={size} height={size} />;

      case 'portrait':
        return <Portrait width={size} height={size} />;

      case 'landscape':
        return <Landscape width={size} height={size} />;

      case 'downloadAppsQr':
        return <DownloadAppsQr width={size} height={size} />;

      case 'flux':
        return <Flux width={size} height={size} />;

      case 'ninja404Logo':
        return <Ninja404Logo width={size} height={size} />;

      case 'documentsFolder':
        return <DocumentsFolder width={size} height={size} />;

      default:
        return null;
    }
  }, [type, size, weight]);

  const iconType = type ? iconMap[type] : null;
  const styles = {
    width: `${width || size}px`,
    height: `${height || size}px`,
    lineHeight: `${height || size}px`,
  };

  const iconColor = useMemo(
    () => masterColor || getCircleColor(),
    [masterColor],
  );

  if (custom) {
    return (
      <span className="icon" style={styles}>
        {custom}
      </span>
    );
  }

  return (
    <span className="icon" style={styles}>
      {iconType ? (
        <img
          referrerPolicy="no-referrer"
          src={iconType}
          alt={alt}
          width={width || size}
          height={height || size}
          {...rest}
        />
      ) : (
        <span
          data-testid="circle-icon"
          className={classNames('circle', iconColor)}
        >
          {alt}
        </span>
      )}
    </span>
  );
};
