import { isObject, isString, isBoolean } from './general.guard';
import { Conversation } from '../api';
import { isMessage } from './api.message.guard';
import log from 'src/utils/logger';

/**
 * isConversation() checks data against Conversation data type.
 * @param data unknown
 * @returns boolean
 */
export function isPartialConversation(
  data: unknown,
): data is Partial<Conversation> {
  const conversation = data as Conversation;

  if (!isObject(conversation)) {
    log.debug(
      `isPartialConversation > not an object ${JSON.stringify(conversation)}`,
    );
    return false;
  }

  if (
    'conversation_id' in conversation &&
    !isString(conversation.conversation_id)
  ) {
    log.debug(
      `isPartialConversation > invalid conversation_id ${JSON.stringify(
        conversation,
      )}`,
    );
    return false;
  }

  if ('user_id' in conversation && !isString(conversation.user_id)) {
    log.debug(
      `isPartialConversation > invalid user_id ${JSON.stringify(conversation)}`,
    );
    return false;
  }

  if ('timestamp' in conversation && !isString(conversation.timestamp)) {
    log.debug(
      `isPartialConversation > invalid timestamp ${JSON.stringify(
        conversation,
      )}`,
    );
    return false;
  }

  if ('messages' in conversation && Array.isArray(conversation.messages)) {
    for (const msg of conversation.messages) {
      if (!isMessage(msg)) {
        log.debug(
          `isPartialConversation > invalid message ${JSON.stringify(
            msg,
          )} in ${JSON.stringify(conversation)}`,
        );
        return false;
      }
    }
  }

  if (
    'conversation_hash' in conversation &&
    !isString(conversation.conversation_hash)
  ) {
    log.debug(
      `isPartialConversation > invalid conversation_hash ${JSON.stringify(
        conversation,
      )}`,
    );
    return false;
  }

  if (
    'is_top_conversation' in conversation &&
    !isBoolean(conversation.is_top_conversation)
  ) {
    log.debug(
      `isPartialConversation > invalid is_top_conversation ${JSON.stringify(
        conversation,
      )}`,
    );
    return false;
  }

  if ('tasks' in conversation && !Array.isArray(conversation.tasks)) {
    log.debug(
      `isPartialConversation > not an array tasks ${JSON.stringify(
        conversation,
      )}`,
    );
    return false;
  }

  if ('created_at' in conversation && !isString(conversation.created_at)) {
    log.debug(
      `isPartialConversation > invalid created_at ${JSON.stringify(
        conversation,
      )}`,
    );
    return false;
  }

  if ('updated_at' in conversation && !isString(conversation.updated_at)) {
    log.debug(
      `isPartialConversation > invalid updated_at ${JSON.stringify(
        conversation,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isConversation() checks data against Conversation data type.
 * @param data unknown
 * @returns boolean
 */
export function isConversation(data: unknown): data is Conversation {
  const conversation = data as Conversation;

  if (!isPartialConversation(conversation)) {
    log.debug(
      `isConversation > not a partial conversation ${JSON.stringify(
        conversation,
      )}`,
    );
    return false;
  }

  if (!('user_id' in conversation)) {
    log.debug(
      `isConversation > mandatory fields (user_id) missing in ${JSON.stringify(
        conversation,
      )}`,
    );
    return false;
  }

  return true;
}
