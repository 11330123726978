/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ConversationRole {
  AGENT = 'agent',
  SYSTEM = 'system',
  USER = 'user',
  FUNCTION = 'function',
}
