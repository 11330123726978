import { useMemo } from 'react';
import {
  useBreakpoint,
  useCurrentConversation,
  useLeftPanelState,
  useRightSidePanelState,
} from 'src/hooks';
import { AddConversationButton } from 'src/pages/ManageTasksChatPage/components/MultiConversationsListPanel/components/AddConversationButton';
import { MainNavToggleButton } from 'src/pages/ManageTasksChatPage/components/ConversationsHeader/components/MainNavToggleButton';
import styles from './ConversationsHeader.module.scss';
import { ChatThreadModelSelector } from 'src/v2/components/ChatThreadModelSelector';
import cn from 'classnames';
import { convertTaskHashToTitle, isBetaEnv } from 'src/utils';
import { NewContentButton } from 'src/v2/components/NewContentButton';
import { ConversationMenu } from 'src/v2/components/ConversationsList/components/ConversationMenu';

export const ConversationsHeader = () => {
  const { isMobileOrTablet, isDesktopAndUp } = useBreakpoint();
  const { currentConversation } = useCurrentConversation();
  const { isLeftPanelExpanded } = useLeftPanelState();
  const { isRightPanelExpanded } = useRightSidePanelState();

  const headerTitle = useMemo(
    () => currentConversation?.conversation_hash?.replace('#', '') || '',
    [currentConversation],
  );

  return (
    <header className={styles.root}>
      {isMobileOrTablet && <MainNavToggleButton />}

      <ChatThreadModelSelector />

      {headerTitle &&
        isDesktopAndUp &&
        !(isLeftPanelExpanded && isRightPanelExpanded) && (
          <h4 className={styles.desktopTitle}>
            {convertTaskHashToTitle(headerTitle)}
          </h4>
        )}

      <div
        className={cn(styles.actionWrapper, styles.rightSideButtons, {
          [styles.actionWrapperDesktopWidth]:
            !isMobileOrTablet && !isRightPanelExpanded, // todo it is temporary till we implement active button here
        })}
      >
        {!!currentConversation && (
          <ConversationMenu conversation={currentConversation} align="end" />
        )}

        {isBetaEnv && isDesktopAndUp ? (
          <NewContentButton />
        ) : (
          <AddConversationButton
            appearance="gradient-button"
            className="nj-thread-header--mobile-create-new-chat"
          />
        )}
      </div>
    </header>
  );
};
