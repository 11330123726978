import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { MessageTabPersistRegistry } from 'src/types';

export interface MessageTabPersistRegistryPayload {
  messageId: string;
  tabName: string;
  isRead: boolean | null;
}

export interface ThreadState {
  messageTabPersistRegistry: MessageTabPersistRegistry;
}

const initialState = {
  // (olha): we save to the persist a "red dot" icon for each tab during streaming. It's a read/unread tab implementation on the FE side
  messageTabPersistRegistry: {},
} as ThreadState;

export const threadPersistSlice = createSlice({
  name: 'threadPersist',
  initialState,
  reducers: {
    setMessageTabPersistRegistry: (
      state,
      { payload }: PayloadAction<MessageTabPersistRegistryPayload>,
    ) => {
      state.messageTabPersistRegistry = {
        ...state.messageTabPersistRegistry,
        [payload.messageId]: {
          ...state.messageTabPersistRegistry?.[payload.messageId],
          [payload.tabName]: {
            isRead: payload.isRead,
          },
        },
      };
    },
  },
});

export const messageTabPersistRegistryState = (state: RootState) =>
  state.threadPersist.messageTabPersistRegistry as MessageTabPersistRegistry;

export const { setMessageTabPersistRegistry } = threadPersistSlice.actions;

export default threadPersistSlice.reducer;
