import { SwitchSimple } from 'src/v2/commonComponents/SwitchSimple';
import { SettingKeys, ToggleSetting } from 'src/types/settings';
import { useUserSettings } from 'src/hooks';

interface SettingsSwitchProps {
  settingKey: SettingKeys;
}

export const SettingsSwitch = ({
  settingKey,
  ...rest
}: SettingsSwitchProps) => {
  const { setting, updateUserSetting } =
    useUserSettings<ToggleSetting>(settingKey);
  return (
    <SwitchSimple
      isDisabled={
        !setting?.user_editable || !setting?.options['true'].selectable
      }
      isChecked={!!setting?.value}
      onChange={updateUserSetting}
      {...rest}
    />
  );
};
