import { GlobeSimple } from '@phosphor-icons/react';
import { SearchWebPageReference } from 'src/types';
import { useVisible } from 'src/hooks';
import { SVG_SIZE_S } from 'src/constants';

interface ResearchReferenceProps extends SearchWebPageReference {
  index: number;
}

export const ResearchReference = ({
  url,
  title,
  favicon,
  source,
  index,
}: ResearchReferenceProps) => {
  const { isVisible: isDefaultIconVisible, onVisibilitySet } =
    useVisible(!favicon);

  return (
    <div className="nj-thread-research-card--reference-item">
      <a
        className="nj-thread-research-card--reference-link"
        title={title || ''}
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <div className="nj-thread-research-card--reference-header">
          <span className="nj-thread-research-card--reference-item-index">
            {index + 1}
          </span>

          {!isDefaultIconVisible ? (
            <img
              src={favicon || undefined}
              alt={title || ''}
              height={SVG_SIZE_S}
              onError={onVisibilitySet}
            />
          ) : (
            <GlobeSimple size={SVG_SIZE_S} />
          )}

          <p className="nj-thread-research-card--reference-item-title">
            {source || `Web Source`}
          </p>
        </div>
      </a>
    </div>
  );
};
