import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './SelectorButton.module.scss';
import { Icon, IconName } from 'src/components/Icon';

interface SelectorButtonProps {
  id: string;
  disabled?: boolean;
  onClick: () => void;
  label?: string;
  isActive?: boolean;
  icon?: IconName;
}

export const SelectorButton = ({
  id,
  disabled,
  onClick,
  label,
  isActive,
  icon,
}: SelectorButtonProps) => {
  return (
    <Button
      id={id}
      disabled={disabled}
      color="transparent"
      onClick={onClick}
      className={styles.root}
    >
      <div className={styles.valueWrapper}>
        {icon && <Icon type={icon} size={SVG_SIZE_M} />}
        <span>{label}</span>
      </div>
      {isActive ? (
        <CaretUp size={SVG_SIZE_M} />
      ) : (
        <CaretDown size={SVG_SIZE_M} />
      )}
    </Button>
  );
};
