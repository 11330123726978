import { Modal } from 'src/components/Modal';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './CommonDeleteModal.module.scss';

interface CommonDeleteModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
  target: string;
  title: string;
}

export const CommonDeleteModal = ({
  isOpen,
  isLoading,
  target,
  title,
  onClose,
  onSubmit,
}: CommonDeleteModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={styles.root}
      overlayClassName={styles.overlay}
    >
      <h5 className={styles.title}>{title}</h5>

      <p>
        Are you sure you want to delete{' '}
        <span className={styles.chatTitle}>{target}</span>? This action cannot
        be undone.
      </p>

      <div className={styles.buttonsWrapper}>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="error" onClick={onSubmit} disabled={isLoading}>
          Delete
        </Button>
      </div>
    </Modal>
  );
};
