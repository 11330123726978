import { useContext, useImperativeHandle, useMemo, useRef } from 'react';
import { AppearingFromRight } from 'src/animations/AppearingFromRight';
import {
  useBreakpoint,
  useLeftPanelState,
  useRightSidePanelActions,
  useRightSidePanelState,
} from 'src/hooks';
import { RightPanelSections } from 'src/types';
import { Editor } from '../Editor';
import { useClearEditor } from '../Editor/hooks/useClearEditor';
import { MaskImageEditor } from '../MaskImageEditor';
import { useGraphContext } from '../Editor/plugins/NinjaCanvasPlugin/contexts/GraphContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { useLoadDefaultEditorContent } from '../Editor/hooks/useLoadDefaultEditorContent';
import {
  getArtifactContentForCode,
  getArtifactContentForDocument,
} from 'src/utils';

// TODO(olha): check design for Tablet. Add outside click if needed
export const RightSidePanel = () => {
  const { codeEditorHandlerRef } = useContext(ForwardRefContext);

  const { isRightPanelExpanded, rightPanelSections, isRightPanelFullWidth } =
    useRightSidePanelState();
  const { isLeftPanelExpanded } = useLeftPanelState();
  const { resetAndCloseRightSidePanelData } = useRightSidePanelActions();
  const { clearEditor } = useClearEditor();
  const { graphData } = useGraphContext();

  useLoadDefaultEditorContent();

  const { setArtifact } = graphData;

  const {
    isTablet,
    isDesktopLAndUp,
    isLaptopLAndUp,
    isDesktopAndUp,
    isDesktopXLAndUp,
    isUltraWideScreen,
  } = useBreakpoint();
  const ref = useRef<HTMLDivElement>(null);

  useImperativeHandle(codeEditorHandlerRef, () => ({
    createCodeArtifact: (language, content) => {
      const newArtifact = getArtifactContentForCode(language, content);

      setArtifact(newArtifact);
    },
    createDocumentArtifact: (content) => {
      const newArtifact = getArtifactContentForDocument(content);

      setArtifact(newArtifact);
    },
  }));

  const Component = useMemo(() => {
    switch (rightPanelSections) {
      case RightPanelSections.IMAGE:
        return <MaskImageEditor />;
      case RightPanelSections.EDITOR:
        return <Editor />;
      default:
        return <></>;
    }
  }, [rightPanelSections]);

  const panelWidth = useMemo(() => {
    switch (rightPanelSections) {
      case RightPanelSections.IMAGE:
        if (isTablet) {
          return 'calc(100vw - 60px)';
        }
        if (isLaptopLAndUp) {
          return 680;
        }
        if (isDesktopLAndUp) {
          return 976;
        }
        return '100vw';

      case RightPanelSections.EDITOR:
        if (isUltraWideScreen) {
          return isLeftPanelExpanded ? 750 : 930;
        }
        if (isDesktopXLAndUp) {
          return isLeftPanelExpanded ? 540 : 690;
        }
        if (isDesktopLAndUp) {
          return isLeftPanelExpanded ? 460 : 660;
        }
        if (isDesktopAndUp) {
          return isLeftPanelExpanded ? 380 : 600;
        }

        return 0;

      // default not used for now
      default:
        if (isTablet) {
          return 'calc(100vw - 60px)';
        }
        if (isLaptopLAndUp) {
          return 680;
        }
        if (isDesktopLAndUp) {
          return 820;
        }
        return '100vw';
    }
  }, [
    rightPanelSections,
    isTablet,
    isLaptopLAndUp,
    isDesktopLAndUp,
    isDesktopXLAndUp,
    isDesktopAndUp,
    isLeftPanelExpanded,
    isUltraWideScreen,
  ]);

  const handleExited = () => {
    resetAndCloseRightSidePanelData();
    clearEditor();
  };

  return (
    <AppearingFromRight
      inProp={isRightPanelExpanded}
      nodeRef={ref}
      onExited={handleExited}
      width={panelWidth}
      fullWidth={isRightPanelFullWidth}
    >
      {Component}
    </AppearingFromRight>
  );
};
