/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TaskState {
  ARCHIVED = 'archived',
  BLOCKED = 'blocked',
  DONE = 'done',
  FAILED = 'failed',
  IN_PROGRESS = 'in_progress',
  UNDO_IN_PROGRESS = 'undo_in_progress',
  HALTING = 'halting',
  STOPPING = 'stopping',
  STOPPED = 'stopped',
}
