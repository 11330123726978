import { useMemo } from 'react';
import {
  useBreakpoint,
  useLeftPanelActions,
  useLeftPanelState,
} from 'src/hooks';
import { ConversationListSection } from 'src/v2/components/LeftSidePanel/components/ConversationListSection';
import { LeftPanelSections, TaskSkill } from 'src/types';
import { SectionAnimatedWrapper } from '../SectionAnimatedWrapper';
import { ImageSettings } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/ImageSettings';
import { Button } from 'src/v2/commonComponents/Button';
import { X } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import styles from './LeftPanelSection.module.scss';
import classNames from 'classnames';
import cn from 'classnames';
import { NavSidebar } from '../NavSidebar';
import { UserMenuSection } from '../UserMenuSection';
import { WriterCoderSection } from '../WriterCoderSection';
import { ResearcherSection } from '../ResearcherSection';
import { ImageGenSection } from '../ImageGenSection';
import { VideoGenSection } from '../VideoGenSection';
import { FileAgentSection } from '../FileAgentSection';
import { SchedulerSection } from '../SchedulerSection';
import { SkillLabel } from 'src/components/SkillLabel';
import { AddConversationButton } from 'src/pages/ManageTasksChatPage/components/MultiConversationsListPanel/components/AddConversationButton';
import { SettingsSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/SettingsSection';
import { FileListSection } from '../FileListSection';
import { AddDocumentControl } from '../AddDocumentControl';

export const LeftPanelSection = () => {
  const { leftPanelSections } = useLeftPanelState();
  const { toggleLeftPanelExpanded } = useLeftPanelActions();

  const { isMobile } = useBreakpoint();

  const sectionTitle: string | JSX.Element = useMemo(() => {
    switch (leftPanelSections) {
      case LeftPanelSections.CONVERSATION_LIST:
        return 'Chat History';
      case LeftPanelSections.WRITER_CODER:
        return (
          <span className={styles.skillsWrapper}>
            <SkillLabel skill={TaskSkill.CHITCHAT} size="medium" />
            <SkillLabel skill={TaskSkill.CODING} size="medium" />
          </span>
        );
      case LeftPanelSections.IMAGE_GEN:
        return <SkillLabel skill={TaskSkill.IMAGE} size="medium" />;
      case LeftPanelSections.RESEARCHER:
        return <SkillLabel skill={TaskSkill.RESEARCH} size="medium" />;
      case LeftPanelSections.FILE_AGENT:
        return <SkillLabel skill={TaskSkill.DOCUMENT_QA} size="medium" />;
      case LeftPanelSections.VIDEO:
        return <SkillLabel skill={TaskSkill.VIDEO} size="medium" />;
      case LeftPanelSections.SCHEDULER:
        return <SkillLabel skill={TaskSkill.SCHEDULING} size="medium" />;
      case LeftPanelSections.AGENTS_SETTINGS:
        return 'Ninja settings';
      case LeftPanelSections.USER_MENU:
        return 'Account';
      case LeftPanelSections.SETTINGS:
        return 'Settings';
      case LeftPanelSections.FILE_LIST:
        return 'Files';
      default:
        return '';
    }
  }, [leftPanelSections]);

  const sectionDescription: string = useMemo(() => {
    switch (leftPanelSections) {
      case LeftPanelSections.WRITER_CODER:
        return 'Writing, Coding & Brainstorming';
      case LeftPanelSections.IMAGE_GEN:
        return 'Image Generation and Editing';
      case LeftPanelSections.RESEARCHER:
        return 'Real-Time Online Research';
      case LeftPanelSections.FILE_AGENT:
        return 'Analyze Attachments';
      case LeftPanelSections.VIDEO:
        return 'Video Generation';
      case LeftPanelSections.SCHEDULER:
        return 'Set Reminders';
      default:
        return '';
    }
  }, [leftPanelSections]);

  const section = useMemo(() => {
    switch (leftPanelSections) {
      case LeftPanelSections.CONVERSATION_LIST:
        return <ConversationListSection />;
      case LeftPanelSections.WRITER_CODER:
        return <WriterCoderSection />;
      case LeftPanelSections.IMAGE_GEN:
        return <ImageGenSection />;
      case LeftPanelSections.RESEARCHER:
        return <ResearcherSection />;
      case LeftPanelSections.FILE_AGENT:
        return <FileAgentSection />;
      case LeftPanelSections.VIDEO:
        return <VideoGenSection />;
      case LeftPanelSections.SCHEDULER:
        return <SchedulerSection />;
      case LeftPanelSections.AGENTS_SETTINGS:
        return <ImageSettings />;
      case LeftPanelSections.USER_MENU:
        return <UserMenuSection />;
      case LeftPanelSections.SETTINGS:
        return <SettingsSection />;
      case LeftPanelSections.FILE_LIST:
        return <FileListSection />;

      default:
        return <></>;
    }
  }, [leftPanelSections]);

  const onCloseSection = () => {
    toggleLeftPanelExpanded();
  };

  const hasDescription = sectionDescription.length > 0;

  return (
    <SectionAnimatedWrapper>
      <div className={styles.root}>
        {isMobile && <NavSidebar />}

        <div className={styles.container}>
          <div
            className={cn(styles.header, {
              [styles.withDescription]: hasDescription,
            })}
          >
            <div className={styles.headerTextWrapper}>
              <p className={styles.title}>{sectionTitle}</p>
              {hasDescription && (
                <span className={styles.description}>{sectionDescription}</span>
              )}
            </div>

            <div className={styles.actionsWrapper}>
              {leftPanelSections !== LeftPanelSections.USER_MENU &&
                leftPanelSections !== LeftPanelSections.FILE_LIST && (
                  <AddConversationButton appearance="pencil-icon" />
                )}

              {leftPanelSections === LeftPanelSections.FILE_LIST && (
                <AddDocumentControl />
              )}

              <Button
                color="transparent"
                onClick={onCloseSection}
                data-e2e="hide-menu-button"
              >
                <X size={SVG_SIZE_M} />
              </Button>
            </div>
          </div>

          <div
            className={classNames(styles.sectionWrapper, {
              [styles.withScroll]:
                leftPanelSections === LeftPanelSections.USER_MENU,
            })}
          >
            {section}
          </div>
        </div>
      </div>
    </SectionAnimatedWrapper>
  );
};
