import { Message, MessageChunk, MessageFooter, MessageType } from 'src/types';
import { conversationApi } from '../services/conversationApi';
import { DEFAULT_CHAT_ID } from 'src/constants';
import { recursiveDeepMerge, recursiveUpdatePayload } from 'src/utils';

export const addMessageToConversation = (message: Message) => {
  const { user_id, conversation_id } = message;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id: conversation_id || DEFAULT_CHAT_ID },
    (draft) => {
      if (draft) {
        draft.messages
          ? draft.messages.push(message)
          : (draft.messages = [message]);
      }
    },
  );
};

export const replaceConversationChatTemporaryMessageId = ({
  temporaryMessageId,
  message: newMessage,
}: {
  temporaryMessageId: string;
  message: Message;
}) => {
  const { user_id, conversation_id } = newMessage;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id: conversation_id || DEFAULT_CHAT_ID },
    (draft) => {
      const updatedMessageIndex = draft?.messages?.findIndex(
        (item) => item.message_id === temporaryMessageId,
      );

      if (
        updatedMessageIndex &&
        draft?.messages &&
        updatedMessageIndex !== -1
      ) {
        draft.messages[updatedMessageIndex] = newMessage;
      }
    },
  );
};

export const appendConversationMessage = (appendedMessage: Message) => {
  const { user_id, conversation_id, message_id } = appendedMessage;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id: conversation_id || DEFAULT_CHAT_ID },
    (draft) => {
      const focusMessage = draft?.messages?.find(
        (message: Message) => message.message_id === message_id,
      );

      if (!focusMessage) {
        return;
      }

      const updatedPayload = focusMessage?.payload
        ? recursiveDeepMerge(
            focusMessage?.payload || {},
            appendedMessage.payload || {},
          )
        : appendedMessage.payload;

      Object.assign(focusMessage, {
        ...appendedMessage,
        payload: updatedPayload,
      });
    },
  );
};

export const replaceConversationMessage = (replacedMessage: Message) => {
  const { user_id, conversation_id, message_id } = replacedMessage;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id: conversation_id || DEFAULT_CHAT_ID },
    (draft) => {
      const focusMessage = draft?.messages?.find(
        (message: Message) => message.message_id === message_id,
      );

      if (!focusMessage) {
        return;
      }

      Object.assign(focusMessage, replacedMessage);
    },
  );
};

export const overwriteConversationMessage = (replacedMessage: Message) => {
  const { user_id, conversation_id, message_id } = replacedMessage;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id: conversation_id || DEFAULT_CHAT_ID },
    (draft) => {
      const messageIndex = draft?.messages?.findIndex(
        (message: Message) => message.message_id === message_id,
      );

      if (messageIndex === -1 || messageIndex === undefined) {
        return;
      }

      if (draft.messages?.[messageIndex]) {
        draft.messages[messageIndex] = replacedMessage;
      }
    },
  );
};

export const updateConversationMessage = (updatedMessage: Message) => {
  const { user_id, conversation_id, message_id } = updatedMessage;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id: conversation_id || DEFAULT_CHAT_ID },
    (draft) => {
      const focusMessage = draft?.messages?.find(
        (message: Message) => message.message_id === message_id,
      );
      if (focusMessage) {
        const updatedPayload = recursiveUpdatePayload(
          focusMessage.payload || {},
          updatedMessage.payload || {},
        );

        Object.assign(focusMessage, {
          ...updatedMessage,
          payload: updatedPayload,
        });
      } else {
        draft.messages
          ? draft.messages.push(updatedMessage)
          : (draft.messages = [updatedMessage]);
      }
    },
  );
};

// TODO(olha): the same as replaceConversationMessage. double-check and replace
export const addConversationMessageFooter = (messageFooter: MessageFooter) => {
  const {
    user_id,
    conversation_id = DEFAULT_CHAT_ID,
    message_id,
  } = messageFooter;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      const focusMessage = draft?.messages?.find(
        (message: Message) => message.message_id === message_id,
      );

      if (!focusMessage) {
        return;
      }

      Object.assign(focusMessage, messageFooter);
    },
  );
};

export const addConversationMessageContentChunk = ({
  user_id,
  messageChunk,
}: {
  user_id: string;
  messageChunk: MessageChunk;
}) => {
  const {
    conversation_id = DEFAULT_CHAT_ID,
    message_id,
    task_id,
  } = messageChunk;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      const focusMessage = draft?.messages?.find(
        (message: Message) => message.message_id === message_id,
      );

      if (!focusMessage) {
        return;
      }

      Object.assign(focusMessage, {
        task_id,
        content: focusMessage.content + messageChunk.content,
      });
    },
  );
};

// TODO(olha): the same as addConversationChatMessageContentChunk. double-check and replace
export const addConversationMessageChunk = ({
  user_id,
  messageChunk,
}: {
  user_id: string;
  messageChunk: MessageChunk;
}) => {
  const {
    conversation_id = DEFAULT_CHAT_ID,
    message_id,
    task_id,
  } = messageChunk;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      const focusMessage = draft?.messages?.find(
        (message: Message) => message.message_id === message_id,
      );

      if (!focusMessage) {
        return;
      }

      Object.assign(focusMessage, {
        // (olha): it's a small workaround since if BE sends task_id, the message_type actually was changed
        message_type: task_id
          ? MessageType.TASK_CREATED
          : MessageType.CONVERSATION,
        task_id,
        content: focusMessage.content + messageChunk.content,
      });
    },
  );
};
