import { Article, CodeBlock, Icon } from '@phosphor-icons/react';
import { EditorType } from 'src/types/editor';
import { FileListType } from 'src/types/fileList';

export const FileIconsSet: Record<FileListType, Icon> = {
  [FileListType.DOCUMENT]: Article,
  [FileListType.CODE]: CodeBlock,
};

export const EditorTypesIconsSet: Record<EditorType, Icon> = {
  [EditorType.DOCUMENT]: Article,
  [EditorType.CODE]: CodeBlock,
};
