import {
  useAppDispatch,
  setMessageId,
  setEditingMode,
  triggerCanvasReset,
  setSelectedImageMask,
  setSelectedImageIndex,
  toggleIsOpenModalImagesList,
  toggleVisibleBrushSizePanel,
  setImageMaskingRightPanelData,
  setImageMaskingSelectionsCount,
  setImageMaskingIsLoading,
  setCurrentImagesList,
} from 'src/store';
import { ImageGenRenderData } from 'src/types';
import { useCallback, useMemo } from 'react';

export const useImageMaskingPanelActions = () => {
  const dispatch = useAppDispatch();

  const handleChangeImageMaskingRightPanelData = useCallback(
    (data: ImageGenRenderData | null) => {
      dispatch(setImageMaskingRightPanelData(data));
    },
    [dispatch],
  );

  const handleSetImageMaskingSelectionsCount = useCallback(
    (value: number) => {
      dispatch(setImageMaskingSelectionsCount(value));
    },
    [dispatch],
  );

  const handleSetSelectedImageMask = useCallback(
    (file: File | null) => {
      dispatch(setSelectedImageMask(file));
    },
    [dispatch],
  );

  const handleToggleVisibleBrushSizePanel = useCallback(
    (value: boolean) => {
      dispatch(toggleVisibleBrushSizePanel(value));
    },
    [dispatch],
  );

  const handleSetImageMaskingIsLoading = useCallback(
    (value: boolean) => {
      dispatch(setImageMaskingIsLoading(value));
    },
    [dispatch],
  );

  const handleTriggerCanvasReset = useCallback(() => {
    dispatch(triggerCanvasReset());
  }, [dispatch]);

  const handleSetEditingMode = useCallback(
    (value: boolean) => {
      dispatch(setEditingMode(value));
    },
    [dispatch],
  );

  const onChangeCurrentImagesList = useCallback(
    (images: ImageGenRenderData[]) => {
      dispatch(setCurrentImagesList(images));
    },
    [dispatch],
  );

  const onChangeSelectedImageIndex = useCallback(
    (index: number) => {
      dispatch(setSelectedImageIndex(index));
    },
    [dispatch],
  );

  const onToggleIsOpenModalImagesList = useCallback(() => {
    dispatch(toggleIsOpenModalImagesList());
  }, [dispatch]);

  const resetImageMaskingPanelInfo = useCallback(() => {
    handleSetSelectedImageMask(null);
    handleSetImageMaskingSelectionsCount(0);
    handleSetEditingMode(false);
    handleTriggerCanvasReset();
    toggleVisibleBrushSizePanel(false);
  }, [
    handleSetEditingMode,
    handleSetImageMaskingSelectionsCount,
    handleSetSelectedImageMask,
    handleTriggerCanvasReset,
  ]);

  const handleSetMessageId = useCallback(
    (messageId: string) => {
      dispatch(setMessageId(messageId));
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      onToggleIsOpenModalImagesList,
      onChangeSelectedImageIndex,
      onChangeCurrentImagesList,
      setImageMaskingIsLoading: handleSetImageMaskingIsLoading,
      toggleVisibleBrushSizePanel: handleToggleVisibleBrushSizePanel,
      triggerCanvasReset: handleTriggerCanvasReset,
      resetImageMaskingPanelInfo,
      setEditingMode: handleSetEditingMode,
      setSelectedImageMask: handleSetSelectedImageMask,
      setImageMaskingSelectionsCount: handleSetImageMaskingSelectionsCount,
      onChangeImageMaskingRightPanelData:
        handleChangeImageMaskingRightPanelData,
      onChangeMessageId: handleSetMessageId,
    }),
    [
      handleChangeImageMaskingRightPanelData,
      handleSetEditingMode,
      handleSetImageMaskingIsLoading,
      handleSetImageMaskingSelectionsCount,
      handleSetMessageId,
      handleSetSelectedImageMask,
      handleToggleVisibleBrushSizePanel,
      handleTriggerCanvasReset,
      onChangeCurrentImagesList,
      onChangeSelectedImageIndex,
      onToggleIsOpenModalImagesList,
      resetImageMaskingPanelInfo,
    ],
  );
};
