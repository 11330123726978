import { useEffect } from 'react';
import {
  useQueryParams,
  useRightSidePanelActions,
  useRightSidePanelState,
} from 'src/hooks';
import { useThreadContext } from '../plugins/NinjaCanvasPlugin/contexts/ThreadProvider';
import { useAddDefaultContentToEditor } from './useAddDefaultContentToEditor';
import { Thread } from '@langchain/langgraph-sdk';
import { DocumentValuesType, CodeValuesType } from 'src/types';

// The hook must be called only once in the Right Panel
export const useLoadDefaultEditorContent = () => {
  const { searchParams } = useQueryParams();
  const { document } = searchParams;

  const { searchOrCreateThread } = useThreadContext();
  const { onAddDefaultContentToEditor } = useAddDefaultContentToEditor();
  const { isEditorPanelExpanded } = useRightSidePanelState();
  const { toggleRightSidePanelFullWidth, toggleRightSidePanelExpanded } =
    useRightSidePanelActions();

  useEffect(() => {
    const loadEditorData = async () => {
      const thread = await searchOrCreateThread();

      if (thread) {
        onAddDefaultContentToEditor(
          thread as Thread<DocumentValuesType | CodeValuesType>,
        );
      }
    };

    if (document && !isEditorPanelExpanded) {
      loadEditorData();
      return;
    }
    if (!document && isEditorPanelExpanded) {
      toggleRightSidePanelFullWidth(false);
      toggleRightSidePanelExpanded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
