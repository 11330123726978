import { useCallback } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { SearchWebPageReference } from 'src/types';
import { insertFootnotesToEditor, insertMarkdownToEditor } from 'src/utils';

export const useInsertContentToDocumentEditor = () => {
  const [editor] = useLexicalComposerContext();

  const insertContentToDocumentEditor = useCallback(
    ({
      markdown,
      footnotes,
    }: {
      markdown: string;
      footnotes?: SearchWebPageReference[];
    }) => {
      insertMarkdownToEditor({ editor, markdown });

      if (footnotes && footnotes.length > 0) {
        insertFootnotesToEditor({ editor, footnotes });
      }
    },
    [editor],
  );

  return { insertContentToDocumentEditor };
};
