// todo(ella): redo this portion with better sharing of libs
import {
  PDUMessage,
  CombusMessageChannel,
  NinjaEventTypes,
  isString,
  isObject,
  ApiTaskSelectable,
  ApiMinimumTask,
  TaskState,
  TaskSkill,
  Action,
} from 'src/types';

/**
 * Create a PDU Packet for ComBus
 * @param content string
 * @param userId string
 * @returns
 */
export function toPDUMessage(content: string, userId: string): PDUMessage {
  // TODO: stan - remove this when stream goes live
  let streaming = true;
  const research_streaming = true;
  if (content.includes('/stream')) {
    streaming = true;
    content = content.replace('/stream', '').trim();
  }

  return {
    event_type: NinjaEventTypes.NEW_MESSAGE,
    user_id: userId,
    channel: [CombusMessageChannel.CE],
    payload: content,
    streaming,
    research_streaming,
  } as PDUMessage;
}

/**
 * recursiveDeepMerge() merges two objects by concatenating their deep values.
 * @param originalPayload Record<string, any>
 * @param newPayload Record<string, any>
 * @returns Record<string, any>
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
export function recursiveDeepMerge(
  originalPayload: Record<string, any>,
  newPayload: Record<string, any>,
) {
  const mergedPayload: Record<string, any> = { ...originalPayload };

  for (const [key, value] of Object.entries(newPayload)) {
    if (!value) continue;
    if (key === 'payload_type') {
      continue;
    } else if (key in mergedPayload) {
      if (Array.isArray(value)) {
        // actions field is a list, but it's streamed with OperationType.APPEND so we need to custom merge it otherwise we may get duplicates
        if (key === 'actions') {
          mergedPayload[key] = mergeActions(mergedPayload[key], value);
        } else {
          mergedPayload[key] = mergedPayload[key].concat(value);
        }
      } else if (isString(value)) {
        // this condition is needed to avoid concatenating some keys
        // value multiple times.
        if (
          key === 'skill' ||
          key === 'task_id' ||
          key === 'model' ||
          key === 'version' ||
          key === 'question' ||
          key === 'image_url' ||
          key === 'model'
        ) {
          mergedPayload[key] = value;
        } else {
          mergedPayload[key] += value;
        }
      } else if (isObject(value) && value !== null) {
        if (key === 'images') {
          mergedPayload[key] = { ...mergedPayload[key], ...value };
        } else {
          mergedPayload[key] = recursiveDeepMerge(mergedPayload[key], value);
        }
      } else {
        mergedPayload[key] = value;
      }
    } else {
      mergedPayload[key] = value;
    }
  }

  return mergedPayload;
}

export function recursiveUpdatePayload(
  originalPayload: Record<string, any>,
  newPayload: Record<string, any>,
) {
  const mergedPayload: Record<string, any> = { ...originalPayload };

  for (const [key, value] of Object.entries(newPayload)) {
    if (!value) continue;
    if (key === 'payload_type') {
      continue;
    }
    if (key === 'task_id') {
      continue;
    }

    if (isObject(value) && !Array.isArray(value)) {
      mergedPayload[key] = recursiveUpdatePayload(mergedPayload[key], value);
    } else {
      mergedPayload[key] = value;
    }
  }
  return mergedPayload;
}

/* eslint-enable @typescript-eslint/no-explicit-any */

/**
 * convertToMinimumApiTask() takes a task and creates
 * a minimum task out of its properties.
 * @param data Partial<ApiTaskSelectable>
 * @returns ApiTaskMinimum
 */
export function convertToMinimumApiTask(
  data: Partial<ApiTaskSelectable>,
): ApiMinimumTask {
  const {
    task_id,
    task_hash,
    user_id,
    conversation_id,
    parent_conversation_id,
    state = TaskState.IN_PROGRESS,
    skill = TaskSkill.CHITCHAT,
    requires_attention = false,
    created_at,
  } = data;
  return {
    task_id,
    task_hash,
    user_id,
    conversation_id,
    parent_conversation_id,
    state,
    skill,
    requires_attention,
    created_at,
  } as ApiMinimumTask;
}

export function mergeActions(mergedPayload: Action[], newPayload: Action[]) {
  const actionMap = new Map();

  (mergedPayload || []).forEach((item) => {
    actionMap.set(item.action, item);
  });

  newPayload.forEach((item) => {
    actionMap.set(item.action, item);
  });

  return Array.from(actionMap.values());
}
