import {
  getNextBillingCycleDate,
  getRoundedPriceWithCurrency,
  uppercaseFirstLetter,
} from 'src/utils';
import styles from './SummaryContent.module.scss';
import { SummaryRow } from '../SummaryRow';
import { PaymentPlanInterval, SubscriptionPlan } from 'src/types';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';

interface SummaryContentProps {
  selectedPlan?: SubscriptionPlan;
  subtotal: number;
  taxes: number;
  discount?: number;
  totalOriginalAmount: number;
  dueToday: number;
  isTeams?: boolean;
  seats?: number | null;
  isLoading?: boolean;
}

export const SummaryContent = ({
  selectedPlan,
  subtotal,
  taxes,
  discount,
  totalOriginalAmount,
  dueToday,
  isTeams,
  seats,
  isLoading = false,
}: SummaryContentProps) => {
  return (
    <div className={styles.content}>
      <div className={styles.checkoutResultSection}>
        <div className={styles.checkoutResultSectionRow}>
          <span className={styles.subscriptionRow}>
            {isTeams ? 'Ninja Business' : ''}{' '}
            {uppercaseFirstLetter(
              isTeams
                ? selectedPlan?.plan_tier.split('_')[1] || ''
                : selectedPlan?.plan_tier || '',
            )}{' '}
            subscription
          </span>
          {isLoading ? (
            <ProgressBar
              progressLineClassName={styles.priceLoaderLine}
              className={styles.priceLoader}
            />
          ) : (
            <span>
              {getRoundedPriceWithCurrency(totalOriginalAmount)}/
              {selectedPlan?.period === PaymentPlanInterval.MONTHLY
                ? 'mo'
                : 'yr'}
            </span>
          )}
        </div>
      </div>

      {!!seats && (
        <div className={styles.checkoutResultSection}>
          <div className={styles.checkoutResultSectionRow}>
            <span className={styles.subscriptionRow}>Seats</span>
            <span>{seats}</span>
          </div>
        </div>
      )}

      <hr className={`divider ${!!seats ? '' : 'no-margin'}`} />

      <div className={styles.checkoutRowWrapper}>
        <SummaryRow
          label="Subtotal"
          value={getRoundedPriceWithCurrency(subtotal)}
          isLoading={isLoading}
        />
        {!!discount && (
          <SummaryRow
            label="Discount"
            value={`-${getRoundedPriceWithCurrency(discount)}`}
            isLoading={isLoading}
          />
        )}
        <SummaryRow
          label="Tax & fees"
          value={getRoundedPriceWithCurrency(taxes)}
          isLoading={isLoading}
        />

        <div className={styles.checkoutResultSectionRow}>
          <span>Due today (USD)</span>

          {isLoading ? (
            <ProgressBar
              progressLineClassName={styles.priceLoaderLine}
              className={styles.priceLoader}
            />
          ) : (
            <span>{getRoundedPriceWithCurrency(dueToday)}</span>
          )}
        </div>

        {isTeams && !!seats && (
          <span className={styles.teamDescription}>
            Then{' '}
            {getRoundedPriceWithCurrency(
              selectedPlan?.total_recurring_amount || 0,
            )}{' '}
            per {selectedPlan?.period === 'monthly' ? 'month' : 'year'}
            {getNextBillingCycleDate(
              selectedPlan?.next_billing_cycle_start_timestamp,
            )}
          </span>
        )}
      </div>
    </div>
  );
};
