import { ProgrammingLanguageOptions } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/shared/types';

export enum EditorType {
  DOCUMENT = 'Document',
  CODE = 'Code',
}
export interface CodeEditorData {
  content?: string;
  language?: ProgrammingLanguageOptions;
  undoStepsCount?: number;
  redoStepsCount?: number;
}

export type EditorTheme = 'light' | 'dark' | 'none';
