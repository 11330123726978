import classNames from 'classnames';
import { useSession } from 'src/hooks';
import { DimensionToggle } from '../DimensionToggle';
import styles from './Dimensions.module.scss';
import { LabelPro } from 'src/components/LabelPro';
import { LabelUltra } from 'src/components/LabelUltra';

interface DimensionsProps {
  selectedValue?: string;
  onChange?: (value: string) => void;
}

export const Dimensions = ({ selectedValue, onChange }: DimensionsProps) => {
  const { isOpenTier, isFreeTier } = useSession();

  const isDisabled = isOpenTier || isFreeTier;

  return (
    <div
      className={classNames(styles.root, { [styles.isDisabled]: isDisabled })}
      data-e2e="image-dimension-form"
    >
      <label className={styles.label}>
        <span>Aspect ratio</span>
        {isDisabled && (
          <>
            <LabelPro /> <LabelUltra />
          </>
        )}
      </label>

      <DimensionToggle onChange={onChange} selectedValue={selectedValue} />
    </div>
  );
};
