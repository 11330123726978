import { Button } from 'src/v2/commonComponents/Button';
import { DotsThreeOutlineVertical } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import type { ToggleProps } from 'src/v2/commonComponents/DrawerTooltipContainer';
import styles from './EditorLeftPanelMenuToggle.module.scss';

export const EditorLeftPanelMenuToggle = ({
  id,
  disabled,
  onClick,
}: ToggleProps) => {
  return (
    <Button
      id={id}
      disabled={disabled}
      color="transparent"
      onClick={onClick}
      className={styles.root}
      shape="round"
    >
      <DotsThreeOutlineVertical size={SVG_SIZE_M} />
    </Button>
  );
};
