import { ProgrammingLanguageOptions } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/shared/types';

export type DocumentValuesType = {
  messages: DocumentMessageType[];
  artifact: {
    currentIndex: number;
    contents: DocumentArtifactType[];
  };
};

export type CodeValuesType = {
  messages: DocumentMessageType[];
  artifact: {
    currentIndex: number;
    contents: CodeArtifactType[];
  };
};

export type DocumentArtifactType = {
  index: number;
  type: string;
  title: string;
  fullMarkdown: string;
};

export type CodeArtifactType = {
  index: number;
  type: string;
  title: string;
  language: ProgrammingLanguageOptions;
  code: string;
};

export type DocumentMessageType = {
  content: string;
  id: string;
  type: string;
};

export enum FileListType {
  DOCUMENT = 'document',
  CODE = 'code',
}
