import { useCallback, useContext } from 'react';
import { X } from '@phosphor-icons/react';
import { Modal } from 'react-responsive-modal';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import log from 'src/utils/logger';
import styles from './CancelSubscriptionModal.tsx.module.scss';
import { useCancelSubscriptionMutation } from 'src/store/services';
import { Button } from 'src/components/Button';
import { toast } from 'react-toastify';
import { capitalizeEachFirstLetter } from 'src/utils';
import { convertTierName } from 'src/utils/user';
import { useSession } from 'src/hooks';

const SVG_SIZE = 24;

type CancelSubscriptionModalProps = {
  cancellationDate?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const CancelSubscriptionModal = ({
  cancellationDate,
  isOpen,
  onClose,
}: CancelSubscriptionModalProps) => {
  const { rootContainerRef } = useContext(ForwardRefContext);
  const { appUser } = useSession();

  const [cancelSubscription, { isLoading: isCancelSubscriptionLoading }] =
    useCancelSubscriptionMutation();

  const handleChange = useCallback(async () => {
    try {
      if (!appUser.user_id) return;
      await cancelSubscription(appUser.user_id).unwrap();
      onClose();
      toast.success('Subscription cancelled successfully.');
    } catch (error: unknown) {
      toast.error('Failed to cancel subscription.');
      log.error(error);
    }
  }, [cancelSubscription, onClose, appUser.user_id]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      container={rootContainerRef?.current || document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: 'nj-modal--access',
      }}
      closeIcon={<X size={SVG_SIZE} />}
      center
      closeOnOverlayClick={false}
    >
      <div className="nj-modal--access--wrapper">
        <p className="nj-modal--access--title">
          Are you sure you want to cancel?
        </p>

        <p>
          <span>
            By cancelling your subscription you will loose access to all Pro
            features and your plan will be downgraded to FREE when your
            subscription expires on <b> {cancellationDate}</b>.
          </span>
        </p>
        <br />
        <div className="nj-modal--access--action-wrapper">
          <Button
            type="reset"
            className={styles.continueButton}
            onClick={onClose}
          >
            Continue using{' '}
            {appUser.tier_id
              ? capitalizeEachFirstLetter(convertTierName(appUser.tier_id))
              : ''}
          </Button>

          <Button
            type="submit"
            className={styles.cancelButton}
            onClick={handleChange}
            disabled={isCancelSubscriptionLoading}
          >
            Cancel Subscription
          </Button>
        </div>
      </div>
    </Modal>
  );
};
