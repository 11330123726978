import cn from 'classnames';
import { TabList, Tab } from 'src/v2/commonComponents/Tabs';
import { Icon } from 'src/components/Icon';
import { SUPER_AGENT_AS_EXTERNAL_MODEL, SVG_SIZE_M } from 'src/constants';
import { MessageTabData, MessageTabs, TabPersistRegistry } from 'src/types';
import styles from './MessageTabList.module.scss';

interface MessageTabListProps {
  data: MessageTabData[];
  withSuperAgent: boolean;
  tabRegistry?: TabPersistRegistry;
}

export const renderMessageTabList = ({
  data,
  withSuperAgent,
  tabRegistry,
}: MessageTabListProps) => {
  return (
    <TabList>
      {data.map(({ tabName, iconName, IconComponent, tabTitle, content }) => {
        const isSuperAgentTab =
          tabName.includes(SUPER_AGENT_AS_EXTERNAL_MODEL) ||
          tabName === MessageTabs.SUPER_AGENT;

        return (
          <Tab
            withAnimation={!content && (withSuperAgent || !isSuperAgentTab)}
            key={tabName}
            tab={tabName}
            className={cn(styles.tab, {
              [styles.highlighted]: isSuperAgentTab,
            })}
          >
            <span
              className={cn(styles.icon, {
                [styles.withIndicator]:
                  tabRegistry && tabRegistry[tabName]?.isRead === false,
                [styles.gradient]: isSuperAgentTab,
              })}
            >
              {iconName ? (
                <Icon type={iconName} size={SVG_SIZE_M} />
              ) : IconComponent ? (
                <IconComponent size={SVG_SIZE_M} />
              ) : null}
            </span>

            <span>{tabTitle}</span>
          </Tab>
        );
      })}
    </TabList>
  );
};
