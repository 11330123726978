import { DownloadSimple } from '@phosphor-icons/react';
import { Button } from 'src/v2/commonComponents/Button';
import { SVG_SIZE_M } from 'src/constants';

interface DownloadFileTriggerProps {
  id: string;
  disabled?: boolean;
  isActive?: boolean;
  onClick: () => void;
}

export const DownloadFileTrigger = ({
  id,
  isActive,
  disabled,
  onClick,
}: DownloadFileTriggerProps) => {
  return (
    <Button
      id={id}
      onClick={onClick}
      shape="round"
      color={isActive ? 'tertiary' : 'transparent'}
      disabled={disabled}
    >
      <DownloadSimple size={SVG_SIZE_M} />
    </Button>
  );
};
