import { Check, Copy } from '@phosphor-icons/react';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { SVG_SIZE_M, SVG_SIZE_L } from 'src/constants';
import { Button, ButtonProps } from 'src/v2/commonComponents/Button';

interface CopyCodeButtonProps {
  content: string;
  color?: ButtonProps['color'];
}

export const CopyCodeButton = ({
  content,
  color = 'transparent',
}: CopyCodeButtonProps) => {
  const sanitizedText = useMemo(
    () =>
      String(content)
        .replace(/\u00A0/g, ' ')
        .replace(/&nbsp;/g, ' ')
        .replace(/\r\n|\n|\r/g, '\n'),
    [content],
  );

  const handleCopyClick = () => {
    navigator.clipboard.writeText(sanitizedText);
    toast(
      <div className="nj-copy-snackbar-content">
        <Check size={SVG_SIZE_L} />
        <span>Code Copied</span>
      </div>,
      {
        className: 'nj-copy-snackbar',
      },
    );
  };

  return (
    <Button color={color} onClick={handleCopyClick}>
      <Copy size={SVG_SIZE_M} />
    </Button>
  );
};
