import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { HumanMessage } from '@langchain/core/messages';
import CodeMirror, { EditorView } from '@uiw/react-codemirror';
import {
  history,
  historyKeymap,
  redoDepth,
  undoDepth,
} from '@codemirror/commands';
import { keymap } from '@codemirror/view';
import {
  cleanContent,
  getArtifactContentForCode,
  getLanguageExtension,
} from 'src/utils';
import {
  useCodeEditorSelection,
  useEditorActions,
  useEditorState,
} from 'src/hooks';
import { convertToOpenAIFormat } from '../../plugins/NinjaCanvasPlugin/lib/convert_messages';
import { useGraphContext } from '../../plugins/NinjaCanvasPlugin/contexts/GraphContext';
import { CodeFloatingElementPlugin } from '../../plugins/CodeFloatingElementPlugin';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { ArtifactCodeV3 } from '../../plugins/NinjaCanvasPlugin/shared/types';
import { getArtifactContent } from '../../plugins/NinjaCanvasPlugin/shared/utils/artifacts';
import { useThreadContext } from '../../plugins/NinjaCanvasPlugin/contexts/ThreadProvider';
import styles from './CodeEditorContainer.module.scss';
import classNames from 'classnames';

export const CodeEditorContainer = () => {
  const [view, setView] = useState<EditorView | null>(null);

  const { codeEditorData, isWordWrapEnabled, isEditorDarkTheme } =
    useEditorState();

  const { graphData } = useGraphContext();
  const { shouldResetEditorContent } = useEditorState();
  const { setShouldResetEditorContent, setCodeEditorData } = useEditorActions();
  const { getUserThreads } = useThreadContext();

  const {
    isStreaming,
    artifact,
    setArtifact,
    setMessages,
    streamMessage,
    setArtifactContent,
  } = graphData;

  const { codeEditorRef } = useContext(ForwardRefContext);
  const prevContentRef = useRef<string | null | undefined>(
    codeEditorData?.content,
  );

  useEffect(() => {
    if (shouldResetEditorContent) {
      setArtifact(getArtifactContentForCode());
      setShouldResetEditorContent(false);
    }

    prevContentRef.current = codeEditorData?.content;
  }, [
    codeEditorData?.content,
    setArtifact,
    setShouldResetEditorContent,
    shouldResetEditorContent,
  ]);

  const {
    contentRef,
    artifactContentRef,
    selectionBoxRef,
    selectionBox,
    selectionIndexes,
    isSelectionActive,
    isValidSelectionOrigin,
    isInputVisible,
    handleCleanupState,
  } = useCodeEditorSelection(codeEditorRef);

  const handleCreateEditor = useCallback(
    (view: EditorView) => {
      codeEditorRef.current = view;
      setView(view);
    },
    [codeEditorRef],
  );

  const handleSubmit = useCallback(
    async (content: string) => {
      const humanMessage = new HumanMessage({ content, id: uuidv4() });
      setMessages((prev) => [...prev, humanMessage]);
      handleCleanupState();

      await streamMessage({
        messages: [convertToOpenAIFormat(humanMessage)],
        ...(selectionIndexes && {
          highlightedCode: {
            startCharIndex: selectionIndexes.start,
            endCharIndex: selectionIndexes.end,
          },
        }),
      });

      await getUserThreads();
    },
    [
      setMessages,
      handleCleanupState,
      streamMessage,
      selectionIndexes,
      getUserThreads,
    ],
  );

  const getExtensions = () => {
    const baseExtensions = codeEditorData?.language
      ? [getLanguageExtension(codeEditorData.language)]
      : [];

    return [
      ...baseExtensions,
      history(),
      keymap.of(historyKeymap),
      ...(isWordWrapEnabled ? [EditorView.lineWrapping] : []),
    ];
  };

  const extensions = getExtensions();

  if (!artifact) {
    return null;
  }

  const artifactContent = getArtifactContent(artifact) as ArtifactCodeV3;

  const handleChange = (value: string) => {
    if (artifact) {
      setArtifactContent(artifactContent.index, value);
    }

    if (view) {
      setCodeEditorData({
        undoStepsCount: undoDepth(view.state),
        redoStepsCount: redoDepth(view.state),
      });
    }
  };

  return (
    <div ref={contentRef}>
      <div
        ref={artifactContentRef}
        className={classNames(styles.container, {
          [styles.withOpacity]: isStreaming,
        })}
      >
        <CodeMirror
          editable
          theme={isEditorDarkTheme ? 'dark' : 'light'}
          value={cleanContent(artifactContent.code)}
          extensions={extensions}
          onChange={handleChange}
          onCreateEditor={handleCreateEditor}
        />
      </div>

      {selectionBox && isSelectionActive && isValidSelectionOrigin && (
        <CodeFloatingElementPlugin
          ref={selectionBoxRef}
          isInputVisible={isInputVisible}
          selectionBox={selectionBox}
          selectionIndexes={selectionIndexes}
          handleSubmitMessage={handleSubmit}
          handleSelectionBoxMouseDown={(e) => e.stopPropagation()}
          handleCleanupState={handleCleanupState}
        />
      )}
    </div>
  );
};
