import { type LexicalEditor, $getSelection, $isRangeSelection } from 'lexical';
import { TRANSFORMERS, $convertToMarkdownString } from '@lexical/markdown';

export const getEditorMarkdown = (editor: LexicalEditor) => {
  let markdown = '';

  editor.read(() => {
    markdown = $convertToMarkdownString(TRANSFORMERS);
  });

  return markdown;
};

export const getSelectedContent = (editor: LexicalEditor) => {
  let selectedText = '';
  editor.update(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      selectedText = selection.getTextContent();
    }
  });

  return selectedText;
};
