import { StateEffect } from '@uiw/react-codemirror';
import { history } from '@codemirror/commands';
import { useCallback, useContext } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';

export const useClearCodeEditor = () => {
  const { codeEditorRef } = useContext(ForwardRefContext);

  const clearCodeEditorContent = useCallback(() => {
    const view = codeEditorRef.current;

    if (view) {
      view.dispatch({
        changes: {
          from: 0,
          to: view.state.doc.length,
          insert: '',
        },
      });

      view.dispatch({
        effects: StateEffect.appendConfig.of([history()]),
      });
    }
  }, [codeEditorRef]);

  const clearCodeEditorHistory = useCallback(() => {
    const view = codeEditorRef.current;

    if (view) {
      view.dispatch({
        effects: StateEffect.appendConfig.of([history()]),
      });
    }
  }, [codeEditorRef]);

  return { clearCodeEditorContent, clearCodeEditorHistory };
};
