import { ParagraphNode, TextNode } from 'lexical';
import { CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListNode, ListItemNode } from '@lexical/list';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { TRANSFORMERS } from '@lexical/markdown';
import { Theme } from './theme';
import log from 'src/utils/logger';

export const editorConfig = {
  namespace: 'Editor',
  theme: Theme,
  nodes: [
    ParagraphNode,
    TextNode,
    HeadingNode,
    HorizontalRuleNode,
    CodeNode,
    LinkNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    AutoLinkNode,
  ],
  onError(error: Error) {
    log.error('Lexical error:', error);
  },
  markdown: {
    transformers: TRANSFORMERS,
  },
};
