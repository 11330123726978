import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import {
  CopySimple,
  LinkSimpleHorizontal,
  PencilSimple,
  Trash,
} from '@phosphor-icons/react';
import classNames from 'classnames';
import {
  $getSelection,
  $isRangeSelection,
  BaseSelection,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  getDOMSelection,
  KEY_ESCAPE_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { Button } from 'src/v2/commonComponents/Button';
import { SVG_SIZE_M } from 'src/constants';
import {
  sanitizeUrl,
  setFloatingElemPositionForLinkEditor,
  getLinkSelectedNode,
  validateUrl,
} from '../../utils';
import styles from './FloatingLinkEditor.module.scss';

const preventDefault = (
  event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>,
) => {
  event.preventDefault();
};

interface FloatingLinkEditorProps {
  editor: LexicalEditor;
  isLink: boolean;
  setIsLink: Dispatch<boolean>;
  anchorElem: HTMLElement;
  isLinkEditMode: boolean;
  setIsLinkEditMode: Dispatch<boolean>;
}

export const FloatingLinkEditor = ({
  editor,
  isLink,
  setIsLink,
  anchorElem,
  isLinkEditMode,
  setIsLinkEditMode,
}: FloatingLinkEditorProps) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [editedLinkUrl, setEditedLinkUrl] = useState<string>('');
  const [lastSelection, setLastSelection] = useState<BaseSelection | null>(
    null,
  );
  const [isError, setError] = useState<boolean>(false);

  const $updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    const editorElem = editorRef.current;
    const nativeSelection = getDOMSelection(editor._window);
    const activeElement = document.activeElement;
    const rootElement = editor.getRootElement();

    if (editorElem === null) {
      return;
    }

    if (
      selection !== null &&
      $isRangeSelection(selection) &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode) &&
      editor.isEditable()
    ) {
      setLastSelection(selection);
      const node = getLinkSelectedNode(selection);
      const linkParent = $findMatchingParent(node, $isLinkNode);

      if (linkParent) {
        setLinkUrl(linkParent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }

      if (isLinkEditMode) {
        setEditedLinkUrl(linkUrl || '');
      }

      const domRect: DOMRect | undefined =
        nativeSelection.focusNode?.parentElement?.getBoundingClientRect();
      if (domRect) {
        domRect.y += 40;
        setFloatingElemPositionForLinkEditor({
          targetRect: domRect,
          floatingElem: editorElem,
          anchorElem,
        });
      }
    } else {
      setLastSelection(null);
      setLinkUrl('');
      setEditedLinkUrl('');
      setIsLinkEditMode(false);
      setFloatingElemPositionForLinkEditor({
        targetRect: null,
        floatingElem: editorElem,
        anchorElem,
      });
    }

    if (!activeElement) {
      setError(false);
    }
  }, [anchorElem, editor, setIsLinkEditMode, isLinkEditMode, linkUrl]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        $updateLinkEditor();
      });
    };

    window.addEventListener('resize', update);
    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);
      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [anchorElem.parentElement, editor, $updateLinkEditor]);

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (!isLink) {
        return;
      }

      const editorEl = editorRef.current;
      if (!editorEl) {
        return;
      }

      if (!editorEl.contains(e.target as Node)) {
        setIsLink(false);
        setIsLinkEditMode(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [isLink, setIsLink, setIsLinkEditMode]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateLinkEditor();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          editor.getEditorState().read(() => {
            $updateLinkEditor();
          });
          return true;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (isLink) {
            setIsLink(false);
            setIsLinkEditMode(false);
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_HIGH,
      ),
    );
  }, [editor, $updateLinkEditor, isLink, setIsLink, setIsLinkEditMode]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      $updateLinkEditor();
    });
  }, [editor, $updateLinkEditor]);

  useEffect(() => {
    if (isLinkEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isLinkEditMode, isLink]);

  const monitorInputInteraction = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      handleLinkSubmission(event);
    } else if (event.key === 'Escape') {
      console.log('Escape');
      event.preventDefault();
      setIsLinkEditMode(false);
      setError(false);
    }
  };

  const handleLinkSubmission = (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement>,
  ) => {
    event.preventDefault();
    setError(false);

    if (lastSelection !== null) {
      if (!editedLinkUrl.trim()) {
        setIsLinkEditMode(false);
        return;
      }

      // const href = getValidHref(editedLinkUrl);

      if (!validateUrl(editedLinkUrl)) {
        setError(true);
        return;
      }

      editor.update(() => {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(editedLinkUrl));
      });

      setEditedLinkUrl('');
      setIsLinkEditMode(false);
    }
  };

  return (
    <div ref={editorRef} className={styles.root}>
      {!isLink ? null : (
        <div className={styles.container}>
          {isLinkEditMode ? (
            <>
              <div
                className={classNames(styles.inputWrapper, {
                  [styles.error]: isError,
                })}
              >
                <LinkSimpleHorizontal size={SVG_SIZE_M} />

                <input
                  ref={inputRef}
                  // TODO(olha): create common component
                  className={styles.input}
                  value={editedLinkUrl}
                  onChange={(event) => {
                    setEditedLinkUrl(event.target.value);
                    setError(false);
                  }}
                  onKeyDown={monitorInputInteraction}
                  placeholder="Paste or enter link"
                />
              </div>

              <div className={styles.actionsWrapper}>
                <Button
                  onMouseDown={preventDefault}
                  onClick={() => {
                    setIsLinkEditMode(false);
                    setError(false);
                  }}
                  color="transparent"
                >
                  Cancel
                </Button>

                <Button
                  onMouseDown={preventDefault}
                  onClick={handleLinkSubmission}
                  color="link"
                  disabled={!editedLinkUrl.trim()}
                >
                  Apply
                </Button>
              </div>
            </>
          ) : (
            <div className={styles.linkView}>
              <a
                href={sanitizeUrl(linkUrl)}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                {linkUrl}
              </a>

              <Button
                onMouseDown={preventDefault}
                onClick={(event) => {
                  event.preventDefault();
                  setEditedLinkUrl(linkUrl || '');
                  setIsLinkEditMode(true);
                }}
                color="transparent"
              >
                <PencilSimple size={SVG_SIZE_M} />
              </Button>

              <Button
                onMouseDown={preventDefault}
                onClick={() => {
                  navigator.clipboard.writeText(linkUrl);
                }}
                color="transparent"
              >
                <CopySimple size={SVG_SIZE_M} />
              </Button>

              <Button
                onMouseDown={preventDefault}
                onClick={() => {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
                  setIsLinkEditMode(false);
                }}
                color="transparent"
              >
                <Trash size={SVG_SIZE_M} />
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
