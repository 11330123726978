import { useEffect, useState } from 'react';
import {
  useBreakpoint,
  useQueryParams,
  useSession,
  useSubscriptionInfo,
  useSubscriptionPlans,
  useVisible,
} from 'src/hooks';
import {
  PAYPAL_PAYMENT_IN_PROGRESS,
  PAYPAL_PAYMENT_IN_PROGRESS_MESSAGE,
  REDIRECT_TO_STRIPE_TIMESTAMP,
  SVG_SIZE_L,
  SVG_SIZE_M,
  SVG_SIZE_S,
} from 'src/constants';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppRoutes,
  GTMEvent,
  PaymentPlanInterval,
  PlanSectionType,
  SubscriptionPlan,
  TeamsTabs,
} from 'src/types';
import styles from './PaymentBetaPage.module.scss';
import { ArrowLeft, ArrowSquareOut } from '@phosphor-icons/react';
import { PlanSection } from './components/PlanSection';
import { PeriodSwitch } from 'src/components/PeriodSwitch';
import { LEARN_MORE_PRICES_LINK } from 'src/constants/externalLinks';
import { CheckoutSection } from 'src/pages/PaymentBetaPage/components/CheckoutSection';
import { NinjaAgentsOutlinedIcon } from 'src/images/icons/ninjaAgentsOutlined';
import { LoadingOverlay } from 'src/pages/PaymentBetaPage/components/LoadingOverlay';
import {
  capitalizeEachFirstLetter,
  isAppleOrAmpSource,
  sendGTMEvent,
} from 'src/utils';
import cn from 'classnames';
import { UpgradeDowngradeSection } from './components/UpgradeDowngradeSection';
import { getWithExpiry } from 'src/utils/localStorage';
import { toast } from 'react-toastify';
import { PaymentPageTeamsTabs } from 'src/v2/pages/payment/components/PaymentPageTeamsTabs';

// TODO(olha): rename to PaymentPage
export const PaymentBetaPage = () => {
  const navigate = useNavigate();

  const {
    isOpenTier,
    appUser,
    isEnterpriseTrialTier,
    isSubscribedTier,
    isTeamsTier,
  } = useSession();

  const { searchParams, removeSearchParam } = useQueryParams();

  const [selectedPeriod, setSelectedPeriod] = useState<PaymentPlanInterval>(
    PaymentPlanInterval.MONTHLY,
  );

  const activeTab =
    searchParams.payment_tab ||
    (isTeamsTier ? TeamsTabs.BUSINESS : TeamsTabs.INDIVIDUAL);

  const [selectedPlan, setSelectedPlan] = useState<
    SubscriptionPlan | undefined
  >();
  const [selectedPlanSection, setSelectedPlanSection] = useState<
    PlanSectionType | undefined
  >();

  const [isPaymentProcessing, setPaymentProcessing] = useState<boolean>(false);

  const {
    subscriptionInfo,
    isSubscriptionInfoLoading,
    isSubscriptionInfoFetching,
  } = useSubscriptionInfo();

  const { isMobile } = useBreakpoint();

  const isTeams = activeTab === TeamsTabs.BUSINESS;

  const PlanSections: PlanSectionType[] = isTeams
    ? ['Teams_Ultra']
    : ['Standard', 'Pro', 'Ultra'];

  const {
    isVisible: isEmbeddedCheckoutVisible,
    onVisibilitySet: onOpenEmbeddedCheckoutVisible,
    onVisibilityRemove: onCloseEmbeddedCheckoutVisible,
  } = useVisible();

  const { plans, isPlansLoading } = useSubscriptionPlans({
    group: isTeams ? 'teams' : 'individual',
    seat_count: isTeams
      ? isTeamsTier
        ? subscriptionInfo?.seat_count
        : undefined
      : undefined,
  });

  const handleChangeTeamTabValue = () => {
    onCloseEmbeddedCheckoutVisible();
    setSelectedPlan(undefined);
    setSelectedPlanSection(undefined);
  };

  const handleOpenCheckout = async (
    type: PlanSectionType,
    period: PaymentPlanInterval,
  ) => {
    // todo add teams check
    if (period === PaymentPlanInterval.MONTHLY) {
      const gtmEvent =
        type === 'Standard'
          ? GTMEvent.GET_STANDARD_MONTHLY_CLICK
          : type === 'Pro'
            ? GTMEvent.GET_PRO_MONTHLY_CLICK
            : GTMEvent.GET_ULTRA_MONTHLY_CLICK;
      sendGTMEvent(gtmEvent);
    } else {
      const gtmEvent =
        type === 'Standard'
          ? GTMEvent.GET_STANDARD_ANNUAL_CLICK
          : type === 'Pro'
            ? GTMEvent.GET_PRO_ANNUAL_CLICK
            : GTMEvent.GET_ULTRA_ANNUAL_CLICK;
      sendGTMEvent(gtmEvent);
    }

    setSelectedPlanSection(type);
    const plan = plans?.find(
      (plan) => plan.period === period && plan.plan_tier === type.toLowerCase(),
    );

    if (plan) {
      setSelectedPlan(plan);
      onOpenEmbeddedCheckoutVisible();
    }
  };

  const handleBuyWithAws = (
    type: PlanSectionType,
    period: PaymentPlanInterval,
  ) => {
    const plan = plans?.find(
      (plan) => plan.period === period && plan.plan_tier === type.toLowerCase(),
    );
    window.open(plan?.metadata?.amp.amp_product_link, '_blank');
  };

  const handleCloseCheckout = () => {
    onCloseEmbeddedCheckoutVisible();
    setSelectedPlan(undefined);
    setSelectedPlanSection(undefined);
  };

  useEffect(() => {
    if (
      subscriptionInfo &&
      (subscriptionInfo.is_cancelled ||
        subscriptionInfo.has_scheduled_downgrade ||
        subscriptionInfo.has_scheduled_plan_change ||
        subscriptionInfo.processing_plan_change ||
        isAppleOrAmpSource(subscriptionInfo.source))
    ) {
      navigate(AppRoutes.HOME, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionInfo]);

  useEffect(() => {
    if (appUser.user_id && isOpenTier) {
      localStorage.setItem(
        REDIRECT_TO_STRIPE_TIMESTAMP,
        dayjs().valueOf().toString(),
      );
      navigate(AppRoutes.LOGIN);
    } else if (
      (appUser.user_id && isEnterpriseTrialTier) ||
      (!!subscriptionInfo && subscriptionInfo?.has_scheduled_downgrade)
    ) {
      navigate(AppRoutes.HOME, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenTier, appUser.user_id, isEnterpriseTrialTier, subscriptionInfo]);

  useEffect(() => {
    if (isSubscribedTier && !!subscriptionInfo?.interval) {
      setSelectedPeriod(subscriptionInfo?.interval);
    }
  }, [isSubscribedTier, subscriptionInfo]);

  useEffect(() => {
    if (!!searchParams.plan) {
      const plan = plans?.find(
        (plan) => plan.plan_tier === searchParams?.plan?.toLowerCase(),
      );

      if (plan) {
        setSelectedPlan(plan);
        setSelectedPlanSection(
          capitalizeEachFirstLetter(plan.plan_tier) as PlanSectionType,
        );
        onOpenEmbeddedCheckoutVisible();

        removeSearchParam('plan');
      }
    }
  }, [
    removeSearchParam,
    searchParams.plan,
    plans,
    onOpenEmbeddedCheckoutVisible,
  ]);

  const handleRemoveFlagFromLS = () => {
    if (!!localStorage.getItem(REDIRECT_TO_STRIPE_TIMESTAMP)) {
      localStorage.removeItem(REDIRECT_TO_STRIPE_TIMESTAMP);
    }
  };

  const handleBackButtonClick = () => {
    sendGTMEvent(GTMEvent.PAYMENT_BACK_BUTTON_CLICK);
    handleRemoveFlagFromLS();
  };

  const handleCompareAllPlanFeaturesClick = () => {
    sendGTMEvent(GTMEvent.PAYMENT_COMPARE_PLAN_FEATURES_CLICK);
  };

  const handlePeriodToggle = (period: PaymentPlanInterval) => {
    setSelectedPeriod(period);
    const plan = plans?.find(
      (plan) =>
        plan.period === period &&
        plan.plan_tier === selectedPlanSection?.toLowerCase(),
    );
    if (plan) {
      setSelectedPlan(plan);
      onOpenEmbeddedCheckoutVisible();
    }
  };

  useEffect(() => {
    const paypalPaymentInProgress =
      getWithExpiry(PAYPAL_PAYMENT_IN_PROGRESS) === 'true';

    if (paypalPaymentInProgress) {
      toast.info(
        `${PAYPAL_PAYMENT_IN_PROGRESS_MESSAGE} Please wait until it's completed.`,
      );
      navigate(AppRoutes.HOME);
    }
  }, [navigate]);

  const isTeamSelected = selectedPlanSection?.includes('Team');

  const isUpgradeToTeam = !isTeamsTier && isTeamSelected;

  const footerComponent = (
    <div className={styles.footer}>
      <div className={styles.hints}>
        <div className={styles.hintRow}>
          <span className={styles.icon}>*</span>
          <span>Subject to abuse thresholds</span>
        </div>
        <div className={styles.hintRow}>
          <NinjaAgentsOutlinedIcon width={SVG_SIZE_S} height={SVG_SIZE_S} />
          <span>
            Ninja LLM: Fine-tuned and powered by Ninja’s infrastructure
          </span>
        </div>
        {isTeams && (
          <div className={styles.buyWithAwsFooter}>
            Available in AWS Marketplace
          </div>
        )}
      </div>
      <a
        href={LEARN_MORE_PRICES_LINK}
        target="_blank"
        rel="noreferrer"
        className={styles.comparePlanLink}
        onClick={handleCompareAllPlanFeaturesClick}
      >
        <ArrowSquareOut size={SVG_SIZE_M} />
        <span>Compare all plan feature</span>
      </a>
    </div>
  );

  return (
    <div className={styles.root}>
      {(isPlansLoading ||
        isPaymentProcessing ||
        isSubscriptionInfoFetching ||
        isSubscriptionInfoLoading) && (
        <LoadingOverlay label={'Loading plans...'} />
      )}

      <div className={styles.centerPaymentPart}>
        <div className={styles.header}>
          <Link
            to={AppRoutes.HOME}
            className={styles.goBackLink}
            onClick={handleBackButtonClick}
          >
            <ArrowLeft size={SVG_SIZE_L} />
          </Link>
          {!isTeamsTier ? (
            <PaymentPageTeamsTabs onChange={handleChangeTeamTabValue} />
          ) : (
            <h3 className={styles.title}>Select your plan</h3>
          )}
        </div>

        {!isSubscribedTier && (
          <div className={styles.periodSwitchWrapper}>
            <PeriodSwitch
              selectedPeriod={selectedPeriod}
              onChangePeriod={handlePeriodToggle}
              isTeams={isTeams}
            />
          </div>
        )}
        <div
          className={cn(styles.planSectionWrapper, {
            [styles.withoutPeriodSelect]: isSubscribedTier,
          })}
        >
          <div className={styles.planWrapper}>
            {PlanSections.map((item) => (
              <PlanSection
                key={item}
                type={item}
                onOpenCheckout={handleOpenCheckout}
                handleBuyWithAws={handleBuyWithAws}
                period={selectedPeriod}
                selected={selectedPlanSection}
                plans={plans}
                isTeams={isTeams}
              />
            ))}
            {isMobile && footerComponent}
          </div>
          {!isMobile && footerComponent}
        </div>
      </div>

      {isSubscribedTier ? (
        <UpgradeDowngradeSection
          isOpen={isEmbeddedCheckoutVisible}
          onClose={handleCloseCheckout}
          selectedPlan={selectedPlan}
          isUpgradeToTeam={isUpgradeToTeam}
        />
      ) : (
        <CheckoutSection
          isOpen={isEmbeddedCheckoutVisible}
          onClose={handleCloseCheckout}
          selectedPlan={selectedPlan}
          isPaymentProcessing={isPaymentProcessing}
          onPaymentProcessing={setPaymentProcessing}
          isTeams={isTeams}
        />
      )}
    </div>
  );
};
