/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AiGenerationStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
  CONTENT_POLICY_VIOLATION = 'content_policy_violation',
}
