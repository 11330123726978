import classNames from 'classnames';
import { useCollapse } from 'react-collapsed';
import { Check, CheckCircle, Circle, CaretDown } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { EXTERNAL_MODELS_LINK } from 'src/constants/externalLinks';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent, UserTier } from 'src/types';
import styles from './MobilePlanCard.module.scss';
import { PlanCardWrapper } from '../PlanCardWrapper';
import { Button } from 'src/v2/commonComponents/Button';

interface MobilePlanCardProps {
  id: UserTier;
  title: string;
  price: number;
  features: { id: string; title: JSX.Element }[];
  models: { id: string; icon: JSX.Element; text: JSX.Element }[];
  description: React.ReactNode;
  onClick: (plan: UserTier) => void;
  isSelected: boolean;
}

export const MobilePlanCard = ({
  id,
  title,
  price,
  features,
  models,
  description,
  isSelected,
  onClick,
}: MobilePlanCardProps) => {
  const { getCollapseProps, isExpanded, setExpanded } = useCollapse();

  const isBasic = id === UserTier.FREE;

  const handleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleViewAllModelsClick = () => {
    sendGTMEvent(GTMEvent.PAYMENT_VIEW_ALL_MODELS_CLICK);
  };

  return (
    <PlanCardWrapper id={id} onClick={onClick} isSelected={isSelected}>
      <div className={styles.planInfo}>
        <div
          className={classNames(styles.selectButton, {
            [styles.selected]: isSelected,
          })}
        >
          {isSelected || isBasic ? (
            <CheckCircle
              size={SVG_SIZE_M}
              weight={!isBasic ? 'fill' : 'regular'}
            />
          ) : (
            <Circle size={SVG_SIZE_M} />
          )}
        </div>

        <div className={styles.shortPlanInfo}>
          <p className={styles.title}>{title}</p>
          <p className={styles.price}>
            {price ? `$${price} USD/Month` : 'Free'}
          </p>
          <p className={styles.description}>{description}</p>
          {isBasic ? (
            <span className={styles.caption}>Current Plan</span>
          ) : (
            <Button
              className={styles.expandButton}
              onClick={handleExpand}
              color="transparent"
            >
              <CaretDown
                size={SVG_SIZE_M}
                className={classNames(styles.arrow, {
                  [styles.rotated]: isExpanded,
                })}
              />
            </Button>
          )}
        </div>
      </div>

      <div {...getCollapseProps()}>
        <div className={styles.expandableContent}>
          <div className={styles.features}>
            {features.map(({ id, title }) => (
              <p key={id} className={styles.feature}>
                <Check size={SVG_SIZE_M} />
                {title}
              </p>
            ))}
          </div>

          <div className={styles.models}>
            {models.map(({ id, icon, text }) => (
              <p key={id} className={styles.model}>
                {icon}
                {text}
              </p>
            ))}
            {id === UserTier.ULTRA && (
              <a
                href={EXTERNAL_MODELS_LINK}
                target="_blank"
                rel="noreferrer"
                className={styles.viewAllModels}
                onClick={handleViewAllModelsClick}
              >
                +16 more models
              </a>
            )}
          </div>
        </div>
      </div>
    </PlanCardWrapper>
  );
};
