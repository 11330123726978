import { ConversationRole } from 'src/types';
import { MessageAuthorPicture } from 'src/v2/components/MessageAuthorPicture';
import styles from './PromptListItem.module.scss';

interface PromptListItemProps {
  role: ConversationRole;
  content: string;
  model?: string;
}

export const PromptListItem = ({
  role,
  content,
  model,
}: PromptListItemProps) => {
  return (
    <div className={styles.root}>
      <MessageAuthorPicture role={role} />

      <div className={styles.container}>
        {model && <p className={styles.model}>{model}</p>}
        <p className={styles.content}>{content}</p>
      </div>
    </div>
  );
};
