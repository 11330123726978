import { useCollapse } from 'react-collapsed';
import { CaretUp, CaretDown } from '@phosphor-icons/react';
import { Message, Action, TaskState, ProgressUpdate } from 'src/types';
import { ProgressStatus } from './components/ProgressStatus';
import { ProgressStatusIcon } from './components/ProgressStatusIcon';
// import { LatestProgressAction } from 'src/v2/components/ThreadMessage/components/LatestProgressAction';

const SVG_SIZE = 20;

type ProgressCardProps = {
  message?: Message;
  messageActions?: Action[];
  taskState: TaskState;
  className?: string;
};

/**
 * @deprecated
 */
export const ProgressSection = ({
  message,
  messageActions,
  taskState,
}: ProgressCardProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  if (message?.payload?.payload_type !== 'progress-update' && !messageActions) {
    return null;
  }

  const actions =
    message?.payload && message?.payload?.payload_type === 'progress-update'
      ? (message.payload as ProgressUpdate).actions
      : messageActions || [];

  return (
    <div className="nj-task-view-header--progress-card">
      <button
        className="nj-task-view-header--progress-card--collapse-button"
        {...getToggleProps()}
      >
        <div className="nj-task-view-header--progress-card--text-container">
          <ProgressStatus actions={actions} taskState={taskState} />
          {/* {!isExpanded && (
            <LatestProgressAction
              actions={actions}
              taskState={TaskState.IN_PROGRESS}
              skill={null}
            />
          )} */}
        </div>
        {isExpanded ? (
          <CaretUp size={SVG_SIZE} />
        ) : (
          <CaretDown size={SVG_SIZE} />
        )}
      </button>
      <ul
        className="nj-task-view-header--progress-card--action-list"
        {...getCollapseProps()}
      >
        {actions.map((item: Action) => (
          <li
            className="nj-task-view-header--progress-card--action-item"
            key={item.action}
          >
            <ProgressStatusIcon
              status={item.status}
              className="nj-task-view-header--progress-card--action-item--icon"
            />
            <span>{item.action}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
