import { Button } from 'src/v2/commonComponents/Button';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { SVG_SIZE_XS } from 'src/constants';
import type { ToggleProps } from 'src/v2/commonComponents/DrawerTooltipContainer';
import styles from './CodeLanguageToggle.module.scss';
import { useEditorState } from 'src/hooks';
import { getLanguageLabel } from 'src/utils/editorUtils';

export const CodeLanguageToggle = ({
  id,
  disabled,
  isActive,
  onClick,
}: ToggleProps) => {
  const { codeEditorData } = useEditorState();

  return (
    <Button
      id={id}
      disabled={disabled}
      color="transparent"
      onClick={onClick}
      className={styles.root}
    >
      <div className={styles.valueWrapper}>
        <span>
          {codeEditorData?.language
            ? getLanguageLabel(codeEditorData.language)
            : 'Languages'}
        </span>
      </div>
      {isActive ? (
        <CaretUp size={SVG_SIZE_XS} />
      ) : (
        <CaretDown size={SVG_SIZE_XS} />
      )}
    </Button>
  );
};
