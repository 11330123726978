import { useCurrentMessage } from 'src/hooks';
import { isVideoGenerationCard } from 'src/types';
import styles from './ThreadVideoGenCard.module.scss';
import { FeedbackRating } from '../FeedbackRating';
import { ThreadVideoPayer } from 'src/v2/components/ThreadVideoPayer';
import { DownloadVideoButton } from 'src/v2/components/ThreadMessage/components/DownloadVideoButton';

interface ThreadVideoGenCardProps {
  messageId: string;
  isStreaming: boolean;
}

export const ThreadVideoGenCard = ({
  messageId,
  isStreaming = false,
}: ThreadVideoGenCardProps) => {
  const { currentMessage } = useCurrentMessage(messageId);

  if (!currentMessage || !isVideoGenerationCard(currentMessage.payload)) {
    return null;
  }

  const {
    payload: { videos },
  } = currentMessage;

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {Object.entries(videos || {}).map(([key, item]) => (
          <ThreadVideoPayer key={key} data={item} isStreaming={isStreaming} />
        ))}
      </div>

      <div className={styles.downloadButtonWrapper}>
        <DownloadVideoButton messageId={messageId} />
      </div>

      {!isStreaming && (
        <FeedbackRating
          title="Rate the video"
          messageId={messageId}
          isStreaming={false}
          operationType="message-level"
        />
      )}
    </div>
  );
};
