/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvitationStatus {
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  ACCEPTED = 'accepted',
  ACTIVE = 'active',
}
