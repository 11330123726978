import { useContext, useMemo, useState } from 'react';
import { Menu, MenuAlign, MenuButton, MenuItem } from '@szhsin/react-menu';
import { toast } from 'react-toastify';
import {
  DotsThreeOutlineVertical,
  PencilSimple,
  Archive,
  Trash,
  Bug,
} from '@phosphor-icons/react';
import {
  useConversationActions,
  useCurrentConversation,
  useSession,
  useVisible,
} from 'src/hooks';
import { AppRoutes, Conversation, ConversationState } from 'src/types';
import { useCheckIsNinjaEmployee } from 'src/hooks';
import { useHotkeys } from 'react-hotkeys-hook';
import DebugContext from 'src/contexts/DebugContext';
import { COMMON_ERROR_TEXT, SVG_SIZE_M } from 'src/constants';
import { RenameModal } from 'src/components/RenameModal';
import styles from './ConversationMenu.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/store';
import {
  deleteByIdFromQueryGetConversations,
  deleteByIdFromQueryGetConversationsWithArchived,
} from 'src/store/updateQueries';
import { CommonDeleteModal } from 'src/components/CommonDeleteModal/CommonDeleteModal';

type ConversationMenuProps = {
  conversation: Conversation;
  align?: MenuAlign;
};

export const ConversationMenu = ({
  conversation,
  align = 'start',
}: ConversationMenuProps) => {
  const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false);

  const {
    state: conversationState,
    conversation_id,
    conversation_hash,
  } = conversation;
  const { toggleDebugMode } = useContext(DebugContext);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isUserNinjaEmployee = useCheckIsNinjaEmployee();
  const { currentConversation } = useCurrentConversation();
  const { updateConversationById, deleteConversationById, isDeleteLoading } =
    useConversationActions();
  const {
    isVisible: isDeleteModalVisible,
    onVisibilitySet: onDeleteModalVisibilitySet,
    onVisibilityRemove: onDeleteModalVisibilityRemove,
  } = useVisible();
  const {
    appUser: { user_id },
  } = useSession();

  useHotkeys('ctrl+d', () => {
    toggleDebugMode();
  });

  const preparedConversationHash = useMemo(
    () => conversation_hash?.replace('#', '') || '',
    [conversation_hash],
  );

  const handleOpenRenameModal = () => {
    setIsRenameModalOpen(true);
  };

  const handleArchiveThread = async (conversationId?: string | null) => {
    if (!conversationId) {
      return;
    }

    await updateConversationById({
      conversation_id: conversationId,
      state: ConversationState.ARCHIVED,
    });

    toast.success('Chat archived');
  };

  const showDeleteConversationModal = (
    conversationId?: string | null,
    conversationHash?: string | null,
  ) => {
    if (!conversationId || !conversationHash) {
      return;
    }

    onDeleteModalVisibilitySet();
  };

  const onDeleteConversation = async () => {
    if (!conversation_id) {
      return;
    }

    try {
      await deleteConversationById({
        conversation_id,
      });

      if (currentConversation?.conversation_id === conversation_id) {
        navigate(AppRoutes.HOME);
      }

      dispatch(
        deleteByIdFromQueryGetConversations({ user_id, conversation_id }),
      );
      dispatch(
        deleteByIdFromQueryGetConversationsWithArchived({
          user_id,
          conversation_id,
        }),
      );

      toast.success('Chat thread deleted');
      onDeleteModalVisibilityRemove();
    } catch (error) {
      toast.error(COMMON_ERROR_TEXT);
      onDeleteModalVisibilityRemove();
    }
  };

  return (
    <>
      {isRenameModalOpen && (
        <RenameModal
          isOpen={isRenameModalOpen}
          onClose={() => setIsRenameModalOpen(false)}
        />
      )}

      {isDeleteModalVisible && conversation_hash && (
        <CommonDeleteModal
          isOpen={isDeleteModalVisible}
          onClose={onDeleteModalVisibilityRemove}
          onSubmit={onDeleteConversation}
          isLoading={isDeleteLoading}
          title="Delete chat"
          target={preparedConversationHash}
        />
      )}

      <Menu
        gap={8}
        align={align}
        menuClassName={styles.root}
        menuButton={
          <MenuButton data-testid="snowman-menu">
            <DotsThreeOutlineVertical
              size={SVG_SIZE_M}
              color="currentColor"
              alt="Conversation menu"
            />
          </MenuButton>
        }
        transition
      >
        <MenuItem data-testid="rename-hash-btn" onClick={handleOpenRenameModal}>
          <PencilSimple size={SVG_SIZE_M} color="currentColor" /> Rename
        </MenuItem>

        {conversationState === ConversationState.ARCHIVABLE && (
          <>
            <MenuItem
              data-testid="archive-btn"
              onClick={() => handleArchiveThread(conversation_id)}
            >
              <Archive size={SVG_SIZE_M} color="currentColor" /> Archive
            </MenuItem>
          </>
        )}

        {isUserNinjaEmployee && (
          <MenuItem data-testid="rename-hash-btn" onClick={toggleDebugMode}>
            <Bug size={SVG_SIZE_M} color="currentColor" /> Debug
          </MenuItem>
        )}

        <MenuItem
          data-testid="delete-conversation-btn"
          onClick={() =>
            showDeleteConversationModal(conversation_id, conversation_hash)
          }
        >
          <Trash size={SVG_SIZE_M} color="currentColor" /> Delete
        </MenuItem>
      </Menu>
    </>
  );
};
