import { useMemo, useState } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Dimensions } from 'src/components/ImageGenSettings/components/Dimensions';
import { AppRoutes, RequestPayloadType } from 'src/types';
import { toast } from 'react-toastify';
import { ImageStyleSelect } from 'src/components/ImageGenSettings/components/ImageStyleSelect';
import styles from './ImageGenModifyPanel.module.scss';
import { useSession, useSubmitUserInput, useUserSettings } from 'src/hooks';
import { COMMON_ERROR_TEXT } from 'src/constants';
import { useDeleteAttachment } from 'src/hooks';
import { useRightSidePanelState } from 'src/hooks';
import { ResolutionToggle } from 'src/components/ImageGenSettings/components/ResolutionToggle';
import { SettingKeys, SingleSelectSetting } from 'src/types/settings';
import log from 'src/utils/logger';
import { Button } from 'src/v2/commonComponents/Button';

type ImageGenModifyPanelProps = {
  isOpen?: boolean;
  onClose: () => void;
  messageOriginalQuery?: string;
  messageCleanQuery?: string;
};

export const ImageGenModifyPanel = ({
  isOpen,
  onClose,
  messageOriginalQuery = '',
  messageCleanQuery = '',
}: ImageGenModifyPanelProps) => {
  const navigate = useNavigate();
  const [isRegenerateLoading, setIsRegenerateLoading] =
    useState<boolean>(false);

  const { setting: dimentionSetting, updateUserSetting: updateDimention } =
    useUserSettings<SingleSelectSetting>(SettingKeys.IMAGE_DIMENTIONS);
  const { setting: resolutionSetting, updateUserSetting: updateResolution } =
    useUserSettings<SingleSelectSetting>(SettingKeys.IMAGE_SIZES);
  const { setting: styleSetting, updateUserSetting: updateStyle } =
    useUserSettings<SingleSelectSetting>(SettingKeys.IMAGE_STYLES);

  const [dimentionValue, setDimentionValue] = useState<string | undefined>(
    dimentionSetting?.value,
  );
  const [resolutionValue, setResolutionValue] = useState<string | undefined>(
    resolutionSetting?.value,
  );
  const [styleValue, setStyleValue] = useState<string | undefined>(
    styleSetting?.value,
  );

  const { isOpenTier, isFreeTier } = useSession();

  const { onSubmitUserInput } = useSubmitUserInput();
  const { onDeleteAllAttachments } = useDeleteAttachment();
  const { isRightPanelExpanded } = useRightSidePanelState();

  const handleSettingsUpdate = async () => {
    try {
      if (!!dimentionValue && dimentionValue !== dimentionSetting?.value) {
        await updateDimention(dimentionValue, true);
      }
      if (!!resolutionValue && resolutionValue !== resolutionSetting?.value) {
        await updateResolution(resolutionValue, true);
      }
      if (!!styleValue && styleValue !== styleSetting?.value) {
        await updateStyle(styleValue, true);
      }
    } catch (e) {
      log.error(e);
    }
  };

  const handleRegenerateClick = async () => {
    if (isOpenTier) {
      navigate(AppRoutes.SIGN_UP);
      return;
    }
    if (isFreeTier) {
      navigate(AppRoutes.PAYMENT);
      return;
    }

    try {
      setIsRegenerateLoading(true);
      await onDeleteAllAttachments();
      const value = `/image ${
        messageCleanQuery || messageOriginalQuery
      }`.trim();

      await handleSettingsUpdate();

      onSubmitUserInput(value, {
        content: value,
        payload_list: [
          {
            payload_type: RequestPayloadType.TEXT,
            content: value,
          },
        ],
      });
    } catch (e) {
      toast(COMMON_ERROR_TEXT);
    } finally {
      setIsRegenerateLoading(false);
      onClose();
    }
  };

  const submitTitle = useMemo(() => {
    if (isOpenTier) {
      return 'Sign up to unlock';
    }
    if (isFreeTier) {
      return 'Upgrade to unlock';
    }
    return 'Regenerate';
  }, [isOpenTier, isFreeTier]);

  const buttonId = isFreeTier
    ? 'Image-modify-upgrade'
    : isOpenTier
      ? 'Image-modify-signup'
      : '';

  return (
    <div className={styles.root}>
      <div
        className={cn(styles.content, {
          [styles.mobileView]: isRightPanelExpanded,
        })}
      >
        <div className={styles.field}>
          <Dimensions
            selectedValue={dimentionValue}
            onChange={setDimentionValue}
          />

          <hr className="divider" />

          <ResolutionToggle
            isVisible={isOpen}
            selectedValue={resolutionValue}
            onChange={setResolutionValue}
          />

          <hr className="divider" />

          <ImageStyleSelect
            maxMenuHeight={200}
            selectedValue={styleValue}
            onChange={setStyleValue}
          />
        </div>
        <Button
          color="primary"
          className={styles.regenerateButton}
          type="button"
          onClick={handleRegenerateClick}
          id={buttonId}
          disabled={isRegenerateLoading}
        >
          {submitTitle}
        </Button>
      </div>
    </div>
  );
};
