import { Button } from 'src/v2/commonComponents/Button';
import { ClockCounterClockwise } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import type { ToggleProps } from 'src/v2/commonComponents/DrawerTooltipContainer';

export const VersioningToggle = ({
  id,
  disabled,
  isActive,
  onClick,
}: ToggleProps) => {
  return (
    <Button
      id={id}
      disabled={disabled}
      color={isActive ? 'tertiary' : 'transparent'}
      onClick={onClick}
      shape="round"
    >
      <ClockCounterClockwise size={SVG_SIZE_M} />
    </Button>
  );
};
