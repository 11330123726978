import { ArrowUUpLeft } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { IconButton } from 'src/v2/components/IconButton';
import { TextDivider } from 'src/v2/commonComponents/TextDivider';
import styles from './UndoPromptButton.module.scss';

interface UndoPromptButtonProps {
  showUndoTranslateButton: boolean;
  showUndoPromptButton: boolean;
  isLoading: boolean;
  handleUndo: () => void;
}

export const UndoPromptButton = ({
  showUndoTranslateButton,
  showUndoPromptButton,
  isLoading,
  handleUndo,
}: UndoPromptButtonProps) => {
  if ((!showUndoTranslateButton && !showUndoPromptButton) || isLoading) {
    return null;
  }

  const label = showUndoTranslateButton
    ? 'Undo Translation'
    : 'Undo Improvement';

  return (
    <>
      <TextDivider />
      <IconButton
        className={styles.undoButton}
        icon={ArrowUUpLeft}
        onClick={handleUndo}
        size={SVG_SIZE_M}
        label={label}
      />
    </>
  );
};
