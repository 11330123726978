/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserStatus {
  ACTIVE = 'active',
  RATE_LIMITED = 'rate_limited',
  MAINTENANCE = 'maintenance',
  QUEUE = 'queue',
  BUDGET_SUSPENDED = 'budget_suspended',
}
