import { FileList } from './components/FileList';
import styles from './FileListSection.module.scss';
import { UpsellFooter } from '../ConversationListSection/components/UpsellFooter';

export const FileListSection = () => {
  return (
    <div className={styles.root} data-testid="first-panel">
      <FileList />

      <UpsellFooter />
    </div>
  );
};
