import { useEffect, useState } from 'react';
import { useQueryParams } from 'src/hooks';
import { createRandomPillarSet } from 'src/utils';
import { PillarBlend, PillarBlendSet, MessageSkill } from 'src/types';
import recommendations from 'src/data/recommendations.json';

const RECOMMENDATIONS: PillarBlend = JSON.parse(
  JSON.stringify(recommendations),
);

// (irina) TODO: need refactoring
export const useRecommendedSkills = ({
  skillsUpdateTrigger,
  skill,
}: {
  skillsUpdateTrigger?: number;
  skill?: MessageSkill;
}) => {
  const { searchParams } = useQueryParams();

  const recommendation = searchParams.recommendation?.toUpperCase();

  const [randomPillarSet, setRandomPillarSet] = useState<PillarBlendSet>(
    createRandomPillarSet,
  );

  useEffect(() => {
    if (skillsUpdateTrigger) {
      setRandomPillarSet(createRandomPillarSet);
    }
  }, [skillsUpdateTrigger]);

  if (skill) {
    return [randomPillarSet[skill]];
    //if recommendation exists, and is recognized and valid use it, else default behavior
  } else if (recommendation && recommendation in RECOMMENDATIONS) {
    return RECOMMENDATIONS[recommendation];
  } else {
    return Object.values(randomPillarSet);
  }
};
