import { useTaskById } from 'src/hooks';
import { TaskSkill, TaskState } from 'src/types';
import { StopTaskButton } from 'src/components/StopTaskButton';
import { CloseTaskButton } from 'src/components/FlatAppearance/components/CloseTaskButton';
import { ReportIssueToZendeskButton } from './components/ReportIssueToZendeskButton';

interface TaskViewHeaderProps {
  onCloseTaskView: () => void;
}

export const TaskViewHeader = ({ onCloseTaskView }: TaskViewHeaderProps) => {
  const { currentTaskData } = useTaskById();

  if (!currentTaskData || !currentTaskData?.task_id) {
    return null;
  }

  const taskName = currentTaskData?.task_hash?.replace('#', '') || '';

  return (
    <div className="nj-task-view-header">
      <div className="nj-task-view-header--actions">
        <h6 className="nj-task-view-header--actions-title ellipsis">
          {taskName}
        </h6>

        {currentTaskData?.skill === TaskSkill.SCHEDULING && (
          <StopTaskButton
            taskId={currentTaskData.task_id}
            taskState={currentTaskData.state || TaskState.IN_PROGRESS}
            taskHash={currentTaskData?.task_hash || ''}
            className="nj-task-view-header--stop-botton"
            buttonName="Stop task"
            data-e2e="stop-task-button"
          />
        )}

        <ReportIssueToZendeskButton
          tags={['task', 'support']}
          taskId={currentTaskData?.task_id}
        />

        <CloseTaskButton onClick={onCloseTaskView} />
      </div>
    </div>
  );
};
