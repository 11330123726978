import { Icon } from 'src/components/Icon';
import styles from './EmptyState.module.scss';

export const EmptyState = () => {
  return (
    <div className={styles.root}>
      <Icon type="documentsFolder" size={172} />

      <p className={styles.caption}>
        Send docs & code to editor from chat, or use "New" to create new files
        with editor
      </p>
    </div>
  );
};
