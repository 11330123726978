import { jsPDF } from 'jspdf';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes } from '@lexical/html';
import { Button } from 'src/v2/commonComponents/Button';

export const DownloadPDFButton = () => {
  const [editor] = useLexicalComposerContext();

  const downloadPDF = async () => {
    let htmlString = '';

    editor.update(() => {
      htmlString = $generateHtmlFromNodes(editor, null);
    });

    const doc = new jsPDF({
      orientation: 'portrait',
      format: 'a4',
    });

    await doc.html(htmlString, {
      // TODO(olha): use value from Redux
      callback: () => doc.save('file.pdf'),
      margin: [10, 10, 10, 10],
      autoPaging: 'text',
      width: 190,
      windowWidth: 800,
    });
  };

  return (
    <Button onClick={downloadPDF} fullWidth color="transparent">
      PDF Document (.pdf)
    </Button>
  );
};
