import styles from './ModelAndSkillItem.module.scss';

interface ModelAndSkillItemProps {
  icon: JSX.Element;
  title: string;
}

export const ModelAndSkillItem = ({ icon, title }: ModelAndSkillItemProps) => {
  return (
    <div className={styles.root}>
      {icon}
      <p className={styles.title}>{title}</p>
    </div>
  );
};
