import { ImageGenSettingsResolutions } from 'src/types';
import styles from './DimensionMoreOptions.module.scss';
import {
  ImageSettingsIconsSet,
  ImageSettingsOtherGroups,
  SVG_SIZE_M,
} from 'src/constants';
import classNames from 'classnames';
import { useUserSettings } from 'src/hooks';
import { SettingKeys, SingleSelectSetting } from 'src/types/settings';

interface DimensionMoreOptionsProps {
  selectedValue: string;
  onClick: (value: string) => void;
}

export const DimensionMoreOptions = ({
  onClick,
  selectedValue,
}: DimensionMoreOptionsProps) => {
  const { setting } = useUserSettings<SingleSelectSetting>(
    SettingKeys.IMAGE_DIMENTIONS,
  );

  return (
    <div>
      {Object.entries(ImageSettingsOtherGroups).map(([group, data], index) => (
        <div key={group}>
          <p className={styles.groupTitle}>{data.groupName}</p>

          {Object.entries(data.options).map(
            ([option, resolutionData]: [
              option: string,
              resolutionData: ImageGenSettingsResolutions,
            ]) => {
              const CurrentIcon = ImageSettingsIconsSet[option];
              const isDisabled = !(setting?.options || {})[option]?.selectable;

              return (
                <button
                  className={classNames(styles.menuItem, {
                    [styles.active]: option === selectedValue,
                    [styles.disabled]: isDisabled,
                  })}
                  key={option}
                  disabled={isDisabled}
                  onClick={() => onClick(option)}
                  data-e2e={`image-dimension-more-options-${option}`}
                >
                  <span className={styles.title}>
                    <CurrentIcon size={SVG_SIZE_M} />

                    {resolutionData.name}
                  </span>
                  <span className={styles.subtitle}>
                    {resolutionData.aspect_ratio}
                  </span>
                </button>
              );
            },
          )}

          {index < Object.keys(ImageSettingsOtherGroups).length - 2 && (
            <hr className={styles.divider} />
          )}
        </div>
      ))}
    </div>
  );
};
