import { Play, MagicWand } from '@phosphor-icons/react';
import Skeleton from 'react-loading-skeleton';
import { useAttachFile, useColorTheme, useSession } from 'src/hooks';
import { Icon, IconName } from 'src/components/Icon';
import classNames from 'classnames';
import { MouseEvent, useMemo, useState } from 'react';
import { AiGenerationStatus } from 'src/types/models/AiGenerationStatus';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';
import { ResearchMediaError } from '../ResearchMediaError';

const SVG_SIZE = 16;
const SVG_SIZE_BIG = 30;

interface ResearchMediaThumbnailProps {
  thumbnail: string;
  title: string;
  isVideo?: boolean;
  onClick: () => void;
  icon?: IconName;
  status?: string | null;
  isPixPro?: boolean;
  isDisabled?: boolean;
  isGenImage?: boolean;
  withWatermark?: boolean;
}

const { darkTheme: baseDark, lightTheme: baseLight } = {
  darkTheme: '#1d282d',
  lightTheme: '#e8eaea',
};

const { darkTheme: highlightDark, lightTheme: highlightLight } = {
  darkTheme: '#11181b',
  lightTheme: '#ffffff',
};

const { darkTheme: basePixProDark, lightTheme: basePixProLight } = {
  darkTheme: '#021733',
  lightTheme: '#CEE3FF',
};

const { darkTheme: highlightPixProDark, lightTheme: highlightPixProLight } = {
  darkTheme: '#0880A6',
  lightTheme: '#E7F9FF',
};

// TODO(olha): needs refactoring. Leave for using only inside Researcher
export const ResearchMediaThumbnail = ({
  thumbnail,
  title,
  isVideo,
  onClick,
  icon,
  status,
  isPixPro = false,
  isDisabled = false,
  isGenImage = false,
  withWatermark,
}: ResearchMediaThumbnailProps) => {
  const { isOpenTier, isFreeTier } = useSession();
  const navigate = useNavigate();
  const { isDarkTheme } = useColorTheme();
  const { sendReceivedFile } = useAttachFile();
  const [isCropped, setIsCropped] = useState(false);

  const isLoadingImage = thumbnail.length === 0 && !isVideo;

  const currentBaseColor = useMemo(() => {
    if (isPixPro) {
      return isDarkTheme ? basePixProDark : basePixProLight;
    }
    return isDarkTheme ? baseDark : baseLight;
  }, [isDarkTheme, isPixPro]);

  const currentHighlightColor = useMemo(() => {
    if (isPixPro) {
      return isDarkTheme ? highlightPixProDark : highlightPixProLight;
    }
    return isDarkTheme ? highlightDark : highlightLight;
  }, [isDarkTheme, isPixPro]);

  if (
    isCropped ||
    status === AiGenerationStatus.CONTENT_POLICY_VIOLATION ||
    status === AiGenerationStatus.FAILURE
  ) {
    return (
      <div
        className="nj-thread-research-media--thumbnail placeholder"
        onClick={onClick}
      >
        <ResearchMediaError status={status} />
      </div>
    );
  }

  const handleEditClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await sendReceivedFile(thumbnail);
  };

  const handleDisabledOverlayClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (isOpenTier) {
      navigate(AppRoutes.SIGN_UP);
    }
    if (isFreeTier) {
      navigate(AppRoutes.PAYMENT);
    }
  };

  return (
    <div className="nj-thread-research-media--thumbnail">
      {isVideo && (
        <span className="nj-thread-research-media--thumbnail-icon">
          <Play size={SVG_SIZE} weight="fill" />
        </span>
      )}
      {icon && (
        <span
          className={classNames('nj-thread-research-media--thumbnail-icon', {
            centered: isLoadingImage,
          })}
        >
          <Icon size={isLoadingImage ? SVG_SIZE_BIG : SVG_SIZE} type={icon} />
        </span>
      )}
      {!isVideo &&
        !isDisabled &&
        !isOpenTier &&
        isGenImage &&
        !withWatermark && (
          <button
            type="button"
            className="nj-thread-research-media--edit-button"
            onClick={handleEditClick}
          >
            <MagicWand size={SVG_SIZE} />
          </button>
        )}
      <div className="nj-gradient-overlay" />
      {isLoadingImage ? (
        <Skeleton
          baseColor={currentBaseColor}
          highlightColor={currentHighlightColor}
          duration={2}
          width="100%"
          height="100%"
          count={1}
        />
      ) : (
        <div
          className={classNames(
            'nj-thread-research-media--thumbnail-image-wrapper',
            {
              mini: !isGenImage,
            },
          )}
        >
          <img
            className="nj-thread-research-media--thumbnail-image"
            alt={title}
            src={thumbnail}
            onClick={onClick}
            onError={() => {
              setIsCropped(true);
            }}
          />
          {isDisabled && (
            <button
              type="button"
              className="nj-thread-research-media--thumbnail-image-disabled-overlay"
              onClick={handleDisabledOverlayClick}
            >
              <span>Unlock to see</span>
              <div className="nj-thread-research-media--thumbnail-image-disabled-overlay-button">
                {isOpenTier ? 'Sign up' : 'Upgrade'}
              </div>
            </button>
          )}
        </div>
      )}
    </div>
  );
};
