import {
  useBreakpoint,
  useGoogleResourcesAccess,
  useSession,
  useVisible,
} from 'src/hooks';
import { ManageAccountContentPage } from '../ManageAccountContentPage/ManageAccountContentPage';
import styles from './Integrations.module.scss';
import {
  Plus,
  CloudCheck,
  CaretDown,
  CaretUp,
  CheckCircle,
} from '@phosphor-icons/react';
import GoogleLogo from 'src/images/logos/google-logo.svg';
import { SVG_SIZE_M, SVG_SIZE_XXXL } from 'src/constants';
import { useCollapse } from 'react-collapsed';
import { useAppDispatch } from 'src/store';
import { contactsApi, ContactsTags } from 'src/store/services';
import { HintTooltip } from 'src/v2/commonComponents/HintTooltip';
import { useMemo } from 'react';

const TOOLTIP_ANCHOR_ID = 'connect-trigger';

export const Integrations = () => {
  const { appUser, isEnterpriseTier } = useSession();
  const {
    canAccessCalendar,
    canAccessContacts,
    canAccessDirectory,
    canAccessGoogleDrive,
    revokeGoogleAccess,
    connectAll,
    currentProvider,
  } = useGoogleResourcesAccess();
  const dispatch = useAppDispatch();

  const { getToggleProps, isExpanded } = useCollapse();
  const { isDesktopAndUp, isMobileOrTablet } = useBreakpoint();

  const isConnectDisabled = useMemo(() => isEnterpriseTier, [isEnterpriseTier]);

  const { isVisible, onVisibilityRemove, onVisibilitySet } = useVisible();

  const hasConnection =
    canAccessCalendar ||
    canAccessContacts ||
    canAccessDirectory ||
    canAccessGoogleDrive;

  const accessData = [
    {
      title: 'Calendar',
      isConnected: canAccessCalendar,
    },
    {
      title: 'Contacts',
      isConnected: canAccessContacts,
    },
    {
      title: 'Directory',
      isConnected: canAccessDirectory,
    },
    {
      title: 'Drive',
      isConnected: canAccessGoogleDrive,
    },
  ];

  const accessCount = accessData.filter((access) => access.isConnected);
  const accessText = accessCount.length === 1 ? ' access' : ' accesses';

  const handleDisconnect = () => {
    if (currentProvider) {
      revokeGoogleAccess({
        userId: appUser.user_id,
        providerId: currentProvider.provider_id,
        email: currentProvider.email,
      });
      dispatch(contactsApi.util.invalidateTags([ContactsTags.Contacts]));
    }
  };

  const handleConnectClick = () => {
    if (isEnterpriseTier) {
      onVisibilitySet();
    } else {
      connectAll();
    }
  };

  return (
    <ManageAccountContentPage
      title="Integrations"
      subtitle="Connect Ninja with your existing tools"
    >
      <div className={styles.root}>
        <h4 className={styles.title}>
          Accounts
          {isDesktopAndUp && (
            <>
              {!hasConnection && (
                <button
                  id={TOOLTIP_ANCHOR_ID}
                  type="button"
                  className={styles.addAccount}
                  onClick={handleConnectClick}
                  disabled={isConnectDisabled}
                >
                  <Plus size={SVG_SIZE_M} /> Add
                </button>
              )}
            </>
          )}
        </h4>
        <h5 className={styles.subtitle}>
          Allow Ninja to access your calendar and contacts.
        </h5>

        <div className={styles.content}>
          {!hasConnection && (
            <CloudCheck size={SVG_SIZE_XXXL} className={styles.cloudImage} />
          )}

          {isMobileOrTablet && (
            <>
              {!hasConnection && (
                <button
                  id={TOOLTIP_ANCHOR_ID}
                  type="button"
                  className={styles.addAccount}
                  onClick={handleConnectClick}
                  disabled={isConnectDisabled}
                >
                  <Plus size={SVG_SIZE_M} /> Add
                </button>
              )}

              <hr className="divider" />
            </>
          )}
        </div>

        {hasConnection && (
          <div className={styles.card}>
            <span className={styles.logo}>
              <img
                src={GoogleLogo}
                alt="Google Account"
                width="20"
                height="20"
              />
            </span>
            <div className={styles.info}>
              <b className={styles.email}>{currentProvider?.email}</b>
              <span className={styles.access}>
                {accessCount.length}
                {accessText}
              </span>

              {isExpanded && (
                <ul className={styles.list}>
                  {accessData.map(({ title, isConnected }) => {
                    return isConnected ? (
                      <li key={title}>
                        <CheckCircle size={SVG_SIZE_M} weight="fill" /> {title}
                      </li>
                    ) : null;
                  })}

                  {accessData.map(({ title, isConnected }) => {
                    return !isConnected ? (
                      <li key={title}>
                        <span className={styles.connect} onClick={connectAll}>
                          <Plus size={SVG_SIZE_M} /> Add {title}
                        </span>
                      </li>
                    ) : null;
                  })}
                </ul>
              )}
            </div>

            {isExpanded && (
              <span className={styles.remove} onClick={handleDisconnect}>
                Remove
              </span>
            )}

            <span className={styles.action} {...getToggleProps()}>
              {isExpanded ? (
                <CaretUp size={SVG_SIZE_M} />
              ) : (
                <CaretDown size={SVG_SIZE_M} />
              )}
            </span>
          </div>
        )}

        <HintTooltip
          content="As per company guidelines, integration with third-party tools is currently disabled"
          isOpen={isVisible}
          onClose={onVisibilityRemove}
          id={TOOLTIP_ANCHOR_ID}
          place="bottom-end"
          sharpCorner="top-right"
        />
      </div>
    </ManageAccountContentPage>
  );
};
