import { forwardRef, ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';
import { HOVER_TOOLTIP_ID } from 'src/constants';
import { PlacesType } from 'react-tooltip';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  shape?: 'regular' | 'round';
  color?:
    | 'primary'
    | 'secondary'
    | 'transparent'
    | 'link'
    | 'tertiary'
    | 'tertiary-outline'
    | 'gray'
    | 'error';
  className?: string;
  tooltipContent?: string;
  tooltipPlace?: PlacesType;
  fullWidth?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      shape = 'regular',
      color = 'primary',
      className,
      tooltipContent,
      tooltipPlace,
      fullWidth = false,
      children,
      ...props
    },
    ref,
  ) => (
    <button
      ref={ref}
      data-tooltip-id={tooltipContent ? HOVER_TOOLTIP_ID : undefined}
      data-tooltip-content={tooltipContent}
      data-tooltip-place={tooltipPlace ? tooltipPlace : undefined}
      {...props}
      className={classNames(
        styles.root,
        {
          [styles.regular]: shape === 'regular',
          [styles.round]: shape === 'round',
          [styles.primary]: color === 'primary',
          [styles.secondary]: color === 'secondary',
          [styles.transparent]: color === 'transparent',
          [styles.link]: color === 'link',
          [styles.tertiary]: color === 'tertiary',
          [styles.tertiaryOutline]: color === 'tertiary-outline',
          [styles.gray]: color === 'gray',
          [styles.error]: color === 'error',
          [styles.fullWidth]: fullWidth,
        },
        className,
      )}
    >
      {children}
    </button>
  ),
);
