/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * FeedbackV2Type
 */
export enum FeedbackV2Type {
  SUPER_AGENT = 'super_agent',
  THUMBS_UP = 'thumbs_up',
  THUMBS_DOWN = 'thumbs_down',
  MODEL_FEEDBACK = 'model_feedback',
  MESSAGE_TOP_LEVEL = 'message_top_level',
}
