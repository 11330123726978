import { useRef } from 'react';
import { AppearingFromRight } from 'src/animations/AppearingFromRight';
import { Icon } from 'src/components/Icon';
import { SVG_SIZE_S } from 'src/constants';
import { useRightSidePanelState } from 'src/hooks';
import { PromptInputBox } from '../PromptInputBox';
import { PromptList } from '../PromptList';
import styles from './PromptPanel.module.scss';

const PANEL_WIDTH = 400;

export const PromptPanel = () => {
  const messagePanelRef = useRef<HTMLDivElement>(null);
  const { isRightPanelFullWidth } = useRightSidePanelState();
  return (
    <AppearingFromRight
      inProp={isRightPanelFullWidth}
      nodeRef={messagePanelRef}
      width={PANEL_WIDTH}
    >
      <div className={styles.root}>
        <div className={styles.header}>
          <Icon type="ninjaOutlined" size={SVG_SIZE_S} />
          <span className={styles.modelName}>Ninja Mode as default model</span>
        </div>

        <PromptList />

        <PromptInputBox />
      </div>
    </AppearingFromRight>
  );
};
