import { APPLE_PAYMENT_ERROR_TEXT, SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import { useSession } from 'src/hooks';
import { useLazyGetBillingPortalUrlQuery } from 'src/store/services';
import {
  AppRoutes,
  BillingSettingsNestedRoutes,
  PaymentsSubscriptionInfo,
} from 'src/types';
import { MinusCircle } from '@phosphor-icons/react';
import { useState } from 'react';

type CancelSubscriptionButtonProps = {
  onClick: () => void;
  subscriptionInfo?: PaymentsSubscriptionInfo;
  isAppleOrAmpPayment?: boolean;
  isSubscriptionInfoLoading?: boolean;
};

export const CancelSubscriptionButton = ({
  onClick,
  subscriptionInfo,
  isAppleOrAmpPayment,
  isSubscriptionInfoLoading,
}: CancelSubscriptionButtonProps) => {
  const { appUser } = useSession();
  const [isLoadingForRedirect, setIsLoadingForRedirect] = useState(false);
  const [trigger, { isLoading }] = useLazyGetBillingPortalUrlQuery();

  const handleCancelSubscriptionClick = async () => {
    if (!subscriptionInfo) {
      return;
    }

    if (subscriptionInfo?.source === 'paypal') {
      onClick();
      return;
    }

    setIsLoadingForRedirect(true);

    const { data } = await trigger({
      user_id: appUser.user_id,
      return_url: `${AppRoutes.MANAGE_ACCOUNT}/${BillingSettingsNestedRoutes.SUBSCRIPTION}`,
    });
    const redirectUrl = data?.url;

    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      setIsLoadingForRedirect(false);
    }
  };

  const isCancelDisabled =
    isLoading ||
    isSubscriptionInfoLoading ||
    subscriptionInfo?.is_cancelled ||
    subscriptionInfo?.processing_plan_change ||
    isAppleOrAmpPayment ||
    !!appUser?.stripe_info?.renew_early_pending_subscription;

  return (
    <Button
      color="transparent"
      disabled={isCancelDisabled || isLoadingForRedirect}
      tooltipContent={isAppleOrAmpPayment ? APPLE_PAYMENT_ERROR_TEXT : ''}
      onClick={handleCancelSubscriptionClick}
    >
      <MinusCircle size={SVG_SIZE_M} />
      <span>Cancel subscription</span>
    </Button>
  );
};
