import { useContext, MouseEvent, useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { useNavigate } from 'react-router-dom';
import AudioContext from 'src/contexts/AudioContext';
import {
  useSession,
  useConversationParams,
  useBreakpoint,
  useLeftPanelActions,
  useScrollToTask,
  useQueryParams,
} from 'src/hooks';
import { ApiMinimumTask, AppRoutes, Conversation } from 'src/types';
import { TaskItem } from '../TaskItem';
import { DEFAULT_CHAT_NAME } from 'src/constants';
import { convertConversationIdToSlug, interruptMetahuman } from 'src/utils';
import { ConversationTitle } from '../ConversationTitle';

type ConversationThreadProps = {
  conversation: Conversation;
  selected?: boolean;
};

export const ConversationItem = ({
  conversation,
  selected = false,
}: ConversationThreadProps) => {
  const navigate = useNavigate();
  const { appUser } = useSession();
  const { isMobile } = useBreakpoint();
  const { currentConversationId } = useConversationParams();
  const { toggleLeftPanelExpanded } = useLeftPanelActions();
  const { activeThreadAnchor, setActiveThreadAnchor } = useScrollToTask();

  const { searchParams } = useQueryParams();
  const { document } = searchParams;

  const { metaHumanTalking } = useContext(AudioContext);

  const { conversation_id, tasks } = conversation;

  const [isExpanded, setExpanded] = useState<boolean>(true);

  const { getCollapseProps, getToggleProps } = useCollapse({
    defaultExpanded: true,
    isExpanded,
  });

  const handleConversationTitleClick = async (e: MouseEvent) => {
    setActiveThreadAnchor(null);

    if (threadId === currentConversationId) {
      setExpanded((prev) => !prev);
      return;
    }

    if (isMobile) {
      toggleLeftPanelExpanded(false);
    }

    if (metaHumanTalking) {
      await interruptMetahuman(appUser.user_id);
    }
  };

  const handleTaskClick = async (taskId: string) => {
    setActiveThreadAnchor(taskId);

    if (isMobile) {
      toggleLeftPanelExpanded(false);
    }

    if (conversation_id !== currentConversationId) {
      if (conversation_id) {
        navigate(
          // TODO(olha): use hook from the nex PR
          `${AppRoutes.HOME}?conv=${convertConversationIdToSlug(threadId)}${document ? `&document=${document}` : ''}`,
        );
      }
    }
  };

  const threadId = conversation_id || DEFAULT_CHAT_NAME;
  const conversationTitleToggleProps = getToggleProps({
    onClick: handleConversationTitleClick,
  });

  return (
    <>
      <ConversationTitle
        isSelected={selected}
        isExpanded={isExpanded}
        hasTasks={!!tasks?.length}
        conversation={conversation}
        toggleProps={conversationTitleToggleProps}
        handleConversationTitleClick={handleConversationTitleClick}
        activeThreadAnchor={activeThreadAnchor}
      />

      <div {...getCollapseProps()}>
        {tasks?.map((task: ApiMinimumTask, index: number) => (
          <TaskItem
            key={`task-${task.task_id}`}
            task={task}
            selected={activeThreadAnchor === task.task_id && selected}
            selectedConversation={selected}
            onClick={handleTaskClick}
          />
        ))}
      </div>
    </>
  );
};
