import type { LexicalEditor } from 'lexical';
import { useCallback, useContext, useEffect, useState } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { getDOMRangeRect, setFloatingElemPosition } from '../../utils';
import { FloatingPrompt } from '../FloatingPrompt';
import styles from './FloatingTooltip.module.scss';

// TODO(olha): rename
export const FloatingTooltip = ({
  editor,
  anchorElem,
}: {
  anchorElem: HTMLElement;
  editor: LexicalEditor;
}) => {
  const { editorFloatingTooltipRef } = useContext(ForwardRefContext);

  const [coordinates, setCoordinates] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const updatePosition = useCallback(() => {
    const tooltipElem = editorFloatingTooltipRef?.current;

    if (!tooltipElem) {
      return;
    }

    const nativeSelection = window.getSelection();
    if (!nativeSelection || nativeSelection.rangeCount === 0) {
      setCoordinates(null);
      return;
    }
    const rootElement = editor.getRootElement();
    if (!rootElement || !rootElement.contains(nativeSelection.anchorNode)) {
      setCoordinates(null);

      return;
    }

    const rangeRect = getDOMRangeRect(nativeSelection, rootElement);
    const position = setFloatingElemPosition({
      targetRect: rangeRect,
      floatingElem: tooltipElem,
      anchorElem: anchorElem,
    });

    setCoordinates(position);
  }, [editor, anchorElem, editorFloatingTooltipRef]);

  useEffect(() => {
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition, true);
    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition, true);
    };
  }, [updatePosition]);

  useEffect(() => {
    const unregister = editor.registerUpdateListener(() => {
      updatePosition();
    });
    return unregister;
  }, [editor, updatePosition]);

  return (
    <div
      ref={editorFloatingTooltipRef}
      className={styles.root}
      style={{
        transform: `translate(${coordinates?.left || 0}px, ${coordinates?.top || 0}px)`,
        opacity: coordinates ? 1 : 0,
      }}
    >
      <FloatingPrompt />
    </div>
  );
};
