import { X } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import {
  useCurrentEditorThread,
  useEditorActions,
  useEditorState,
  useQueryParams,
  useRightSidePanelActions,
  useRightSidePanelState,
} from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';
import { DownloadFileTool } from '../DownloadFileTool';
import styles from './EditorHeader.module.scss';
import { FullWidthToggle } from '../FullWidthToggle';
import { EditorType } from 'src/types';
import { CopyCodeButton } from 'src/v2/components/CopyCodeButton';
import { EditorMenuControl } from '../EditorMenuControl';
import { VersioningControl } from '../VersioningControl';

export const EditorHeader = () => {
  const { removeSearchParam } = useQueryParams();

  const { currentEditorThreadTitle } = useCurrentEditorThread();
  const { editorType, codeEditorData } = useEditorState();
  const { isRightPanelFullWidth } = useRightSidePanelState();
  const { toggleRightSidePanelExpanded, toggleRightSidePanelFullWidth } =
    useRightSidePanelActions();
  const { setShouldResetEditorContent } = useEditorActions();

  const handleClose = () => {
    removeSearchParam('document');
    toggleRightSidePanelFullWidth(false);
    toggleRightSidePanelExpanded();
    setShouldResetEditorContent(true);
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <FullWidthToggle />

        <p className={styles.title}>{currentEditorThreadTitle}</p>

        <EditorMenuControl />
      </div>

      <div className={styles.wrapper}>
        <VersioningControl />

        <DownloadFileTool />

        {editorType === EditorType.CODE && codeEditorData?.content && (
          <CopyCodeButton content={codeEditorData.content} />
        )}

        {!isRightPanelFullWidth && (
          <Button
            shape="round"
            color="transparent"
            className={styles.closeButton}
            onClick={handleClose}
          >
            <X size={SVG_SIZE_M} />
          </Button>
        )}
      </div>
    </div>
  );
};
