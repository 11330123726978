/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SeatRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  MEMBER = 'member',
  UNASSIGNED = 'unassigned',
}
