import { LeftSidePanel } from 'src/v2/components/LeftSidePanel';
import styles from './NotFound.module.scss';
import { Icon } from 'src/components/Icon';
import { MainNavToggleButton } from '../ManageTasksChatPage/components/ConversationsHeader/components/MainNavToggleButton';
import { useBanner, useBreakpoint } from 'src/hooks';
import { AddConversationButton } from '../ManageTasksChatPage/components/MultiConversationsListPanel/components/AddConversationButton';
import classNames from 'classnames';

export const NotFound = () => {
  const { bannersSet } = useBanner();
  const { isMobileOrTablet } = useBreakpoint();

  return (
    <div
      className={classNames(styles.root, {
        [styles.reducedHight]: bannersSet.length !== 0,
      })}
    >
      <div className={styles.container}>
        <LeftSidePanel />

        <div className={styles.middleContainer}>
          <div className={styles.header}>
            {isMobileOrTablet && <MainNavToggleButton />}

            <AddConversationButton
              appearance="gradient-button"
              className="nj-thread-header--mobile-create-new-chat"
            />
          </div>

          <div className={styles.content}>
            <Icon type="ninja404Logo" size={268} />
            <h2>Oops, Page not found</h2>
            <span>The page you’re trying to access doesn’t exist</span>
          </div>
        </div>
      </div>
    </div>
  );
};
