/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ModelGroup {
  NINJA_TECH = 'NinjaTech',
  AMAZON_BEDROCK = 'Amazon Bedrock',
  DEEP_SEEK = 'DeepSeek',
  OPEN_AI = 'OpenAI',
  GOOGLE = 'Google',
}
