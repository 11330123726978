import { isObject, isString, isBoolean } from './general.guard';
import { PDUMessage, MessageChannel } from '../api';
import { MessageChunk, MessageFooter } from '../api.extended';
import log from 'src/utils/logger';

/**
 * isPDUMessage() type guards for combus objects.
 * @param data unknown
 * @returns boolean
 */
export function isPDUMessage(data: unknown): data is PDUMessage {
  const response = data as PDUMessage;

  if (!isObject(response)) {
    log.debug(`isPDUMessage > not an object ${JSON.stringify(response)}`);
    return false;
  }

  if (!('user_id' in response && isString(response.user_id))) {
    log.debug(
      `isPDUMessage > not a string user_id ${JSON.stringify(response)}`,
    );
    return false;
  }

  if (!('event_type' in response && isString(response.event_type))) {
    log.debug(
      `isPDUMessage > not a string event_type ${JSON.stringify(response)}`,
    );
    return false;
  }

  if (!('channel' in response && Array.isArray(response.channel))) {
    log.debug(
      `isPDUMessage > not an array channel ${JSON.stringify(response)}`,
    );
    return false;
  }

  for (const channel of response.channel) {
    if (!Object.values(MessageChannel).includes(channel)) {
      log.debug(Object.values(MessageChannel));
      log.debug(
        `isPDUMessage > invalid channel ${JSON.stringify(
          channel,
        )} in ${JSON.stringify(response)}`,
      );
      return false;
    }
  }

  if (!('payload' in response && isString(response.payload))) {
    log.debug(
      `isPDUMessage > not a string payload ${JSON.stringify(response)}`,
    );
    return false;
  }

  return true;
}

/**
 * isMessageChunk() checks the format of the chunk and the type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageChunk(data: unknown): data is MessageChunk {
  const chunk = data as MessageChunk;

  if (!('message_id' in chunk && isString(chunk.message_id))) {
    log.debug(`isMessageChunk > invalid message_id ${JSON.stringify(chunk)}`);
    return false;
  }

  if ('conversation_id' in chunk && !isString(chunk.conversation_id)) {
    log.debug(
      `isMessageChunk > invalid conversation_id ${JSON.stringify(chunk)}`,
    );
    return false;
  }

  if ('task_id' in chunk && !isString(chunk.task_id)) {
    log.debug(`isMessageChunk > invalid task_id ${JSON.stringify(chunk)}`);
    return false;
  }

  if (!('content' in chunk && isString(chunk.content))) {
    log.debug(`isMessageChunk > invalid content ${JSON.stringify(chunk)}`);
    return false;
  }

  return true;
}

/**
 * isMessageFooter() checks the format of the message footer type.
 * @param data unknown
 * @return boolean
 */
export function isMessageFooter(data: unknown): data is MessageFooter {
  const footer = data as MessageFooter;

  if ('message_type' in footer && !isString(footer.message_type)) {
    log.debug(
      `isMessageFooter > invalid message_type ${JSON.stringify(footer)}`,
    );
    return false;
  }

  if ('task_id' in footer && !isString(footer.task_id)) {
    log.debug(`isMessageFooter > invalid task_id ${JSON.stringify(footer)}`);
    return false;
  }

  if ('is_final_answer' in footer && !isBoolean(footer.is_final_answer)) {
    log.debug(
      `isMessageFooter > invalid is_final_answer ${JSON.stringify(footer)}`,
    );
    return false;
  }

  if ('conversation_id' in footer && !isString(footer.conversation_id)) {
    log.debug(
      `isMessageFooter > invalid conversation_id ${JSON.stringify(footer)}`,
    );
    return false;
  }

  return true;
}
