import { EditorTypesIconsSet, SVG_SIZE_M } from 'src/constants';
import {
  useEditorActions,
  useQueryParams,
  useRightSidePanelActions,
} from 'src/hooks';
import { EditorType, RightPanelSections } from 'src/types';
import { Button } from 'src/v2/commonComponents/Button';
import { useClearEditor } from 'src/v2/components/Editor/hooks/useClearEditor';
import { useThreadContext } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/contexts/ThreadProvider';
import log from 'src/utils/logger';

interface NewEditorButtonProps {
  buttonType: EditorType;
  onClick?: () => void;
}

export const NewEditorButton = ({
  buttonType,
  onClick,
}: NewEditorButtonProps) => {
  const { searchOrCreateThread, setThreadId } = useThreadContext();
  const { setSearchParams } = useQueryParams();
  const { setRightSidePanelData } = useRightSidePanelActions();
  const { clearEditor } = useClearEditor();
  const { setShouldResetEditorContent } = useEditorActions();

  const handleClick = async () => {
    try {
      const thread = await searchOrCreateThread(true);

      clearEditor();
      setRightSidePanelData({
        panelType: RightPanelSections.EDITOR,
        isExpanded: true,
        editorType: buttonType,
      });

      if (buttonType === EditorType.CODE) {
        setShouldResetEditorContent(true);
      }

      setThreadId(thread?.thread_id as string);
      setSearchParams({ document: thread?.thread_id }, { replace: true });
      onClick?.();
    } catch (error) {
      log.error(error);
    }
  };

  const CurrentIcon = EditorTypesIconsSet[buttonType];

  return (
    <Button color="transparent" onClick={handleClick}>
      <CurrentIcon size={SVG_SIZE_M} />
      {buttonType}
    </Button>
  );
};
