import { IconName } from 'src/components/Icon';
import type {
  ApiTask,
  ApiUser,
  ProgressUpdate,
  ResearchData,
  TestPayload,
  CalendarCard,
  EmailCard,
  ChoicesCard,
  MessageType,
  ConversationState,
  Message,
  TaskSkill,
  TaskState,
} from './api';
import { ConversationRole } from './api';
import { ChatsTasksFilter, Metadata } from 'src/types';

export type SingleSelectOption = {
  value: string;
  label: string;
  selected?: boolean;
};

// extended
export type ApiTaskSelectable = ApiTask & {
  selected?: boolean;
};

export type AppUser = ApiUser & {
  avatar?: string;
  role?: ConversationRole;
  metadata?: Metadata | null;
};

export enum Tag {
  SUMMARY = 'SUMMARY',
  CONVERSATION = 'CONVERSATION',
  NEW_CONVERSATION = 'NEW_CONVERSATION',
  TASK_CREATED = 'TASK_CREATED',
  TASK_DISPLAY = 'TASK_DISPLAY',
  WAITING = 'WAITING',
  ERROR = 'ERROR',
  PROGRESS_UPDATE = 'PROGRESS_UPDATE',
  RESEARCH = 'RESEARCH',
  RESEARCH_WEB = 'RESEARCH_WEB',
  RESEARCH_LLM = 'RESEARCH_LLM',
  RESEARCH_CODE = 'RESEARCH_CODE',
  RESEARCH_CARD = 'RESEARCH_CARD',
  RESEARCH_STATUS_UPDATE = 'RESEARCH_STATUS_UPDATE',
  CALENDAR_CARD = 'CALENDAR_CARD',
  EMAIL_CARD = 'EMAIL_CARD',
  CHOICES_CARD = 'CHOICES_CARD',
}

// simplified gmail types
export type ProfileMetadata = {
  objectType: string;
  userTypes: string[];
};

export type Source = {
  type: string;
  id: string;
  etag: string;
  updateTime: string;
  profileMetadata: ProfileMetadata;
};

export type FieldMetadata = {
  primary: boolean;
  sourcePrimary: boolean;
  verified: boolean;
  source: Source;
};

export type EmailAddress = {
  metadata: FieldMetadata;
  value: string;
  type: string;
  formattedType: string;
  displayName: string;
};

export type Names = {
  metadata: FieldMetadata;
  displayName: string;
  displayNameLastFirst: string;
  unstructuredName: string;
  familyName: string;
  givenName: string;
  middleName: string;
  honorificPrefix: string;
  honorificSuffix: string;
  phoneticFullName: string;
  phoneticFamilyName: string;
  phoneticGivenName: string;
  phoneticMiddleName: string;
  phoneticHonorificPrefix: string;
  phoneticHonorificSuffix: string;
};

export interface Person {
  resourceName: string;
  emailAddresses: EmailAddress[];
  names: Names[];
}

export interface SearchResult {
  person: Person;
}

export interface Photo {
  url: string;
  metadata: {
    primary: boolean;
    source: {
      id: string;
      type: string;
    };
  };
}

export interface MyContactResult {
  etag: string;
  photos: Array<Photo>;
  resourceName: string;
}

export interface SearchResults {
  results: Array<SearchResult>;
}

export interface ApiContact {
  email: string;
  first_name: string;
  last_name?: string;
  color?: string;
  avatar?: string;
}

export interface MessageChunk {
  message_id?: string;
  conversation_id?: string;
  task_id?: string;
  content: string;
  message_type?: MessageType;
}

export interface MessageFooter {
  user_id: string;
  message_id?: string;
  conversation_id?: string;
  task_id?: string;
  is_final_answer?: boolean;
  message_type?: MessageType;
}

export type PayloadType =
  | ProgressUpdate
  | ResearchData
  | TestPayload
  | CalendarCard
  | EmailCard
  | ChoicesCard;

export interface ConversationsByPageRequest {
  user_id: string;
  limit?: number;
  page_token?: string;
  include_archived?: boolean;
  filter_task_skills?: TaskSkill[];
  filter_task_states?: TaskState[];
}

/**
 * @deprecated
 */
export interface FilteredTasksByPageRequest {
  user_id: string;
  limit?: number;
  page_token?: string;
  filter_state?: ChatsTasksFilter;
  filter_states?: ChatsTasksFilter[];
  filter_skills?: TaskSkill[];
}

export interface UserConversationId {
  userId: string;
  conversationId: string;
}

export interface TaskRequestArgs {
  user_id: string;
  task_id: string;
}

export interface UserApiTask {
  userId: string;
  task: ApiTask;
}

export enum QueueMessageType {
  AVAILABLE = 'AVAILABLE',
  WAITING_ROOM = 'WAITING_ROOM',
  UNAVAILABLE = 'UNAVAILABLE',
  QUOTA_EXCEEDED = 'QUOTA_EXCEEDED',
  INVALID_REQUEST = 'INVALID_REQUEST',
}

export interface MatchMakerQueryResponse {
  signallingServer?: string;
  userID?: string;
  available?: boolean;
  invalidRequest?: boolean;
  positionInQueue?: number;
  expectedWaitingTime?: number;
  message?: string;
  messageID?: string;
  messageType?: QueueMessageType;
}

export type AvatarQueue = MatchMakerQueryResponse & {
  joinedAvatarQueue?: boolean;
  step?: number;
  ready?: boolean;
};

export interface ConversationRequestArgs {
  user_id: string;
  conversation_id: string;
}

export interface ConversationUpdateRequest extends ConversationRequestArgs {
  conversation_hash?: string;
  state?: ConversationState;
  model_ids?: string[];
  isDelete?: boolean;
}

export interface ImageGenRenderData {
  isDisabled: boolean | undefined;
  id: string;
  url: string;
  icon?: IconName | null;
  title: string;
  height: number;
  width: number;
  source: string;
  status: string;
  withWatermark?: boolean;
}

export interface MessageForRender extends Message {
  showDate?: boolean;
  anchorId?: string;
}

export enum DeleteAccountReason {
  PRIVACY = 'privacy',
  UNUSED = 'unused',
  UNWANTED_COMMUNICATION = 'unwanted_communication',
  SECURITY = 'security',
  FINANCIAL = 'financial',
  DISSATISFACTION = 'dissatisfaction',
  OTHER = 'other',
}

export interface DeleteAccountFormData {
  'delete-account-checkbox': boolean;
  selectedReason: DeleteAccountReason;
  reasonText: string;
}
