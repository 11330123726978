import { Button } from 'src/v2/commonComponents/Button';
import { DotsThreeOutlineVertical } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import type { ToggleProps } from 'src/v2/commonComponents/DrawerTooltipContainer';
import { useSubscriptionInfo } from 'src/hooks';

export const MoreOptionsToggle = ({
  id,
  disabled,
  isActive,
  onClick,
}: ToggleProps) => {
  const { isSubscriptionInfoLoading, isSubscriptionInfoFetching } =
    useSubscriptionInfo();
  return (
    <div id={id}>
      <Button
        color={isActive ? 'tertiary-outline' : 'secondary'}
        onClick={onClick}
        disabled={
          disabled || isSubscriptionInfoLoading || isSubscriptionInfoFetching
        }
      >
        <DotsThreeOutlineVertical size={SVG_SIZE_M} />
        <span>More options</span>
      </Button>
    </div>
  );
};
