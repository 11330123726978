import { researchedMediaSettingOptions } from 'src/constants';
import { MediaSettingsItem } from './components/MediaSettingsItem';
import styles from './ResearcherSection.module.scss';

export const ResearcherSection = () => {
  return (
    <div className={styles.root}>
      <p className={styles.caption}>Display other related resources</p>

      <div className={styles.options}>
        {researchedMediaSettingOptions.map(
          ({ value, label, icon, settingKey }) => (
            <MediaSettingsItem
              key={value}
              value={value}
              label={label}
              icon={icon}
              settingKey={settingKey}
            />
          ),
        )}
      </div>
    </div>
  );
};
