// Define types
import { IconName } from 'src/components/Icon';
import type { UserTier } from 'src/types/models/UserTier';
import { Icon } from '@phosphor-icons/react';

export enum SettingKeys {
  RESEARCH = 'researcher:',
  RESEARCH_PRIMARY_MODEL = 'researcher:primary_model',
  RESEARCH_VIDEOS_ENABLED = 'researcher:videos_enabled',
  RESEARCH_IMAGES_ENABLED = 'researcher:images_enabled',
  WRITER_CODER = 'writer:',
  WRITER_CODER_PRIMARY_MODEL = 'writer:primary_model',
  IMAGE = 'image:',
  IMAGE_PRIMARY_MODEL = 'image:primary_model',
  IMAGE_SIZES = 'image:size',
  IMAGE_STYLES = 'image:style',
  IMAGE_DIMENTIONS = 'image:image_type',
}

enum TierGroup {
  OPEN_TIERS = 'open_tiers',
  FREE_TIERS = 'free_tiers',
  STANDARD_TIERS = 'standard_tiers',
  STANDARD_OR_HIGHER = 'standard_or_higher_tiers',
  PRO_TIERS = 'pro_tiers',
  PRO_OR_HIGHER = 'pro_or_higher_tiers',
  ULTRA_TIERS = 'ultra_tiers',
  PAID_TIERS = 'paid_tiers',
  UNPAID_TIERS = 'unpaid_tiers',
}

export type SettingPath = string;
export type SettingValue = string | boolean | string[];

export interface BaseUserSetting {
  user_editable: boolean;
  description: string;
}

export interface UserSettingOption {
  selectable: boolean;
  unselectable_reason: string | null;
  group: string | null;
  requires?: UserTier;
}

export interface SingleSelectSetting extends BaseUserSetting {
  setting_type: 'Select';
  value: string;
  options: Record<string, UserSettingOption>;
}

export interface MultiSelectSetting extends BaseUserSetting {
  setting_type: 'MultiSelect';
  value: string[];
  options: Record<string, UserSettingOption>;
}

export interface ToggleSetting extends BaseUserSetting {
  setting_type: 'Toggle';
  value: boolean;
  options: Record<string, UserSettingOption>;
}

export type UserSetting =
  | SingleSelectSetting
  | MultiSelectSetting
  | ToggleSetting;

export interface GetUserSettingsResponse {
  settings: Record<SettingPath, UserSetting>;
}

export interface GetSettingsValuesResponse {
  settings_values: Record<SettingPath, string>;
}

export interface UpdateSettingValueRequestBody {
  new_value: string | string[] | boolean;
}

export interface UpdateSettingValueRequest {
  user_id: string;
  setting_key: SettingKeys | string;
  body: UpdateSettingValueRequestBody;
}

export interface BaseSettingsDefinition {
  user_editable: boolean;
  description: string;
}

export interface OptionDefinition {
  requires_tier_group: TierGroup | null;
}

export interface SingleSelectDefinition extends BaseSettingsDefinition {
  setting_definition_type: 'SingleSelect';
  options: Record<string, OptionDefinition>;
  default: string;
}

export interface ToggleDefinition extends BaseSettingsDefinition {
  setting_definition_type: 'Toggle';
  options: Record<string, OptionDefinition>;
  default: boolean;
}

export type SettingsDefinition = SingleSelectDefinition;

export type SettingLLMModels = {
  id: string;
  iconName?: IconName;
  title: string;
  availableIn?: UserTier;
}[];

export type ImageDimentionsOption = {
  icon: Icon;
  name: string;
  aspect_ratio: string;
};

export enum ImageGenNewSettingsDimensionOptionTypes {
  SQUARE = 'square',
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  X = 'x',
  TIKTOK = 'tiktok',
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TV = 'tv',
  FILM = 'film',
}
