import classNames from 'classnames';
import styles from './PlanCardWrapper.module.scss';
import { UserTier } from 'src/types';

interface PlanCardWrapperProps {
  onClick: (plan: UserTier) => void;
  isSelected: boolean;
  children: React.ReactNode;
  id: UserTier;
}

export const PlanCardWrapper = ({
  id,
  onClick,
  isSelected,
  children,
}: PlanCardWrapperProps) => {
  const isBasic = id === UserTier.FREE;

  const handleSelectPlan = () => {
    if (!isBasic) {
      onClick(id);
    }
  };

  return (
    <div
      onClick={handleSelectPlan}
      className={classNames(styles.root, {
        [styles.disabled]: isBasic,
        [styles.selected]: isSelected,
      })}
    >
      {children}
    </div>
  );
};
