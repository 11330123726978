import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { FloatingTooltip } from '../components/FloatingTooltip';

export const DocumentFloatingElementPlugin = ({
  anchorElem = document.body,
}: {
  anchorElem?: HTMLElement;
}) => {
  const { floatingElementPluginRef } = useContext(ForwardRefContext);
  const [editor] = useLexicalComposerContext();
  const [showTooltip, setShowTooltip] = useState(true);

  const handleOpen = useCallback(() => {
    setShowTooltip(true);
  }, [setShowTooltip]);

  const handleClose = useCallback(() => {
    setShowTooltip(false);
  }, [setShowTooltip]);

  useImperativeHandle(floatingElementPluginRef, () => ({
    onElementOpen: handleOpen,
    onElementClose: handleClose,
    get isOpen() {
      return showTooltip;
    },
  }));

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_, newEditorState) => {
        newEditorState.read(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection) && !selection.isCollapsed()) {
            handleOpen();
          } else {
            handleClose();
          }
        });
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, handleOpen, handleClose]);

  if (!showTooltip) {
    return null;
  }
  return createPortal(
    <FloatingTooltip editor={editor} anchorElem={anchorElem} />,
    anchorElem,
  );
};
