import {
  NonTeamTierConfigType,
  TierConfigItem,
  TeamTierConfigType,
  UserTier,
} from 'src/types';
import { LabelFree } from 'src/components/LabelFree';
import LabelStandard from 'src/components/LabelStandard/LabelStandard';
import { LabelPro } from 'src/components/LabelPro';
import { LabelUltra } from 'src/components/LabelUltra';
import { LabelEnterpriseTrial } from 'src/components/LabelEnterpriseTrial';
import { LabelEnterprise } from 'src/components/LabelEnterprise';
import { LabelBusiness } from 'src/components/LabelBusiness';
import { ReactElement } from 'react';

export const openTierConfig: TierConfigItem = {
  labelShort: null,
  labelLong: null,
  upsellFooterTitle: 'Sign Up For Free',
  upsellFooterDescription:
    'Get access to your own AI assistant for research, writing, coding, image generation, scheduling and so much more. No credit card required.',
  upsellFooterSecondaryButtonTitle: null,
  upsellFooterPrimaryButtonTitle: null,
};

export const freeTierConfig: TierConfigItem = {
  labelShort: <LabelFree />,
  labelLong: <LabelFree />,
  upsellFooterTitle: 'Get more with Ninja',
  upsellFooterDescription: 'Flexible plans for personal and business success',
  upsellFooterSecondaryButtonTitle: 'Business Plan',
  upsellFooterPrimaryButtonTitle: 'Individual Plans',
};

export const standardTierConfig: TierConfigItem = {
  labelShort: <LabelStandard labelType="short" />,
  labelLong: <LabelStandard labelType="full" />,
  upsellFooterTitle: 'Get more with Ninja',
  upsellFooterDescription: 'Flexible plans for personal and business success',
  upsellFooterSecondaryButtonTitle: 'Business Plan',
  upsellFooterPrimaryButtonTitle: 'Individual Plans',
};

export const standardTeamOwnerTierConfig: TierConfigItem = {
  labelShort: <LabelBusiness labelType="short" />,
  labelLong: <LabelBusiness labelType="full" />,
  upsellFooterTitle: 'Get more with Ninja',
  upsellFooterDescription: 'Expand your team & unlock advanced features',
  upsellFooterSecondaryButtonTitle: 'Invite Users',
  upsellFooterPrimaryButtonTitle: 'Upgrade',
};

export const standardTeamAdminTierConfig: TierConfigItem = {
  labelShort: <LabelBusiness labelType="short" />,
  labelLong: <LabelBusiness labelType="full" />,
  upsellFooterTitle: 'Get more with Ninja',
  upsellFooterDescription: 'Expand your team & unlock advanced features',
  upsellFooterSecondaryButtonTitle: 'Invite Users',
  upsellFooterPrimaryButtonTitle: 'Request Upgrade',
};

export const standardTeamMemberTierConfig: TierConfigItem = {
  labelShort: <LabelBusiness labelType="short" />,
  labelLong: <LabelBusiness labelType="full" />,
  upsellFooterTitle: 'Get more with Ninja',
  upsellFooterDescription: 'Ask your workspace owner to unlock more features.',
  upsellFooterSecondaryButtonTitle: null,
  upsellFooterPrimaryButtonTitle: 'Request Upgrade',
};

export const proTierConfig: TierConfigItem = {
  labelShort: <LabelPro labelType="short" />,
  labelLong: <LabelPro labelType="full" />,
  upsellFooterTitle: 'Get more with Ninja',
  upsellFooterDescription: 'Flexible plans for personal and business success',
  upsellFooterSecondaryButtonTitle: 'Business Plan',
  upsellFooterPrimaryButtonTitle: 'Get Ultra',
};

export const proTeamOwnerTierConfig: TierConfigItem = {
  labelShort: <LabelBusiness labelType="short" />,
  labelLong: <LabelBusiness labelType="full" />,
  upsellFooterTitle: 'Get more with Ninja',
  upsellFooterDescription: 'Expand your team & unlock advanced features',
  upsellFooterSecondaryButtonTitle: 'Invite Users',
  upsellFooterPrimaryButtonTitle: 'Upgrade',
};

export const proTeamAdminTierConfig: TierConfigItem = {
  labelShort: <LabelBusiness labelType="short" />,
  labelLong: <LabelBusiness labelType="full" />,
  upsellFooterTitle: 'Get more with Ninja',
  upsellFooterDescription: 'Expand your team & unlock advanced features',
  upsellFooterSecondaryButtonTitle: 'Invite Users',
  upsellFooterPrimaryButtonTitle: 'Request Upgrade',
};

export const proTeamMemberTierConfig: TierConfigItem = {
  labelShort: <LabelBusiness labelType="short" />,
  labelLong: <LabelBusiness labelType="full" />,
  upsellFooterTitle: 'Get more with Ninja',
  upsellFooterDescription: 'Ask your workspace owner to unlock more features.',
  upsellFooterSecondaryButtonTitle: null,
  upsellFooterPrimaryButtonTitle: 'Request Upgrade',
};

export const ultraTierConfig: TierConfigItem = {
  labelShort: <LabelUltra labelType="short" />,
  labelLong: <LabelUltra labelType="full" />,
  upsellFooterTitle: 'Get more with Ninja',
  upsellFooterDescription: 'Flexible plans for personal and business success',
  upsellFooterSecondaryButtonTitle: 'Business Plan',
  upsellFooterPrimaryButtonTitle: null,
};

export const ultraTeamOwnerTierConfig: TierConfigItem = {
  labelShort: <LabelBusiness labelType="short" />,
  labelLong: <LabelBusiness labelType="full" />,
  upsellFooterTitle: 'Get more with Ninja',
  upsellFooterDescription: 'Need more capabilities for your business?',
  upsellFooterSecondaryButtonTitle: 'Invite Users',
  upsellFooterPrimaryButtonTitle: 'Get Enterprise',
};

export const ultraTeamAdminTierConfig: TierConfigItem = {
  labelShort: <LabelBusiness labelType="short" />,
  labelLong: <LabelBusiness labelType="full" />,
  upsellFooterTitle: 'Get more with Ninja',
  upsellFooterDescription: 'Invite coworkers to join your team.',
  upsellFooterSecondaryButtonTitle: 'Invite Users',
  upsellFooterPrimaryButtonTitle: null,
};

export const ultraTeamMemberTierConfig: TierConfigItem = {
  labelShort: <LabelBusiness labelType="short" />,
  labelLong: <LabelBusiness labelType="full" />,
  upsellFooterTitle: '',
  upsellFooterDescription: '',
  upsellFooterSecondaryButtonTitle: null,
  upsellFooterPrimaryButtonTitle: null,
};

export const enterpriseTrialTierConfig: TierConfigItem = {
  labelShort: <LabelEnterpriseTrial />,
  labelLong: <LabelEnterpriseTrial />,
  upsellFooterTitle: '',
  upsellFooterDescription: '',
  upsellFooterSecondaryButtonTitle: null,
  upsellFooterPrimaryButtonTitle: null,
};

export const enterpriseTierConfig: TierConfigItem = {
  labelShort: <LabelEnterprise />,
  labelLong: <LabelEnterprise />,
  upsellFooterTitle: '',
  upsellFooterDescription: '',
  upsellFooterSecondaryButtonTitle: null,
  upsellFooterPrimaryButtonTitle: null,
};

export const defaultTierConfig: TierConfigItem = {
  labelShort: null,
  labelLong: null,
  upsellFooterTitle: '',
  upsellFooterDescription: '',
  upsellFooterSecondaryButtonTitle: null,
  upsellFooterPrimaryButtonTitle: null,
};

export const nonTeamTierConfig: NonTeamTierConfigType = {
  [UserTier.OPEN]: openTierConfig,
  [UserTier.FREE]: freeTierConfig,
  [UserTier.STANDARD]: standardTierConfig,
  [UserTier.PRO]: proTierConfig,
  [UserTier.PRO_TRIAL]: defaultTierConfig,
  [UserTier.ULTRA]: ultraTierConfig,
  [UserTier.ENTERPRISE_TRIAL]: enterpriseTrialTierConfig,
  [UserTier.ENTERPRISE]: enterpriseTierConfig,
  [UserTier.TESTING]: defaultTierConfig,
  [UserTier.PAID]: defaultTierConfig,
  [UserTier.TEAM]: defaultTierConfig,
};

export const teamOwnerTierConfig: TeamTierConfigType = {
  [UserTier.TEAMS_STANDARD]: standardTeamOwnerTierConfig,
  [UserTier.TEAMS_PRO]: proTeamOwnerTierConfig,
  [UserTier.TEAMS_ULTRA]: ultraTeamOwnerTierConfig,
};

export const teamAdminTierConfig: TeamTierConfigType = {
  [UserTier.TEAMS_STANDARD]: standardTeamAdminTierConfig,
  [UserTier.TEAMS_PRO]: proTeamAdminTierConfig,
  [UserTier.TEAMS_ULTRA]: ultraTeamAdminTierConfig,
};

export const teamMemberTierConfig: TeamTierConfigType = {
  [UserTier.TEAMS_STANDARD]: standardTeamMemberTierConfig,
  [UserTier.TEAMS_PRO]: proTeamMemberTierConfig,
  [UserTier.TEAMS_ULTRA]: ultraTeamMemberTierConfig,
};

export const availableInLabel: Partial<Record<UserTier, ReactElement>> = {
  [UserTier.STANDARD]: <LabelStandard />,
  [UserTier.PRO]: <LabelPro />,
  [UserTier.ULTRA]: <LabelUltra />,
  [UserTier.TEAMS_STANDARD]: <LabelStandard />,
  [UserTier.TEAMS_PRO]: <LabelPro />,
  [UserTier.TEAMS_ULTRA]: <LabelUltra />,
};
