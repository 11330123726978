import { useCallback, useMemo } from 'react';
import { useCurrentEditorThread } from './useCurrentEditorThread';
import { EditorType } from 'src/types';
import { useClearEditor } from 'src/v2/components/Editor/hooks';
import { useClearCodeEditor } from './useClearCodeEditor';
import { useEditorState } from './useEditorState';
import { useInsertContentToDocumentEditor } from './useInsertContentToDocumentEditor';
import { useInsertContentToCodeEditor } from './useInsertContentToCodeEditor';

export const useEditorControl = () => {
  const { editorType } = useEditorState();
  const { clearEditor } = useClearEditor();
  const { clearCodeEditorContent } = useClearCodeEditor();
  const { insertContentToCodeEditor } = useInsertContentToCodeEditor();
  const { insertContentToDocumentEditor } = useInsertContentToDocumentEditor();
  const { getArtifactContentByIndex } = useCurrentEditorThread();

  const handleInsertArtifactContentToEditor = useCallback(
    (content: string) => {
      if (editorType === EditorType.DOCUMENT) {
        clearEditor();
        insertContentToDocumentEditor({ markdown: content });
        return;
      }

      if (editorType === EditorType.CODE) {
        clearCodeEditorContent();
        insertContentToCodeEditor({ codeContent: content });
        return;
      }
    },
    [
      clearCodeEditorContent,
      clearEditor,
      editorType,
      insertContentToCodeEditor,
      insertContentToDocumentEditor,
    ],
  );

  const handleInsertArtifactContentToEditorByIndex = useCallback(
    (index: number) => {
      const content = getArtifactContentByIndex({ index });

      handleInsertArtifactContentToEditor(content);
    },
    [getArtifactContentByIndex, handleInsertArtifactContentToEditor],
  );

  return useMemo(
    () => ({
      handleInsertArtifactContentToEditorByIndex,
      handleInsertArtifactContentToEditor,
    }),
    [
      handleInsertArtifactContentToEditorByIndex,
      handleInsertArtifactContentToEditor,
    ],
  );
};
