/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum NinjaSubscriptionPlan {
  PRO = 'pro',
  TEAMS_PRO = 'teams_pro',
  ULTRA = 'ultra',
  TEAMS_ULTRA = 'teams_ultra',
  STANDARD = 'standard',
  TEAMS_STANDARD = 'teams_standard',
}
