import { useContext } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { Sparkle } from '@phosphor-icons/react';
import { PromptLoadingReason, SVG_SIZE_M } from 'src/constants';
import { useSession } from 'src/hooks';
import log from 'loglevel';
import { getBaseHeaders } from 'src/store/services/config';
import { bffTasksBaseUrl } from 'src/store/constants';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import styles from './PromptButton.module.scss';
import { Button } from 'src/v2/commonComponents/Button';

type PromptButtonProps = {
  isDisabled?: boolean;
  onClose?: () => void;
  undoTriggerRef: React.MutableRefObject<boolean>;
  setImprovedPrompt: (value: string) => void;
};

const textDecoder = new TextDecoder();

export const PromptButton = ({
  isDisabled = false,
  onClose,
  undoTriggerRef,
  setImprovedPrompt,
}: PromptButtonProps) => {
  const { threadInputBoxRef } = useContext(ForwardRefContext);
  const { appUser } = useSession();
  const {
    threadInputBoxValue,
    setTemporaryInputValue,
    setPromptLoading,
    setPromptModificationReason,
    temporaryInputValue,
    setThreadInputBoxValue,
  } = useContext(ThreadInputBoxContext);

  const handleReturnInputToFocus = () => {
    if (threadInputBoxRef?.current) {
      threadInputBoxRef.current.focus();
    }
  };

  const updatePrompt = async (response: Response, stream: boolean) => {
    let value = '';
    if (stream) {
      if (undoTriggerRef.current) {
        undoTriggerRef.current = false;
        return;
      }
      if (response.body) {
        if (response.body) {
          const reader = response.body.getReader();

          while (true) {
            const { done, value: chunk } = await reader.read();

            if (done) {
              break;
            }

            value += textDecoder.decode(chunk, { stream: true });

            if (undoTriggerRef.current) {
              undoTriggerRef.current = false;
              return;
            }

            setThreadInputBoxValue(value);
          }
          setImprovedPrompt(value);
        }
      }
    } else {
      const result = await response.json();
      setThreadInputBoxValue(result.improved_prompt);
      setImprovedPrompt(value);
    }
  };

  const handleSendPrompt = async () => {
    try {
      onClose?.();
      setPromptLoading(true);
      setPromptModificationReason(PromptLoadingReason.IMPROVING);
      setTemporaryInputValue(threadInputBoxValue);
      setThreadInputBoxValue('');

      // TODO(Inna): it's a temporary workaround to handle response as a streaming (needs to investigate RTK ability)
      await fetch(
        `${bffTasksBaseUrl}/users/${appUser.user_id}/prompts/improve`,
        {
          method: 'POST',
          headers: await getBaseHeaders(
            new Headers({
              'Content-Type': 'application/json',
            }),
          ),
          body: JSON.stringify({ prompt: threadInputBoxValue, stream: true }),
        },
      )
        .then((response) => updatePrompt(response, true))
        .catch((err) => log.error(err));
      setPromptLoading(false);
    } catch (e) {
      if (undoTriggerRef.current) {
        undoTriggerRef.current = false;
      }
      setPromptLoading(false);
      setThreadInputBoxValue(temporaryInputValue);
      setTemporaryInputValue('');
      log.error(e);
    }
    handleReturnInputToFocus();
  };

  return (
    <Button
      fullWidth
      className={styles.root}
      color="transparent"
      onClick={handleSendPrompt}
      disabled={isDisabled || !threadInputBoxValue.trim()}
    >
      <Sparkle size={SVG_SIZE_M} />
      Improve Prompt
    </Button>
  );
};
