import { useEffect, useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import {
  useBreakpoint,
  useContacts,
  useConversationParams,
  useGoogleResourcesAccess,
  useVisible,
} from 'src/hooks';
import { KeyCodes } from 'src/types';
import { getInitials } from 'src/utils';
import { ContactsAccessDeniedModal } from 'src/components/ContactsAccessDeniedModal';
import { Icon } from 'src/components/Icon';
import { useQuickPickerControls } from 'src/hooks';
import AuthContext from 'src/contexts/AuthContext';
import { useCanConnectToGoogle } from 'src/hooks/useCanConnectToGoogle';
import { PortalWrapper } from 'src/components/PortalWrapper';

const SVG_SIZE = 32;

interface QuickContactPickerProps {
  isOpen: boolean;
  cursorPosition: number;
  onClose: () => void;
  setCursorPosition: (position: number) => void;
}

// TODO(olha): component needs deep refactoring
export const QuickContactPicker = ({
  isOpen,
  cursorPosition,
  onClose,
  setCursorPosition,
}: QuickContactPickerProps) => {
  const { threadInputBoxValue, setThreadInputBoxValue } = useContext(
    ThreadInputBoxContext,
  );

  const { threadInputBoxRef } = useContext(ForwardRefContext);

  const { isGuestAccess } = useContext(AuthContext);
  const { canAccessContacts } = useGoogleResourcesAccess();

  const { isLandingPage } = useConversationParams();
  const { isMobile, isTablet } = useBreakpoint();

  const [isFocus, setIsFocus] = useState<boolean>(true);

  const { contacts } = useContacts({
    cursorPosition,
    shouldSearch: isOpen,
  });

  const canConnectToGoogle = useCanConnectToGoogle();

  const {
    isVisible: isVisibleAccessDeniedModal,
    onVisibilitySet: onOpenAccessDeniedModal,
    onVisibilityRemove: onCloseAccessDeniedModal,
  } = useVisible();

  const handleContactClick = useCallback(
    (index: number) => {
      const contact = contacts[index];
      const insertValue = `${contact.first_name} ${contact.last_name} (${contact.email}) `;

      const lastAtPos = threadInputBoxValue.lastIndexOf(
        KeyCodes.AT,
        cursorPosition - 1,
      );

      const beforeCursor =
        lastAtPos >= 0
          ? threadInputBoxValue.substring(0, lastAtPos + 1)
          : threadInputBoxValue.substring(0, cursorPosition);

      const afterCursor = threadInputBoxValue.substring(cursorPosition);

      const newValue = `${beforeCursor}${insertValue}${afterCursor}`;

      setThreadInputBoxValue(newValue);
      setCursorPosition(beforeCursor.length + insertValue.length);
    },
    [
      cursorPosition,
      contacts,
      threadInputBoxValue,
      setThreadInputBoxValue,
      setCursorPosition,
    ],
  );

  const { selectedIndex } = useQuickPickerControls({
    options: contacts,
    isOpen,
    shouldFocusInputBox: true,
    onClose,
    onItemClick: handleContactClick,
  });

  useEffect(() => {
    if (canConnectToGoogle && !canAccessContacts && !isGuestAccess) {
      onOpenAccessDeniedModal();
    } else {
      onCloseAccessDeniedModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAccessContacts, isGuestAccess, canConnectToGoogle]);

  useEffect(() => {
    const handleFocus = () => {
      setIsFocus(true);
    };

    const handleBlur = () => {
      setIsFocus(false);
    };

    const inputElement = threadInputBoxRef?.current;

    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus);
      inputElement.addEventListener('blur', handleBlur);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus);
        inputElement.removeEventListener('blur', handleBlur);
      }
    };
  }, [threadInputBoxRef]);

  const handleClose = () => {
    onCloseAccessDeniedModal();
    onClose();
  };

  const tooltipPlace = useMemo(() => {
    if (isLandingPage) {
      if (isMobile) {
        return 'top-end';
      }

      if (isTablet) {
        return 'bottom-start';
      }

      return 'right';
    }

    return 'top-start';
  }, [isLandingPage, isMobile, isTablet]);

  const tooltipOffset = useMemo(() => {
    if (!isLandingPage) {
      return 44;
    }

    if (isMobile) {
      return -140;
    }

    if (isTablet) {
      return -40;
    }

    return -360;
  }, [isLandingPage, isMobile, isTablet]);

  if (!isOpen) {
    return null;
  }

  if (isVisibleAccessDeniedModal) {
    return (
      <PortalWrapper>
        <ReactTooltip
          anchorSelect="#input-box-anchor-tooltip"
          isOpen={true}
          clickable
          noArrow
          openOnClick
          opacity={1}
          place={tooltipPlace}
          offset={tooltipOffset}
          className={classNames(
            'nj-thread-input-box--quick-picker-tooltip nj-access-denied',
            {
              'reduced-height': isFocus,
            },
          )}
        >
          <ContactsAccessDeniedModal onClose={handleClose} />
        </ReactTooltip>
      </PortalWrapper>
    );
  }

  if (contacts.length === 0 || isGuestAccess) {
    return null;
  }

  return (
    <PortalWrapper>
      <ReactTooltip
        anchorSelect="#input-box-anchor-tooltip"
        isOpen={true}
        clickable
        noArrow
        openOnClick
        opacity={1}
        place={tooltipPlace}
        className={classNames(
          'nj-thread-input-box--quick-picker-tooltip contacts',
          {
            'reduced-height': isFocus,
          },
        )}
        offset={tooltipOffset}
      >
        <div className="nj-thread-input-box--quick-picker-container">
          {contacts.map((contact, index) => (
            <div
              key={index}
              className={classNames('nj-thread-input-box--quick-picker-item', {
                selected: index === selectedIndex,
              })}
              onMouseDown={(e) => {
                e.preventDefault();
                handleContactClick(index);
              }}
              data-testid="thread-input-box-quick-picker-item"
            >
              <Icon
                alt={getInitials(contact)}
                size={SVG_SIZE}
                // TODO(olha): There is a bug with changing color while searching. should fix later
                masterColor={contact.color}
              />

              <div>
                <p className="nj-thread-input-box--quick-picker-title">{`${contact.first_name} ${contact.last_name}`}</p>
                <p className="nj-thread-input-box--quick-picker-subtitle">
                  {contact.email}
                </p>
              </div>
            </div>
          ))}
        </div>
      </ReactTooltip>
    </PortalWrapper>
  );
};
