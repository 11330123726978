import { useRef } from 'react';
import { Paperclip } from '@phosphor-icons/react';
import { Button } from 'src/v2/commonComponents/Button';
import { ReactTooltip } from 'src/v2/commonComponents/ReactTooltip';
import { useBreakpoint, useOutsideClick, useVisible } from 'src/hooks';
import { SVG_SIZE_M } from 'src/constants';
import { AddAttachmentButton } from 'src/v2/components/ThreadInputBox/components/AddAttachmentButton';
import { CapturePhoto } from 'src/components/CapturePhoto';
import styles from './MoreActionsButton.module.scss';
import { useRightSidePanelState } from 'src/hooks';

const MORE_ACTIONS_ANCHOR_ID = 'more-actions-input-trigger';

const MoreActionsButton = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { isVisible, onVisibilityRemove, onVisibilityToggle } = useVisible();
  const { isImageEditSectionExpanded } = useRightSidePanelState();
  const { isTabletAndUp } = useBreakpoint();

  const handleOutsideClick = () => {
    onVisibilityRemove();
  };

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <div id={MORE_ACTIONS_ANCHOR_ID} ref={containerRef} className={styles.root}>
      <Button
        shape="round"
        color="gray"
        onClick={onVisibilityToggle}
        disabled={isImageEditSectionExpanded}
        tooltipContent={isTabletAndUp ? 'Attach files' : undefined}
      >
        <Paperclip size={SVG_SIZE_M} />
      </Button>

      <ReactTooltip
        place={isTabletAndUp ? 'top-start' : 'bottom-start'}
        id={MORE_ACTIONS_ANCHOR_ID}
        isOpen={isVisible}
        offset={isTabletAndUp ? -38 : 0}
        className={styles.tooltip}
      >
        <AddAttachmentButton withTitle onClose={onVisibilityRemove} />

        <CapturePhoto withTitle onClose={onVisibilityRemove} />
      </ReactTooltip>
    </div>
  );
};

export default MoreActionsButton;
