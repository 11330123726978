import './UpgradeForm.scss';
import {
  GTMEvent,
  PaymentPlanInterval,
  PlanSectionType,
  SubscriptionPlan,
} from 'src/types';
import {
  PRO_FEATURES,
  PRO_MODELS,
  SVG_SIZE_M,
  ULTRA_FEATURES,
  ULTRA_MODELS,
  STANDARD_MODELS,
  STANDARD_FEATURES,
  TEAMS_FEATURES,
  ULTRA_TEAMS_PREMIUM_MODELS,
} from 'src/constants';
import { Check, CheckCircle, Image } from '@phosphor-icons/react';
import { EXTERNAL_MODELS_LINK } from 'src/constants/externalLinks';
import { useCallback, useMemo } from 'react';
import { sendGTMEvent } from 'src/utils';
import { useSession } from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';
import classNames from 'classnames';

type UpgradeFormProps = {
  onOpenCheckout: (type: PlanSectionType, period: PaymentPlanInterval) => void;
  handleBuyWithAws: (
    type: PlanSectionType,
    period: PaymentPlanInterval,
  ) => void;
  period: PaymentPlanInterval;
  type: PlanSectionType;
  isSelected: boolean;
  selectedPlan?: SubscriptionPlan;
  isTeams?: boolean;
};

export const UpgradeForm = ({
  onOpenCheckout,
  handleBuyWithAws,
  period,
  type,
  isSelected,
  selectedPlan,
  isTeams,
}: UpgradeFormProps) => {
  const {
    isProTier,
    isUltraTier,
    isStandardTier,
    isSubscribedTier,
    isTeamsStandardTier,
    isTeamsProTier,
    isTeamsUltraTier,
    isTeamsTier,
  } = useSession();

  const isMonthlyPeriod = period === PaymentPlanInterval.MONTHLY;

  const isProType = type === 'Pro' || type === 'Teams_Pro';
  const isStandardType = type === 'Standard' || type === 'Teams_Standard';
  const isUltraType = type === 'Ultra' || type === 'Teams_Ultra';

  const roundAmount = useCallback((value?: number) => {
    if (value !== 0 && !value) {
      return value;
    }
    return Math.ceil(value / 100);
  }, []);

  const originalPriceLabel = useMemo(() => {
    const price = isTeams
      ? selectedPlan?.per_seat?.original_price
      : selectedPlan?.original_price;
    if (!price) {
      return '-';
    }
    return roundAmount(isMonthlyPeriod ? price : price / 12);
  }, [
    selectedPlan?.original_price,
    selectedPlan?.per_seat,
    isMonthlyPeriod,
    roundAmount,
    isTeams,
  ]);

  const discountedPriceLabel = useMemo(() => {
    const price = isTeams
      ? selectedPlan?.per_seat?.total_recurring_amount
      : selectedPlan?.discounted_price;
    if (!price) {
      return '-';
    }
    return roundAmount(isMonthlyPeriod ? price : price / 12);
  }, [
    selectedPlan?.discounted_price,
    selectedPlan?.per_seat,
    isMonthlyPeriod,
    roundAmount,
    isTeams,
  ]);

  const isDowngrade = useMemo(
    () =>
      ((isUltraTier && type === 'Pro') || type === 'Standard') &&
      isSubscribedTier,
    [type, isUltraTier, isSubscribedTier],
  );

  const isCurrentPlan = useMemo(() => {
    return (
      (isStandardTier && type === 'Standard') ||
      (isProTier && type === 'Pro') ||
      (isUltraTier && type === 'Ultra') ||
      (isTeamsStandardTier && type === 'Teams_Standard') ||
      (isTeamsProTier && type === 'Teams_Pro') ||
      (isTeamsUltraTier && type === 'Teams_Ultra')
    );
  }, [
    isStandardTier,
    isProTier,
    isUltraTier,
    type,
    isTeamsStandardTier,
    isTeamsProTier,
    isTeamsUltraTier,
  ]);

  const buttonLabel = useMemo(() => {
    if (isCurrentPlan) {
      return 'Current plan';
    }

    if (type === 'Standard' && isSubscribedTier) {
      return 'Downgrade to Standard';
    }

    if (type === 'Teams_Standard' && isTeamsTier) {
      return 'Downgrade to Business Standard';
    }

    if (type === 'Ultra' && isSubscribedTier) {
      return 'Upgrade to Ultra';
    }

    if (type === 'Teams_Ultra' && isTeamsTier) {
      return 'Upgrade to Business Ultra';
    }

    if (type === 'Pro' && isUltraTier) {
      return 'Downgrade to Pro';
    }

    if (type === 'Teams_Pro' && isTeamsUltraTier) {
      return 'Downgrade to Business Pro';
    }

    if (type === 'Pro' && isStandardTier) {
      return 'Upgrade to Pro';
    }

    if (type === 'Teams_Pro' && isTeamsStandardTier) {
      return 'Upgrade to Business Pro';
    }

    return isTeams ? 'Buy Business' : `Get ${type}`;
  }, [
    isStandardTier,
    isUltraTier,
    isSubscribedTier,
    type,
    isCurrentPlan,
    isTeams,
    isTeamsStandardTier,
    isTeamsUltraTier,
    isTeamsTier,
  ]);

  const features = useMemo(() => {
    if (isStandardType) {
      return STANDARD_FEATURES;
    }
    if (isProType) {
      return PRO_FEATURES;
    }
    return ULTRA_FEATURES;
  }, [isStandardType, isProType]);

  const models = useMemo(() => {
    if (isStandardType) {
      return STANDARD_MODELS;
    }
    if (isProType) {
      return PRO_MODELS;
    }
    return ULTRA_MODELS;
  }, [isStandardType, isProType]);

  const premiumModels = ULTRA_TEAMS_PREMIUM_MODELS;

  const handleViewAllModelsClick = () => {
    sendGTMEvent(GTMEvent.PAYMENT_VIEW_ALL_MODELS_CLICK);
  };

  const onBuyWithAwsClick = () => {
    handleBuyWithAws(type, period);
  };
  const onCheckoutClick = () => {
    onOpenCheckout(type, period);
  };

  const showBuyWithAws = isTeams && !isTeamsTier;

  return (
    <div className="nj-upgrade-form">
      <div
        className={classNames({
          'nj-upgrade-form--team-header': isTeams,
          'nj-upgrade-form--individual-header': !isTeams,
        })}
      >
        <div className="nj-plan-section--current-plan">
          <div className="nj-plan-section--header-wrapper">
            <h5 className="nj-plan-section--title-wrapper">
              <span className="nj-plan-section--title">
                {isTeams ? 'Business ' : ''}
                {!isTeams && type}
              </span>

              {isMonthlyPeriod &&
                !!selectedPlan?.one_time_discount_percentage && (
                  <p className="nj-plan-section--current-plan-sales-description">
                    {`${selectedPlan?.one_time_discount_percentage}% off first month`}
                  </p>
                )}

              {period === PaymentPlanInterval.ANNUALLY && (
                <p className="nj-plan-section--current-plan-sales-description">
                  Billed annually
                </p>
              )}
            </h5>

            <p className="nj-plan-section--current-plan-price">
              {selectedPlan?.discounted_price !== 0 && (
                <span className="nj-plan-section--current-plan-original-price">
                  <s>${originalPriceLabel}</s>
                  &nbsp;
                </span>
              )}
              <span className="nj-plan-section--current-plan-title">
                $
                {selectedPlan?.discounted_price !== 0
                  ? discountedPriceLabel
                  : originalPriceLabel}
                &nbsp;
              </span>
              <span className="nj-plan-section--current-plan-subtitle">
                {isTeams ? 'per seat/month' : '/mo'}
              </span>
            </p>

            <span className="nj-plan-section--current-plan-description colored">
              Unlimited tasks
            </span>
          </div>
        </div>

        <div className="nj-upgrade-form--button-wrapper">
          {isSelected ? (
            <button
              type="button"
              className="nj-upgrade-form--selected"
              disabled
            >
              <CheckCircle size={SVG_SIZE_M} weight="fill" />
              <span>Selected</span>
            </button>
          ) : (
            <button
              type="button"
              className={`nj-button nj-upgrade-form--submit ${isDowngrade ? 'nj-downgrade-button' : ''}`}
              onClick={onCheckoutClick}
              id="Plan-card-upgrade"
              disabled={isCurrentPlan}
              data-e2e={`upgrade-to-${type}-button`}
            >
              {buttonLabel}
            </button>
          )}
          {showBuyWithAws && (
            <Button
              type="button"
              fullWidth
              className={'nj-upgrade-form--buy-with-aws'}
              onClick={onBuyWithAwsClick}
              id="Plan-card-buy-with-aws"
              disabled={isCurrentPlan}
              data-e2e={`buy-with-aws-${type}-button`}
            >
              Buy with AWS
            </Button>
          )}

          <p className="nj-upgrade-form--caption">
            Cancel anytime, effective next billing cycle
          </p>
        </div>
      </div>

      <div className="nj-plan-section--current-plan-features-list">
        <h5 className="nj-plan-section--current-plan-features-list-title">
          {isStandardType && 'Includes'}
          {isProType &&
            `Everything in ${isTeams ? 'Business ' : ''}Standard, plus`}
          {isUltraType && !isTeams && `Everything in Pro, plus`}
        </h5>
        {features.map((item) => (
          <div
            className="nj-plan-section--current-plan-feature-row"
            key={item.id}
          >
            <Check size={SVG_SIZE_M} />
            <span>{item.title}</span>
          </div>
        ))}
        {isTeams &&
          TEAMS_FEATURES.map((item) => (
            <div
              className="nj-plan-section--current-plan-feature-row"
              key={item.id}
            >
              <Check size={SVG_SIZE_M} />
              <span>{item.title}</span>
            </div>
          ))}

        <hr className="divider" />

        <div className="nj-plan-section--current-plan-models-list">
          {!isTeams && (
            <h5 className="nj-plan-section--current-plan-models-list-title">
              <Check size={SVG_SIZE_M} />
              <span>
                <span className="colored">Unlimited </span>
                <span>
                  {isStandardType && 'access to 3 AI models *'}
                  {isProType && 'access to 6 AI models *'}
                  {isUltraType && 'access to 20+ premium AI models *'}
                </span>
              </span>
            </h5>
          )}
          <div
            className={classNames(
              'nj-plan-section--current-plan-models-list-content',
              {
                'nj-plan-section--current-plan-models-list-content--teams':
                  isTeams,
                'nj-plan-section--current-plan-models-list-content--individual':
                  !isTeams,
              },
            )}
          >
            <div className="nj-plan-section--current-plan--external-models">
              {isTeams && (
                <p className="nj-feature-bold-item">External models</p>
              )}
              {models.map((item) => (
                <div
                  className="nj-plan-section--current-plan-models-list-item"
                  key={item.id}
                >
                  {item.icon}
                  <div>{item.text}</div>
                  {item.isShowImageIcon && (
                    <div className="nj-plan-section--current-plan-models-list-item-image">
                      <Image size={SVG_SIZE_M} weight="fill" />
                    </div>
                  )}
                </div>
              ))}
            </div>

            {isTeams && (
              <div
                className={classNames({
                  'nj-plan-section--current-plan--premium-models': isTeams,
                })}
              >
                <div className="nj-plan-section--current-plan--premium-models--header">
                  <p className="nj-feature-bold-item">
                    Pay-per-use Premium models <br />
                    <span className="nj-upgrade-form--caption">
                      (coming soon)
                    </span>
                  </p>
                </div>
                {premiumModels.map((item) => (
                  <div
                    className="nj-plan-section--current-plan-models-list-item"
                    key={item.id}
                  >
                    {item.icon}
                    <div>{item.text}</div>
                    {item.isShowImageIcon && (
                      <div className="nj-plan-section--current-plan-models-list-item-image">
                        <Image size={SVG_SIZE_M} weight="fill" />
                      </div>
                    )}
                  </div>
                ))}

                <div className="nj-plan-section--current-plan--premium-models--header">
                  <p className="nj-api-access nj-feature-bold-item">
                    Pay-per-use Ninja API access <br />
                    <span className="nj-upgrade-form--caption">
                      (coming soon)
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isUltraType && (
        <a
          href={EXTERNAL_MODELS_LINK}
          target="_blank"
          rel="noreferrer"
          className={classNames(
            'nj-plan-section--current-plan-models-list-view-all-link',
            {
              'nj-plan-section--current-plan-models-list-view-all-link--teams':
                isTeams,
              'nj-plan-section--current-plan-models-list-view-all-link--individual':
                !isTeams,
            },
          )}
          onClick={handleViewAllModelsClick}
        >
          View all models
        </a>
      )}
    </div>
  );
};
