import { DrawerTooltipContainer } from 'src/v2/commonComponents/DrawerTooltipContainer';
import { DownloadFileTrigger } from '../DownloadFileTrigger';
import styles from './DownloadFileTool.module.scss';
import { DownloadPDFButton } from '../DownloadPDFButton';

const TOOLTIP_ID = 'download-file-tool-anchor';

export const DownloadFileTool = () => {
  return (
    <DrawerTooltipContainer
      onlyTooltip
      id={TOOLTIP_ID}
      ToggleButton={DownloadFileTrigger}
    >
      <div className={styles.root}>
        <DownloadPDFButton />
      </div>
    </DrawerTooltipContainer>
  );
};
