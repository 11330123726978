import { CheckCircle } from '@phosphor-icons/react';
import {
  reasoningSuperAgentModelSet,
  superAgentModelSet,
  SVG_SIZE_M,
} from 'src/constants';
import { LeftPanelSections, SuperAgentModels } from 'src/types';
import { TextDivider } from 'src/v2/commonComponents/TextDivider';
import { SuperAgentSelectItem } from '../SuperAgentSelectItem';
import styles from './SuperAgentSelect.module.scss';
import { useActiveModel } from 'src/hooks/userSettingsHooks/useActiveModel';
import { SettingLLMModels } from 'src/types/settings';
import { Button } from 'src/v2/commonComponents/Button';
import { useLeftPanelActions } from 'src/hooks';

type SuperAgentSelectProps = {
  onClose: () => void;
  models?: SettingLLMModels;
};

export const SuperAgentSelect = ({
  onClose,
  models,
}: SuperAgentSelectProps) => {
  const { activeModel, changeCurrentModel } = useActiveModel();
  const { setLeftPanelData } = useLeftPanelActions();

  const handleChangeValue = (value?: SuperAgentModels) => {
    changeCurrentModel(value);
    onClose();
  };

  const handleGoToSettingsClick = () => {
    setLeftPanelData({
      panelType: LeftPanelSections.SETTINGS,
      isExpanded: true,
    });
    onClose();
  };

  return (
    <div className={styles.root}>
      <div className={styles.rowWrapper} onClick={() => handleChangeValue()}>
        <div className={styles.labelWrapper}>
          <span className={styles.title}>Ninja Mode</span>
          <span className={styles.description}>
            Orchestrates using your preferred AI models. See{' '}
            <Button
              color="link"
              onClick={handleGoToSettingsClick}
              className={styles.link}
            >
              Skill Settings
            </Button>
          </span>
        </div>
        <div>
          {!activeModel && <CheckCircle size={SVG_SIZE_M} weight="fill" />}
        </div>
      </div>

      <TextDivider />

      <div className={styles.headerRow}>
        <span className={styles.headerTitle}>Reasoning Compound AI Models</span>
      </div>

      {Object.keys(reasoningSuperAgentModelSet).map((item) => {
        const foundModel = (models || []).find((model) => model.id === item);
        return (
          <SuperAgentSelectItem
            key={item}
            item={item}
            activeSuperAgentModel={activeModel}
            onClick={() => handleChangeValue(item as SuperAgentModels)}
            availableIn={foundModel?.availableIn}
            isNew={reasoningSuperAgentModelSet[item].isNew}
          />
        );
      })}

      <TextDivider />

      <div className={styles.headerRow}>
        <span className={styles.headerTitle}>
          Conventional Compound AI Models
        </span>
      </div>

      {Object.keys(superAgentModelSet).map((item) => {
        const foundModel = (models || []).find((model) => model.id === item);
        return (
          <SuperAgentSelectItem
            key={item}
            item={item}
            activeSuperAgentModel={activeModel}
            onClick={() => handleChangeValue(item as SuperAgentModels)}
            availableIn={foundModel?.availableIn}
          />
        );
      })}
    </div>
  );
};
