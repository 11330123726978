import { useContext, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { X } from '@phosphor-icons/react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { Modal } from 'react-responsive-modal';
import { RenameForm } from './components/RenameForm';
import {
  useTaskById,
  useCurrentConversation,
  useConversationActions,
} from 'src/hooks';
import { RenameType, RenameTypeProps } from 'src/types';

const SVG_SIZE = 20;

type RenameModalProps = {
  isOpen: boolean;
  onClose: (val: boolean) => void;
};

export const RenameModal = ({ isOpen, onClose }: RenameModalProps) => {
  const { rootContainerRef } = useContext(ForwardRefContext);
  const { currentTaskData } = useTaskById();
  const { currentConversation } = useCurrentConversation();
  const { updateConversationById } = useConversationActions();

  const renameProps = useMemo(() => {
    if (currentTaskData) {
      return {
        itemId: currentTaskData.task_id,
        itemName: currentTaskData.task_hash?.replace('#', ''),
        itemType: RenameType.TASK,
      } as RenameTypeProps;
    } else if (currentConversation) {
      return {
        itemId: currentConversation.conversation_id,
        itemName: currentConversation.conversation_hash?.replace('#', ''),
        itemType: RenameType.CONVERSATION,
      } as RenameTypeProps;
    } else {
      return {
        itemId: 'default',
        itemName: 'new-chat',
        itemType: RenameType.CONVERSATION,
      } as RenameTypeProps;
    }
  }, [currentTaskData, currentConversation]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose(false);
    }
  }, [onClose]);

  const handleRenameSubmit = async ({ itemId, itemName }: RenameTypeProps) => {
    if (currentConversation) {
      await updateConversationById({
        conversation_id: itemId,
        conversation_hash: `#${itemName}`,
      });

      toast.success('Chat renamed');
    }

    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      container={rootContainerRef?.current || document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: 'nj-modal--rename-form',
        closeIcon: 'nj-video-avatar-modal-closeButton',
      }}
      closeIcon={<X size={SVG_SIZE} />}
      center
    >
      <RenameForm {...renameProps} onRenameSubmit={handleRenameSubmit} />
    </Modal>
  );
};
