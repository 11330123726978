import { Client } from '@langchain/langgraph-sdk';
import { X_NINJA_EVENT_ID_HEADER } from 'src/constants/api';
import { v4 as uuid_v4 } from 'uuid';

export const createClient = async (accessToken?: string) => {
  const apiUrl =
    process.env.REACT_APP_LANGGRAPH_API_URL ?? 'http://localhost:54367';

  if (!accessToken) {
    // There is a weird issue with this import inside ServiceWorker
    // dynamically import this only if we are in the main thread
    const utils = await import('src/utils');
    accessToken = await utils.get_access_token();
  }

  return new Client({
    apiUrl,
    defaultHeaders: {
      Authorization: `Bearer ${accessToken}`,
      [X_NINJA_EVENT_ID_HEADER]: uuid_v4(),
    },
  });
};
