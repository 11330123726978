import { DimensionPrimaryItem } from 'src/components/ImageGenSettings/components/DimensionPrimaryItem';
import { DimensionMoreSelector } from 'src/components/ImageGenSettings/components/DimensionMoreSelector';
import styles from './DimensionToggle.module.scss';
import { useUserSettings } from 'src/hooks';
import { SettingKeys, SingleSelectSetting } from 'src/types/settings';
import { ImageSettingsPrimaryGroup } from 'src/constants';
import { useMemo } from 'react';

interface DimensionToggleProps {
  selectedValue?: string;
  onChange?: (value: string) => void;
}

export const DimensionToggle = ({
  onChange,
  selectedValue,
}: DimensionToggleProps) => {
  const { setting, updateUserSetting } = useUserSettings<SingleSelectSetting>(
    SettingKeys.IMAGE_DIMENTIONS,
  );

  const value = !!selectedValue ? selectedValue : setting?.value || '';

  const isMoreButtonDisabled = useMemo(() => {
    return Object.values(setting?.options || {})
      .filter((item) => item.group !== 'standard')
      .every((item) => !item.selectable);
  }, [setting?.options]);

  const handlePrimaryDimensionSelect = (value: string) => {
    if (value !== selectedValue) {
      if (!!onChange) {
        onChange(value);
      } else {
        updateUserSetting(value);
      }
    }
  };

  return (
    <div className={styles.root}>
      {Object.entries(ImageSettingsPrimaryGroup).map(([key, data]) => (
        <DimensionPrimaryItem
          key={key}
          isSelected={key === value}
          data={{
            ...data,
            name: key,
            isDisabled: Boolean(
              setting?.options &&
                !!setting?.options[key] &&
                !setting?.options[key].selectable,
            ),
          }}
          onClick={() => handlePrimaryDimensionSelect(key)}
        />
      ))}

      <DimensionMoreSelector
        onSelect={handlePrimaryDimensionSelect}
        selectedValue={value}
        isMoreDisabled={isMoreButtonDisabled}
      />
    </div>
  );
};
