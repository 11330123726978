import type { Thread } from '@langchain/langgraph-sdk';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { FileIconsSet, SVG_SIZE_M } from 'src/constants';
import { CodeValuesType } from 'src/types';

import {
  useBreakpoint,
  useConversationLink,
  useLeftPanelActions,
  useQueryParams,
} from 'src/hooks';
import { type DocumentValuesType, FileListType } from 'src/types';
import { Button } from 'src/v2/commonComponents/Button';
import { useThreadContext } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/contexts/ThreadProvider';
import styles from './FileListItem.module.scss';
import {
  getDocumentTitleFromArtifactValues,
  getDocumentTypeFromArtifactValues,
} from 'src/utils';
import { EditorMenuControl } from 'src/v2/components/Editor/components/EditorMenuControl';
import { useAddDefaultContentToEditor } from 'src/v2/components/Editor/hooks';

type FileListItemProps = {
  data: Thread<DocumentValuesType | CodeValuesType>;
};

export const FileListItem = ({ data }: FileListItemProps) => {
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();
  const { searchParams } = useQueryParams();
  const { toggleLeftPanelExpanded } = useLeftPanelActions();
  const { linkTo } = useConversationLink({ document: data.thread_id });
  const { setThreadId } = useThreadContext();
  const { onAddDefaultContentToEditor } = useAddDefaultContentToEditor();

  const { document } = searchParams;
  const { thread_id, values } = data;

  const documentTitle = getDocumentTitleFromArtifactValues(data);
  const documentType = getDocumentTypeFromArtifactValues(values);

  const handleDocumentClick = async () => {
    if (isMobile) {
      toggleLeftPanelExpanded(false);
    }

    onAddDefaultContentToEditor(data);

    if (thread_id !== document) {
      if (thread_id) {
        setThreadId(thread_id);
        navigate(linkTo);
      }
    }
  };

  const CurrentIcon = FileIconsSet[documentType];
  const isSelected = thread_id === document;

  return (
    <div
      className={classNames(styles.container, {
        [styles.selected]: thread_id === document,
      })}
    >
      <Button
        color="transparent"
        className={styles.root}
        onClick={handleDocumentClick}
      >
        <CurrentIcon
          size={SVG_SIZE_M}
          className={classNames({
            [styles.text]: documentType === FileListType.DOCUMENT,
            [styles.code]: documentType === FileListType.CODE,
          })}
        />
        <span className={styles.title}>{documentTitle}</span>
      </Button>

      {isSelected && <EditorMenuControl withThreeDotsIcon />}
    </div>
  );
};
