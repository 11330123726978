import { Button } from 'src/v2/commonComponents/Button';
import { FilePlus } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import type { ToggleProps } from 'src/v2/commonComponents/DrawerTooltipContainer';
import styles from './AddDocumentToggle.module.scss';

export const AddDocumentToggle = ({
  id,
  disabled,
  isActive,
  onClick,
}: ToggleProps) => {
  return (
    <div id={id}>
      <Button
        color={isActive ? 'tertiary' : 'transparent'}
        onClick={onClick}
        disabled={disabled}
        className={styles.root}
      >
        <FilePlus size={SVG_SIZE_M} />
      </Button>
    </div>
  );
};
