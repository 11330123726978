import styles from './ResolutionToggle.module.scss';
import { ResolutionSlider } from 'src/components/ImageGenSettings/components/ResolutionSlider';

type ResolutionToggleProps = {
  isVisible?: boolean;
  selectedValue?: string;
  onChange?: (value: string) => void;
};

export const ResolutionToggle = ({
  isVisible,
  selectedValue,
  onChange,
}: ResolutionToggleProps) => {
  return (
    <div className={styles.root}>
      <label className={styles.label}>
        <span>Resolution</span>
      </label>

      <ResolutionSlider
        onChange={onChange}
        isVisible={isVisible}
        selectedValue={selectedValue}
      />
    </div>
  );
};
