import { isString, isBoolean, isObject } from './general.guard';
import { TaskState, TaskSkill, TaskChannel, ApiTask } from '../api';
import { ApiTaskSelectable } from '../api.extended';
import { isConversation } from './api.conversation.guard';
import log from 'src/utils/logger';

/**
 * isApiTaskSelectable() guards tasks against ApiTaskSelectable data type.
 * @param data unknown
 * @returns boolean
 */
export function isApiTaskSelectable(data: unknown): data is ApiTaskSelectable {
  const task = data as ApiTaskSelectable;

  if (!isApiTask(task)) {
    log.debug(`isApiTaskSelectable > not an api task ${JSON.stringify(task)}`);
    return false;
  }

  if ('selected' in task && !isBoolean(task.selected)) {
    log.debug(`isApiTaskSelectable > invalid selected ${JSON.stringify(task)}`);
    return false;
  }

  return true;
}

/**
 * isPartialApiTask() tests data to be a partial task with optional data.
 * @param data unknown
 * @returns boolean
 */
export function isPartialApiTask(data: unknown): data is Partial<ApiTask> {
  const task = data as ApiTask;

  if (!isObject(task)) {
    log.debug(`isPartialApiTask > not an object ${JSON.stringify(task)}`);
    return false;
  }

  if ('task_id' in task && !isString(task.task_id)) {
    log.debug(`isPartialApiTask > invalid task_id ${JSON.stringify(task)}`);
    return false;
  }

  if ('user_id' in task && !isString(task.user_id)) {
    log.debug(`isPartialApiTask > invalid user_id ${JSON.stringify(task)}`);
    return false;
  }

  if ('agent_id' in task && !isString(task.agent_id)) {
    log.debug(`isPartialApiTask > invalid agent_id ${JSON.stringify(task)}`);
    return false;
  }

  if ('task_subject' in task && !isString(task.task_subject)) {
    log.debug(
      `isPartialApiTask > invalid task_subject ${JSON.stringify(task)}`,
    );
    return false;
  }

  if ('task_hash' in task && !isString(task.task_hash)) {
    log.debug(`isPartialApiTask > invalid task_hash ${JSON.stringify(task)}`);
    return false;
  }

  if (
    'channel' in task &&
    !(
      isString(task.channel) &&
      Object.values(TaskChannel).includes(task.channel)
    )
  ) {
    log.debug(`isPartialApiTask > invalid channel ${JSON.stringify(task)}`);
    return false;
  }

  if (
    'state' in task &&
    !(isString(task.state) && Object.values(TaskState).includes(task.state))
  ) {
    log.debug(`isPartialApiTask > invalid state ${JSON.stringify(task)}`);
    return false;
  }

  if (
    'skill' in task &&
    !(isString(task.skill) && Object.values(TaskSkill).includes(task.skill))
  ) {
    log.debug(`isPartialApiTask > invalid skill ${JSON.stringify(task)}`);
    return false;
  }

  if ('requires_attention' in task && !isBoolean(task.requires_attention)) {
    log.debug(
      `isPartialApiTask > invalid requires_attention ${JSON.stringify(task)}`,
    );
    return false;
  }

  if ('conversation_id' in task && !isString(task.conversation_id)) {
    log.debug(
      `isPartialApiTask > invalid conversation_id ${JSON.stringify(task)}`,
    );
    return false;
  }

  if (
    'conversation' in task &&
    !(task.conversation === undefined || isConversation(task.conversation))
  ) {
    log.debug(
      `isPartialApiTask > invalid conversation ${JSON.stringify(task)}`,
    );
    return false;
  }

  if ('created_at' in task && !isString(task.created_at)) {
    log.debug(`isPartialApiTask > invalid created_at ${JSON.stringify(task)}`);
    return false;
  }

  if ('updated_at' in task && !isString(task.updated_at)) {
    log.debug(`isPartialApiTask > invalid updated_at ${JSON.stringify(task)}`);
    return false;
  }

  return true;
}

/**
 * isApiTask() guards data to be of chat task type.
 * @param data unknown
 * @returns boolean
 */
export function isApiTask(data: unknown): data is ApiTask {
  const task = data as ApiTask;

  if (!isPartialApiTask(task)) {
    log.debug(`isApiTask > not a partial task ${JSON.stringify(task)}`);
    return false;
  }

  if (!('user_id' in task && 'skill' in task && 'agent_id' in task)) {
    log.debug(
      `isApiTask > mandatory fields (user_id, channel, skill, agent_id) missing in ${JSON.stringify(
        task,
      )}`,
    );
    return false;
  }

  return true;
}
