import { useNavigate } from 'react-router-dom';
import { AppRoutes, TeamsTabs } from 'src/types';
import cn from 'classnames';
import { BuildingOffice, Icon, User } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import styles from './PaymentPageTeamsTabs.module.scss';
import { useQueryParams, useSession } from 'src/hooks';

type PaymentPageTeamsTabsProps = {
  onChange: () => void;
};

const TAB_LIST: { tab: TeamsTabs; Icon: Icon }[] = [
  { tab: TeamsTabs.INDIVIDUAL, Icon: User },
  { tab: TeamsTabs.BUSINESS, Icon: BuildingOffice },
];

export const PaymentPageTeamsTabs = ({
  onChange,
}: PaymentPageTeamsTabsProps) => {
  const { isTeamsTier } = useSession();

  const navigate = useNavigate();
  const { searchParams } = useQueryParams();
  const activeTab =
    searchParams.payment_tab ||
    (isTeamsTier ? TeamsTabs.BUSINESS : TeamsTabs.INDIVIDUAL);

  return (
    <div className={styles.root}>
      {TAB_LIST.map(({ tab, Icon }) => {
        const handleTabClick = () => {
          onChange();
          navigate(`${AppRoutes.PAYMENT}?payment_tab=${tab}`);
        };

        return (
          <button
            type="button"
            key={tab}
            className={cn(styles.tab, {
              [styles.active]: activeTab === tab,
            })}
            onClick={handleTabClick}
            data-e2e={`account-type-${tab}-button`}
          >
            <Icon size={SVG_SIZE_M} />
            {tab}
          </button>
        );
      })}
    </div>
  );
};
