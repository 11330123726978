import { TaskState } from 'src/types';
import { useBreakpoint } from 'src/hooks';
import { StopTaskButton } from 'src/components/StopTaskButton';
import styles from './ThreadMessageActions.module.scss';

interface ThreadMessageActionsProps {
  taskState: TaskState;
  taskId?: string;
  taskHash?: string;
  showStopButton?: boolean;
}

export const ThreadMessageActions = ({
  taskState,
  taskId = '',
  taskHash,
  showStopButton,
}: ThreadMessageActionsProps) => {
  const { isMobile } = useBreakpoint();

  return (
    <div className={styles.root}>
      {showStopButton && (
        <StopTaskButton
          taskId={taskId}
          taskState={taskState}
          taskHash={taskHash}
          className="nj-thread-message--action-round-button"
          buttonName={!isMobile ? 'Stop' : ''}
        />
      )}
    </div>
  );
};
