import { useContext } from 'react';
import { Translate } from '@phosphor-icons/react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { SVG_SIZE_M } from 'src/constants';
import styles from './TranslatePromptButton.module.scss';
import { Button } from 'src/v2/commonComponents/Button';

type TranslatePromptButtonProps = {
  isDisabled?: boolean;
  setIsModalVisible: () => void;
  onClose: () => void;
};

export const TranslatePromptButton = ({
  onClose,
  isDisabled = false,
  setIsModalVisible,
}: TranslatePromptButtonProps) => {
  const { threadInputBoxValue } = useContext(ThreadInputBoxContext);

  const handleClick = () => {
    setIsModalVisible();
    onClose();
  };

  return (
    <Button
      fullWidth
      className={styles.root}
      color="transparent"
      onClick={handleClick}
      disabled={isDisabled || !threadInputBoxValue.trim()}
    >
      <Translate size={SVG_SIZE_M} />
      Translate
    </Button>
  );
};
