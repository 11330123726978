import { useContext } from 'react';
import { Notebook } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { useGetPromptTemplatesQuery } from 'src/store/services';
import { Button } from 'src/v2/commonComponents/Button';
import ForwardRefContext from 'src/contexts/ForwardRefContext';

type PromptLibraryProps = {
  onOpenModal: () => void;
};

export const PromptLibrary = ({ onOpenModal }: PromptLibraryProps) => {
  const { isLoading } = useGetPromptTemplatesQuery();
  const { promptLoading } = useContext(ThreadInputBoxContext);
  const { threadInputBoxRef } = useContext(ForwardRefContext);

  const handleReturnInputToFocus = () => {
    if (threadInputBoxRef?.current) {
      threadInputBoxRef.current.focus();
    }
  };

  const handleClick = () => {
    onOpenModal();
    handleReturnInputToFocus();
  };

  return (
    <>
      <Button
        fullWidth
        color="transparent"
        onClick={handleClick}
        disabled={isLoading || promptLoading}
      >
        <Notebook size={SVG_SIZE_M} />

        <span>Prompt Library</span>
      </Button>
    </>
  );
};
