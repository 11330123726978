/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MessageChannel {
  EMAIL = 'email',
  SMS = 'sms',
  WEB_APP = 'web_app',
  CE = 'ce',
}
