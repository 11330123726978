import { Button } from 'src/v2/commonComponents/Button';
import styles from './ExternalModelItem.module.scss';
import { CheckCircle } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { UserTier } from 'src/types';
import { LabelPro } from 'src/components/LabelPro';
import { LabelUltra } from 'src/components/LabelUltra';
import LabelStandard from 'src/components/LabelStandard/LabelStandard';
import { LabelNew } from 'src/components/LabelNew';

export interface ExternalModelItemProps {
  id: string;
  icon: JSX.Element;
  title: string;
  selected?: boolean;
  availableIn?: UserTier;
  onClick?: (id: string) => void;
  showRegenerate?: boolean;
  description?: string | null;
  isNew?: boolean;
}

export const ExternalModelItem = ({
  id,
  icon,
  title,
  selected,
  availableIn,
  onClick,
  showRegenerate,
  description,
  isNew,
}: ExternalModelItemProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <Button
      disabled={!!availableIn}
      onClick={handleClick}
      className={styles.root}
      color="transparent"
      data-e2e={id}
    >
      <span className={styles.titleWrapper}>
        <div className={styles.leftSideWrapper}>
          {icon}
          <div className={styles.textWrapper}>
            <span className={styles.title}>{title}</span>
            {description && (
              <span className={styles.description}>{description}</span>
            )}
          </div>
        </div>

        {isNew && !showRegenerate && !availableIn && <LabelNew />}
        {(availableIn === UserTier.PRO ||
          availableIn === UserTier.TEAMS_PRO) && <LabelPro />}
        {(availableIn === UserTier.ULTRA ||
          availableIn === UserTier.TEAMS_ULTRA) && <LabelUltra />}
        {(availableIn === UserTier.STANDARD ||
          availableIn === UserTier.TEAMS_STANDARD) && <LabelStandard />}
      </span>

      {selected && !showRegenerate && (
        <CheckCircle size={SVG_SIZE_M} weight="fill" />
      )}
    </Button>
  );
};
