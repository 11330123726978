import {
  WebSearchTypes,
  WebSearchTypesLabels,
  NinjaSubscriptionPlan,
  SuperAgentModels,
} from 'src/types';
import { Image, MonitorPlay } from '@phosphor-icons/react';
import { IconName } from 'src/components/Icon';
import { SettingKeys } from 'src/types/settings';
import { ExternalModelDisplayName } from 'src/types/models/ExternalModelDisplayName';
import { ModelGroup } from 'src/types/models/ModelGroup';
import { ModelIcon } from 'src/types/models/ModelIcon';

export const researchedMediaSettingOptions = [
  {
    value: WebSearchTypes.VIDEO_SEARCH,
    label: WebSearchTypesLabels.VIDEO_SEARCH,
    icon: MonitorPlay,
    settingKey: SettingKeys.RESEARCH_VIDEOS_ENABLED,
  },
  {
    value: WebSearchTypes.IMAGE_SEARCH,
    label: WebSearchTypesLabels.IMAGE_SEARCH,
    icon: Image,
    settingKey: SettingKeys.RESEARCH_IMAGES_ENABLED,
  },
];

export enum ExternalModelProvider {
  AMAZON_BEDROCK = 'amazon_bedrock',
  GOOGLE = 'google',
  OPENAI = 'openai',
  TOGETHER_AI = 'together_ai',
}

export const ExternalModelsGroupName = {
  [ExternalModelProvider.AMAZON_BEDROCK]: 'Amazon Bedrock',
  [ExternalModelProvider.TOGETHER_AI]: 'DeepSeek',
  [ExternalModelProvider.OPENAI]: 'OpenAI',
  [ExternalModelProvider.GOOGLE]: 'Google',
};

export enum ImageGeneratorsModelProvider {
  BEDROCK = 'bedrock',
  OPENAI = 'openai',
  NINJA_PIX = 'sagemaker',
  NINJA_PIX_PRO = 'anywhere',
}

// the list of image generator models which will have "NEW" label
export const newImageGeneratorModels: string[] = [
  'openai/openai/dall-e-3',
  'bedrock/amazon/amazon.titan-image-generator-v1',
  'bedrock/amazon/stability.stable-diffusion-xl-v1',
];

// TODO(olha): it's a hard workaround for rendering labels (temporary solution)
export const availableForProLLMs: string[] = [
  'openai/openai/gpt-4o-mini',
  'bedrock/anthropic/anthropic.claude-3-5-haiku-20241022-v1:0',
  'gemini/google/gemini-1.5-flash',
  'bedrock/amazon/us.amazon.nova-micro-v1:0',
  'bedrock/amazon/us.amazon.nova-lite-v1:0',
];

export const NINJA_AGENT_AS_EXTERNAL_MODEL = 'alias/ninjatech/ninja-agent';
export const SUPER_AGENT_AS_EXTERNAL_MODEL = 'alias/ninjatech/super-agent';
export const SUPER_AGENT_DEEP_RESEARCH_MODEL =
  'agent/ninjatech/super-agent:deep-research';
export const NINJA_AGENT_TEXT_AUTO_MODEL = 'agent/ninjatech/text-auto';
export const NINJA_AGENT_TITLE = 'Ninja Agent';

export const NINJA_AUTO_MODELS = [
  'agent/ninjatech/image-auto',
  'agent/ninjatech/text-auto',
];

export const DEFAULT_NINJA_MODEL = {
  is_capable: true,
  enabled: true,
  order_priority: 0,
  icon_name: 'ninja',
  display_name: 'Fine-tuned Llama 3.3 70B' as ExternalModelDisplayName,
  model_name: 'ninja', // TODO: change when BE will return model_name
};

// (olha): WIP we are moving to reading all data from BE using getDefaultModelIconName and getTextModelDisplayName
export const externalModelsSet: Record<
  string,
  {
    icon_name: IconName;
    display_name: string;
    provider?: ExternalModelProvider;
    hide_icon_in_settings?: boolean;
  }
> = {
  // deprecated
  'bedrock/amazon/amazon.titan-text-express-v1': {
    icon_name: 'awsLogo',
    display_name: 'Titan Text G1 - Express',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  // deprecated
  'bedrock/anthropic/anthropic.claude-3-haiku-20240307-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3 Haiku',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // deprecated
  'bedrock/anthropic/anthropic.claude-3-5-sonnet-20240620-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Sonnet',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // deprecated
  'bedrock/ai21/ai21.j2-mid-v1': {
    icon_name: 'jurassicLogo',
    display_name: 'Jurassic-2 Mid',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // deprecated
  'bedrock/ai21/ai21.j2-ultra-v1': {
    icon_name: 'jurassicLogo',
    display_name: 'Jurassic-2 Ultra',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // deprecated
  'bedrock/anthropic/anthropic.claude-3-sonnet-20240229-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Sonnet',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // deprecated
  'bedrock/amazon/amazon.titan-text-lite-v1': {
    icon_name: 'awsLogo',
    display_name: 'Titan Text G1 - Lite',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  // deprecated
  'bedrock/amazon/amazon.titan-text-premier-v1:0': {
    icon_name: 'awsLogo',
    display_name: 'Amazon Titan Text Premier',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  'bedrock/anthropic/anthropic.claude-3-5-haiku-20241022-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Haiku',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/anthropic/anthropic.claude-3-opus-20240229-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3 Opus',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/anthropic/anthropic.claude-3-5-sonnet-20241022-v2:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Sonnet',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/cohere/cohere.command-r-v1:0': {
    icon_name: 'cohereLogo',
    display_name: 'Command R',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/cohere/cohere.command-r-plus-v1:0': {
    icon_name: 'cohereLogo',
    display_name: 'Command R+',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/mistral/mistral.mistral-small-2402-v1:0': {
    icon_name: 'mistralLogo',
    display_name: 'Mistral Small',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/mistral/mistral.mistral-large-2402-v1:0': {
    icon_name: 'mistralLogo',
    display_name: 'Mistral Large',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // @deprecated
  'gemini/google/gemini-1.0-pro': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.0 Pro',
    provider: ExternalModelProvider.GOOGLE,
    hide_icon_in_settings: true,
  },
  'gemini/google/gemini-1.5-pro': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.5 Pro',
    provider: ExternalModelProvider.GOOGLE,
    hide_icon_in_settings: true,
  },
  'gemini/google/gemini-1.5-flash': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.5 Flash',
    provider: ExternalModelProvider.GOOGLE,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'openai/openai/gpt-4-0125-preview': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4 Turbo',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'openai/openai/gpt-4o-2024-05-13': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4o',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'openai/openai/gpt-3.5-turbo-1106': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-3.5 Turbo',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  'openai/openai/gpt-4o': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4o',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  'openai/openai/gpt-4o-mini': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4o Mini',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  [NINJA_AGENT_AS_EXTERNAL_MODEL]: {
    icon_name: 'ninjaPixProLogo',
    display_name: 'Fine-tuned Llama 3.3 70B',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
  [SUPER_AGENT_AS_EXTERNAL_MODEL]: {
    icon_name: 'ninjaPixProLogo',
    display_name: 'Ninja SuperAgent',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
  'alias/ninjatech/super-agent:turbo': {
    icon_name: 'ninjaPixProLogo',
    display_name: 'SuperAgent Turbo',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
  'alias/ninjatech/super-agent:nexus': {
    icon_name: 'ninjaPixProLogo',
    display_name: 'SuperAgent Nexus',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
  'alias/ninjatech/super-agent:apex': {
    icon_name: 'ninjaPixProLogo',
    display_name: 'SuperAgent Apex',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
  'alias/ninjatech/super-agent:reasoning': {
    icon_name: 'ninjaPixProLogo',
    display_name: 'SuperAgent-R 2.0',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
  'togetherai/deepseek/deepseek-v3': {
    icon_name: 'deepSeekLogo',
    display_name: 'DeepSeek V3',
    provider: ExternalModelProvider.TOGETHER_AI,
    hide_icon_in_settings: true,
  },
  'anywhere/deepseek/deepseek-v3': {
    icon_name: 'deepSeekLogo',
    display_name: 'DeepSeek V3',
    provider: ExternalModelProvider.TOGETHER_AI,
    hide_icon_in_settings: true,
  },
  'anywhere/deepseek/deepseek-r1': {
    icon_name: 'deepSeekLogo',
    display_name: 'DeepSeek R1',
    provider: ExternalModelProvider.TOGETHER_AI,
    hide_icon_in_settings: true,
  },
};

export const temporaryNewModelsArray = [
  'alias/deepseek/deepseek-v3',
  'alias/deepseek/deepseek-r1',
  'alias/anthropic/claude-3.7-sonnet',
];

export const researcherModelsContainsThinking = [
  SUPER_AGENT_DEEP_RESEARCH_MODEL,
  'alias/ninjatech/super-agent:reasoning',
  'alias/ninjatech/super-agent:apex',
  'alias/ninjatech/super-agent:turbo',
  'alias/deepseek/deepseek-r1',
];

export const writerCoderModelsContainsThinking = [
  'alias/deepseek/deepseek-r1',
  'alias/ninjatech/super-agent:reasoning',
];

export const newExternalModelsList: Record<
  string,
  {
    icon_name: IconName;
    display_name: string;
  }
> = {
  [NINJA_AGENT_TEXT_AUTO_MODEL]: {
    display_name: 'Ninja Auto-selects AI model',
    icon_name: 'ninjaOutlined',
  },
  [NINJA_AGENT_AS_EXTERNAL_MODEL]: {
    display_name: 'Fine-tuned Llama 3.3 70B',
    icon_name: 'ninjaOutlined',
  },
  [SUPER_AGENT_DEEP_RESEARCH_MODEL]: {
    display_name: 'SuperAgent Deep Research',
    icon_name: 'ninjaPixProLogo',
  },
  'alias/ninjatech/super-agent:turbo': {
    display_name: 'SuperAgent Turbo',
    icon_name: 'ninjaPixProLogo',
  },
  'alias/ninjatech/super-agent:nexus': {
    display_name: 'SuperAgent Nexus',
    icon_name: 'ninjaPixProLogo',
  },
  'alias/ninjatech/super-agent:apex': {
    display_name: 'SuperAgent Apex',
    icon_name: 'ninjaPixProLogo',
  },
  'alias/ninjatech/super-agent:reasoning': {
    display_name: 'SuperAgent-R 2.0',
    icon_name: 'ninjaPixProLogo',
  },
  'alias/amazon/nova-micro': {
    display_name: 'Amazon Nova Micro',
    icon_name: 'nova',
  },
  'alias/amazon/nova-lite': {
    display_name: 'Amazon Nova Lite',
    icon_name: 'nova',
  },
  'alias/amazon/nova-pro': {
    display_name: 'Amazon Nova Pro',
    icon_name: 'nova',
  },
  'alias/amazon/titan-text-g1-express': {
    display_name: 'Titan Text G1–Express',
    icon_name: 'awsLogo',
  },
  'alias/amazon/titan-text-g1-lite': {
    display_name: 'Titan Text G1–Lite',
    icon_name: 'awsLogo',
  },
  // deprecated
  'alias/amazon/titan-text-premier': {
    display_name: 'Amazon Titan Text Premier',
    icon_name: 'awsLogo',
  },
  'alias/anthropic/claude-3.5-haiku': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Haiku',
  },
  'alias/anthropic/claude-3.5-sonnet': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Sonnet',
  },
  'alias/anthropic/claude-3.7-sonnet': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.7 Sonnet',
  },
  'alias/cohere/command-r': {
    icon_name: 'cohereLogo',
    display_name: 'Command R',
  },
  'alias/cohere/command-r-plus': {
    icon_name: 'cohereLogo',
    display_name: 'Command R+',
  },
  'alias/mistral/mistral-small': {
    icon_name: 'mistralLogo',
    display_name: 'Mistral Small',
  },
  'alias/mistral/mistral-large': {
    icon_name: 'mistralLogo',
    display_name: 'Mistral Large',
  },
  'alias/deepseek/deepseek-v3': {
    icon_name: 'deepSeekLogo',
    display_name: 'DeepSeek V3',
  },
  'alias/deepseek/deepseek-r1': {
    icon_name: 'deepSeekLogo',
    display_name: 'DeepSeek R1',
  },
  'alias/openai/gpt-4o-mini': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4o Mini',
  },
  'alias/openai/gpt-4o': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4o',
  },
  'alias/google/gemini-1.5-flash': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.5 Flash',
  },
  'alias/google/gemini-1.5-pro': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.5 Pro',
  },
  'alias/google/gemini-2.0-flash': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 2.0 Flash',
  },
  'agent/ninjatech/image-auto': {
    display_name: 'Ninja Auto-selects AI model',
    icon_name: 'ninjaOutlined',
  },
  'alias/ninjatech/ninja-pixart': {
    display_name: 'PixArt',
    icon_name: 'ninjaOutlined',
  },
  'alias/ninjatech/flux': {
    display_name: 'FLUX Schnell',
    icon_name: 'ninjaOutlined',
  },
  'alias/ninjatech/flux-pro': {
    display_name: 'FLUX 1.1 PRO',
    icon_name: 'ninjaOutlined',
  },
  // deprecated
  'alias/amazon/titan-image-generator': {
    display_name: 'Amazon Titan Image Generator',
    icon_name: 'awsLogo',
  },
  // deprecated
  'alias/amazon/nova-canvas': {
    display_name: 'Amazon Nova Canvas',
    icon_name: 'nova',
  },
  'alias/stability/stable-diffusion-xl': {
    display_name: 'Stable Diffusion XL 1.0',
    icon_name: 'bedrockStableLogo',
  },
  'alias/openai/dall-e-3': {
    display_name: 'DALL-E 3',
    icon_name: 'chatGPTLogo',
  },
};

export const llmGroupsList = {
  [ModelGroup.GOOGLE]: {
    icon_name: 'googleGeminiProLogo',
  },
  [ModelGroup.NINJA_TECH]: {
    icon_name: 'ninjaOutlined',
  },
  [ModelGroup.AMAZON_BEDROCK]: {
    icon_name: 'awsLogo',
  },
  [ModelGroup.DEEP_SEEK]: {
    icon_name: 'deepSeekLogo',
  },
  [ModelGroup.OPEN_AI]: {
    icon_name: 'chatGPTLogo',
  },
};

export enum ImageGenerationModels {
  PIX = 'alias/ninjatech/ninja-pix', // pix art
  PIX_PRO = 'alias/ninjatech/ninja-pix-pro', // flux
}

export enum ImageGenerationModelOptions {
  PIX = 'alias/ninjatech/ninja-pix',
  PIX_PRO = 'alias/ninjatech/ninja-pix-pro',
  BOTH = 'both',
}

export const AvailableImageGenerationModels = [
  ImageGenerationModels.PIX,
  ImageGenerationModels.PIX_PRO,
];

export const AvailableFluxImageModels = [ImageGenerationModels.PIX_PRO];

// (olha): WIP we are moving to reading all data from BE using getDefaultModelIconName and getImageModelDisplayName
export const imageGeneratorsModelsSet: Record<
  string,
  {
    display_name: string;
    icon_name?: IconName;
    provider: ImageGeneratorsModelProvider;
    hide_icon_in_settings?: boolean;
  }
> = {
  'openai/openai/dall-e-3': {
    icon_name: 'chatGPTLogo',
    display_name: 'DALL-E 3',
    provider: ImageGeneratorsModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'openai/openai/dall-e-2': {
    icon_name: 'chatGPTLogo',
    display_name: 'DALL-E 2',
    provider: ImageGeneratorsModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'bedrock/amazon/amazon.titan-image-generator-v1': {
    display_name: 'Amazon Titan Image Generator',
    provider: ImageGeneratorsModelProvider.BEDROCK,
    icon_name: 'awsLogo',
    hide_icon_in_settings: true,
  },
  // deprecated
  'bedrock/amazon/amazon.titan-image-generator-v2:0': {
    display_name: 'Amazon Titan Image Generator',
    provider: ImageGeneratorsModelProvider.BEDROCK,
    icon_name: 'awsLogo',
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/stability.stable-diffusion-xl-v1': {
    display_name: 'Stable Diffusion XL 1.0',
    provider: ImageGeneratorsModelProvider.BEDROCK,
    icon_name: 'bedrockStableLogo',
  },
  'bedrock/amazon/amazon.nova-reel-v1:0': {
    display_name: 'Amazon Nova Reel',
    provider: ImageGeneratorsModelProvider.BEDROCK,
    icon_name: 'nova',
  },
  [ImageGenerationModels.PIX]: {
    display_name: 'Ninja PixArt',
    provider: ImageGeneratorsModelProvider.NINJA_PIX,
    icon_name: 'ninjaOutlined',
  },
  [ImageGenerationModels.PIX_PRO]: {
    display_name: 'Ninja Flux',
    provider: ImageGeneratorsModelProvider.NINJA_PIX_PRO,
    icon_name: 'ninjaPixProLogo',
  },
};

export const imageStyleOptions = [
  { value: 'none', label: 'None' },
  { value: 'cinematic', label: 'Cinematic' },
  { value: 'photographic', label: 'Photographic' },
  { value: 'anime', label: 'Anime' },
  { value: 'manga', label: 'Manga' },
  { value: 'digital_art', label: 'Digital' },
  { value: 'pixel_art', label: 'Pixel Art' },
  { value: 'fantasy_art', label: 'Fantasy' },
  { value: 'neonpunk', label: 'Neonpunk' },
  { value: 'model_3d', label: '3D' },
];

export const imageModelOptions = [
  {
    value: ImageGenerationModelOptions.BOTH,
    label: 'Ninja PixArt & Ninja Flux',
  },
  {
    value: ImageGenerationModelOptions.PIX,
    label: 'Ninja PixArt',
  },
  {
    value: ImageGenerationModelOptions.PIX_PRO,
    label: 'Ninja Flux',
  },
];

export const reasoningSuperAgentModelSet: Record<
  string,
  {
    title: string;
    description: string;
    isCapableFrom?: NinjaSubscriptionPlan;
    isNew?: boolean;
  }
> = {
  'alias/ninjatech/super-agent:reasoning': {
    title: 'SuperAgent-R 2.0',
    description: 'Uses advanced reasoning. Best for complex math and coding',
    isCapableFrom: NinjaSubscriptionPlan.ULTRA,
    isNew: true,
  },
};

export const superAgentModelSet: Record<
  string,
  {
    title: string;
    description: string;
    isCapableFrom?: NinjaSubscriptionPlan;
    isNew?: boolean;
  }
> = {
  'alias/ninjatech/super-agent:turbo': {
    title: 'SuperAgent Turbo',
    description: 'Fast with mix of internal models',
  },

  'alias/ninjatech/super-agent:apex': {
    title: 'SuperAgent Apex',
    description: 'Slow, but high quality using largest external models',
    isCapableFrom: NinjaSubscriptionPlan.ULTRA,
  },
};

export const allSuperAgentModelsSet = {
  ...reasoningSuperAgentModelSet,
  ...superAgentModelSet,
};

export const superAgentModelSetLegacy: Record<
  SuperAgentModels,
  { title: string; description: string; isCapableFrom?: NinjaSubscriptionPlan }
> = {
  [SuperAgentModels.TURBO]: {
    title: 'SuperAgent Turbo',
    description: 'Fast with mix of internal models',
  },

  [SuperAgentModels.NEXUS]: {
    title: 'SuperAgent Nexus',
    description: 'Medium speed with mid-sized external models',
    isCapableFrom: NinjaSubscriptionPlan.PRO,
  },

  [SuperAgentModels.APEX]: {
    title: 'SuperAgent Apex',
    description: 'Slow with highest quality using largest external models',
    isCapableFrom: NinjaSubscriptionPlan.ULTRA,
  },

  [SuperAgentModels.REASONING]: {
    title: 'SuperAgent-R 2.0',
    description: 'Reasoning model',
    isCapableFrom: NinjaSubscriptionPlan.ULTRA,
  },
};

export const modelIconsMap: Record<ModelIcon, string> = {
  [ModelIcon.NINJATECH]: 'ninjaOutlined',
  [ModelIcon.NINJATECH_SUPER_AGENT]: 'ninjaPixProLogo',
  [ModelIcon.AMAZON_NOVA]: 'nova',
  [ModelIcon.ANTHROPIC]: 'claudeLogo',
  [ModelIcon.COHERE]: 'cohereLogo',
  [ModelIcon.DEEPSEEK]: 'deepSeekLogo',
  [ModelIcon.GOOGLE_GEMINI]: 'googleGeminiProLogo',
  [ModelIcon.MISTRAL]: 'mistralLogo',
  [ModelIcon.OPENAI]: 'chatGPTLogo',
  [ModelIcon.STABLE_DIFFUSION]: 'chatGPTLogo',
};
