import styles from './PreferencesSettings.module.scss';
import { useState } from 'react';
import { Button } from 'src/v2/commonComponents/Button';
import { SVG_SIZE_M } from 'src/constants';
import { Images, ListMagnifyingGlass } from '@phosphor-icons/react';
import classNames from 'classnames';
import { ImageSettings } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/ImageSettings';
import { ResearcherSection } from 'src/v2/components/LeftSidePanel/components/ResearcherSection';

enum PreferencesSettingsTabs {
  Image = 'image',
  Researcher = 'researcher',
}

const Tabs = [
  {
    value: PreferencesSettingsTabs.Image,
    label: 'Image',
    icon: <Images size={SVG_SIZE_M} />,
  },
  {
    value: PreferencesSettingsTabs.Researcher,
    label: 'Researcher',
    icon: <ListMagnifyingGlass size={SVG_SIZE_M} />,
  },
];

export const PreferencesSettings = () => {
  const [activeTab, setActiveTab] = useState<PreferencesSettingsTabs>(
    PreferencesSettingsTabs.Image,
  );
  return (
    <div className={styles.root}>
      <div className={styles.tabsWrapper}>
        {Tabs.map((tab) => (
          <Button
            onClick={() => setActiveTab(tab.value)}
            key={tab.value}
            className={classNames(styles.button, {
              [styles.active]: tab.value === activeTab,
            })}
          >
            {tab.icon}
            <span>{tab.label}</span>
          </Button>
        ))}
      </div>
      {activeTab === PreferencesSettingsTabs.Image ? (
        <ImageSettings />
      ) : (
        <ResearcherSection />
      )}
    </div>
  );
};
