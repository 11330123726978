import { feedbackApi } from './feedbackApi';
import { tasksApi } from './tasksApi';
import { usersApi } from './usersApi';
import { guestUsersApi } from './guestUsersApi';
import { contactsApi } from './contactsApi';
import { conversationApi } from './conversationApi';
import { savingsApi } from './savingsApi';
import { tokenApi } from './tokenApi';
import { jsonDataApi } from './jsonDataApi';
import { offlineRecordingApi } from './offlineRecordingApi';
import { metahumanApi } from './metahumanApi';
import { sharingApi } from './sharingApi';
import { socketApi } from './socketApi';
import { zendeskApi } from './zendeskApi';
import { matchmakerApi } from './matchmakerApi';
import { publicAuthApi } from './publicAuthApi';
import { msftApi } from './msftApi';
import { walletApi } from './walletApi';
import { contentApi } from './contentApi';
import { teamsApi } from './teamsApi';
import { walletAmpApi } from 'src/store/services/walletAmpApi';
import { searchApi } from './searchApi';
import { userSettingsApi } from 'src/store/services/userSettingsApi';

export const apiServices = [
  feedbackApi,
  tasksApi,
  usersApi,
  guestUsersApi,
  contactsApi,
  conversationApi,
  savingsApi,
  tokenApi,
  jsonDataApi,
  offlineRecordingApi,
  metahumanApi,
  sharingApi,
  socketApi,
  zendeskApi,
  matchmakerApi,
  publicAuthApi,
  msftApi,
  walletApi,
  walletAmpApi,
  contentApi,
  teamsApi,
  searchApi,
  tokenApi,
  userSettingsApi,
];

export * from './feedbackApi';
export * from './tasksApi';
export * from './usersApi';
export * from './guestUsersApi';
export * from './contactsApi';
export * from './conversationApi';
export * from './savingsApi';
export * from './tokenApi';
export * from './jsonDataApi';
export * from './offlineRecordingApi';
export * from './metahumanApi';
export * from './sharingApi';
export * from './socketApi';
export * from './zendeskApi';
export * from './matchmakerApi';
export * from './publicAuthApi';
export * from './msftApi';
export * from './walletApi';
export * from './walletAmpApi';
export * from './contentApi';
export * from './teamsApi';
export * from './useResetAllApiServices';
export * from './searchApi';
export * from './tokenApi';
export * from './userSettingsApi';
