import { useRef } from 'react';
import { Plus } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import styles from './NewContentButton.module.scss';
import { useBreakpoint, useOutsideClick, useVisible } from 'src/hooks';
import { ReactTooltip } from 'src/v2/commonComponents/ReactTooltip';
import { NewEditorButton } from './components/NewEditorButton';
import { AddConversationButton } from 'src/pages/ManageTasksChatPage/components/MultiConversationsListPanel/components/AddConversationButton';
import { EditorType } from 'src/types';

const anchorId = 'new-content-tooltip';

export const NewContentButton = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isVisible, onVisibilityRemove, onVisibilityToggle } = useVisible();

  // TODO(olha): it's temporary. will be another condition
  const { isDesktopAndUp } = useBreakpoint();

  const handleOutsideClick = () => {
    if (isVisible) {
      onVisibilityRemove();
    }
  };

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <div className={styles.root} role="button" tabIndex={0} ref={containerRef}>
      <div className={styles.gradientShadow} />
      <div className={styles.gradientBorder} />

      <button
        data-e2e="new-content-button"
        onClick={onVisibilityToggle}
        className={styles.triggerButton}
        tabIndex={-1}
        id={anchorId}
      >
        <Plus size={SVG_SIZE_M} />

        {isDesktopAndUp && <span>New</span>}
      </button>

      <ReactTooltip place="bottom-end" isOpen={isVisible} id={anchorId}>
        <div className={styles.tooltipContainer} onClick={onVisibilityRemove}>
          <AddConversationButton appearance="transparent-button" />
          <NewEditorButton buttonType={EditorType.DOCUMENT} />
          <NewEditorButton buttonType={EditorType.CODE} />
        </div>
      </ReactTooltip>
    </div>
  );
};
