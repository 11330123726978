import { useMemo } from 'react';
import { useGetSubscriptionPlanByIdQuery } from 'src/store/services';
import { useSession } from 'src/hooks';

type UseSubscriptionPlanByIdProps = {
  plan_id?: string;
  seat_count?: number | null;
  shouldSkip?: boolean;
};

export const useSubscriptionPlanById = ({
  plan_id,
  seat_count,
  shouldSkip,
}: UseSubscriptionPlanByIdProps) => {
  const { appUser, isEnterpriseTier } = useSession();

  const { data, isLoading, isFetching } = useGetSubscriptionPlanByIdQuery(
    { user_id: appUser.user_id, plan_id: plan_id || '', seat_count },
    { skip: isEnterpriseTier || shouldSkip || !plan_id },
  );

  return useMemo(
    () => ({
      plan: data,
      isPlanLoading: isLoading || isFetching,
    }),
    [data, isLoading, isFetching],
  );
};
