import { ArrowUUpLeft, ArrowUUpRight } from '@phosphor-icons/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { undo, redo, undoDepth, redoDepth } from '@codemirror/commands';
import { Button } from 'src/v2/commonComponents/Button';
import { SVG_SIZE_M } from 'src/constants';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { useEditorState } from 'src/hooks';

export const UndoRedoCoderButtons = () => {
  const { codeEditorData } = useEditorState();

  const [canUndo, setCanUndo] = useState<boolean>(false);
  const [canRedo, setCanRedo] = useState<boolean>(false);
  const { codeEditorRef } = useContext(ForwardRefContext);

  useEffect(() => {
    if (codeEditorData?.undoStepsCount) {
      setCanUndo(true);
    }

    if (codeEditorData?.redoStepsCount) {
      setCanRedo(true);
    }
  }, [codeEditorData?.redoStepsCount, codeEditorData?.undoStepsCount]);

  const updateUndoRedoState = useCallback(() => {
    const view = codeEditorRef.current;

    if (view) {
      setCanUndo(undoDepth(view.state) > 0);
      setCanRedo(redoDepth(view.state) > 0);
    }
  }, [codeEditorRef]);

  const handleUndo = () => {
    const view = codeEditorRef.current;
    if (view) {
      undo(view);
      updateUndoRedoState();
    }
  };

  const handleRedo = () => {
    const view = codeEditorRef.current;
    if (view) {
      redo(view);
      updateUndoRedoState();
    }
  };

  return (
    <>
      <Button
        shape="round"
        color="transparent"
        onClick={handleUndo}
        disabled={!canUndo}
      >
        <ArrowUUpLeft size={SVG_SIZE_M} weight="regular" />
      </Button>

      <Button
        shape="round"
        color="transparent"
        onClick={handleRedo}
        disabled={!canRedo}
      >
        <ArrowUUpRight size={SVG_SIZE_M} weight="regular" />
      </Button>
    </>
  );
};
