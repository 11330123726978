/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * RequestPayloadType
 */
export enum RequestPayloadType {
  TEXT = 'text',
  FILE = 'file',
  IMAGE_SKILL = 'image-skill',
  IMAGE_EDIT = 'image-edit',
  MODEL_SELECTION = 'model-selection',
}
