import {
  deepResearchState,
  setIsDeepResearchEnabled,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { useCallback, useMemo } from 'react';

export const useIsDeepResearchEnabled = () => {
  const { isDeepResearchEnabled } = useAppSelector(deepResearchState);
  const dispatch = useAppDispatch();

  const changeIsDeepResearchEnabled = useCallback(
    (value: boolean) => {
      dispatch(setIsDeepResearchEnabled(value));
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      isDeepResearchEnabled,
      changeIsDeepResearchEnabled,
    }),
    [changeIsDeepResearchEnabled, isDeepResearchEnabled],
  );
};
