import { useState, useMemo, useContext } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { Modal } from 'src/components/Modal';
import {
  ArrowLeft,
  PushPin,
  ChatCircleText,
  Target,
  GraduationCap,
  PiggyBank,
  ChartLineUp,
  Code,
  User,
  Chats,
} from '@phosphor-icons/react';
import { SidebarSection } from '../SidebarSection';
import { PromptCard } from '../PromptCard';
import { useBreakpoint } from 'src/hooks';
import { SVG_SIZE_M } from 'src/constants';
import { useGetPromptTemplatesQuery } from 'src/store/services';
import styles from './PromptLibraryModal.module.scss';

interface PromptLibraryModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PromptLibraryModal = ({
  isOpen,
  onClose,
}: PromptLibraryModalProps) => {
  const { data } = useGetPromptTemplatesQuery();

  const {
    temporaryInputValue,
    setThreadFromPromptTemplate,
    setThreadInputBoxValue,
    setTemporaryInputValue,
  } = useContext(ThreadInputBoxContext);

  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [activeSubcategory, setActiveSubcategory] = useState<string | null>(
    null,
  );

  const { isMobile } = useBreakpoint();

  const categoryIcons: { [key: string]: React.ReactNode } = {
    Marketing: <Target size={SVG_SIZE_M} />,
    Academia: <GraduationCap size={SVG_SIZE_M} />,
    Finance: <PiggyBank size={SVG_SIZE_M} />,
    Sales: <ChartLineUp size={SVG_SIZE_M} />,
    Developer: <Code size={SVG_SIZE_M} />,
    Personal: <User size={SVG_SIZE_M} />,
    'PR/Comms': <Chats size={SVG_SIZE_M} />,
  };

  const selectedPrompts = useMemo(
    () =>
      data && activeCategory && activeSubcategory
        ? data[activeCategory][activeSubcategory]
        : null,
    [data, activeCategory, activeSubcategory],
  );

  const setPrompt = (prompt: string) => {
    if (temporaryInputValue) {
      setTemporaryInputValue('');
    }
    setThreadFromPromptTemplate(prompt);
    setThreadInputBoxValue(prompt);
  };

  if (!data) {
    return null;
  }

  const handleClose = () => {
    onClose();
    setExpandedCategory(null);
    setActiveCategory(null);
    setActiveSubcategory(null);
  };

  const handlePromptClick = (prompt: string) => {
    setPrompt(prompt);
    handleClose();
  };

  return (
    <Modal
      blockScroll={false}
      open={isOpen}
      onClose={handleClose}
      className={styles.modal}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          {selectedPrompts && isMobile && (
            <ArrowLeft
              size={24}
              className={styles.backArrow}
              onClick={() => setActiveSubcategory(null)}
              style={{ cursor: 'pointer', marginRight: '16px' }}
            />
          )}
          <h2>Prompts</h2>
        </div>

        <div className={styles.container}>
          {(!isMobile || !selectedPrompts) && (
            <div className={styles.sidebar}>
              {Object.keys(data).map((category) => (
                <SidebarSection
                  category={category}
                  key={category}
                  items={Object.keys(data[category])}
                  icon={categoryIcons[category] || <PushPin size={16} />}
                  isExpanded={expandedCategory === category}
                  setExpandedCategory={setExpandedCategory}
                  activeSubcategory={activeSubcategory}
                  isActive={category === activeCategory}
                  setActiveCategory={setActiveCategory}
                  setActiveSubcategory={setActiveSubcategory}
                />
              ))}
            </div>
          )}

          {(!isMobile || selectedPrompts) && (
            <div className={styles.content}>
              {activeSubcategory ? (
                selectedPrompts?.map((item, index) => (
                  <PromptCard
                    key={index}
                    title={item.Title}
                    description={item.Prompt}
                    tag={item.Skill}
                    setPrompt={handlePromptClick}
                  />
                ))
              ) : (
                <div className={styles.emptyState}>
                  <ChatCircleText size={64} weight="thin" />
                  <div className={styles.emptyTitle}>Select a Prompt</div>
                  <p className={styles.emptyDescription}>
                    Explore a curated library of prompts and choose the one that
                    best fits your needs.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
