import { useMemo } from 'react';
import { ResearchCard, MessageTabs, MessageTabData, GTMEvent } from 'src/types';
import { Tabs, TabPanel } from 'src/v2/commonComponents/Tabs';
import { prepareExternalModelsTabContent, sendGTMEvent } from 'src/utils';
import { renderMessageTabList } from '../MessageTabList';
import styles from './ThreadResearchCard.module.scss';

// TODO(olha): needs refactoring
import { ThreadMessageContent } from 'src/components/FlatAppearance/components/ThreadMessageContent';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';
import { ThreadResearchReferences } from 'src/components/FlatAppearance/components/ThreadResearchCard/components/ThreadResearchReferences';
import { ThreadResearchMedia } from 'src/components/FlatAppearance/components/ThreadResearchCard/components/ThreadResearchMedia';
import { SuperAgentUpsell } from '../SuperAgentUpsell';
import {
  useMessageTab,
  useSession,
  useExternalModels,
  useMessageSwitchTabs,
  useCurrentMessage,
  useUserMetadata,
} from 'src/hooks';
import { FeedbackRating } from 'src/v2/components/ThreadMessage/components/FeedbackRating';
import {
  researcherModelsContainsThinking,
  NINJA_AGENT_TITLE,
  SUPER_AGENT_DEEP_RESEARCH_MODEL,
} from 'src/constants';
import { SeeSuperAgentAnswerButton } from '../SeeSuperAgentAnswerButton';
import { IconName } from 'src/components/Icon';
import { UpsellCard } from 'src/v2/commonComponents/UpsellCard';
import researcherDarkIcon from 'src/images/icons/researcher-doc-dark.svg';
import researcherLightIcon from 'src/images/icons/researcher-doc-light.svg';

interface ThreadResearchCardProps {
  messageId: string;
  researchCard?: ResearchCard | null;
  isStreaming?: boolean;
  taskId?: string;
  onSetActiveTab: (activeTab: string) => void;
}

// TODO(olha): Tabs feature needs deep refactoring
export const ThreadResearchCard = ({
  messageId,
  researchCard,
  isStreaming = false,
  taskId,
  onSetActiveTab,
}: ThreadResearchCardProps) => {
  const {
    appUser: { settings },
    isProTier,
    isStandardTier,
  } = useSession();
  const { textExternalModels } = useExternalModels();

  const { currentMessage } = useCurrentMessage(messageId);
  const { metadata } = useUserMetadata();

  const { search_result, deep_research_result, generative_ai_result } =
    researchCard?.data || {};
  const { referenced_model_summary } = generative_ai_result || {};
  const { references: external_models_result } = referenced_model_summary || {};
  const { web_page_references, image_references, video_references } =
    search_result || {};

  const withSuperAgent = useMemo(
    () => !!deep_research_result,
    [deep_research_result],
  );

  const tabsData: MessageTabData[] = useMemo(
    () => [
      ...(search_result
        ? [
            {
              tabName: MessageTabs.NINJA_AGENT,
              iconName: 'ninjaOutlined' as IconName,
              tabTitle:
                !!currentMessage?.model_ids && !!metadata
                  ? metadata[currentMessage?.model_ids[0] || '']
                      ?.display_name || ''
                  : NINJA_AGENT_TITLE,
              content:
                search_result.content?.content || search_result.summary || '',
              references: web_page_references,
              reasoning: search_result.content?.reasoning || undefined,
            },
          ]
        : []),
      ...(withSuperAgent
        ? [
            {
              tabName: MessageTabs.SUPER_AGENT,
              iconName: 'ninjaPixProLogo' as IconName,
              tabTitle:
                !!currentMessage?.model_ids && !!metadata
                  ? metadata[currentMessage?.model_ids[0] || '']
                      ?.display_name || ''
                  : 'Ninja SuperAgent',
              content:
                deep_research_result?.content?.content ||
                deep_research_result?.summary ||
                '',
              references: deep_research_result?.web_page_references,
              reasoning: deep_research_result?.content?.reasoning || undefined,
            },
          ]
        : []),
      ...(external_models_result
        ? prepareExternalModelsTabContent(
            external_models_result,
            textExternalModels,
          )
        : []),
    ],
    [
      search_result,
      web_page_references,
      deep_research_result,
      external_models_result,
      withSuperAgent,
      textExternalModels,
      currentMessage,
      metadata,
    ],
  );

  const { getTabSRegistry, onTabClick } = useMessageTab({
    messageId,
    tabsData,
  });

  const withoutSuperAgentNewStructure = useMemo(
    () => !withSuperAgent && tabsData.length === 1,
    [withSuperAgent, tabsData.length],
  );

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    onSetActiveTab(tabName);
    onTabClick(tabName);

    if (
      tabName === MessageTabs.SUPER_AGENT &&
      !settings?.super_agent?.is_capable
    ) {
      sendGTMEvent(GTMEvent.SUPERAGENT_UPSELL_TAB_CLICK);
      return;
    }

    if (withSuperAgent && tabName === MessageTabs.SUPER_AGENT) {
      deep_research_result?.content?.content || deep_research_result?.summary
        ? sendGTMEvent(GTMEvent.SUPERAGENT_COMPLETE_CLICK)
        : sendGTMEvent(GTMEvent.SUPERAGENT_IN_PROGRESS_CLICK);
    }
  };

  const { activeTab, setActiveTab, handleSeeSuperAgentAnswerButton } =
    useMessageSwitchTabs({
      taskId,
      handleTabClick,
      defaultTabModel: tabsData[0]?.tabName,
      onSetActiveTab,
    });

  const isDeepResearch =
    currentMessage?.model_ids?.[0] === SUPER_AGENT_DEEP_RESEARCH_MODEL;

  const withThinking =
    currentMessage?.model_ids && currentMessage?.model_ids[0]
      ? researcherModelsContainsThinking.includes(currentMessage?.model_ids[0])
      : false;

  return (
    <div className={styles.root}>
      <Tabs<string> defaultActiveTab={activeTab} onTabClick={handleTabClick}>
        {tabsData.length > 1 &&
          renderMessageTabList({
            data: tabsData,
            withSuperAgent,
            tabRegistry: getTabSRegistry(),
          })}

        {tabsData.map(
          ({ tabName, content, references, tabTitle, reasoning }) => (
            <TabPanel key={tabName} tab={tabName}>
              {content.length === 0 && !reasoning ? (
                <>
                  {tabName === MessageTabs.SUPER_AGENT &&
                    !!deep_research_result &&
                    isDeepResearch && (
                      <div className={styles.blinkingProgressText}>
                        Deep research digging up the internet for answers...
                      </div>
                    )}

                  {(tabName !== MessageTabs.SUPER_AGENT ||
                    !!deep_research_result) &&
                    !isDeepResearch && (
                      <>
                        <div className={styles.blinkingProgressText}>
                          Generating the response...
                        </div>
                        {!withThinking && <ProgressBar count={3} withColor />}
                      </>
                    )}

                  {tabName === MessageTabs.SUPER_AGENT && (
                    <SuperAgentUpsell description="An advanced research agent that dives deep into your questions, leveraging real-time web data and relevant sources to deliver more accurate and richer answers." />
                  )}
                </>
              ) : (
                <div className={styles.container}>
                  <ThreadMessageContent
                    content={content}
                    isStreaming={isStreaming}
                    references={references}
                    reasoning={reasoning}
                    isDeepResearch={isDeepResearch}
                  />

                  {references && (
                    <ThreadResearchReferences researchReferences={references} />
                  )}

                  {tabName === MessageTabs.NINJA_AGENT && (
                    <ThreadResearchMedia
                      images={image_references || []}
                      videos={video_references || []}
                    />
                  )}

                  {((tabName === MessageTabs.NINJA_AGENT &&
                    withSuperAgent &&
                    tabsData.length > 1) ||
                    withoutSuperAgentNewStructure) &&
                    false && ( // todo temporary hide
                      <SeeSuperAgentAnswerButton
                        onClick={handleSeeSuperAgentAnswerButton}
                        isWithoutSuperAgent={withoutSuperAgentNewStructure}
                        messageId={messageId}
                        isDeepResearch
                      />
                    )}

                  {isDeepResearch && (isStandardTier || isProTier) && (
                    <div className={styles.upsellCardWrapper}>
                      <UpsellCard
                        darkIcon={researcherDarkIcon}
                        lightIcon={researcherLightIcon}
                        description="You have limited Deep Research tasks per day. Unlock unlimited access by upgrading to our Ultra or Business tier!"
                        dataGTM="Deep-Researcher-upsell-message"
                        shouldShowUpgrade
                      />
                    </div>
                  )}

                  <FeedbackRating
                    tabTitle={tabTitle}
                    messageId={messageId}
                    isStreaming={isStreaming}
                    operationType="models"
                    modelId={tabName}
                  />
                </div>
              )}
            </TabPanel>
          ),
        )}
      </Tabs>
    </div>
  );
};
