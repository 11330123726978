/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ModelIcon {
  NINJATECH = 'ninjatech',
  NINJATECH_SUPER_AGENT = 'ninjatech:super-agent',
  AMAZON_NOVA = 'amazon:nova',
  STABLE_DIFFUSION = 'stable-diffusion',
  ANTHROPIC = 'anthropic',
  COHERE = 'cohere',
  MISTRAL = 'mistral',
  DEEPSEEK = 'deepseek',
  OPENAI = 'openai',
  GOOGLE_GEMINI = 'google:gemini',
}
