import { useCallback, useContext } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';

export const useInsertContentToCodeEditor = () => {
  const { codeEditorRef } = useContext(ForwardRefContext);

  const insertContentToCodeEditor = useCallback(
    ({ codeContent }: { codeContent: string }) => {
      const view = codeEditorRef.current;

      view?.dispatch({
        changes: {
          from: view.state.selection.main.head,
          insert: codeContent,
        },
      });
    },
    [codeEditorRef],
  );

  return { insertContentToCodeEditor };
};
