import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import classNames from 'classnames';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { batch } from 'react-redux';
import { toast } from 'react-toastify';
import { MainPageHelmet } from 'src/analyticsComponents/MainPageHelmet';
import { AvatarUpsellVideoModal } from 'src/components/AvatarUpsellVideoModal';
import { BasicTierUpsellModal } from 'src/components/BasicTierUpsellModal';
import { TaskView } from 'src/components/FlatAppearance/components/TaskView';
import { ModalWithGallery } from 'src/components/ModalWithGallery';
import { OnboardingModal } from 'src/components/OnboardingModal';
import { SupportModal } from 'src/components/SupportModal';
import {
  LOCAL_STORAGE_VALUE,
  MAX_FILE_SIZE,
  SHOWN_ONBOARDING_MODAL,
} from 'src/constants';
import AudioContext from 'src/contexts/AudioContext';
import AuthContext from 'src/contexts/AuthContext';
import SessionContext from 'src/contexts/SessionContext';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import {
  useAttachFile,
  useAvatarIdleTimer,
  useAvatarPreferences,
  useAvatarQueue,
  useBreakpoint,
  useChatPageQueryParams,
  useFullScreen,
  useRightSidePanelState,
  useSession,
  useSpeechRecognizer,
  useSubmitUserInput,
  useThreadRegistry,
} from 'src/hooks';
import { useBanner } from 'src/hooks/useBanner';
import { useUpgradeDowngradeParams } from 'src/hooks/useUpgradeDowngradeParamsHandler';
import { WelcomeModal } from 'src/pages/AuthPages/components/WelcomeModal';
import { DnDOverlay } from 'src/pages/ManageTasksChatPage/components/DnDOverlay';
import {
  modalsControlState,
  setWelcomeUpsellModalType,
  toggleWelcomeModal,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { ChatMode } from 'src/types';
import { logAction, replaceFirstWordWithCommands } from 'src/utils';
import { CustomZendeskLauncher } from 'src/v2/components/CustomZendeskLauncher';
import { editorConfig } from 'src/v2/components/Editor/constants';
import { ThreadProvider } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/contexts/ThreadProvider';
import { LeftSidePanel } from 'src/v2/components/LeftSidePanel';
import { RightSidePanel } from 'src/v2/components/RightSidePanel';
import styles from './ManageTasksChatPage.module.scss';
import { AvatarSelectorModal } from './components/AvatarSelectorButton/components/AvatarSelectorModal';
import { ConversationsHeader } from './components/ConversationsHeader';
import { MultiConversationsPanel } from './components/MultiConversationsPanel';
import { PromoTooltip } from './components/PromoTooltip/PromoTooltip';
import { GraphProvider } from '../../v2/components/Editor/plugins/NinjaCanvasPlugin/contexts/GraphContext';
import { AssistantProvider } from '../../v2/components/Editor/plugins/NinjaCanvasPlugin/contexts/AssistantContext';

export const ManageTasksChatPage = () => {
  const { chatMode, appUser: user, isOpenTier } = useSession();
  const dispatch = useAppDispatch();

  const { isGuestAccess } = useContext(AuthContext);
  const { bannersSet } = useBanner();
  const { isRightPanelExpanded, isRightPanelFullWidth } =
    useRightSidePanelState();
  const { isMobileOrTablet } = useBreakpoint();
  const { isSubmitHappened } = useThreadRegistry();
  const { onSubmitUserInput } = useSubmitUserInput();
  const { avatarAudioLocale } = useAvatarPreferences();
  useChatPageQueryParams();
  const { isVisibleModalGallery } = useContext(SessionContext);

  const { isVisibleWelcomeModal } = useAppSelector(modalsControlState);

  useUpgradeDowngradeParams();

  const { threadInputBoxValue, setThreadInputBoxValue } = useContext(
    ThreadInputBoxContext,
  );

  const { setMetaHumanTalking } = useContext(AudioContext);

  useFullScreen();
  useAvatarQueue();
  useAvatarIdleTimer();

  const isAvatarMode = useMemo(() => chatMode === ChatMode.AVATAR, [chatMode]);

  const { handleUploadedFiles } = useAttachFile();

  const { getRootProps, isDragActive } = useDropzone({
    onDropAccepted: async (files) => {
      if (isOpenTier) {
        toast.error(
          'File attachment is not available on your current plan. Please upgrade ',
        );
        return;
      }
      const uploadedFiles = (files || []) as File[];
      await handleUploadedFiles(uploadedFiles);
    },
    onDropRejected: async (fileRejections) => {
      fileRejections.forEach((item) => {
        item.errors.forEach((error) => {
          switch (error.code) {
            case ErrorCode.FileInvalidType: {
              toast.error(
                `.${item.file.name.split('.').pop()?.toLowerCase()} file type is not supported. Please try again with a compatible file format.`,
              );
              break;
            }
            case ErrorCode.FileTooLarge: {
              toast.error(
                `Sorry, the attached file "${item.file.name}" is too large to process. Please upload a smaller one and try again.`,
              );
              break;
            }
            default:
              break;
          }
        });
      });
    },
    noClick: true,
    disabled: isVisibleModalGallery || isRightPanelExpanded,
    multiple: true,
    maxSize: MAX_FILE_SIZE,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'text/plain': ['.txt', '.tsv'],
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'text/csv': ['.csv'],
      'text/html': ['.html', '.htm', '.shtml', '.htx', '.htt', '.acgi'],
      'application/javascript': ['.js'],
      'text/javascript': ['.ts'],
      'text/php': ['.php'],
      'text/x-c': ['.c'],
      'text/x-c++': ['.cpp', '.cc'],
      'text/x-fortran': ['.f', '.f77', '.f90', '.for'],
      'text/x-java': ['.java'],
      'text/x-lisp': ['.lsp'],
      'text/x-pascal': ['.p', '.pas'],
      'text/x-perl': ['.pl'],
      'text/x-python': ['.py'],
      'text/x-shellscript': ['.sh', '.zsh', '.fish'],
      'text/x-tcl': ['.tcl', '.tch'],
      'text/x-haskell': ['.hs', '.lhs'],
      'text/x-go': ['.go'],
      'text/x-lua': ['.lua'],
      'image/apng': ['.apng'],
      'image/bmp': ['.bmp'],
      'image/dib': ['.dib'],
      'image/drle': ['.drle'],
      'image/dpx': ['.dpx'],
      'image/fits': ['.fits', '.fit', '.fts'],
      'image/g3': ['.g3'],
      'image/gif': ['.gif'],
      'image/ico': ['.ico'],
      'image/jp2': ['.jp2'],
      'image/jpg2': ['.jpg2'],
      'image/jfif': ['.jfif'],
      'image/pjpeg': ['.pjpeg'],
      'image/pjp': ['.pjp'],
      'image/webp': ['.webp'],
      'image/jpm': ['.jpm'],
      'image/jpgm': ['.jpgm'],
      'image/svg': ['.svg', '.svgz', '.svg.gz'],
      'image/tif': ['.tif', '.tiff'],
      'image/tfx': ['.tfx'],
      'image/psd': ['.psd'],
      'image/wbmp': ['.wbmp'],
      'image/xif': ['.xif'],
      'image/pcx': ['.pcx'],
      'image/wmf': ['.wmf'],
      'image/arw': ['.arw'],
      'image/cr2': ['.cr2'],
      'image/crw': ['.crw'],
      'image/dcr': ['.dcr'],
      'image/dng': ['.dng'],
      'image/erf': ['.erf'],
      'image/k25': ['.k25'],
      'image/kdc': ['.kdc'],
      'image/mrw': ['.mrw'],
      'image/nef': ['.nef'],
      'image/nrw': ['.nrw'],
      'image/orf': ['.orf'],
      'image/pef': ['.pef'],
      'image/raf': ['.raf'],
      'image/raw': ['.raw'],
      'image/sr2': ['.sr2'],
      'image/srf': ['.srf'],
      'image/x3f': ['.x3f'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        ['.pptx'],
      'application/json': ['.json'],
      // todo temporary not supported on BE side
      // 'application/rtf': ['.rtf'],
    },
  });

  useEffect(() => {
    if (
      !isVisibleWelcomeModal &&
      isOpenTier &&
      !isMobile &&
      localStorage.getItem(SHOWN_ONBOARDING_MODAL) !== LOCAL_STORAGE_VALUE
    ) {
      batch(() => {
        dispatch(toggleWelcomeModal(true));
        dispatch(setWelcomeUpsellModalType('open-tier-welcome-modal'));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenTier]);

  const handleSpeechRecognizing = useCallback(
    (newValue: string) => {
      setThreadInputBoxValue(`${threadInputBoxValue} ${newValue}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threadInputBoxValue],
  );

  const handleSpeechRecognized = useCallback(
    async (newValue: string) => {
      // We don't want to send the message if "stop record" was clicked.
      if (!recordInProgressRef?.current) {
        return;
      }

      const updatedNewValue = threadInputBoxValue
        ? `${threadInputBoxValue} ${
            newValue[0].toLowerCase() + newValue.slice(1)
          }`
        : newValue;

      setThreadInputBoxValue(updatedNewValue);

      const resultValue = replaceFirstWordWithCommands(updatedNewValue);

      logAction('send_message', { type: 'voice' });

      muteMicrophone();
      await onSubmitUserInput(resultValue);
      if (isAvatarMode) {
        // Event that is coming from the iFrame is taking some time and UI behavior in not smooth.
        // So setting metaHumanTalking to true as soon as we send the request.
        setMetaHumanTalking(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threadInputBoxValue, onSubmitUserInput],
  );

  const {
    startSpeechRecognizing,
    stopSpeechRecognizing,
    recordInProgressRef,
    microphoneIsMuted,
    muteMicrophone,
    unMuteMicrophone,
  } = useSpeechRecognizer({
    onRecognizing: handleSpeechRecognizing,
    onRecognized: handleSpeechRecognized,
    sourceLanguage: avatarAudioLocale,
  });

  useEffect(() => {
    // TODO(olha): double-check isSubmitHappened here
    if (!isAvatarMode && !isSubmitHappened && microphoneIsMuted) {
      unMuteMicrophone();
    }
  }, [isSubmitHappened, microphoneIsMuted, isAvatarMode, unMuteMicrophone]);

  const conversationsPanelProps = {
    startSpeechRecognizing,
    stopSpeechRecognizing,
    unMuteMicrophone,
  };

  const props = { ...getRootProps() };

  //  don't wait for user_id in the guest mode
  return user.user_id || isGuestAccess ? (
    <>
      <MainPageHelmet />
      <CustomZendeskLauncher />

      <div
        className={classNames(styles.root, {
          [styles.reducedHight]: bannersSet.length !== 0,
        })}
        ref={props.ref}
        onDrop={props.onDrop}
        onDragLeave={props.onDragLeave}
        onDragEnter={props.onDragEnter}
      >
        {isDragActive && <DnDOverlay />}

        <ThreadProvider>
          <LexicalComposer
            initialConfig={{
              ...editorConfig,
              editorState: () =>
                // TODO(olha): WIP Editor
                $convertFromMarkdownString('', TRANSFORMERS),
            }}
          >
            <div className={styles.container}>
              <LeftSidePanel />

              {isMobileOrTablet ? (
                <div className={styles.middleContainer}>
                  <ConversationsHeader />
                  <MultiConversationsPanel {...conversationsPanelProps} />
                </div>
              ) : (
                <div
                  className={classNames(styles.middleContainer, {
                    [styles.fadeAnimation]: isRightPanelFullWidth,
                  })}
                >
                  <ConversationsHeader />

                  <div className={styles.threadContainerWrapper}>
                    {/* TODO(olha): rename to ThreadMainContainer */}
                    <MultiConversationsPanel {...conversationsPanelProps} />
                  </div>
                </div>
              )}

              <AssistantProvider>
                <GraphProvider>
                  <RightSidePanel />
                </GraphProvider>
              </AssistantProvider>
            </div>
          </LexicalComposer>
        </ThreadProvider>

        <TaskView />

        <ModalWithGallery />

        <PromoTooltip />

        {isMobileOrTablet && <AvatarSelectorModal />}

        <AvatarUpsellVideoModal />

        <SupportModal />

        <OnboardingModal />

        <WelcomeModal />

        <BasicTierUpsellModal />
      </div>
    </>
  ) : (
    <></>
  );
};
