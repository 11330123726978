import { ImageCard } from 'src/types/models/ImageCard';
import { useMemo } from 'react';
import {
  ImageGenSettingsDimensionGroups,
  ImageGenSettingsResolutions,
  RightPanelSections,
} from 'src/types';
import './ThreadImageGenerationCard.scss';
import { SVG_SIZE_M } from 'src/constants';
import {
  useSession,
  useLeftPanelActions,
  useDeleteAttachment,
  useRightSidePanelActions,
  useConversationImages,
} from 'src/hooks';
import { SlidersHorizontal, X } from '@phosphor-icons/react';
import { useCollapse } from 'react-collapsed';
import { ImageGenModifyPanel } from 'src/components/FlatAppearance/components/ImageGenModifyPanel';
import { EditImageUpsell } from 'src/v2/components/ThreadMessage/components/EditImageUpsell';
import { FeedbackRating } from 'src/v2/components/ThreadMessage/components/FeedbackRating';
import { ThreadMediaThumbnail } from 'src/v2/components/ThreadMessage/components/ThreadMediaThumbnail';
import {
  useImageMaskingPanelActions,
  useUpdateImagesForEditor,
} from 'src/hooks/imageMaskingPanelHooks';
import { LearnMoreLink } from 'src/v2/commonComponents/LearnMoreLink';
import { IMAGE_EDITOR, IMAGE_GENERATION } from 'src/constants/externalLinks';

interface ThreadImageGenerationCardProps {
  messageId: string;
  imageCard: ImageCard;
  messageOriginalQuery?: string;
  messageCleanQuery?: string;
  isStreaming?: boolean;
}

export const ThreadImageGenerationCard = ({
  messageId,
  imageCard,
  messageOriginalQuery,
  messageCleanQuery,
  isStreaming,
}: ThreadImageGenerationCardProps) => {
  const { appUser, isLimitedTier } = useSession();
  const { toggleLeftPanelExpanded } = useLeftPanelActions();
  const { watermarked_images } = imageCard;
  const { onDeleteAllAttachments } = useDeleteAttachment();
  const {
    onChangeImageMaskingRightPanelData,
    resetImageMaskingPanelInfo,
    onChangeCurrentImagesList,
    onChangeSelectedImageIndex,
    onChangeMessageId,
  } = useImageMaskingPanelActions();
  const { setRightSidePanelData } = useRightSidePanelActions();
  const imagesArray = useConversationImages(imageCard);

  useUpdateImagesForEditor(imagesArray, messageId);

  const isLimitedEdited = useMemo(
    () => !!watermarked_images && isLimitedTier,
    [watermarked_images, isLimitedTier],
  );

  const {
    isExpanded: isExpandedImageGenSettings,
    setExpanded,
    getCollapseProps,
  } = useCollapse({
    defaultExpanded: false,
  });

  const handleCloseImageGenSettings = () => {
    setExpanded(false);
  };

  const handleSetCurrentMedia = (index: number) => {
    resetImageMaskingPanelInfo();
    onDeleteAllAttachments();
    toggleLeftPanelExpanded(false);
    onChangeImageMaskingRightPanelData(imagesArray[index]);
    onChangeCurrentImagesList(imagesArray);
    onChangeSelectedImageIndex(index);
    setRightSidePanelData({
      panelType: RightPanelSections.IMAGE,
      isExpanded: true,
    });

    if (messageId) {
      onChangeMessageId(messageId);
    }
  };

  const dimensionDescription = useMemo(() => {
    //TODO(olha): it's a temporary workaround until BE sends proper dimension values
    const currentImage = imagesArray[0];
    if (!currentImage) {
      return '';
    }

    const { size, dimension_description } = currentImage;

    if (!dimension_description?.group || !dimension_description?.option) {
      return size;
    }

    const { group, option } = dimension_description;

    const currentOption =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      appUser?.settings?.image_gen_settings?.dimensions?.groups?.[
        group as keyof ImageGenSettingsDimensionGroups
      ]?.[option] as ImageGenSettingsResolutions;

    return [
      [currentOption?.name, currentOption?.aspect_ratio]
        .filter(Boolean)
        .join(' '),
      size || '',
    ]
      .filter(Boolean)
      .join(', ');
  }, [imagesArray, appUser]);

  return (
    <div className="nj-thread-research-media nj-thread-image-generation-media-wrapper">
      <div className="nj-thread-image-generation-header-wrapper">
        <h6 className="nj-thread-image-generation-header-title">
          <span>Image generation</span>
          <span className="nj-thread-image-generation-header-title--caption">
            {imagesArray ? dimensionDescription : ''}
          </span>
        </h6>
        {!isStreaming &&
          (isExpandedImageGenSettings ? (
            <button
              type="button"
              className="nj-thread-image-generation-cancel-modify-button"
              onClick={handleCloseImageGenSettings}
            >
              <X size={SVG_SIZE_M} />
              <span>Cancel</span>
            </button>
          ) : !watermarked_images ? (
            <button
              type="button"
              className="nj-thread-image-generation-modify-button"
              onClick={() => {
                setExpanded(true);
              }}
            >
              <SlidersHorizontal size={SVG_SIZE_M} />
              <span>Modify</span>
            </button>
          ) : (
            <span className="nj-thread-image-generation--caption-italic">
              Edited
            </span>
          ))}
      </div>

      <div {...getCollapseProps()}>
        <ImageGenModifyPanel
          isOpen={isExpandedImageGenSettings}
          onClose={handleCloseImageGenSettings}
          messageOriginalQuery={messageOriginalQuery}
          messageCleanQuery={messageCleanQuery}
        />
      </div>

      <div className="nj-thread-image-generation-media-wrapper--image-list">
        {imagesArray.map((item, index) => (
          <ThreadMediaThumbnail
            mediaType="image"
            key={item.id}
            thumbnail={item.url}
            title={item.title}
            onClick={() => handleSetCurrentMedia(index)}
            icon={item.icon || undefined}
            source={item.source}
            status={item.status}
            withDisabledOverlay={item.isDisabled}
            withWatermark={isLimitedEdited}
          />
        ))}
      </div>

      {isLimitedEdited && <EditImageUpsell />}

      <FeedbackRating
        title="Rate the images"
        messageId={messageId}
        isStreaming={!!isStreaming}
        operationType="message-level"
      />

      {!!watermarked_images ? (
        <LearnMoreLink
          to={IMAGE_EDITOR}
          dataE2E="learn-more-image-editor-link"
          title="Learn more about image editing"
          className="nj-thread-image-generation--learn-more"
        />
      ) : (
        <LearnMoreLink
          to={IMAGE_GENERATION}
          dataE2E="learn-more-image-generation-link"
          title="Learn more about image generation"
          className="nj-thread-image-generation--learn-more"
        />
      )}
    </div>
  );
};
