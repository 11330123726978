import { javascript } from '@codemirror/lang-javascript';
import { cpp } from '@codemirror/lang-cpp';
import { css } from '@codemirror/lang-css';
import { java } from '@codemirror/lang-java';
import { php } from '@codemirror/lang-php';
import { python } from '@codemirror/lang-python';
import { html } from '@codemirror/lang-html';
import { sql } from '@codemirror/lang-sql';
import { json } from '@codemirror/lang-json';
import { rust } from '@codemirror/lang-rust';
import { xml } from '@codemirror/lang-xml';
import { clojure } from '@nextjournal/lang-clojure';
import { csharp } from '@replit/codemirror-lang-csharp';
import {
  CONVERTIBLE_LANGUAGES,
  PROGRAMMING_LANGUAGES,
} from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/shared/constants';
import {
  ArtifactCodeV3,
  ArtifactMarkdownV3,
  ArtifactV3,
  ProgrammingLanguageOptions,
} from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/shared/types';
import { isCodeValuesType } from 'src/types/guards/editor.guard';
import { CodeValuesType, DocumentValuesType } from 'src/types';

const actualNewline = `
`;

export const cleanContent = (content: string): string => {
  return content ? content.replace(/\\n/g, actualNewline) : '';
};

export const getLanguageExtension = (language: string) => {
  switch (language) {
    case 'javascript':
    case 'vue':
    case 'jsx':
      return javascript({ jsx: true, typescript: false });
    case 'typescript':
      return javascript({ jsx: true, typescript: true });
    case 'cpp':
      return cpp();
    case 'java':
      return java();
    case 'php':
      return php();
    case 'python':
      return python();
    case 'html':
      return html();
    case 'sql':
      return sql();
    case 'json':
      return json();
    case 'rust':
      return rust();
    case 'xml':
      return xml();
    case 'clojure':
      return clojure();
    case 'csharp':
      return csharp();
    case 'css':
      return css();
    default:
      return [];
  }
};

export const getLanguageKey = (language?: string) => {
  if (language === 'vue' || language === 'jsx') {
    return 'javascript';
  }

  if (!!getLanguageInfoFromList(language)) {
    return language as ProgrammingLanguageOptions;
  }

  return 'other';
};

export const getLanguageInfoFromList = (language?: string) =>
  PROGRAMMING_LANGUAGES.find((value) => value.language === language);

export const getLanguageLabel = (
  language: ProgrammingLanguageOptions,
): string => {
  const languageInfo = getLanguageInfoFromList(language);

  return languageInfo ? languageInfo.label : 'Other';
};

export const getCodeEditorChangeLanguagePrompt = (
  from: ProgrammingLanguageOptions,
  to: ProgrammingLanguageOptions,
) => `
  Convert the following ${from} code to ${to} while preserving its logic, structure, and functionality.
  Ensure that the ${to} code follows best practices and idiomatic conventions.
  If necessary, replace ${from}-specific functions with their closest ${to} equivalents.
`;

export function canConvertLanguages(
  from: ProgrammingLanguageOptions,
  to: ProgrammingLanguageOptions,
): boolean {
  if (from === to) {
    return true;
  }

  return !!CONVERTIBLE_LANGUAGES[from]?.includes(to);
}

export const getLanguageFromArtifactValues = (
  values: DocumentValuesType | CodeValuesType,
) => {
  const lastContentIndex = values?.artifact?.contents?.length - 1;
  const languageForCodeEditor = isCodeValuesType(values)
    ? values.artifact.contents[lastContentIndex].language
    : undefined;

  return languageForCodeEditor;
};

export const getArtifactContentForCode = (
  language?: ProgrammingLanguageOptions,
  content?: string,
) => {
  const artifactContent: ArtifactCodeV3 = {
    index: 1,
    type: 'code',
    title: 'Untitled code',
    code: content ? cleanContent(content) : '',
    language: language ? language : 'other',
  };

  const newArtifact: ArtifactV3 = {
    currentIndex: 1,
    contents: [artifactContent],
  };

  return newArtifact;
};

export const getArtifactContentForDocument = (content?: string) => {
  const artifactContent: ArtifactMarkdownV3 = {
    index: 1,
    type: 'text',
    title: 'Untitled document',
    fullMarkdown: content ? cleanContent(content) : '',
  };

  const newArtifact: ArtifactV3 = {
    currentIndex: 1,
    contents: [artifactContent],
  };

  return newArtifact;
};
