import {
  isMessageWithCreationTaskId,
  isMessageWithImageGenTask,
} from 'src/types';
import { MessageWithTaskProgress } from '../MessageWithTaskProgress';
import styles from './MessageProgress.module.scss';
import { useCurrentMessage } from 'src/hooks';
import { DEFAULT_THINKING_TEXT } from 'src/constants';

interface MessageProgressProps {
  messageId: string;
  customComponent?: React.ReactNode;
  activeTab: string;
  isFinalMessageChunk?: boolean;
}

export const MessageProgress = ({
  messageId,
  customComponent,
  activeTab,
  isFinalMessageChunk,
}: MessageProgressProps) => {
  const { currentMessage } = useCurrentMessage(messageId);

  if (customComponent) {
    return <>{customComponent}</>;
  }

  if (!currentMessage?.payload) {
    return <p className={styles.text}>{DEFAULT_THINKING_TEXT}</p>;
  }

  if (
    !isMessageWithCreationTaskId(currentMessage) &&
    !isMessageWithImageGenTask(currentMessage)
  ) {
    return null;
  }

  return (
    <MessageWithTaskProgress
      messageId={messageId}
      payload={currentMessage.payload}
      isFinalMessageChunk={isFinalMessageChunk}
      activeTab={activeTab}
    />
  );
};
