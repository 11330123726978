/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MeetingInputTypes {
  LOCATION_AND_CONFERENCE = 'location_and_conference',
  DATETIME_RANGE_LIST = 'datetime_range_list',
  RRULE = 'rrule',
  ATTENDEES = 'attendees',
  DURATION = 'duration',
}
