import { ChangeEvent, KeyboardEvent, forwardRef } from 'react';
import { isMobile as isMobileDevice } from 'react-device-detect';
import classNames from 'classnames';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import styles from './TextareaSimple.module.scss';
import { KeyCodes } from 'src/types';

interface TextareaSimpleProps
  extends Omit<TextareaAutosizeProps, 'onChange' | 'onSubmit'> {
  value: string;
  isDisabled?: boolean;
  className?: string;
  onChange: (value: string) => void;
  onSubmit?: () => Promise<void> | void;
}

export const TextareaSimple = forwardRef<
  HTMLTextAreaElement,
  TextareaSimpleProps
>(({ value, isDisabled, className, onChange, onSubmit, ...rest }, ref) => {
  const handleChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    if (!isDisabled) {
      onChange(target.value);
    }
  };

  const handleSubmit = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (!onSubmit) {
      return;
    }

    const { key, shiftKey } = e;

    if (key === KeyCodes.ENTER && !shiftKey && !isMobileDevice && !isDisabled) {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <TextareaAutosize
      ref={ref}
      value={value}
      disabled={isDisabled}
      className={classNames(styles.root, className)}
      onChange={handleChange}
      onKeyDown={handleSubmit}
      {...rest}
    />
  );
});
