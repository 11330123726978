import { SVGProps } from 'react';
import { useColorTheme } from 'src/hooks';

export const Flux = ({ width = 24, height = 24 }: SVGProps<SVGSVGElement>) => {
  const { isDarkTheme } = useColorTheme();
  const fillColor = isDarkTheme ? '#E8EAEA' : '#11181B';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="flux 1" clipPath="url(#clip0_2170_37085)">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.11084 15.4323L9.00714 1.96338L17.8886 15.4323H16.2345L9.0064 4.4656L2.68195 14.0419H11.6612L12.579 15.4323H0.11084Z"
          fill={fillColor}
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.08813 12.4997L7.62369 10.1923L9.15999 12.4997H6.08813ZM13.6222 15.4323L9.42369 8.98265H11.0363L15.2481 15.4323H13.6222ZM14.7333 8.76042L16.3111 6.39746L17.8889 8.76042H14.7333Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_2170_37085">
          <rect
            width="17.7778"
            height="17.7778"
            fill={fillColor}
            transform="translate(0.111084 0.111328)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
