/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SupportTicketType {
  CUSTOMER_QUESTION = 'customer_question',
  SALES = 'sales',
  BUG_OR_TECHNICAL_ISSUE = 'bug_or_technical_issue',
  PRODUCT_QUESTIONS_AND_FEEDBACK = 'product_questions_and_feedback',
  BILLING_AND_SUBSCRIPTION = 'billing_and_subscription',
  TECHNICAL_SUPPORT = 'technical_support',
  OTHER = 'other',
}
