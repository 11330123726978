import {
  ArrowsInSimple,
  ArrowsOutSimple,
  MagicWand,
} from '@phosphor-icons/react';
import { useHotkeys } from 'react-hotkeys-hook';
import { SVG_SIZE_M } from 'src/constants';
import { useRightSidePanelState, useRightSidePanelActions } from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './FullWidthToggle.module.scss';

interface FullWidthToggleProps {
  toggleType?: 'icon' | 'ninja-button';
}

export const FullWidthToggle = ({
  toggleType = 'icon',
}: FullWidthToggleProps) => {
  const { isRightPanelFullWidth } = useRightSidePanelState();
  const { toggleRightSidePanelFullWidth } = useRightSidePanelActions();

  useHotkeys('esc', () => {
    if (isRightPanelFullWidth) {
      toggleRightSidePanelFullWidth(false);
    }
  });

  const handleFullWidthClick = () => {
    toggleRightSidePanelFullWidth();
  };

  if (toggleType === 'ninja-button' && isRightPanelFullWidth) {
    return null;
  }

  if (toggleType === 'ninja-button' && !isRightPanelFullWidth) {
    return (
      <Button
        onClick={handleFullWidthClick}
        className={styles.root}
        color="tertiary"
      >
        <MagicWand size={SVG_SIZE_M} />
        Edit with Ninja
      </Button>
    );
  }

  return (
    <Button shape="round" color="transparent" onClick={handleFullWidthClick}>
      {isRightPanelFullWidth ? (
        <ArrowsInSimple size={SVG_SIZE_M} />
      ) : (
        <ArrowsOutSimple size={SVG_SIZE_M} />
      )}
    </Button>
  );
};
