import { useState, createContext, useCallback, useMemo } from 'react';
import {
  AttachmentFileInputId,
  CONVERTED_PASTED_TEXT_TO_FILE_NAME,
  PromptLoadingReason,
  TAGS_TO_REPLACE,
} from 'src/constants';
import { useIsDeepResearchEnabled } from 'src/hooks';
import { FileObject, QuickSkillCommand } from 'src/types';

export type ThreadInputBoxContextType = {
  threadInputBoxValue: string;
  threadInputBoxFiles: {
    [key: string]: FileObject | null;
  } | null;
  showReplaceTooltip: boolean;
  setThreadInputBoxValue: (value: string) => void;
  setFileInfoToArray: (key: string, newFile: Partial<FileObject>) => void;
  setThreadInputBoxFiles: (
    files: {
      [key: string]: FileObject | null;
    } | null,
  ) => void;
  onCheckInputTagReplacement: (value: string) => boolean;
  onToggleReplaceTooltip: (value?: boolean) => void;
  deleteAttachment: (fileId: string) => void;
  attachmentLoading: boolean;
  setAttachmentLoading: (value: boolean) => void;
  fileDataToSend: string;
  setFileDataToSend: (value: string) => void;
  threadInputBoxTextError: string;
  setThreadInputBoxTextError: (value: string) => void;
  promptLoading: boolean;
  setPromptLoading: (value: boolean) => void;
  setPromptModificationReason: (reason: PromptLoadingReason | null) => void;
  temporaryInputValue: string;
  promptModificationReason: PromptLoadingReason | null;
  setTemporaryInputValue: (value: string) => void;
  onClearAttachments: () => void;
  onClearInputBox: () => void;
  // it's just a temporary flag for rendering PromptInput
  threadFromPromptTemplate: string;
  setThreadFromPromptTemplate: (value: string) => void;
  convertedFromTextFilesLength: number;
};

type ThreadInputBoxContextProviderProps = {
  children: React.ReactNode;
};

const ThreadInputBoxContext = createContext<ThreadInputBoxContextType>({
  threadInputBoxValue: '',
  threadInputBoxFiles: null,
  showReplaceTooltip: false,
  setThreadInputBoxValue: () => undefined,
  setFileInfoToArray: () => undefined,
  setThreadInputBoxFiles: () => undefined,
  onCheckInputTagReplacement: () => false,
  onToggleReplaceTooltip: () => undefined,
  deleteAttachment: () => undefined,
  attachmentLoading: false,
  setAttachmentLoading: () => undefined,
  fileDataToSend: '',
  setFileDataToSend: () => undefined,
  threadInputBoxTextError: '',
  setThreadInputBoxTextError: () => undefined,
  promptLoading: false,
  setPromptLoading: () => undefined,
  setPromptModificationReason: () => undefined,
  temporaryInputValue: '',
  setTemporaryInputValue: () => undefined,
  onClearAttachments: () => undefined,
  threadFromPromptTemplate: '',
  setThreadFromPromptTemplate: () => undefined,
  onClearInputBox: () => undefined,
  convertedFromTextFilesLength: 0,
  promptModificationReason: null, // updated to allow `null`
});

const ThreadInputBoxContextProvider = ({
  children,
}: ThreadInputBoxContextProviderProps) => {
  const [value, setValue] = useState<string>('');
  const [temporaryInputValue, setTemporaryInputValue] = useState<string>('');
  const [fileDataToSend, setFileDataToSend] = useState<string>('');
  const [files, setFiles] = useState<{
    [key: string]: FileObject | null;
  } | null>(null);
  const [showReplaceTooltip, setReplaceTooltip] = useState(false);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [promptLoading, setPromptLoading] = useState(false);
  const [promptModificationReason, setPromptModificationReason] =
    useState<PromptLoadingReason | null>(null);
  const [threadInputBoxTextError, setThreadInputBoxTextError] =
    useState<string>('');
  const [threadFromPromptTemplate, setThreadFromPromptTemplate] =
    useState<string>('');

  const { isDeepResearchEnabled } = useIsDeepResearchEnabled();

  const handleCheckInputTagReplacement = useCallback((value: string) => {
    return TAGS_TO_REPLACE.some((tag) =>
      value.toLocaleLowerCase().includes(tag),
    );
  }, []);

  const handleToggleReplaceTooltip = useCallback(
    (value?: boolean) => {
      setReplaceTooltip(value ?? !showReplaceTooltip);
    },
    [showReplaceTooltip],
  );

  const handleValueChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      const shouldShowReplaceTooltip = handleCheckInputTagReplacement(newValue);
      if (shouldShowReplaceTooltip) {
        handleToggleReplaceTooltip(true);
      }

      if (showReplaceTooltip && !shouldShowReplaceTooltip) {
        setReplaceTooltip(false);
      }
    },
    [
      showReplaceTooltip,
      handleCheckInputTagReplacement,
      handleToggleReplaceTooltip,
    ],
  );

  const handleAddNewFileToArray = (
    key: string,
    newFile: Partial<FileObject>,
  ) => {
    setFiles((prev) => ({ ...prev, [key]: { ...newFile } }));
  };

  const handleDeleteAttachedFile = (fileId: string) => {
    setFileDataToSend('');
    setThreadInputBoxTextError('');
    const updatedFiles = files;
    if (updatedFiles) {
      delete updatedFiles[fileId];
      setFiles({ ...updatedFiles });
    }

    if (files && Object.keys(files).length === 0) {
      const fileInput = document.getElementById(
        AttachmentFileInputId,
      ) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
        fileInput.files = null;
      }
    }
  };

  const handleDeleteAllAttachments = async () => {
    setFiles(null);
    setFileDataToSend('');
    const fileInput = document.getElementById(
      AttachmentFileInputId,
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
      fileInput.files = null;
    }
  };

  const handleClearInputBox = () => {
    setTemporaryInputValue('');

    const newInputValue = isDeepResearchEnabled
      ? `${QuickSkillCommand.DEEP_RESEARCHER} `
      : '';
    handleValueChange(newInputValue);

    setThreadFromPromptTemplate('');
    setThreadInputBoxTextError('');
  };

  const convertedFromTextFilesLength = useMemo(
    () =>
      Object.values(files || {}).filter((item) =>
        item?.file?.name.includes(CONVERTED_PASTED_TEXT_TO_FILE_NAME),
      ).length,
    [files],
  );

  return (
    <ThreadInputBoxContext.Provider
      value={{
        threadInputBoxValue: value,
        showReplaceTooltip,
        setThreadInputBoxValue: handleValueChange,
        onCheckInputTagReplacement: handleCheckInputTagReplacement,
        onToggleReplaceTooltip: handleToggleReplaceTooltip,
        threadInputBoxFiles: files,
        setThreadInputBoxFiles: setFiles,
        setFileInfoToArray: handleAddNewFileToArray,
        deleteAttachment: handleDeleteAttachedFile,
        attachmentLoading,
        setAttachmentLoading,
        fileDataToSend,
        setFileDataToSend,
        threadInputBoxTextError,
        setThreadInputBoxTextError,
        promptLoading,
        setPromptLoading,
        setPromptModificationReason,
        temporaryInputValue,
        setTemporaryInputValue,
        onClearAttachments: handleDeleteAllAttachments,
        threadFromPromptTemplate,
        setThreadFromPromptTemplate,
        onClearInputBox: handleClearInputBox,
        convertedFromTextFilesLength,
        promptModificationReason,
      }}
    >
      {children}
    </ThreadInputBoxContext.Provider>
  );
};

export { ThreadInputBoxContextProvider };
export default ThreadInputBoxContext;
