import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CodeEditorData, EditorType } from 'src/types';
import { RootState } from '../index';

export interface EditorState {
  content: string;
  fileName: string;
  editorType: EditorType | null;
  codeEditorData: null | CodeEditorData;
  isWordWrapEnabled: boolean;
  shouldResetEditorContent: boolean;
  isEditorDarkTheme: boolean;
}

const initialState: EditorState = {
  content: '',
  fileName: '',
  editorType: null,
  codeEditorData: null,
  isWordWrapEnabled: false,
  shouldResetEditorContent: false,
  isEditorDarkTheme: false,
} as EditorState;

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setEditorType: (state, { payload }: PayloadAction<EditorType | null>) => {
      state.editorType = payload;
    },
    setCodeEditorData: (
      state,
      { payload }: PayloadAction<CodeEditorData | null>,
    ) => {
      state.codeEditorData = {
        ...state.codeEditorData,
        ...payload,
      };
    },
    setIsWordWrapEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.isWordWrapEnabled = payload;
    },
    setEditorIsDarkTheme: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditorDarkTheme = payload;
    },
    setShouldResetEditorContent: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.shouldResetEditorContent = payload;
      state.codeEditorData = null;
    },
  },
});

export const editorState = (state: RootState) => state.editor as EditorState;

export const {
  setEditorType,
  setCodeEditorData,
  setIsWordWrapEnabled,
  setEditorIsDarkTheme,
  setShouldResetEditorContent,
} = editorSlice.actions;

export default editorSlice.reducer;
