import { useContext, useCallback } from 'react';
import dayjs from 'dayjs';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import {
  useSession,
  useFetchUserInput,
  useUserLocation,
  useConversationParams,
  useBanner,
  useTimeZone,
  useAttachFile,
  useConversationActions,
  useConversationsFiltering,
  useRightSidePanelState,
} from 'src/hooks';
import {
  ConversationRole,
  MessageChannel,
  Message,
  Tag,
  LocationSource,
  BannerType,
  CustomMessageParams,
  RequestPayloadType,
} from 'src/types';
import { DEFAULT_CHAT_ID, NINJA_AGENT_TEXT_AUTO_MODEL } from 'src/constants';
import { FileRequestPayload } from 'src/types/models/FileRequestPayload';
import { DATE_RANGE_STRING_FORMAT } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/CreateMeetingCard/constants/defaultValues';
import {
  useImageMaskingCurrentImageData,
  useImageMaskingPanelActions,
  useImageMaskingSelectedArea,
} from '../imageMaskingPanelHooks';
import { useRightSidePanelActions } from '../themeHooks/useRightSidePanelActions';
import { useActiveModel } from 'src/hooks/userSettingsHooks/useActiveModel';

export const useSubmitUserInput = () => {
  const {
    onClearInputBox,
    onClearAttachments,
    threadInputBoxFiles,
    fileDataToSend,
    setFileDataToSend,
    convertedFromTextFilesLength,
    setThreadInputBoxTextError,
  } = useContext(ThreadInputBoxContext);
  const { threadMessageListAnchorRef } = useContext(ForwardRefContext);
  const { resetAndCloseRightSidePanelData } = useRightSidePanelActions();
  const {
    setImageMaskingIsLoading,
    resetImageMaskingPanelInfo,
    onChangeImageMaskingRightPanelData,
  } = useImageMaskingPanelActions();

  const { taskStatusFilter } = useConversationsFiltering();
  const { setTaskStatusFilter } = useConversationActions();

  const { agent, appUser, isFirstUserQuery, updateFirstUserQuery } =
    useSession();
  const { fetchAiData } = useFetchUserInput();
  const { setLocationInterval } = useUserLocation();
  const { currentConversationId } = useConversationParams();
  const { removeBanner } = useBanner();
  const { userTimeZone } = useTimeZone();
  const { handleUploadedImageMask } = useAttachFile();
  const { selectedImageMask } = useImageMaskingSelectedArea();
  const { currentMaskingImageData } = useImageMaskingCurrentImageData();
  const { activeModel } = useActiveModel();

  const { isImageEditSectionExpanded } = useRightSidePanelState();

  const handleUpdateUserLocation = useCallback(() => {
    if (!isFirstUserQuery && appUser.location?.source !== LocationSource.GPS) {
      setLocationInterval();
      updateFirstUserQuery();
    }
  }, [isFirstUserQuery, appUser, updateFirstUserQuery, setLocationInterval]);

  const handleValidateSubmit = useCallback(() => {
    if (!appUser.rate_limited_until) {
      return true;
    }

    const currentTime = dayjs();
    const rateLimitedTime = dayjs(appUser.rate_limited_until);

    if (rateLimitedTime.isSameOrBefore(currentTime)) {
      removeBanner(BannerType.USER_RATE_LIMITED_UNTIL);
      setThreadInputBoxTextError('');
      return true;
    }

    const rateLimitedTimeString = rateLimitedTime
      .tz(userTimeZone)
      .format(DATE_RANGE_STRING_FORMAT);

    setThreadInputBoxTextError(
      `You have hit a rate limit until ${rateLimitedTimeString}.`,
    );

    return false;
  }, [
    appUser.rate_limited_until,
    userTimeZone,
    removeBanner,
    setThreadInputBoxTextError,
  ]);

  const onSubmitUserInput = useCallback(
    async (
      userInput: string,
      params?: Partial<Message>,
      customParams?: Partial<CustomMessageParams>,
    ) => {
      if (!userInput.trim() && convertedFromTextFilesLength === 0) {
        return;
      }

      const isSubmitValidated = handleValidateSubmit();

      if (!isSubmitValidated) {
        return;
      }

      if (isImageEditSectionExpanded) {
        resetAndCloseRightSidePanelData();
        setImageMaskingIsLoading(true);
      }

      handleUpdateUserLocation();

      let imageMaskFileName: string | undefined;

      if (selectedImageMask) {
        imageMaskFileName = await handleUploadedImageMask(selectedImageMask);
      }

      const filteredFileList = !!threadInputBoxFiles
        ? Object.keys(threadInputBoxFiles).filter(
            (item) => !threadInputBoxFiles[item]?.errorMessage,
          )
        : [];

      const isEditing =
        !!threadInputBoxFiles &&
        Object.keys(threadInputBoxFiles).filter(
          (item) => threadInputBoxFiles[item]?.isEdit,
        ).length > 0;

      const filesList =
        !!threadInputBoxFiles && filteredFileList.length > 0
          ? filteredFileList.map((key) => {
              if (currentMaskingImageData?.url) {
                return {
                  payload_type: RequestPayloadType.IMAGE_EDIT,
                  image_url: currentMaskingImageData.url,
                  mask_url: imageMaskFileName
                    ? `upload://${imageMaskFileName}`
                    : null,
                };
              }

              return {
                payload_type: RequestPayloadType.FILE,
                original_filename: threadInputBoxFiles[key]?.file?.name,
                converted_filename: threadInputBoxFiles[key]?.convertedFileName,
              };
            })
          : ([] as FileRequestPayload[]);

      const overrideModelName =
        customParams?.overrideModelName === null
          ? NINJA_AGENT_TEXT_AUTO_MODEL
          : customParams?.overrideModelName;

      const newMessage = {
        user_id: appUser.user_id,
        from_user_id: appUser.user_id,
        to_user_id: agent.user_id,
        channel: MessageChannel.WEB_APP,
        role: ConversationRole.USER,
        content: isEditing ? `${fileDataToSend} ${userInput}` : userInput,
        is_read: false,
        tag: Tag.CONVERSATION,
        timestamp: new Date().toISOString(),
        conversation_id: currentConversationId || DEFAULT_CHAT_ID,
        persona: appUser.persona,
        payload_list: [
          {
            payload_type: RequestPayloadType.TEXT,
            content: userInput,
          },
          {
            payload_type: RequestPayloadType.MODEL_SELECTION,
            selected_model:
              overrideModelName ?? (activeModel || NINJA_AGENT_TEXT_AUTO_MODEL),
          },
          ...filesList,
        ],
        ...(params ? params : {}),
      } as Message;

      setFileDataToSend('');
      onClearInputBox();
      onClearAttachments();
      resetImageMaskingPanelInfo();
      onChangeImageMaskingRightPanelData(null);

      threadMessageListAnchorRef?.current?.onScrollToBottomMessageList(0);

      await fetchAiData(newMessage);

      if (taskStatusFilter) {
        setTaskStatusFilter();
      }
    },
    [
      taskStatusFilter,
      setTaskStatusFilter,
      convertedFromTextFilesLength,
      handleValidateSubmit,
      resetAndCloseRightSidePanelData,
      setImageMaskingIsLoading,
      handleUpdateUserLocation,
      selectedImageMask,
      threadInputBoxFiles,
      appUser.user_id,
      appUser.persona,
      agent.user_id,
      fileDataToSend,
      currentConversationId,
      setFileDataToSend,
      onClearInputBox,
      onClearAttachments,
      resetImageMaskingPanelInfo,
      onChangeImageMaskingRightPanelData,
      threadMessageListAnchorRef,
      fetchAiData,
      handleUploadedImageMask,
      currentMaskingImageData?.url,
      activeModel,
      isImageEditSectionExpanded,
    ],
  );

  return { onSubmitUserInput };
};
