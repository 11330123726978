import {
  FacebookLogo,
  InstagramLogo,
  XLogo,
  TiktokLogo,
  Desktop,
  DeviceMobile,
  Monitor,
  FilmStrip,
  Icon,
} from '@phosphor-icons/react';
import { ImageGenNewSettingsDimensionOptionTypes } from 'src/types/settings';

export const ImageSettingsIconsSet: Record<string, Icon> = {
  [ImageGenNewSettingsDimensionOptionTypes.FACEBOOK]: FacebookLogo,
  [ImageGenNewSettingsDimensionOptionTypes.INSTAGRAM]: InstagramLogo,
  [ImageGenNewSettingsDimensionOptionTypes.X]: XLogo,
  [ImageGenNewSettingsDimensionOptionTypes.TIKTOK]: TiktokLogo,
  [ImageGenNewSettingsDimensionOptionTypes.DESKTOP]: Desktop,
  [ImageGenNewSettingsDimensionOptionTypes.MOBILE]: DeviceMobile,
  [ImageGenNewSettingsDimensionOptionTypes.TV]: Monitor,
  [ImageGenNewSettingsDimensionOptionTypes.FILM]: FilmStrip,
};

export const ImageSettingsAspectRatioSet: Record<string, string> = {
  [ImageGenNewSettingsDimensionOptionTypes.FACEBOOK]: '16:9',
  [ImageGenNewSettingsDimensionOptionTypes.INSTAGRAM]: '4:5',
  [ImageGenNewSettingsDimensionOptionTypes.X]: '4:3',
  [ImageGenNewSettingsDimensionOptionTypes.TIKTOK]: '9:16',
  [ImageGenNewSettingsDimensionOptionTypes.DESKTOP]: '16:9',
  [ImageGenNewSettingsDimensionOptionTypes.MOBILE]: '9:16',
  [ImageGenNewSettingsDimensionOptionTypes.TV]: '2:1',
  [ImageGenNewSettingsDimensionOptionTypes.FILM]: '1.85:1',
};

export const ImageSettingsPrimaryGroup: Record<
  string,
  { aspect_ratio: string }
> = {
  [ImageGenNewSettingsDimensionOptionTypes.SQUARE]: {
    aspect_ratio: '1:1',
  },
  [ImageGenNewSettingsDimensionOptionTypes.PORTRAIT]: {
    aspect_ratio: '2:3',
  },
  [ImageGenNewSettingsDimensionOptionTypes.LANDSCAPE]: {
    aspect_ratio: '16:9',
  },
};

export const ImageSettingsOtherGroups = {
  social_media: {
    groupName: 'Social Media',
    options: {
      [ImageGenNewSettingsDimensionOptionTypes.FACEBOOK]: {
        icon: FacebookLogo,
        name: 'Facebook',
        aspect_ratio:
          ImageSettingsAspectRatioSet[
            ImageGenNewSettingsDimensionOptionTypes.FACEBOOK
          ],
      },
      [ImageGenNewSettingsDimensionOptionTypes.INSTAGRAM]: {
        icon: InstagramLogo,
        name: 'Instagram',
        aspect_ratio:
          ImageSettingsAspectRatioSet[
            ImageGenNewSettingsDimensionOptionTypes.INSTAGRAM
          ],
      },
      [ImageGenNewSettingsDimensionOptionTypes.X]: {
        icon: XLogo,
        name: 'X',
        aspect_ratio:
          ImageSettingsAspectRatioSet[
            ImageGenNewSettingsDimensionOptionTypes.X
          ],
      },
      [ImageGenNewSettingsDimensionOptionTypes.TIKTOK]: {
        icon: TiktokLogo,
        name: 'Tik Tok',
        aspect_ratio:
          ImageSettingsAspectRatioSet[
            ImageGenNewSettingsDimensionOptionTypes.TIKTOK
          ],
      },
    },
  },
  devices: {
    groupName: 'Devices',
    options: {
      [ImageGenNewSettingsDimensionOptionTypes.DESKTOP]: {
        icon: Desktop,
        name: 'Desktop',
        aspect_ratio:
          ImageSettingsAspectRatioSet[
            ImageGenNewSettingsDimensionOptionTypes.DESKTOP
          ],
      },
      [ImageGenNewSettingsDimensionOptionTypes.MOBILE]: {
        icon: DeviceMobile,
        name: 'Mobile',
        aspect_ratio:
          ImageSettingsAspectRatioSet[
            ImageGenNewSettingsDimensionOptionTypes.MOBILE
          ],
      },
      [ImageGenNewSettingsDimensionOptionTypes.TV]: {
        icon: Monitor,
        name: 'TV',
        aspect_ratio:
          ImageSettingsAspectRatioSet[
            ImageGenNewSettingsDimensionOptionTypes.TV
          ],
      },
    },
  },
  film: {
    groupName: 'Film',
    options: {
      [ImageGenNewSettingsDimensionOptionTypes.FILM]: {
        icon: FilmStrip,
        name: 'Cinema',
        aspect_ratio:
          ImageSettingsAspectRatioSet[
            ImageGenNewSettingsDimensionOptionTypes.FILM
          ],
      },
    },
  },
};
