/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ImageGenSettingsDimensionResolutionTypes {
  _720P = '720p',
  _1080P = '1080p',
  _2K = '2k',
  _4K = '4k',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
