import {
  isChitChatCard,
  isResearchTaskCreationCard,
  Message,
  MessageSkill,
  MessageTabs,
  MessageType,
  SearchWebPageReference,
  TaskSkill,
} from 'src/types';

export const getSkillFromMessage = (message?: Message): MessageSkill | null => {
  if (!message) {
    return null;
  }

  const { message_type, payload } = message;

  const taskSkill = isChitChatCard(payload) ? payload.data?.skill : undefined;

  switch (message_type) {
    case MessageType.SCHEDULER_TASK_CREATION_CARD:
      return MessageSkill.SCHEDULER;

    case MessageType.RESEARCH_TASK_CREATION_CARD:
      if (taskSkill === TaskSkill.DEEP_RESEARCH) {
        return MessageSkill.DEEP_RESEARCHER;
      }
      return MessageSkill.RESEARCHER;

    case MessageType.CODE_TASK_CREATION_CARD:
      return MessageSkill.CODER;

    case MessageType.CHAT_CARD:
      if (taskSkill === TaskSkill.CODING) {
        return MessageSkill.CODER;
      }
      if (taskSkill === TaskSkill.DOCUMENT_QA) {
        return MessageSkill.FILE;
      }

      return MessageSkill.WRITER;

    case MessageType.VIDEO_CARD:
      return MessageSkill.VIDEO_GENERATION;

    case MessageType.IMAGE_CARD:
      return MessageSkill.IMAGE_GENERATION;

    default:
      return MessageSkill.WRITER;
  }
};

export const getTextContentFromMessage = ({
  message,
  activeTab,
}: {
  message?: Message;
  activeTab: string;
}) => {
  if (!message) {
    return '';
  }

  const { payload } = message;

  if (isResearchTaskCreationCard(payload)) {
    if (activeTab === MessageTabs.NINJA_AGENT) {
      return (
        payload.research_card?.data?.search_result?.content?.content ||
        payload.research_card?.data?.search_result?.summary ||
        ''
      );
    }

    if (activeTab.includes(MessageTabs.SUPER_AGENT)) {
      return (
        payload.research_card?.data?.deep_research_result?.content?.content ||
        payload.research_card?.data?.deep_research_result?.summary ||
        ''
      );
    }

    const currentContent =
      Object.values(
        payload.research_card?.data?.generative_ai_result
          ?.referenced_model_summary?.references || {},
      ).find((item) => item.model === activeTab)?.content || '';

    return currentContent;
  }

  if (isChitChatCard(payload)) {
    if (activeTab === MessageTabs.NINJA_AGENT) {
      return payload.data?.ninja_model_output || '';
    }

    const currentContent =
      Object.values(
        payload.data?.referenced_model_summary?.references || {},
      ).find((item) => item.model === activeTab)?.content || '';

    return currentContent;
  }

  return '';
};

export const getWebPageReferencesFromMessage = ({
  message,
  activeTab,
}: {
  message?: Message;
  activeTab: string;
}): SearchWebPageReference[] | undefined => {
  if (!message) {
    return;
  }

  const { payload } = message;

  if (!isResearchTaskCreationCard(payload)) {
    return;
  }

  if (activeTab === MessageTabs.NINJA_AGENT) {
    return payload.research_card?.data?.search_result?.web_page_references;
  }

  if (activeTab.includes(MessageTabs.SUPER_AGENT)) {
    return payload.research_card?.data?.deep_research_result
      ?.web_page_references;
  }
};

export const getStringUrlListFromReferences = (
  references: SearchWebPageReference[],
) => {
  if (references.length === 0) {
    return '';
  }

  const urlList = references
    .map(({ url }, index) => `[${index + 1}]: ${url}`)
    .join('\n');

  return `\n\nReferences:\n${urlList}`;
};
