import { useGetUserMetaQuery } from 'src/store/services';
import { useSession } from '../useSession';
import { useMemo } from 'react';

export const useUserMetadata = () => {
  const {
    appUser: { user_id },
  } = useSession();

  const { data } = useGetUserMetaQuery(user_id, {
    skip: !user_id,
  });

  return useMemo(
    () => ({
      metadata: data?.model_metadata || {},
    }),
    [data],
  );
};
