import { Slideshow } from '@phosphor-icons/react/dist/ssr';
import { SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './WordWrapButton.module.scss';
import { useEditorActions, useEditorState } from 'src/hooks';

export const WordWrapButton = () => {
  const { toggleIsWordWrapEnabled } = useEditorActions();
  const { isWordWrapEnabled } = useEditorState();

  const toggleWordWrap = () => {
    toggleIsWordWrapEnabled(!isWordWrapEnabled);
  };

  return (
    <Button
      color={isWordWrapEnabled ? 'tertiary' : 'transparent'}
      onClick={toggleWordWrap}
      className={styles.root}
    >
      <Slideshow size={SVG_SIZE_M} />
    </Button>
  );
};
