import { useContext, useId, useRef } from 'react';
import { FilePlus, SquareHalf } from '@phosphor-icons/react';
import {
  CITATION_REGEX_WITHOUT_SPACE,
  SVG_SIZE_M,
  SVG_SIZE_S,
} from 'src/constants';
import {
  useBreakpoint,
  useCurrentMessage,
  useEditorActions,
  useEditorState,
  useInsertContentToCodeEditor,
  useInsertContentToDocumentEditor,
  useQueryParams,
  useRightSidePanelActions,
  useRightSidePanelState,
} from 'src/hooks';
import { EditorType, RightPanelSections } from 'src/types';
import {
  getLanguageKey,
  getTextContentFromMessage,
  getWebPageReferencesFromMessage,
  sanitizeHTMLId,
} from 'src/utils';
import { Button } from 'src/v2/commonComponents/Button';
import {
  DrawerTooltipContainer,
  type DrawerTooltipContainerRef,
} from 'src/v2/commonComponents/DrawerTooltipContainer';
import { useClearEditor } from 'src/v2/components/Editor/hooks/useClearEditor';
import { useThreadContext } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/contexts/ThreadProvider';
import type { ProgrammingLanguageOptions } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/shared/types';
import { MoveToEditorToggle } from '../MoveToEditorToggle';
import styles from './MoveToEditorTool.module.scss';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import log from 'src/utils/logger';
import { SpinnerSimple } from 'src/v2/commonComponents/SpinnerSimple';

type MoveToEditorCodeTool = {
  componentType: EditorType.CODE;
  messageId?: never;
  activeTab?: never;
  disabled?: never;
  codeContent: string;
  language: ProgrammingLanguageOptions;
};

type MoveToEditorDocumentTool = {
  messageId: string;
  activeTab: string;
  disabled?: boolean;
  componentType: EditorType.DOCUMENT;
  codeContent?: never;
  language?: never;
};

type MoveToEditorToolProps = MoveToEditorCodeTool | MoveToEditorDocumentTool;

export const MoveToEditorTool = ({
  messageId,
  activeTab,
  disabled,
  componentType,
  codeContent,
  language,
}: MoveToEditorToolProps) => {
  const { codeEditorHandlerRef } = useContext(ForwardRefContext);

  const { isMobileOrTablet } = useBreakpoint();
  const { setSearchParams } = useQueryParams();

  const { currentMessage } = useCurrentMessage(messageId || '');
  const { setRightSidePanelData } = useRightSidePanelActions();
  const { isEditorPanelExpanded } = useRightSidePanelState();
  const { insertContentToDocumentEditor } = useInsertContentToDocumentEditor();
  const { insertContentToCodeEditor } = useInsertContentToCodeEditor();
  const { clearEditor } = useClearEditor();
  const { editorType, codeEditorData } = useEditorState();
  const { setCodeEditorData } = useEditorActions();
  const { searchOrCreateThread, setThreadId, isSearchInProgress } =
    useThreadContext();

  const drawerRef = useRef<DrawerTooltipContainerRef>(null);
  const uniqueId = sanitizeHTMLId(useId());

  const tooltipAnchorId = `move-to-editor-${uniqueId}-tooltip`;

  const getDocumentData = () => {
    if (componentType !== EditorType.DOCUMENT) {
      return;
    }

    const content = getTextContentFromMessage({
      message: currentMessage,
      activeTab,
    });

    const references = getWebPageReferencesFromMessage({
      message: currentMessage,
      activeTab,
    });

    const processedContent = content.replace(
      CITATION_REGEX_WITHOUT_SPACE,
      (_, num: number) =>
        `[[${num}]](${references?.[num - 1].url || 'about:blank'})`,
    );

    return {
      content: processedContent,
      references,
    };
  };

  const handleInsertDocumentContent = () => {
    const documentData = getDocumentData();

    if (!documentData) {
      return;
    }

    const { content, references } = documentData;

    insertContentToDocumentEditor({
      markdown: content,
      footnotes: references,
    });
  };

  const handleOpenedFileClick = () => {
    drawerRef.current?.onVisibilityToggle();

    if (componentType === EditorType.DOCUMENT) {
      handleInsertDocumentContent();
      return;
    }

    if (componentType === EditorType.CODE) {
      codeEditorHandlerRef?.current?.createCodeArtifact();
      insertContentToCodeEditor({ codeContent });
      return;
    }
  };

  const handleNewFileClick = async () => {
    try {
      const thread = await searchOrCreateThread(true);

      clearEditor();
      setThreadId(thread?.thread_id as string);
      setSearchParams({ document: thread?.thread_id }, { replace: true });

      drawerRef.current?.onVisibilityToggle();

      if (componentType === EditorType.DOCUMENT) {
        handleInsertDocumentContent();
      }

      if (componentType === EditorType.CODE) {
        setCodeEditorData({
          content: codeContent,
          language: getLanguageKey(language),
        });

        codeEditorHandlerRef?.current?.createCodeArtifact(
          getLanguageKey(language),
          codeContent,
        );
      }

      setRightSidePanelData({
        panelType: RightPanelSections.EDITOR,
        isExpanded: true,
        editorType: componentType,
      });
    } catch (error) {
      log.error(error);
    }
  };

  const isAddToOpenedFileDisable =
    !isEditorPanelExpanded ||
    editorType !== componentType ||
    (editorType === EditorType.CODE &&
      getLanguageKey(language) !== codeEditorData?.language);

  if (isMobileOrTablet) {
    return null;
  }

  return (
    <DrawerTooltipContainer<{ componentType: EditorType }>
      ref={drawerRef}
      id={tooltipAnchorId}
      place={componentType === EditorType.DOCUMENT ? 'right' : 'left'}
      offset={8}
      ToggleButton={MoveToEditorToggle}
      disabledToggle={disabled}
      onlyTooltip
      toggleButtonProps={{ componentType }}
    >
      <div className={styles.root}>
        <div className={styles.title}>Send to edit</div>

        <Button
          fullWidth
          color="transparent"
          onClick={handleNewFileClick}
          disabled={isSearchInProgress}
        >
          <FilePlus size={SVG_SIZE_M} />
          New file
          {isSearchInProgress && <SpinnerSimple size={SVG_SIZE_S} />}
        </Button>

        <Button
          color="transparent"
          disabled={isAddToOpenedFileDisable}
          onClick={handleOpenedFileClick}
          fullWidth
        >
          <SquareHalf size={SVG_SIZE_M} />
          Opened file
        </Button>
      </div>
    </DrawerTooltipContainer>
  );
};
