import {
  DrawerTooltipContainer,
  DrawerTooltipContainerRef,
} from 'src/v2/commonComponents/DrawerTooltipContainer';
import { useRef } from 'react';
import { NewEditorButton } from 'src/v2/components/NewContentButton/components/NewEditorButton';
import { EditorType } from 'src/types';
import { AddDocumentToggle } from '../AddDocumentToggle';
import styles from './AddDocumentControl.module.scss';

const tooltipAnchorId = 'add-document-button-tooltip-anchor';

export const AddDocumentControl = () => {
  const drawerRef = useRef<DrawerTooltipContainerRef>(null);

  const handleClick = () => {
    drawerRef.current?.onVisibilityToggle();
  };

  return (
    <DrawerTooltipContainer
      ref={drawerRef}
      id={tooltipAnchorId}
      place="bottom"
      offset={8}
      ToggleButton={AddDocumentToggle}
      onlyTooltip
    >
      <div className={styles.container}>
        <NewEditorButton
          buttonType={EditorType.DOCUMENT}
          onClick={handleClick}
        />
        <NewEditorButton buttonType={EditorType.CODE} onClick={handleClick} />
      </div>
    </DrawerTooltipContainer>
  );
};
