import styles from './RedoWithDifferentModel.module.scss';
import { ArrowsClockwise } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { TextDivider } from 'src/v2/commonComponents/TextDivider';
import { SuperAgentSelectItem } from 'src/v2/components/ChatThreadModelSelector/components/SuperAgentSelectItem';
import { SettingLLMModels } from 'src/types/settings';
import { OtherModelsButton } from 'src/v2/components/ChatThreadModelSelector/components/OtherModelsButton';
import { useBreakpoint, useVisible } from 'src/hooks';
import { NinjaSubscriptionPlan } from 'src/types';

type RedoWithDifferentModelsProps = {
  models?: SettingLLMModels;
  externalLLMModels?: SettingLLMModels;
  onClick: (model: string | null) => void;
  modelsSet?: Record<
    string,
    {
      title: string;
      description: string;
      isCapableFrom?: NinjaSubscriptionPlan;
      isNew?: boolean;
    }
  >;
};

export const RedoWithDifferentModel = ({
  models,
  onClick,
  externalLLMModels,
  modelsSet,
}: RedoWithDifferentModelsProps) => {
  const { isVisible, onVisibilitySet, onVisibilityRemove } = useVisible();
  const { isMobileOrTablet } = useBreakpoint();

  return (
    <div className={styles.root}>
      {((!isVisible && isMobileOrTablet) || !isMobileOrTablet) && (
        <>
          <div
            className={styles.rowWrapper}
            onClick={() => {
              onClick(null);
            }}
          >
            <div className={styles.labelWrapper}>
              <span className={styles.title}>Ninja Mode</span>
              <span className={styles.description}>
                Preferred AI models based on your skill settings
              </span>
            </div>
            <ArrowsClockwise size={SVG_SIZE_M} />
          </div>

          <TextDivider />

          {!!modelsSet && (
            <>
              <div className={styles.headerRow}>
                <span className={styles.headerTitle}>Ninja SuperAgent</span>
              </div>

              {Object.keys(modelsSet).map((item) => {
                const foundModel = (models || []).find(
                  (model) => model.id === item,
                );
                return (
                  <SuperAgentSelectItem
                    key={item}
                    item={item}
                    onClick={() => {
                      onClick(item);
                    }}
                    availableIn={foundModel?.availableIn}
                    showRegenerate
                  />
                );
              })}
              <TextDivider />
            </>
          )}
        </>
      )}
      <OtherModelsButton
        onClose={() => {
          // nothing to do
        }}
        handleOpenExternalModels={onVisibilitySet}
        handleCloseExternalModels={onVisibilityRemove}
        isExternalModelsMenuOpened={isVisible}
        models={externalLLMModels}
        onRegenerateClick={onClick}
        showRegenerate
      />
    </div>
  );
};
