import { $insertNodes, LexicalEditor } from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';
import { marked } from 'marked';
import { SearchWebPageReference } from 'src/types';

export const buildFootnotesHTML = (footnotes: SearchWebPageReference[]) => {
  return (
    footnotes.reduce((html, footnote, i) => {
      const index = i + 1;
      const safeUrl = footnote.url ?? '#';
      const displayTitle = footnote.title || `Footnote #${index}`;

      return `${html}
      <li>
        <a href="${safeUrl}" target="_blank" rel="noopener noreferrer">
        ${displayTitle}
        </a>
      </li>
    `;
    }, '<hr /><ol>') + '</ol>'
  );
};

export const insertFootnotesToEditor = ({
  editor,
  footnotes,
}: {
  editor: LexicalEditor;
  footnotes: SearchWebPageReference[];
}) => {
  const htmlString = buildFootnotesHTML(footnotes);
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  editor.update(() => {
    const nodes = $generateNodesFromDOM(editor, doc);
    $insertNodes(nodes);
  });
};

export const insertMarkdownToEditor = ({
  editor,
  markdown,
}: {
  editor: LexicalEditor;
  markdown: string;
}) => {
  editor.update(() => {
    const htmlString = marked(markdown);

    const parser = new DOMParser();
    const htmlDOM =
      typeof htmlString === 'string'
        ? parser.parseFromString(htmlString, 'text/html')
        : null;

    const nodes = htmlDOM ? $generateNodesFromDOM(editor, htmlDOM) : null;

    if (nodes) {
      $insertNodes(nodes);
    }
  });
};
