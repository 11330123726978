import { useMemo, useState } from 'react';
import { RenameTypeProps, RenameType } from 'src/types';
import { sanitizeOutgoingContent } from 'src/utils';
import { Button } from 'src/v2/commonComponents/Button';

type RenameFormProps = {
  itemId: string;
  itemName: string;
  itemType: RenameType;
  onRenameSubmit: (values: RenameTypeProps) => void;
};

export const RenameForm = ({
  itemId,
  itemName,
  itemType,
  onRenameSubmit,
}: RenameFormProps) => {
  const [newNameValue, setNewNameValue] = useState<string>(itemName);
  const [error, setError] = useState<string>('');

  const isDisabledSubmit = useMemo(
    () => !newNameValue || newNameValue === itemName,
    [newNameValue, itemName],
  );

  // submit data to be updated as conversation or task
  const handleSubmit = () => {
    if (onRenameSubmit) {
      onRenameSubmit({
        itemId,
        itemType,
        itemName: sanitizeOutgoingContent(newNameValue),
      } as unknown as RenameTypeProps);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length === 0) {
      setError('This field is required.');
    } else {
      if (error.length > 0) {
        setError('');
      }
    }
    const cleanedValue = value.replace(/\s+/g, '-').replace(/[^\w\s-]/g, '');
    setNewNameValue(cleanedValue);
  };

  return (
    <div className="nj-modal--rename-form--form">
      <div className="nj-form--header">
        <h2>{itemType === RenameType.TASK ? 'Rename task' : 'Rename chat'}</h2>
      </div>

      <div className="nj-form--block">
        <div className="nj-form--field">
          <input
            className="nj-form--input"
            value={newNameValue}
            onInput={handleChange}
          />
        </div>

        {error.length > 0 && <span className="nj-field-error">{error}</span>}
      </div>

      <footer className="nj-form--footer">
        <Button onClick={handleSubmit} disabled={isDisabledSubmit}>
          Submit
        </Button>
      </footer>
    </div>
  );
};
