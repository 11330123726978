import { useContext, useRef, useState } from 'react';
import { TRANSFORMERS } from '@lexical/markdown';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { SelectionAlwaysOnDisplay } from '@lexical/react/LexicalSelectionAlwaysOnDisplay';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { useEditorState, useOutsideClick } from 'src/hooks';
import { EditorType } from 'src/types';
import { CodeEditorToolbar } from './components/CodeEditorToolbar';
import { DocumentEditorToolbar } from './components/DocumentEditorToolbar';
import { EditorContainer } from './components/EditorContainer';
import { EditorHeader } from './components/EditorHeader';
import { PromptPanel } from './components/PromptPanel';
import { DocumentFloatingElementPlugin } from './plugins/DocumentFloatingElementPlugin';
import NinjaCanvasPlugin from './plugins/NinjaCanvasPlugin/NinjaCanvasPlugin';
import { CodeEditorContainer } from './components/CodeEditorContainer';
import {
  AutoLinkPlugin,
  FloatingLinkEditorPlugin,
  LinkPlugin,
} from './plugins';
import styles from './Editor.module.scss';
import './editor.scss';

export const Editor = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { floatingElementPluginRef, editorFloatingTooltipRef } =
    useContext(ForwardRefContext);

  const { editorType } = useEditorState();

  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);

  const handleFloatingElementClose = () => {
    if (floatingElementPluginRef?.current?.isOpen) {
      floatingElementPluginRef?.current?.onElementClose();
    }
  };

  const onRef = (floatingAnchorElem: HTMLDivElement) => {
    if (floatingAnchorElem !== null) {
      setFloatingAnchorElem(floatingAnchorElem);
    }
  };

  useOutsideClick(
    containerRef,
    handleFloatingElementClose,
    editorFloatingTooltipRef,
  );

  const handleInsertLinkClick = () => {
    setIsLinkEditMode((prev) => !prev);
    handleFloatingElementClose();
  };

  if (!editorType) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.mainPanel}>
        <EditorHeader />

        {editorType === EditorType.DOCUMENT ? (
          <DocumentEditorToolbar
            onInsertLinkClick={handleInsertLinkClick}
            isLinkEditMode={isLinkEditMode}
          />
        ) : (
          <CodeEditorToolbar />
        )}

        <div className={styles.container} ref={containerRef}>
          {editorType === EditorType.DOCUMENT ? (
            // TODO(olha): WIP Editor rename to doc
            <EditorContainer onRef={onRef} />
          ) : (
            <CodeEditorContainer />
          )}

          <NinjaCanvasPlugin />

          {editorType === EditorType.DOCUMENT && (
            <DocumentFloatingElementPlugin />
          )}

          {/* <OnChangePlugin onChange={onChange} /> */}
          <SelectionAlwaysOnDisplay />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />

          <AutoLinkPlugin />
          <LinkPlugin />
          {floatingAnchorElem && (
            <FloatingLinkEditorPlugin
              anchorElem={floatingAnchorElem}
              isLinkEditMode={isLinkEditMode}
              setIsLinkEditMode={setIsLinkEditMode}
            />
          )}
        </div>
      </div>
      <PromptPanel />
    </div>
  );
};
