import { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import {
  ChoicesCard,
  Choices,
  Message,
  Choice,
  OperationType,
} from 'src/types';
import {
  updateOptionSelectedValueForKey,
  formatTimestampToDateAndTime,
} from 'src/utils';
import { Button } from 'src/components/Button';
import { useUpdateTaskMessageByPDU, useTimeZone } from 'src/hooks';
import { Markdown } from 'src/components/Markdown';
import { OptionsList } from './components/OptionsList';
import { ChoiceCardEntry } from './components/ChoiceCardEntry';

type ChoicesLayoutCardProps = {
  taskId: string;
  openDefaultChoice?: boolean;
  message: Message;
  className?: string;
};

enum ChoicesNotifications {
  SUBMISSION_FAILED = 'Choice submission failed.',
  SUBMISSION_SUCCESS = 'Choice was submitted.',
}

export const ChoicesLayoutCard = ({
  openDefaultChoice = false,
  message,
  className,
}: ChoicesLayoutCardProps) => {
  const { userTimeZone } = useTimeZone();
  const [choiceCardMessage, setChoiceCardMessage] = useState<Message>(message);
  const [isCalculating, setIsCalculating] = useState<boolean>(false);

  const { payload = {}, message_id } = choiceCardMessage;
  const {
    description = '',
    choices,
    executed = false,
  } = (payload || {}) as ChoicesCard;
  const {
    multi_select = false,
    choice_list = [],
    select_key = '',
  } = (choices || {}) as Choices;

  const { isSubmitted, onSubmit } = useUpdateTaskMessageByPDU({
    successMessage: ChoicesNotifications.SUBMISSION_SUCCESS,
    errorMessage: ChoicesNotifications.SUBMISSION_FAILED,
  });

  // since only 2 tiers exist, validate top options & sub options only
  const isValidChoice = useMemo(() => {
    const { choices } = choiceCardMessage.payload as ChoicesCard;

    if (!choices || !choices.choice_list || choices.choice_list?.length === 0) {
      return false;
    }

    const topChoices = choices.choice_list.filter(
      (choice: Choice) => choice.selected,
    );

    if (topChoices.length === 0) {
      return false;
    }

    for (const topChoice of topChoices) {
      if (
        !topChoice.choices ||
        !topChoice.choices.choice_list ||
        topChoice.choices.choice_list.length === 0
      ) {
        continue;
      }

      const selectedSubChoices = topChoice.choices.choice_list?.some(
        (choice: Choice) => choice.selected,
      );

      if (!selectedSubChoices) {
        return false;
      }
    }

    return true;
  }, [choiceCardMessage]);

  useEffect(() => {
    // set default choice on load if the card was never executed
    if (!executed && openDefaultChoice) {
      const { choices } = choiceCardMessage.payload as ChoicesCard;
      const choiceList = choices?.choice_list?.map(
        (choice: Choice, index: number) => {
          if (index === 0) {
            return { ...choice, selected: true };
          }
          return choice;
        },
      );
      const choicePayload = {
        ...choiceCardMessage.payload,
        choices: { ...choices, choice_list: choiceList },
      };
      setChoiceCardMessage({
        ...choiceCardMessage,
        payload: choicePayload as ChoicesCard,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update choices card data on a choice made by user
  const handleChoiceClick = ({
    select_key,
    select_value,
    selected,
    multi_select,
  }: {
    select_key: string;
    select_value: string;
    selected: boolean;
    multi_select: boolean;
  }) => {
    if (!isCalculating) {
      setIsCalculating(true);
      const updatedPayload = updateOptionSelectedValueForKey(
        payload as ChoicesCard,
        select_key,
        select_value,
        selected,
        multi_select,
      );
      setChoiceCardMessage({
        ...choiceCardMessage,
        payload: updatedPayload as ChoicesCard,
        operation_type: OperationType.REPLACE,
      });
      setIsCalculating(false);
    }
  };

  // submit a request to store data in db via combus
  const handleChoicesSubmission = async () => {
    onSubmit(choiceCardMessage);
  };

  // show submission time on executed flag
  const submissionTime = useMemo(
    () => formatTimestampToDateAndTime(message.timestamp, userTimeZone),
    [message.timestamp, userTimeZone],
  );

  const isExecuted = executed || isSubmitted;

  return (
    <div
      className={classNames('nj-options-card', className, {
        executed: isExecuted,
      })}
    >
      <div className="nj-options-card--header">
        {description && <Markdown>{description}</Markdown>}
      </div>
      <div className="nj-options-card--body--container">
        <div className="nj-options-card--body">
          <h3>How would you like to proceed?</h3>
          <div className="nj-options-card--entries">
            {choice_list.map((choice: Choice, index: number) => {
              const {
                title = '',
                description = '',
                selected = false,
                choices,
                select_value = '',
              } = choice;
              return (
                <ChoiceCardEntry
                  key={`${message_id}-options-card-${index}`}
                  data-index={index}
                  data-key={select_key}
                  aria-selected={selected}
                  select_key={select_key}
                  selected={!!selected}
                  submitted={isExecuted}
                  multi_select={multi_select}
                  select_value={select_value || ''}
                  title={title || ''}
                  description={description || ''}
                  onChoiceClick={handleChoiceClick}
                >
                  {choices && (
                    <OptionsList
                      list={choices}
                      {...(!isExecuted && {
                        onClick: handleChoiceClick,
                      })}
                    />
                  )}
                </ChoiceCardEntry>
              );
            })}
          </div>
          {!isExecuted ? (
            <div
              className="nj-options-card--footer"
              onClick={() => handleChoicesSubmission()}
            >
              <Button
                data-e2e="options-card-submit-buton"
                className="nj-button nj-button--primary"
                disabled={!isValidChoice}
              >
                Submit
              </Button>
            </div>
          ) : (
            <i data-e2e="option-card-submited-time">
              Submitted on {submissionTime}
            </i>
          )}
        </div>
      </div>
    </div>
  );
};
