import { Button } from 'src/v2/commonComponents/Button';
import { Atom } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { useBreakpoint, useIsDeepResearchEnabled } from 'src/hooks';
import { useContext } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { QuickSkillCommand } from 'src/types';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { removeQuickSkillCommandPrefix } from 'src/utils';

export const DeepResearchToggle = () => {
  const { isMobile } = useBreakpoint();
  const { threadInputBoxRef } = useContext(ForwardRefContext);
  const { threadInputBoxValue, setThreadInputBoxValue } = useContext(
    ThreadInputBoxContext,
  );
  const { isDeepResearchEnabled, changeIsDeepResearchEnabled } =
    useIsDeepResearchEnabled();

  const handleInputChange = () => {
    let newInputValue = '';
    if (isDeepResearchEnabled) {
      newInputValue = threadInputBoxValue.slice(
        QuickSkillCommand.DEEP_RESEARCHER.length + 1,
      );
    } else {
      newInputValue = `${QuickSkillCommand.DEEP_RESEARCHER} ${removeQuickSkillCommandPrefix(threadInputBoxValue)}`;
    }

    setThreadInputBoxValue(newInputValue);
    changeIsDeepResearchEnabled(!isDeepResearchEnabled);

    threadInputBoxRef?.current?.focus();
  };

  return (
    <Button
      shape={isMobile ? 'round' : 'regular'}
      color={isDeepResearchEnabled ? 'tertiary-outline' : 'gray'}
      onClick={handleInputChange}
    >
      <Atom size={SVG_SIZE_M} />
      {!isMobile && <span>Deep Research</span>}
    </Button>
  );
};
