import { HumanMessage } from '@langchain/core/messages';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useContext } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { v4 as uuidv4 } from 'uuid';
import { useGraphContext } from '../../plugins/NinjaCanvasPlugin/contexts/GraphContext';
import { convertToOpenAIFormat } from '../../plugins/NinjaCanvasPlugin/lib/convert_messages';
import {
  getEditorMarkdown,
  getSelectedContent,
  getTextBlock,
} from '../../utils';
import { PromptForm } from '../PromptForm';

import type {
  ArtifactMarkdownV3,
  ArtifactV3,
} from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/shared/types';

export const FloatingPrompt = () => {
  const [editor] = useLexicalComposerContext();
  const { graphData } = useGraphContext();
  const { setMessages, streamMessage, artifact } = graphData;
  const { floatingElementPluginRef } = useContext(ForwardRefContext);

  const handleSubmit = async (userPrompt: string) => {
    const selectedContent = getSelectedContent(editor);
    const textBlock = await getTextBlock(editor);
    const fullContent = getEditorMarkdown(editor);

    const humanMessage = new HumanMessage({
      content: userPrompt,
      id: uuidv4(),
    });
    setMessages((prevMessages) => [...prevMessages, humanMessage]);

    floatingElementPluginRef?.current?.onElementClose();

    editor.read(() => {
      const rootElement = editor.getRootElement();
      if (rootElement) {
        rootElement.focus();
      }
    });

    await streamMessage({
      messages: [convertToOpenAIFormat(humanMessage)],
      ...(!!artifact && {
        artifact: {
          currentIndex: 1,
          contents: [
            {
              type: 'text',
              title: 'Document',
              index: 1,
              fullMarkdown: fullContent,
            } as ArtifactMarkdownV3,
          ],
        } as ArtifactV3,
      }),
      ...(selectedContent && {
        highlightedText: {
          fullMarkdown: fullContent,
          markdownBlock: textBlock,
          selectedText: selectedContent,
        },
      }),
    });
  };

  return (
    <PromptForm
      onSubmit={handleSubmit}
      placeholder="Add more detail about this"
    />
  );
};
