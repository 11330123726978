import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  CONVERTED_TEXT_TO_FILE_NAME,
  DEFAULT_CHAT_ID,
  MAX_NUMBER_OF_WORDS,
  REG_EXP_FOR_WORD_SEPARATORS,
  TEMPORARY_CONVERSATION_ID,
} from 'src/constants';
import {
  useConversationParams,
  useQueryParams,
  useSession,
  useThreadActions,
} from 'src/hooks';
import { useAppDispatch } from 'src/store';
import AudioContext from 'src/contexts/AudioContext';
import {
  useGetURLForFileUploadMutation,
  useSendMessagePDUMutation,
} from 'src/store/services';
import {
  addConversation,
  addMessageToConversation,
  replaceConversationChatTemporaryMessageId,
} from 'src/store/updateQueries';
import {
  Conversation,
  ExtendedMessage,
  GTMEvent,
  isMessage,
  Message,
  PDUMessage,
  RequestPayloadType,
} from 'src/types';
import {
  convertConversationIdToSlug,
  sendGTMEvent,
  toPDUMessage,
} from 'src/utils';
import {
  performanceMarkerEnd,
  performanceMarkerStart,
} from 'src/utils/performanceMarkers';
import log from 'src/utils/logger';
import { useContext } from 'react';
import { TextRequestPayload } from 'src/types/models/TextRequestPayload';
import { useImageMaskingPanelActions } from './imageMaskingPanelHooks';
import { initTaskMetric } from 'src/utils/metrics';
import { useActiveModel } from 'src/hooks/userSettingsHooks/useActiveModel';

const ERROR_MESSAGE_FOR_TEXT_FILE = 'Error processing the request';

// TODO(olha): move to the App all dayjs.extend()
dayjs.extend(utc);

export const useFetchUserInput = () => {
  const dispatch = useAppDispatch();

  const { microphoneTriggerRef } = useContext(AudioContext);

  const [sendPDUMessage] = useSendMessagePDUMutation();
  const [getFileUploadUrl, { isError }] = useGetURLForFileUploadMutation();

  const {
    appUser: { user_id, location },
  } = useSession();
  const { setSearchParams } = useQueryParams();
  const { updateThreadStatus } = useThreadActions();
  const { currentConversationId } = useConversationParams();
  const { setImageMaskingIsLoading } = useImageMaskingPanelActions();
  const { activeModel } = useActiveModel();

  const newStatusRegistry = {
    ignoreMessages: false,
    isSubmitHappened: true,
  };

  const sendMessage = async ({
    message,
    conversationId,
    temporaryMessageId,
  }: {
    message: PDUMessage;
    conversationId: string;
    temporaryMessageId: string;
  }) => {
    try {
      const messageTimestamp = Date.now();

      const result = await sendPDUMessage({
        message: message,
        apiContext: {
          conversation_id: conversationId,
        },
      }).unwrap();

      const messageFromPDU = JSON.parse(result.payload);

      if (!isMessage(messageFromPDU)) {
        log.error(`Incorrect data type for socket ${result.payload}`);
        return;
      }

      initTaskMetric({
        conversation_id: messageFromPDU.conversation_id,
        start_time: messageTimestamp,
        user_id: user_id,
        location: location?.country_code || 'na',
      });

      if (
        currentConversationId === DEFAULT_CHAT_ID &&
        messageFromPDU.conversation_id
      ) {
        const slug = convertConversationIdToSlug(
          messageFromPDU.conversation_id,
        );
        setSearchParams({ conv: slug }, { replace: true });
        updateThreadStatus({
          threadId: messageFromPDU.conversation_id,
          statusRegistry: newStatusRegistry,
        });
      }
      sendGTMEvent(GTMEvent.USER_SENT_QUERY, {
        message_id: messageFromPDU.message_id || '',
      });

      dispatch(
        replaceConversationChatTemporaryMessageId({
          temporaryMessageId,
          message: messageFromPDU,
        }),
      );
    } catch (error) {
      log.error('Request error:', error);
      updateThreadStatus({
        threadId: currentConversationId,
        statusRegistry: { ignoreMessages: false, isSubmitHappened: false },
      });
    }
  };

  const addMessageToThread = async (message: Message) => {
    const { conversation_id, ...rest } = message;

    let apiMessage = {
      conversation_id:
        conversation_id === DEFAULT_CHAT_ID ? '' : conversation_id,
      ...rest,
    };

    const timestamp = dayjs().unix();
    const temporaryMessageId = `new-message-${timestamp}`;
    const temporaryConversationId = `${TEMPORARY_CONVERSATION_ID}-${timestamp}`;

    const userInputTextPayload = (message.payload_list || []).find(
      (item) => item.payload_type === RequestPayloadType.TEXT,
    );

    const userInputText = userInputTextPayload
      ? (userInputTextPayload as TextRequestPayload).content
      : message.content;

    const shouldConvertTextToFile =
      userInputText.split(REG_EXP_FOR_WORD_SEPARATORS).length >
      MAX_NUMBER_OF_WORDS;

    const newMessage: ExtendedMessage = {
      ...message,
      message_id: temporaryMessageId,
      content: shouldConvertTextToFile
        ? 'Processing the request'
        : message.content,
      isBlinking: shouldConvertTextToFile,
    };

    const newConversation: Conversation = {
      user_id,
      conversation_id: temporaryConversationId,
      conversation_hash: '#new-chat',
      messages: [newMessage],
      model_ids: activeModel ? [activeModel] : undefined,
    };

    if (currentConversationId === DEFAULT_CHAT_ID) {
      dispatch(addConversation(newConversation));
      setSearchParams(
        {
          conv: temporaryConversationId,
        },
        { replace: true },
      );
      updateThreadStatus({
        threadId: temporaryConversationId,
        statusRegistry: newStatusRegistry,
      });

      microphoneTriggerRef?.current?.stopRecording(true);
    } else {
      dispatch(addMessageToConversation(newMessage));
      updateThreadStatus({
        threadId: currentConversationId,
        statusRegistry: newStatusRegistry,
      });
    }

    if (shouldConvertTextToFile) {
      const newFile = new File([userInputText], CONVERTED_TEXT_TO_FILE_NAME, {
        type: 'text/plain',
      });

      const payloadListForErrorCase = (apiMessage.payload_list || []).map(
        (item) => {
          if (item.payload_type === RequestPayloadType.TEXT) {
            return {
              ...item,
              content: ERROR_MESSAGE_FOR_TEXT_FILE,
            };
          }
          return item;
        },
      );

      try {
        const result = await getFileUploadUrl({
          user_id,
          file_name: CONVERTED_TEXT_TO_FILE_NAME,
          content_type: 'text/plain',
          apiContext: {
            user_id,
            conversation_id: apiMessage.conversation_id,
          },
        }).unwrap();

        if (!!result) {
          const formData = new FormData();
          for (const [key, value] of Object.entries(result.form_data.fields)) {
            formData.append(key, value);
          }
          formData.append('file', newFile);

          const uploadFileUrl = result.form_data?.url;

          await fetch(uploadFileUrl, {
            method: 'POST',
            body: formData,
            mode: 'cors',
          })
            .then(async (response) => {
              if (response.ok) {
                apiMessage = {
                  ...apiMessage,
                  content: '',
                  payload_list: [
                    ...(apiMessage.payload_list || []).filter(
                      (item) => item.payload_type !== RequestPayloadType.TEXT,
                    ),
                    {
                      payload_type: RequestPayloadType.FILE,
                      original_filename: CONVERTED_TEXT_TO_FILE_NAME,
                      converted_filename: result.filename,
                    },
                  ],
                };
              } else {
                apiMessage = {
                  ...apiMessage,
                  content: ERROR_MESSAGE_FOR_TEXT_FILE,
                  payload_list: payloadListForErrorCase,
                };
              }
            })
            .catch(() => {
              apiMessage = {
                ...apiMessage,
                content: ERROR_MESSAGE_FOR_TEXT_FILE,
                payload_list: payloadListForErrorCase,
              };
            });
        } else if (isError) {
          apiMessage = {
            ...apiMessage,
            content: ERROR_MESSAGE_FOR_TEXT_FILE,
            payload_list: payloadListForErrorCase,
          };
        }
      } catch (e) {
        apiMessage = {
          ...apiMessage,
          content: ERROR_MESSAGE_FOR_TEXT_FILE,
          payload_list: payloadListForErrorCase,
        };
      }
    }

    setImageMaskingIsLoading(false);

    await sendMessage({
      message: toPDUMessage(JSON.stringify(apiMessage), user_id),
      conversationId: apiMessage.conversation_id || '',
      temporaryMessageId,
    });
  };

  const fetchAiData = async (newMessage: Message) => {
    performanceMarkerStart('ce-response-time');
    await addMessageToThread(newMessage);
    performanceMarkerEnd('ce-response-time', undefined);
  };

  return { fetchAiData };
};
