import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

export type StatusRegistry = {
  ignoreMessages?: boolean;
  isSubmitHappened?: boolean;
};

export type ThreadsStatusRegistry = Record<string, StatusRegistry>;

export interface ThreadsStatusRegistryPayload {
  threadId: string;
  statusRegistry: StatusRegistry;
}

export interface ThreadState {
  threadsStatusRegistry: ThreadsStatusRegistry;
}

const initialState = {
  isSubmitHappened: false,
  // (olha): this is a handing of Stop Input button. It might don't work
  threadsStatusRegistry: {},
} as ThreadState;

export const threadSlice = createSlice({
  name: 'thread',
  initialState,
  reducers: {
    setThreadStatus: (
      state,
      action: PayloadAction<ThreadsStatusRegistryPayload>,
    ) => {
      state.threadsStatusRegistry = {
        ...state.threadsStatusRegistry,
        [action.payload.threadId]: {
          ...(state.threadsStatusRegistry[action.payload.threadId] || {}),
          ...action.payload.statusRegistry,
        },
      };
    },
  },
});

export const threadState = (state: RootState) => state.thread as ThreadState;

export const threadStatusRegistryState = (state: RootState) =>
  state.thread.threadsStatusRegistry as ThreadsStatusRegistry;

export const { setThreadStatus } = threadSlice.actions;

export default threadSlice.reducer;
