import { useEffect, useRef, useState } from 'react';
import {
  DrawerTooltipContainer,
  DrawerTooltipContainerRef,
} from 'src/v2/commonComponents/DrawerTooltipContainer';
import { Button } from 'src/v2/commonComponents/Button';
import { useCurrentEditorThread, useEditorControl } from 'src/hooks';
import { VersioningToggle } from '../VersioningToggle';
import styles from './VersioningControl.module.scss';

const anchorId = 'versioning-control-tooltip-anchor';

export const VersioningControl = () => {
  const drawerRef = useRef<DrawerTooltipContainerRef>(null);

  const { currentEditorArtifacts, getArtifactContentByIndex } =
    useCurrentEditorThread();
  const { handleInsertArtifactContentToEditor } = useEditorControl();

  const [selectedVersionIndex, setSelectedVersionIndex] = useState<number>(0);

  useEffect(() => {
    setSelectedVersionIndex(currentEditorArtifacts.length - 1);
  }, [currentEditorArtifacts]);

  const handleClick = (index: number) => () => {
    drawerRef.current?.onVisibilityToggle();

    setSelectedVersionIndex(index);

    const contentByEditorType = getArtifactContentByIndex({ index });

    handleInsertArtifactContentToEditor(contentByEditorType);
  };

  return (
    <DrawerTooltipContainer
      ref={drawerRef}
      id={anchorId}
      place="bottom-end"
      offset={8}
      onlyTooltip
      classNameTooltip={styles.tooltip}
      ToggleButton={VersioningToggle}
      disabledToggle={currentEditorArtifacts.length < 2}
    >
      <div className={styles.container}>
        {currentEditorArtifacts.map((_, index) => {
          const isLatestElement = index === currentEditorArtifacts.length - 1;
          const isSelected = selectedVersionIndex === index;

          return (
            <Button
              className={styles.button}
              color={isSelected ? 'tertiary' : 'transparent'}
              onClick={handleClick(index)}
              key={index}
            >
              <span>
                {isLatestElement ? 'Latest version' : `Version ${index + 1}`}
              </span>
            </Button>
          );
        })}
      </div>
    </DrawerTooltipContainer>
  );
};
