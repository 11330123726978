import { FormSwitch } from 'src/components/FormSwitch';
import { ManageAccountContentPage } from '../ManageAccountContentPage/ManageAccountContentPage';
import styles from './General.module.scss';
import { useForm, useWatch } from 'react-hook-form';
import { FormProvider } from 'src/components/FormProvider';
import { useLazyGetUserByIdQuery } from 'src/store/services';
import { useSession, useUserData } from 'src/hooks';
import { Select } from 'src/components/Select';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { ArchiveAll } from 'src/pages/ManageTasksChatPage/components/AccessAndDataSettings/components/ArchiveAll';
import { DisconnectAccess } from 'src/pages/ManageTasksChatPage/components/AccessAndDataSettings/components/DisconnectAccess';
import { DeleteAccount } from 'src/pages/ManageTasksChatPage/components/AccessAndDataSettings/components/DeleteAccount';
import { useCanConnectToGoogle } from 'src/hooks/useCanConnectToGoogle';
import { DEFAULT_LANGUAGE } from 'src/constants';

interface GeneralFormData {
  is_automatically_timezone?: boolean;
}

type TimeZoneOption = {
  value: string;
  label: string;
  time: string;
};

export const General = () => {
  const { appUser } = useSession();
  const { updateUserData } = useUserData();

  const { user_id } = appUser;

  const canConnectToGoogle = useCanConnectToGoogle();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore todo remove with "typescript" update - supportedValuesOf supported by TS version >= 5.1
  const listOfTimezones = Intl.supportedValuesOf('timeZone');

  const sortedListOfTimezones = useMemo(() => {
    return listOfTimezones
      .map((item: string) => ({
        label: `${item} ${new Date().toLocaleTimeString('en-US', {
          timeZone: item,
          hour12: true,
          timeStyle: 'short',
        })}`,
        value: item,
        time: new Date().toLocaleString('en-US', { timeZone: item }),
      }))
      .slice()
      .sort((a: TimeZoneOption, b: TimeZoneOption) =>
        dayjs(a.time).isAfter(dayjs(b.time)) ? 1 : -1,
      );
  }, [listOfTimezones]);

  const [trigger] = useLazyGetUserByIdQuery();

  const methods = useForm<GeneralFormData>({
    defaultValues: async () => {
      const result = await trigger(user_id, true);
      const { data } = result;
      return {
        assistant_audio_language:
          data?.settings?.video_language_setting?.avatar_audio_locale ||
          DEFAULT_LANGUAGE,
        close_caption_language:
          data?.settings?.video_language_setting?.avatar_cc_locale ||
          DEFAULT_LANGUAGE,
        is_automatically_timezone:
          data?.settings?.timezone_setting?.is_automatically_timezone !==
          undefined
            ? data?.settings?.timezone_setting?.is_automatically_timezone
            : true,
        timezone:
          data?.settings?.timezone_setting?.timezone ||
          Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    },
  });

  const { control } = methods;
  const fieldValues = useWatch({ control });

  const handleChangeCheckboxValue = (value: boolean) => {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    updateUserData(
      {
        settings: {
          ...appUser.settings,
          timezone_setting: {
            timezone: currentTimezone,
            is_automatically_timezone: value,
          },
        },
      },
      false,
    );
  };

  return (
    <FormProvider<GeneralFormData> methods={methods}>
      <ManageAccountContentPage
        title="General"
        subtitle="Manage general settings"
      >
        <div className={styles.root}>
          <h4 className={styles.title}>Timezone</h4>

          <div className={styles.field}>
            <label className={styles.label}>Set timezone automatically</label>
            <FormSwitch
              name="is_automatically_timezone"
              onChangeHandler={handleChangeCheckboxValue}
            />
          </div>

          <div className={`${styles.field} ${styles.select}`}>
            <Select<string>
              aria-label="location-time"
              name="timezone"
              isSearchable
              isDisabled={fieldValues.is_automatically_timezone}
              options={sortedListOfTimezones}
              onChangeHandler={(value) =>
                updateUserData({
                  settings: {
                    ...appUser.settings,
                    timezone_setting: {
                      timezone: value,
                      is_automatically_timezone: false,
                    },
                  },
                })
              }
            />
          </div>

          <hr className={styles.divider} />

          <h4 className={styles.title}>Data control</h4>
          <h5 className={styles.subtitle}>Manage your data preferences.</h5>

          <ArchiveAll />

          {canConnectToGoogle && <DisconnectAccess />}

          <DeleteAccount />

          <hr className={styles.divider} />
        </div>
      </ManageAccountContentPage>
    </FormProvider>
  );
};
