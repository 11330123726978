import { useCallback, useMemo } from 'react';
import {
  useAppDispatch,
  setCodeEditorData,
  setIsWordWrapEnabled,
  setEditorIsDarkTheme,
  setShouldResetEditorContent,
} from 'src/store';
import { CodeEditorData } from 'src/types';

export const useEditorActions = () => {
  const dispatch = useAppDispatch();

  const handleSetCodeEditorData = useCallback(
    (value: CodeEditorData | null) => {
      dispatch(setCodeEditorData(value));
    },
    [dispatch],
  );

  const handleToggleIsWordWrapEnabled = useCallback(
    (value: boolean) => {
      dispatch(setIsWordWrapEnabled(value));
    },
    [dispatch],
  );

  const handleSetEditorIsDarkTheme = useCallback(
    (value: boolean) => {
      dispatch(setEditorIsDarkTheme(value));
    },
    [dispatch],
  );

  const handleSetShouldResetEditorContent = useCallback(
    (value: boolean) => {
      dispatch(setShouldResetEditorContent(value));
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      setCodeEditorData: handleSetCodeEditorData,
      toggleIsWordWrapEnabled: handleToggleIsWordWrapEnabled,
      setEditorIsDarkTheme: handleSetEditorIsDarkTheme,
      setShouldResetEditorContent: handleSetShouldResetEditorContent,
    }),
    [
      handleSetCodeEditorData,
      handleToggleIsWordWrapEnabled,
      handleSetEditorIsDarkTheme,
      handleSetShouldResetEditorContent,
    ],
  );
};
