/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TaskSkill {
  CHITCHAT = 'chitchat',
  FUN = 'fun',
  OUTREACH = 'outreach',
  PHISHING = 'phishing',
  RESEARCH = 'research',
  DEEP_RESEARCH = 'deep_research',
  CODING = 'coding',
  RESERVATION = 'reservation',
  SCHEDULING = 'scheduling',
  STATUS = 'status',
  SUMMARIZE = 'summarize',
  IMAGE = 'image',
  AUDIO = 'audio',
  VIDEO = 'video',
  DOCUMENT_QA = 'document_qa',
  UNDEFINED = 'undefined',
}
