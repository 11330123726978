import { ArrowLeft, CaretRight } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { useBreakpoint } from 'src/hooks';
import styles from './OtherModelsButton.module.scss';
import { OtherModelsSelector } from 'src/v2/components/OtherModelsSelector';
import { SettingLLMModels } from 'src/types/settings';

interface OtherModelsButtonProps {
  onClose: () => void;
  handleOpenExternalModels: () => void;
  handleCloseExternalModels: () => void;
  isExternalModelsMenuOpened: boolean;
  models?: SettingLLMModels;
  showRegenerate?: boolean;
  onRegenerateClick?: (value: string) => void;
}

export const OtherModelsButton = ({
  onClose,
  isExternalModelsMenuOpened,
  handleOpenExternalModels,
  handleCloseExternalModels,
  models,
  showRegenerate,
  onRegenerateClick,
}: OtherModelsButtonProps) => {
  const { isMobileOrTablet } = useBreakpoint();

  const handleClose = () => {
    onClose();
    handleCloseExternalModels();
  };

  return (
    <div
      className={styles.rowWrapper}
      onMouseEnter={!isMobileOrTablet ? handleOpenExternalModels : undefined}
      onMouseLeave={!isMobileOrTablet ? handleCloseExternalModels : undefined}
    >
      {((isMobileOrTablet && !isExternalModelsMenuOpened) ||
        !isMobileOrTablet) && (
        <div
          className={styles.labelWrapper}
          onClick={
            isMobileOrTablet && !isExternalModelsMenuOpened
              ? handleOpenExternalModels
              : undefined
          }
        >
          <span className={styles.title}>External AI Models</span>
          <CaretRight size={SVG_SIZE_M} />
        </div>
      )}

      {isMobileOrTablet && isExternalModelsMenuOpened && (
        <div
          className={styles.labelBackWrapper}
          onClick={handleCloseExternalModels}
        >
          <ArrowLeft size={SVG_SIZE_M} />
          <span className={styles.title}>External AI Models</span>
        </div>
      )}

      {((isMobileOrTablet && isExternalModelsMenuOpened) ||
        !isMobileOrTablet) && (
        <OtherModelsSelector
          onClose={handleClose}
          isOpen={isExternalModelsMenuOpened}
          modelsList={models}
          showRegenerate={showRegenerate}
          onRegenerateClick={onRegenerateClick}
        />
      )}
    </div>
  );
};
