import styles from './CodeEditorToolbar.module.scss';
import { VerticalDivider } from 'src/v2/commonComponents/VerticalDivider';
import { useRightSidePanelState } from 'src/hooks';
import classNames from 'classnames';
import { FullWidthToggle } from '../FullWidthToggle';
import { CodeLanguageControl } from '../CodeLanguageControl';
import { useRef } from 'react';
import { WordWrapButton } from '../WordWrapButton';
import { DarkThemeButton } from '../DarkThemeButton';
import { UndoRedoCoderButtons } from '../UndoRedoCoderButtons';

export const CodeEditorToolbar = () => {
  const toolbarRef = useRef<HTMLDivElement>(null);

  const { isRightPanelFullWidth } = useRightSidePanelState();

  return (
    <div
      ref={toolbarRef}
      className={classNames(styles.root, {
        [styles.alignCenter]: isRightPanelFullWidth,
      })}
    >
      <div className={styles.wrapper}>
        <UndoRedoCoderButtons />

        <VerticalDivider />

        <CodeLanguageControl />

        {!isRightPanelFullWidth && <VerticalDivider />}

        <WordWrapButton />
        <DarkThemeButton />
      </div>

      <FullWidthToggle toggleType="ninja-button" />
    </div>
  );
};
