import { Icon } from 'src/components/Icon';
import { SVG_SIZE_M, temporaryNewModelsArray } from 'src/constants';
import { ExternalModelItem } from './components/ExternalModelItem';
import { useActiveModel } from 'src/hooks/userSettingsHooks/useActiveModel';
import { SettingLLMModels } from 'src/types/settings';
import { useMemo } from 'react';
import styles from './TextModeList.module.scss';

interface TextModelListProps {
  onClick: (id: string) => void;
  modelsList: SettingLLMModels;
  showRegenerate?: boolean;
}

export const TextModelList = ({
  onClick,
  modelsList,
  showRegenerate,
}: TextModelListProps) => {
  const { activeModel } = useActiveModel();

  const models = useMemo(() => {
    return modelsList.map((model) => {
      return {
        id: model.id,
        icon: model.iconName ? (
          <Icon type={model.iconName} size={SVG_SIZE_M} />
        ) : (
          <></>
        ),
        title: model.title,
        selected: activeModel === model.id,
        availableIn: model.availableIn,
        onClick: () => onClick(model.id),
        isNew: temporaryNewModelsArray.includes(model.id), // todo it is temporary till BE adds field "new" to metadata
      };
    });
  }, [modelsList, activeModel, onClick]);

  return (
    <div className={styles.root}>
      {models.map((item) => (
        <ExternalModelItem
          key={item.id}
          {...item}
          showRegenerate={showRegenerate}
        />
      ))}
    </div>
  );
};
