import { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import { Sparkle, X } from '@phosphor-icons/react';
import { Button } from 'src/v2/commonComponents/Button';
import { ReactTooltip } from 'src/v2/commonComponents/ReactTooltip';
import { useOutsideClick, useVisible } from 'src/hooks';
import { PromptLoadingReason, SVG_SIZE_M } from 'src/constants';
import styles from './ImproveAndTranslateButton.module.scss';
import { TranslatePromptButton } from 'src/pages/ManageTasksChatPage/components/ChatForm/components/TranslatePromptButton';
import { PromptButton } from 'src/pages/ManageTasksChatPage/components/ChatForm/components/PromptButton';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { SpinnerSimple } from 'src/v2/commonComponents/SpinnerSimple';
import { PortalWrapper } from 'src/components/PortalWrapper';
import { UndoPromptButton } from '../UndoPromptButton';
import { TranslatePromptModal } from 'src/pages/ManageTasksChatPage/components/ChatForm/components/TranslatePromptModal';
import { TextDivider } from 'src/v2/commonComponents/TextDivider';
import { PromptLibrary } from 'src/components/PromptLibrary';
import { PromptLibraryModal } from 'src/components/PromptLibrary/components/PromptLibraryModal';

const PROMPT_ANCHOR_ID = 'improve-and-translate-input-trigger';
const TOOLTIP_ANCHOR_ID = 'prompt-translate-trigger';

export const ImproveAndTranslateButton = () => {
  const defaultLanguage = 'en';

  const [improvedPrompt, setImprovedPrompt] = useState<string>('');
  const [translatedText, setTranslatedText] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] =
    useState<string>(defaultLanguage);

  const containerRef = useRef<HTMLDivElement>(null);
  const undoTriggerRef = useRef<boolean>(false);

  const { isVisible, onVisibilityRemove, onVisibilityToggle } = useVisible();
  const {
    isVisible: isTranslationModalVisible,
    onVisibilitySet: onTranslationModalVisibilitySet,
    onVisibilityRemove: onTranslationModalVisibilityRemove,
  } = useVisible();

  const {
    isVisible: isVisiblePromptLibraryModal,
    onVisibilitySet: onPromptLibraryModalVisibilitySet,
    onVisibilityRemove: onPromptLibraryModalVisibilityRemove,
  } = useVisible();

  const { threadInputBoxRef } = useContext(ForwardRefContext);
  const {
    threadInputBoxValue,
    setTemporaryInputValue,
    setPromptLoading,
    temporaryInputValue,
    setThreadInputBoxValue,
    promptLoading,
    promptModificationReason,
  } = useContext(ThreadInputBoxContext);

  const handleOutsideClick = () => {
    if (isVisible) {
      onVisibilityRemove();
    }
  };

  useOutsideClick(containerRef, handleOutsideClick);

  const handleReturnInputToFocus = () => {
    if (threadInputBoxRef?.current) {
      threadInputBoxRef.current.focus();
    }
  };

  const handleUndo = () => {
    if (promptLoading) {
      undoTriggerRef.current = true;
    }

    setPromptLoading(false);
    setThreadInputBoxValue(temporaryInputValue);
    setTemporaryInputValue('');
    handleReturnInputToFocus();
  };

  const isPromptButtonLoading =
    promptModificationReason === PromptLoadingReason.IMPROVING && promptLoading;

  const isTranslateButtonLoading =
    promptModificationReason === PromptLoadingReason.TRANSLATING &&
    promptLoading;

  const showUndoPromptButton =
    promptModificationReason === PromptLoadingReason.IMPROVING &&
    !!temporaryInputValue &&
    threadInputBoxValue.trim() === improvedPrompt.trim();

  const showUndoTranslateButton =
    promptModificationReason === PromptLoadingReason.TRANSLATING &&
    !!temporaryInputValue &&
    threadInputBoxValue.trim() === translatedText.trim();

  return (
    <div className={styles.root}>
      <div id={PROMPT_ANCHOR_ID} ref={containerRef}>
        {isPromptButtonLoading || isTranslateButtonLoading ? (
          <Button
            className={styles.loadingButton}
            shape="round"
            color="transparent"
            onClick={handleUndo}
          >
            <X size={SVG_SIZE_M} />
            <SpinnerSimple className={styles.spinner} />
          </Button>
        ) : (
          <Button
            className={classNames({
              [styles.active]: isVisible,
            })}
            id={TOOLTIP_ANCHOR_ID}
            shape="round"
            color="transparent"
            onClick={onVisibilityToggle}
          >
            <Sparkle size={SVG_SIZE_M} />
          </Button>
        )}

        <PortalWrapper>
          <ReactTooltip
            place="bottom-end"
            id={PROMPT_ANCHOR_ID}
            isOpen={isVisible}
            offset={0}
            className={styles.tooltip}
          >
            <PromptButton
              onClose={onVisibilityRemove}
              undoTriggerRef={undoTriggerRef}
              setImprovedPrompt={setImprovedPrompt}
            />
            <TranslatePromptButton
              onClose={onVisibilityRemove}
              setIsModalVisible={onTranslationModalVisibilitySet}
            />

            <UndoPromptButton
              showUndoTranslateButton={showUndoTranslateButton}
              showUndoPromptButton={showUndoPromptButton}
              isLoading={isPromptButtonLoading || isTranslateButtonLoading}
              handleUndo={handleUndo}
            />

            <TextDivider />

            <PromptLibrary onOpenModal={onPromptLibraryModalVisibilitySet} />
          </ReactTooltip>
        </PortalWrapper>
      </div>

      <PortalWrapper>
        <TranslatePromptModal
          onClose={onVisibilityRemove}
          undoTriggerRef={undoTriggerRef}
          setTranslatedText={setTranslatedText}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          isModalOpen={isTranslationModalVisible}
          removeModalVisibility={onTranslationModalVisibilityRemove}
        />
      </PortalWrapper>
      <PromptLibraryModal
        isOpen={isVisiblePromptLibraryModal}
        onClose={onPromptLibraryModalVisibilityRemove}
      />
    </div>
  );
};
