import { useCallback, useEffect, useRef, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import styles from './DocumentEditorToolbar.module.scss';
import {
  ArrowUUpLeft,
  ArrowUUpRight,
  TextB,
  TextItalic,
  TextUnderline,
  LinkSimpleHorizontal,
} from '@phosphor-icons/react';
import { ToolbarButton } from '../ToolbarButton';
import { VerticalDivider } from 'src/v2/commonComponents/VerticalDivider';
import { useRightSidePanelState } from 'src/hooks';
import classNames from 'classnames';
import { FullWidthToggle } from '../FullWidthToggle';
import { Button } from 'src/v2/commonComponents/Button';
import { SVG_SIZE_M } from 'src/constants';

const LOW_PRIORITY = 1;

interface DocumentEditorToolbarProps {
  isLinkEditMode: boolean;
  onInsertLinkClick: () => void;
}

export const DocumentEditorToolbar = ({
  isLinkEditMode,
  onInsertLinkClick,
}: DocumentEditorToolbarProps) => {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef<HTMLDivElement>(null);

  const { isRightPanelFullWidth } = useRightSidePanelState();

  const [canUndo, setCanUndo] = useState<boolean>(false);
  const [canRedo, setCanRedo] = useState<boolean>(false);
  const [isBold, setIsBold] = useState<boolean>(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
    }
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar();
          return false;
        },
        LOW_PRIORITY,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LOW_PRIORITY,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LOW_PRIORITY,
      ),
    );
  }, [editor, $updateToolbar]);

  return (
    <div
      ref={toolbarRef}
      className={classNames(styles.root, {
        [styles.alignCenter]: isRightPanelFullWidth,
      })}
    >
      <div className={styles.wrapper}>
        <ToolbarButton
          command={UNDO_COMMAND}
          payload={undefined}
          Icon={ArrowUUpLeft}
          disabled={!canUndo}
        />

        <ToolbarButton
          command={REDO_COMMAND}
          payload={undefined}
          Icon={ArrowUUpRight}
          disabled={!canRedo}
        />

        <VerticalDivider />

        <ToolbarButton
          command={FORMAT_TEXT_COMMAND}
          payload="bold"
          Icon={TextB}
          isActive={isBold}
          bold
        />

        <ToolbarButton
          command={FORMAT_TEXT_COMMAND}
          payload="italic"
          Icon={TextItalic}
          isActive={isItalic}
        />

        <ToolbarButton
          command={FORMAT_TEXT_COMMAND}
          payload="underline"
          Icon={TextUnderline}
          isActive={isUnderline}
        />

        <Button
          onClick={onInsertLinkClick}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          color={isLinkEditMode ? 'tertiary' : 'transparent'}
          shape="round"
          style={{ width: 'min-content' }}
        >
          <LinkSimpleHorizontal size={SVG_SIZE_M} />
        </Button>

        {!isRightPanelFullWidth && <VerticalDivider />}
      </div>

      <FullWidthToggle toggleType="ninja-button" />
    </div>
  );
};
