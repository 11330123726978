import { useContext, useMemo } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  useCurrentConversationTasks,
  useCurrentMessage,
  useTaskById,
} from 'src/hooks';
import { ListBullets } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import {
  ChitChatCard,
  GTMEvent,
  MessageType,
  ResearchTaskCreationCard,
} from 'src/types';
import { getTaskIdFromMessage, sendGTMEvent } from 'src/utils';
import styles from './TaskDetailsButton.module.scss';
import { Button } from 'src/v2/commonComponents/Button';

interface TaskDetailsButtonProps {
  messageId: string;
}

export const TaskDetailsButton = ({ messageId }: TaskDetailsButtonProps) => {
  const { currentMessage } = useCurrentMessage(messageId);

  const { setCurrentTaskId } = useTaskById();
  const { onToggleVisibleTaskViewModal } = useContext(SessionContext);
  const { getTaskFromConversationById } = useCurrentConversationTasks();

  const taskId = useMemo(
    () => getTaskIdFromMessage(currentMessage),
    [currentMessage],
  );
  const taskState = useMemo(
    () => (taskId ? getTaskFromConversationById(taskId)?.state : undefined),
    [taskId, getTaskFromConversationById],
  );

  const isContentStartedToCome = useMemo(() => {
    if (
      currentMessage?.message_type === MessageType.RESEARCH_TASK_CREATION_CARD
    ) {
      const data = (currentMessage?.payload as ResearchTaskCreationCard)
        .research_card?.data;
      return (
        Boolean(data?.search_result?.content?.content) ||
        Boolean(data?.search_result?.summary) ||
        Boolean(data?.deep_research_result?.content?.content) ||
        Boolean(data?.generative_ai_result?.summary)
      );
    }
    if (currentMessage?.message_type === MessageType.CHAT_CARD) {
      const data = (currentMessage?.payload as ChitChatCard).data;
      return (
        Boolean(data?.ninja_model_output) ||
        Boolean(
          Object.values(data?.referenced_model_summary?.references || {})[0]
            ?.content,
        )
      );
    }
    return false;
  }, [currentMessage?.message_type, currentMessage?.payload]);

  const isAnimated = useMemo(() => {
    return (
      Boolean(messageId) &&
      !Boolean(currentMessage?.content) &&
      (currentMessage?.message_type ===
        MessageType.RESEARCH_TASK_CREATION_CARD ||
        currentMessage?.message_type === MessageType.CHAT_CARD) &&
      !isContentStartedToCome
    );
  }, [
    currentMessage?.content,
    messageId,
    currentMessage?.message_type,
    isContentStartedToCome,
  ]);

  const handleClick = () => {
    if (!taskId) {
      return;
    }
    setCurrentTaskId(taskId);
    onToggleVisibleTaskViewModal(true);
    sendGTMEvent(GTMEvent.TASK_VIEW_OPENING, {
      taskId,
      task_state: taskState,
    });
  };

  return (
    <div className={styles.buttonWrapper}>
      <Button
        disabled={!taskId}
        onClick={handleClick}
        data-e2e="task-properties-button"
        className={styles.button}
      >
        <ListBullets size={SVG_SIZE_M} />
      </Button>
      {isAnimated && <div className={styles.buttonShadow} />}
    </div>
  );
};
