import { IconName } from 'src/components/Icon';
import { ExternalModelSettings, ImageGenSettingsOption } from 'src/types';
import { llmGroupsList, newExternalModelsList } from 'src/constants';
import { UserSettingOption } from 'src/types/settings';
import type { ModelMetadata } from 'src/types/models/ModelMetadata';

// TODO(olha): expand function with others icons
export const getDefaultModelIconName = (modelId: string): IconName | null => {
  const provider = modelId.split('/')[0];

  switch (provider) {
    case 'bedrock':
      if (modelId.includes('nova-')) {
        return 'nova';
      }
      return 'awsLogo';

    case 'openai':
      return 'chatGPTLogo';

    case 'gemini':
      return 'googleGeminiProLogo';

    case 'ninja':
      return 'ninjaOutlined';

    default:
      return null;
  }
};

export const getTextModelDisplayName = (
  settings: ExternalModelSettings,
  targetModel: string,
): string | undefined => {
  return Object.values(settings)
    .flatMap((value) => (Array.isArray(value) ? value : []))
    .find((option) => option?.model_name === targetModel)?.display_name;
};

export const getImageModelDisplayName = (
  settings: ImageGenSettingsOption[],
  targetModel: string,
): string | undefined => {
  return (
    settings.find((option) => option?.model === targetModel)?.display_name ||
    undefined
  );
};

export const prepareModelsList = (
  options: Record<string, UserSettingOption>,
  metadata: Record<string, ModelMetadata>,
) => {
  return Object.entries(options || {}).map(([keyOption, settingOption]) => {
    return {
      id: keyOption,
      iconName:
        newExternalModelsList[keyOption]?.icon_name ||
        llmGroupsList[metadata[keyOption]?.group],
      title:
        metadata[keyOption]?.display_name ||
        newExternalModelsList[keyOption]?.display_name ||
        '',
      availableIn: settingOption.requires,
      description: metadata[keyOption]?.description,
    };
  });
};
