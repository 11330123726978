import { forwardRef } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import styles from './CodeFloatingElementPlugin.module.scss';
import { PromptForm } from '../../components/PromptForm';

interface CodeFloatingElementPluginProps {
  isInputVisible: boolean;
  selectionBox: { top: number; left: number; text?: string };
  selectionIndexes?: { start: number; end: number };
  handleSubmitMessage: (inputValue: string) => Promise<void>;
  handleSelectionBoxMouseDown: (e: React.MouseEvent) => void;
  handleCleanupState: () => void;
}

export const CodeFloatingElementPlugin = forwardRef<
  HTMLDivElement,
  CodeFloatingElementPluginProps
>((props, ref) => {
  const {
    isInputVisible,
    selectionBox,
    selectionIndexes,
    handleSubmitMessage,
    handleSelectionBoxMouseDown,
    handleCleanupState,
  } = props;

  const handleSubmit = async (userPrompt: string) => {
    try {
      if (!selectionBox) {
        toast.error('Can not get selection box');
        handleCleanupState();
        return;
      }

      if (!selectionIndexes) {
        toast.error('Can not get selection indexes');
        handleCleanupState();
        return;
      }

      await handleSubmitMessage(userPrompt);
    } catch (error) {
      toast.error('Failed to submit message');
      handleCleanupState();
    }
  };

  return (
    <div
      ref={ref}
      className={classNames(
        styles.codeFloatingElement,
        isInputVisible ? styles.inputVisible : styles.inputHidden,
      )}
      style={{
        top: `${selectionBox.top}px`,
        left: `${selectionBox.left}px`,
      }}
      onMouseDown={handleSelectionBoxMouseDown}
    >
      <PromptForm
        onSubmit={handleSubmit}
        placeholder="Add more detail about this"
      />
    </div>
  );
});
