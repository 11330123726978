/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SeatStatus {
  ASSIGNED = 'assigned',
  UNASSIGNED = 'unassigned',
  INVITE_PENDING = 'invite_pending',
  PENDING_RELEASE = 'pending_release',
  CANCELLED = 'cancelled',
}
