import { EditorView } from '@uiw/react-codemirror';
import { useCallback, useEffect, useRef, useState } from 'react';

interface SelectionBox {
  top: number;
  left: number;
  text: string;
}

interface SelectionIndexes {
  start: number;
  end: number;
}

interface UseSelectionResult {
  contentRef: React.RefObject<HTMLDivElement>;
  artifactContentRef: React.RefObject<HTMLDivElement>;
  selectionBoxRef: React.RefObject<HTMLDivElement>;
  selectionBox?: SelectionBox;
  selectionIndexes?: SelectionIndexes;
  isSelectionActive: boolean;
  isValidSelectionOrigin: boolean;
  isInputVisible: boolean;
  inputValue: string;
  setIsInputVisible: (value: boolean) => void;
  setInputValue: (value: string) => void;
  handleCleanupState: () => void;
}

export const useCodeEditorSelection = (
  editorRef: React.RefObject<EditorView | null>,
): UseSelectionResult => {
  const contentRef = useRef<HTMLDivElement>(null);
  const artifactContentRef = useRef<HTMLDivElement>(null);
  const selectionBoxRef = useRef<HTMLDivElement>(null);

  const [selectionBox, setSelectionBox] = useState<SelectionBox | undefined>();
  const [selectionIndexes, setSelectionIndexes] = useState<
    SelectionIndexes | undefined
  >();
  const [isSelectionActive, setIsSelectionActive] = useState(false);
  const [isValidSelectionOrigin, setIsValidSelectionOrigin] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleCleanupState = useCallback(() => {
    setIsInputVisible(false);
    setSelectionBox(undefined);
    setSelectionIndexes(undefined);
    setIsSelectionActive(false);
    setIsValidSelectionOrigin(false);
    setInputValue('');
  }, []);

  const handleMouseUp = useCallback(() => {
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0 && contentRef.current) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString().trim();

      if (selectedText && artifactContentRef.current) {
        const isWithinArtifact = (node: Node | null): boolean => {
          if (!node) {
            return false;
          }

          if (node === artifactContentRef.current) {
            return true;
          }

          return isWithinArtifact(node.parentNode);
        };

        const startInArtifact = isWithinArtifact(range.startContainer);
        const endInArtifact = isWithinArtifact(range.endContainer);

        if (startInArtifact && endInArtifact) {
          setIsValidSelectionOrigin(true);

          const rects = range.getClientRects();
          const firstRect = rects[0];
          const lastRect = rects[rects.length - 1];
          const contentRect = contentRef.current.getBoundingClientRect();

          const boxWidth = 400; // TODO: calculate width based on text length
          let left = lastRect.right - contentRect.left - boxWidth;

          if (left < 0) {
            left = Math.min(0, firstRect.left - contentRect.left);
          }

          setSelectionBox({
            top: lastRect.bottom - contentRect.top,
            left: left,
            text: selectedText,
          });
          setIsInputVisible(false);
          setIsSelectionActive(true);

          if (editorRef.current) {
            const from = editorRef.current.posAtDOM(
              range.startContainer,
              range.startOffset,
            );

            const to = editorRef.current.posAtDOM(
              range.endContainer,
              range.endOffset,
            );

            setSelectionIndexes({
              start: from,
              end: to,
            });
          }
        } else {
          setIsValidSelectionOrigin(false);
          handleCleanupState();
        }
      }
    }
  }, [editorRef, handleCleanupState]);

  const handleDocumentMouseDown = useCallback(
    (event: MouseEvent) => {
      if (
        isSelectionActive &&
        selectionBoxRef.current &&
        !selectionBoxRef.current.contains(event.target as Node)
      ) {
        handleCleanupState();
      }
    },
    [isSelectionActive, handleCleanupState],
  );

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousedown', handleDocumentMouseDown);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousedown', handleDocumentMouseDown);
    };
  }, [handleMouseUp, handleDocumentMouseDown]);

  return {
    contentRef,
    artifactContentRef,
    selectionBoxRef,
    selectionBox,
    selectionIndexes,
    isSelectionActive,
    isValidSelectionOrigin,
    isInputVisible,
    inputValue,
    setIsInputVisible,
    setInputValue,
    handleCleanupState,
  };
};
