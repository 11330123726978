import { useRef } from 'react';
import { Trash } from '@phosphor-icons/react';
import { toast } from 'react-toastify';
import {
  DrawerTooltipContainer,
  DrawerTooltipContainerRef,
} from 'src/v2/commonComponents/DrawerTooltipContainer';
import { Button } from 'src/v2/commonComponents/Button';
import { SVG_SIZE_M } from 'src/constants';
import {
  useCurrentEditorThread,
  useQueryParams,
  useRightSidePanelActions,
  useVisible,
} from 'src/hooks';
import { EditorToolbarMenuToggle } from '../EditorToolbarMenuToggle';
import { EditorLeftPanelMenuToggle } from '../EditorLeftPanelMenuToggle';
import styles from './EditorMenuControl.module.scss';
import { useThreadContext } from '../../plugins/NinjaCanvasPlugin/contexts/ThreadProvider';
import { CommonDeleteModal } from 'src/components/CommonDeleteModal';

const tooltipHeaderAnchorId = 'editor-menu-header-tooltip-anchor';
const tooltipLeftMenuAnchorId = 'editor-left-menu-tooltip-anchor';

interface EditorMenuControlProps {
  align?: 'bottom' | 'bottom-end';
  withThreeDotsIcon?: boolean;
}

export const EditorMenuControl = ({
  align = 'bottom-end',
  withThreeDotsIcon = false,
}: EditorMenuControlProps) => {
  const drawerRef = useRef<DrawerTooltipContainerRef>(null);

  const { currentEditorThreadTitle } = useCurrentEditorThread();
  const { threadId, isDeleteThreadLoading, deleteThread } = useThreadContext();
  const { removeSearchParam } = useQueryParams();
  const { toggleRightSidePanelExpanded, toggleRightSidePanelFullWidth } =
    useRightSidePanelActions();

  const {
    isVisible: isDeleteModalVisible,
    onVisibilitySet: onDeleteModalVisibilitySet,
    onVisibilityRemove: onDeleteModalVisibilityRemove,
  } = useVisible();

  const handleDeleteClick = () => {
    drawerRef.current?.onVisibilityToggle();
    onDeleteModalVisibilitySet();
  };

  const onSubmit = () => {
    removeSearchParam('document');

    if (threadId) {
      deleteThread(threadId);
    }

    toggleRightSidePanelFullWidth(false);
    toggleRightSidePanelExpanded();

    toast.success('File deleted');
  };

  return (
    <>
      {currentEditorThreadTitle && (
        <CommonDeleteModal
          isOpen={isDeleteModalVisible}
          onClose={onDeleteModalVisibilityRemove}
          onSubmit={onSubmit}
          isLoading={isDeleteThreadLoading}
          title="Delete file"
          target={currentEditorThreadTitle}
        />
      )}

      <DrawerTooltipContainer
        ref={drawerRef}
        id={withThreeDotsIcon ? tooltipLeftMenuAnchorId : tooltipHeaderAnchorId}
        place={align}
        offset={8}
        classNameTooltip={styles.tooltip}
        onlyTooltip
        ToggleButton={
          withThreeDotsIcon
            ? EditorLeftPanelMenuToggle
            : EditorToolbarMenuToggle
        }
      >
        <div className={styles.container}>
          <Button color="transparent" onClick={handleDeleteClick}>
            <Trash size={SVG_SIZE_M} />
            <span>Delete file</span>
          </Button>
        </div>
      </DrawerTooltipContainer>
    </>
  );
};
