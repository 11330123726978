import { ChatCircleDots, FolderSimple, Sliders } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { AddConversationButton } from 'src/pages/ManageTasksChatPage/components/MultiConversationsListPanel/components/AddConversationButton';
import { Button } from 'src/v2/commonComponents/Button';
import {
  useAttachedImageTooltipActions,
  useAttachedImageTooltipState,
  useBreakpoint,
  useConversationActions,
  useConversations,
  useLeftPanelActions,
  useLeftPanelState,
  useRightSidePanelActions,
  useRightSidePanelState,
} from 'src/hooks';
import classNames from 'classnames';
import styles from './NavSidebar.module.scss';
import { LeftPanelSections } from 'src/types';
import { UserImage } from 'src/v2/components/UserImage';
import { DownloadAppsButton } from '../DownloadAppsButton';
import { isBetaEnv } from 'src/utils';

export const NavSidebar = () => {
  const { isDesktopAndUp } = useBreakpoint();
  const { toggleRightSidePanelExpanded } = useRightSidePanelActions();
  const { toggleLeftPanelExpanded, setLeftPanelData } = useLeftPanelActions();
  const { isLeftPanelExpanded, leftPanelSections } = useLeftPanelState();
  const { isImageEditSectionExpanded } = useRightSidePanelState();
  const { isAttachedImageEditingMode } = useAttachedImageTooltipState();
  const {
    onChangeIsAttachedImageEditingMode,
    onToggleImageEditTooltipVisible,
  } = useAttachedImageTooltipActions();
  const { hasUnreadMessage } = useConversations();
  // const { getUserThreads } = useThreadContext();

  const { setTaskSkillFilter } = useConversationActions();

  const ACTION_LIST = [
    {
      section: LeftPanelSections.CONVERSATION_LIST,
      Icon: ChatCircleDots,
      tooltipContent: 'Chat history',
      visible: true,
    },
    {
      section: LeftPanelSections.FILE_LIST,
      Icon: FolderSimple,
      tooltipContent: 'Files',
      visible: isBetaEnv && isDesktopAndUp,
    },
  ];

  const handleButtonClick = (panelType: LeftPanelSections) => {
    if (leftPanelSections === panelType && isLeftPanelExpanded) {
      toggleLeftPanelExpanded(false);
      return;
    }

    setLeftPanelData({
      panelType,
      isExpanded: true,
    });

    if (isImageEditSectionExpanded) {
      toggleRightSidePanelExpanded(false);
    }

    if (isAttachedImageEditingMode) {
      onChangeIsAttachedImageEditingMode(false);
      onToggleImageEditTooltipVisible(true);
    }

    // if (panelType === LeftPanelSections.FILE_LIST) {
    //   getUserThreads();
    // }
  };

  const handleActionClick = (panelType: LeftPanelSections) => {
    setTaskSkillFilter();
    handleButtonClick(panelType);
  };

  return (
    <div
      className={classNames(styles.root, {
        [styles.withBorder]: isLeftPanelExpanded,
        [styles.withShadow]: !isLeftPanelExpanded,
      })}
    >
      <div className={styles.container}>
        <AddConversationButton appearance="ninja-icon" />

        {ACTION_LIST.map(({ section, Icon, tooltipContent, visible }) =>
          visible ? (
            <Button
              key={section}
              color="transparent"
              shape="round"
              onClick={() => handleActionClick(section)}
              tooltipContent={tooltipContent}
              className={classNames(styles.button, {
                [styles.active]:
                  isLeftPanelExpanded && section === leftPanelSections,
                [styles.withRedDot]:
                  section === LeftPanelSections.CONVERSATION_LIST &&
                  hasUnreadMessage,
              })}
              data-e2e={`action-${section}-button`}
            >
              <Icon size={SVG_SIZE_M} />
            </Button>
          ) : null,
        )}

        <hr className="divider" />

        <Button
          color="transparent"
          shape="round"
          onClick={() => handleActionClick(LeftPanelSections.SETTINGS)}
          tooltipContent="Settings"
          className={classNames(styles.button, {
            [styles.active]:
              isLeftPanelExpanded &&
              leftPanelSections === LeftPanelSections.SETTINGS,
          })}
          data-e2e={`action-settings-button`}
        >
          <Sliders size={SVG_SIZE_M} />
        </Button>
      </div>

      <div className={styles.container}>
        <DownloadAppsButton />

        <Button
          data-e2e="user-menu-button"
          color="transparent"
          shape="round"
          onClick={() => handleButtonClick(LeftPanelSections.USER_MENU)}
          tooltipContent="Account"
        >
          <UserImage />
        </Button>
      </div>
    </div>
  );
};
