import { env } from 'src/env';
import packageJson from '../../package.json';
import { AppUser, ConversationRole, UserType } from 'src/types';

export const E_ASSISTANT = env.REACT_APP_E_ASSISTANT || 'EASSISTANT';

export const DEFAULT_PORTAL = 'manage-tasks';

export const DEFAULT_OPTION = 'default';

export const VERSION_NUMBER = packageJson.version;

// todo: play with these settings if they work on all env after push
export const APP_ENV =
  env.REACT_APP_ENVIRONMENT || env.NODE_ENV || 'development';

export const DEFAULT_AGENT: AppUser = {
  user_id: E_ASSISTANT,
  first_name: 'Ninja',
  last_name: '',
  user_type: UserType.AGENT,
  role: ConversationRole.AGENT,
  email: 'ninja@ninjatech.ai',
  company: 'NinjaTech AI',
  avatar: 'avatarAtlas',
};

export const TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export const SCHEDULE_ACTION_CREATED = 'created';
export const SCHEDULE_ACTION_DELETED = 'deleted';

export const REGEX_EMAIL_PATTERN =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const CALENDAR_URL = 'https://www.googleapis.com/auth/calendar';

// This permissions is only needed for workspace user as opposed to just normal google users,
// i.e with a @gmail.com domain
export const DIRECTORY_URL =
  'https://www.googleapis.com/auth/directory.readonly';

export const CONTACTS_URL = 'https://www.googleapis.com/auth/contacts.readonly';

export const GOOGLE_DRIVE_READONLY_URL =
  'https://www.googleapis.com/auth/drive.file';

export const ENTERPRISE_ENVIRONMENT = 'enterprise';
export const PRODUCTION_ENVIRONMENT = 'production';
export const GAMMA_ENVIRONMENT = 'gamma';
export const BETA_ENVIRONMENT = 'beta';
export const LOCALHOST_ENVIRONMENT = 'development';

export const IMAGE_FILE_EXTENSION_LIST = [
  'png',
  'jpg',
  'jpeg',
  'apng',
  'bmp',
  'dib',
  'drle',
  'dpx',
  'fits',
  'fit',
  'fts',
  'g3',
  'gif',
  'ico',
  'jp2',
  'jpg2',
  'jfif',
  'pjpeg',
  'pjp',
  'jpm',
  'jpgm',
  'svg',
  'svgz',
  'svg.gz',
  'tif',
  'tiff',
  'tfx',
  'psd',
  'wbmp',
  'xif',
  'pcx',
  'webp',
  'wmf',
  'arw',
  'cr2',
  'crw',
  'dcr',
  'dng',
  'erf',
  'k25',
  'kdc',
  'mrw',
  'nef',
  'nrw',
  'orf',
  'pef',
  'raf',
  'raw',
  'sr2',
  'srf',
  'x3f',
];

export const MAX_NUMBER_OF_FILES = 10;

export const MAX_FILE_SIZE = 25 * 1024 * 1024;

export const USER_NAME_PATTERN_1 = {
  value: /^[A-Za-z]+(?:[\s'-][A-Za-z]+)*$/,
  message: 'Please enter a valid name.',
};

export const ENTERPRISE_USER_VALID_NAME_PATTERN = {
  value: /^[A-Za-z]+(?:['-][A-Za-z]+)*$/,
  message:
    'Enter a valid name with at least 2 letters, no numbers or special characters.',
};

export const USER_NAME_PATTERN_2 = {
  value: /(.)\1{3,}/,
  message: 'Please enter a valid name without repeating characters.',
};

export const USER_NAME_MIN_LENGTH = 2;
export const USER_NAME_MAX_LENGTH = 100;

export const VALID_EMAIL_PATTERN = {
  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  message: 'Please enter a valid email.',
};

export const REG_EXP_FOR_WORD_SEPARATORS = /\s+|\n+/;

export const UI_DOMAIN = env.REACT_APP_NINJA_UI_URL.replace(/^https?:\/\//, '');

export const SEO_MAIN_PAGE_TITLE =
  'Ninja AI | All-In-One AI Agent For Boundless Productivity';

export const SEO_MAIN_PAGE_DESCRIPTION =
  'Accomplish more everyday with the best AI tools for research, writing, coding, image generation, file analysis, and more. Try Ninja for free today.';

export const SEO_LOGIN_TITLE = 'Login To Your Account | Ninja AI';
export const SEO_LOGIN_DESCRIPTION =
  "Login to your Ninja AI account to get access to your all-in-one AI agent for unlimited productivity. Let's get started.";

export const SEO_SIGN_UP_TITLE = 'Sign Up | Ninja AI';
export const SEO_SIGN_UP_DESCRIPTION =
  'Sign up for your free Ninja account today and get access to the best AI models and agents for productivity. Accomplish more everyday with Ninja.';

export const PASSWORD_DOES_NOT_MEET_REQUIREMENTS_MESSAGE =
  'Password doesn’t meet required criteria. Review and try again.';
export const PASSWORDS_DO_NOT_MATCH_MESSAGE =
  'Passwords do not match. Please try again.';

export const COMMON_ERROR_TEXT =
  'Sorry, something went wrong. Please try again later';

export const SAVED_TEXT = 'Saved';

export const AVAILABLE_TO_UPLOAD_FILE_TYPES_LIST = [
  'image/jpeg',
  'image/png',
  'text/plain',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv',
  'text/html',
  'application/javascript',
  'text/javascript',
  'text/php',
  'text/x-c',
  'text/x-c++',
  'text/x-fortran',
  'text/x-java',
  'text/x-lisp',
  'text/x-pascal',
  'text/x-perl',
  'text/x-python',
  'text/x-shellscript',
  'text/x-tcl',
  'text/x-haskell',
  'text/x-go',
  'text/x-lua',
  'image/apng',
  'image/bmp',
  'image/dib',
  'image/drle',
  'image/dpx',
  'image/fits',
  'image/g3',
  'image/gif',
  'image/ico',
  'image/jp2',
  'image/jpg2',
  'image/jfif',
  'image/pjpeg',
  'image/pjp',
  'image/webp',
  'image/jpm',
  'image/jpgm',
  'image/svg',
  'image/tif',
  'image/tfx',
  'image/psd',
  'image/wbmp',
  'image/xif',
  'image/pcx',
  'image/wmf',
  'image/arw',
  'image/cr2',
  'image/crw',
  'image/dcr',
  'image/dng',
  'image/erf',
  'image/k25',
  'image/kdc',
  'image/mrw',
  'image/nef',
  'image/nrw',
  'image/orf',
  'image/pef',
  'image/raf',
  'image/raw',
  'image/sr2',
  'image/srf',
  'image/x3f',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/json',
];

export const HOVER_TOOLTIP_ID = 'hover-tooltip';

export const NINJA_SUPPORT_EMAIL = 'support@ninjatech.ai';
