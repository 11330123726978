import { GoogleDriveLogo } from '@phosphor-icons/react';
import { MAX_NUMBER_OF_FILES, SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './AttachFromGoogleDriveButton.module.scss';
import classNames from 'classnames';
import {
  useAttachFile,
  useBreakpoint,
  useGoogleResourcesAccess,
  useSession,
} from 'src/hooks';
import { ChangeEvent, useContext, useMemo } from 'react';
import { useGoogleDrivePicker } from 'src/hooks';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';
import GoogleApiContext from 'src/contexts/GoogleApiContext';
import { TextDivider } from 'src/v2/commonComponents/TextDivider';

interface AttachFromGoogleDriveButtonProps {
  withTitle?: boolean;
  onClose?: () => void;
  onShowTooltip: () => void;
}

export const AttachFromGoogleDriveButton = ({
  withTitle,
  onClose,
  onShowTooltip,
}: AttachFromGoogleDriveButtonProps) => {
  const { isOpenTier } = useSession();
  const { isMobile } = useBreakpoint();
  const { handleUploadedFiles } = useAttachFile();
  const { connectGoogleDrive, canAccessGoogleDrive } =
    useGoogleResourcesAccess();

  const {
    loadedApis: { drive: driveApiLoaded },
  } = useContext(GoogleApiContext);
  const { threadInputBoxFiles } = useContext<ThreadInputBoxContextType>(
    ThreadInputBoxContext,
  );

  const handleFileSelect = async (event: ChangeEvent | File[]) => {
    onClose?.();

    const files = event?.hasOwnProperty('target')
      ? ((((event as ChangeEvent).target as HTMLInputElement)?.files ||
          []) as FileList)
      : (event as File[]);
    handleUploadedFiles(files);
  };

  const { showGooglePicker, isReady: isGooglePickerReady } =
    useGoogleDrivePicker({
      callback: handleFileSelect,
    });

  const handleDriveClick = () => {
    if (isOpenTier) {
      onShowTooltip();
    } else {
      if (canAccessGoogleDrive) {
        showGooglePicker();
        onClose?.();
      } else {
        connectGoogleDrive();
      }
    }
  };

  const isGoogleDriveDisabled = useMemo(() => {
    return (
      !isGooglePickerReady ||
      (!!threadInputBoxFiles &&
        Object.keys(threadInputBoxFiles).length === MAX_NUMBER_OF_FILES)
    );
  }, [isGooglePickerReady, threadInputBoxFiles]);

  if (!driveApiLoaded) {
    return null;
  }

  return (
    <>
      {isMobile && <TextDivider />}

      <Button
        disabled={canAccessGoogleDrive ? isGoogleDriveDisabled : false}
        onClick={handleDriveClick}
        fullWidth={isMobile}
        data-tooltip-id="attachment-tooltip"
        data-tooltip-place={isMobile ? 'bottom-start' : 'top-start'}
        shape={withTitle ? 'regular' : 'round'}
        color="transparent"
        // TODO(olha): double-check this style. looks like unnecessary
        // (irina): transparent conflicts with regular paddings, so we need to add a class to fix it
        className={classNames({
          [styles.extendedButton]: withTitle,
          [styles.mobileButton]: isMobile,
        })}
      >
        <GoogleDriveLogo size={SVG_SIZE_M} />

        {withTitle &&
          (canAccessGoogleDrive
            ? 'Attach from Google Drive'
            : 'Connect Google Drive')}
      </Button>
    </>
  );
};
