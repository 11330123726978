import { useContext } from 'react';
import classNames from 'classnames';
import { Icon } from '@phosphor-icons/react';
import { Button } from 'src/v2/commonComponents/Button';
import { SVG_SIZE_M } from 'src/constants';
import { MessageSkill, QuickSkillCommand } from 'src/types';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import styles from './CommandPickerButton.module.scss';

interface CommandPickerButtonProps {
  skill: QuickSkillCommand;
  icon: Icon;
  onClose?: () => void;
  description: string;
  isSelected?: boolean;
  onClick?: () => void;
}

export const CommandPickerButton = ({
  skill,
  icon: IconComponent,
  onClose,
  description,
  isSelected,
  onClick,
}: CommandPickerButtonProps) => {
  const { threadInputBoxRef } = useContext(ForwardRefContext);
  const { threadInputBoxValue, setThreadInputBoxValue } = useContext(
    ThreadInputBoxContext,
  );

  const getNewInputValue = () => {
    const newInputSkill = `${skill} `;

    const skillsList = Object.values(MessageSkill).map(
      (item) => `/${item.replace('_', '-')} `,
    );

    const existedInputSkill = skillsList.find((item) =>
      threadInputBoxValue.startsWith(item),
    );

    if (existedInputSkill) {
      return `${newInputSkill}${threadInputBoxValue.slice(existedInputSkill.length)}`;
    }

    return `${newInputSkill}${threadInputBoxValue}`;
  };

  const handleClick = () => {
    const newInputValue = getNewInputValue();

    setThreadInputBoxValue(newInputValue);

    threadInputBoxRef?.current?.focus();
    onClose?.();
    onClick?.();
  };

  return (
    <Button
      className={classNames(styles.root, {
        [styles.active]: isSelected,
      })}
      color="transparent"
      onClick={handleClick}
      fullWidth
      onMouseDown={(e) => {
        e.preventDefault();
        onClick?.();
      }}
    >
      <span className={styles.titleWrapper}>
        <IconComponent size={SVG_SIZE_M} />
        {skill}
      </span>

      {description && <span className={styles.caption}>{description}</span>}
    </Button>
  );
};
