import { useSession, useUserMetadata } from 'src/hooks';
import { useCallback, useMemo } from 'react';
import {
  useGetSettingsQuery,
  useUpdateSettingMutation,
} from 'src/store/services/userSettingsApi';
import {
  SettingKeys,
  SettingLLMModels,
  SettingValue,
} from 'src/types/settings';
import { settingKeyToRootKey } from 'src/utils/settings';
import { prepareModelsList } from 'src/utils';
import log from 'src/utils/logger';
import { toast } from 'react-toastify';
import { COMMON_ERROR_TEXT, SAVED_TEXT } from 'src/constants';

export const useUserSettings = <T>(
  settingKey: SettingKeys,
): {
  setting: T | undefined;
  updateUserSetting: (value: SettingValue, hideToast?: boolean) => void;
  superAgentModels: SettingLLMModels;
  otherLLMModels: SettingLLMModels;
} => {
  const {
    appUser: { user_id },
  } = useSession();

  const { metadata } = useUserMetadata();

  const { settings } = useGetSettingsQuery(
    { user_id, settingKey: settingKeyToRootKey(settingKey) },
    {
      skip: !user_id,
      selectFromResult: ({ data }) => ({
        settings: data?.settings,
      }),
    },
  );

  const [changeSetting] = useUpdateSettingMutation({});

  const setting = settings ? settings[settingKey] : undefined;

  const superAgentModels = useMemo(() => {
    if (setting?.options) {
      return prepareModelsList(setting?.options, metadata).filter(
        (item) => item.id.includes('/super-agent'), // todo it is temporary till BE implements groups
      );
    }
    return [];
  }, [setting, metadata]);

  const otherLLMModels = useMemo(() => {
    if (setting?.options) {
      return prepareModelsList(setting?.options, metadata).filter(
        (item) => !item.id.includes('/ninjatech/'), // todo it is temporary till BE implements groups
      );
    }
    return [];
  }, [setting, metadata]);

  const updateSetting = useCallback(
    async (value: SettingValue, hideToast?: boolean) => {
      try {
        await changeSetting({
          user_id: user_id,
          setting_key: settingKey,
          body: {
            new_value: value,
          },
        }).unwrap();
        if (!hideToast) {
          toast.success(SAVED_TEXT);
        }
      } catch (error) {
        log.error(error);
        toast.error(COMMON_ERROR_TEXT);
      }
    },
    [changeSetting, user_id, settingKey],
  );

  return useMemo(
    () => ({
      setting: setting as T,
      updateUserSetting: updateSetting,
      superAgentModels,
      otherLLMModels,
    }),
    [setting, updateSetting, superAgentModels, otherLLMModels],
  );
};
