import { ArrowCircleUpRight, ArrowUpRight } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import type { ToggleProps } from 'src/v2/commonComponents/DrawerTooltipContainer';
import styles from './MoveToEditorToggle.module.scss';
import { EditorType } from 'src/types';
import { useLeftPanelState, useRightSidePanelState } from 'src/hooks';
import classNames from 'classnames';

interface MoveToEditorToggleProps extends ToggleProps {
  componentType?: EditorType;
}

export const MoveToEditorToggle = ({
  id,
  disabled,
  isActive,
  componentType,
  onClick,
}: MoveToEditorToggleProps) => {
  const isDocument = componentType === EditorType.DOCUMENT;
  const { isRightPanelExpanded } = useRightSidePanelState();
  const { isLeftPanelExpanded } = useLeftPanelState();

  const isNarrowButton = isRightPanelExpanded && isLeftPanelExpanded;

  if (isDocument) {
    return (
      <div id={id}>
        <Button
          disabled={disabled}
          color={isActive ? 'primary' : 'secondary'}
          shape={isNarrowButton ? 'round' : 'regular'}
          onClick={onClick}
          className={classNames(styles.documentTypeRoot, {
            [styles.withTitle]: !isNarrowButton,
            [styles.active]: isActive,
          })}
          tooltipContent="Send to Editor"
          tooltipPlace={
            isRightPanelExpanded || isLeftPanelExpanded ? 'right' : 'left'
          }
        >
          <ArrowUpRight size={SVG_SIZE_M} />

          {!isNarrowButton && 'Editor'}
        </Button>
      </div>
    );
  }

  return (
    <div id={id}>
      <Button
        disabled={disabled}
        color={isActive ? 'tertiary' : 'link'}
        shape="round"
        onClick={onClick}
        className={styles.root}
        tooltipContent="Send to Editor"
        tooltipPlace="right"
      >
        <ArrowCircleUpRight weight="fill" size={SVG_SIZE_M} />
      </Button>
    </div>
  );
};
