import { useCallback, useMemo } from 'react';
import {
  useAppDispatch,
  setRightPanelSections,
  setIsRightPanelExpanded,
  setIsRightPanelFullWidth,
  setEditorType,
} from 'src/store';
import { EditorType, RightPanelSections } from 'src/types';

interface RightSidePanelDataProps {
  panelType: RightPanelSections | null;
  isExpanded?: boolean;
  editorType?: EditorType | null;
}

// TODO(olha): rename to useRightPanelActions
export const useRightSidePanelActions = () => {
  const dispatch = useAppDispatch();

  const toggleRightSidePanelExpanded = useCallback(
    (value?: boolean) => {
      dispatch(setIsRightPanelExpanded(value));
    },
    [dispatch],
  );

  const toggleRightSidePanelFullWidth = useCallback(
    (value?: boolean) => {
      dispatch(setIsRightPanelFullWidth(value));
    },
    [dispatch],
  );

  const handleSetRightSidePanelType = useCallback(
    (value: RightPanelSections | null) => {
      dispatch(setRightPanelSections(value));
    },
    [dispatch],
  );

  const handleSetEditorType = useCallback(
    (value: EditorType | null) => {
      dispatch(setEditorType(value));
    },
    [dispatch],
  );

  const setRightSidePanelData = useCallback(
    ({ panelType, isExpanded, editorType }: RightSidePanelDataProps) => {
      toggleRightSidePanelExpanded(isExpanded);
      handleSetRightSidePanelType(panelType);

      if (editorType || editorType === null) {
        handleSetEditorType(editorType);
      }
    },
    [
      handleSetEditorType,
      handleSetRightSidePanelType,
      toggleRightSidePanelExpanded,
    ],
  );

  const resetAndCloseRightSidePanelData = useCallback(() => {
    setRightSidePanelData({
      // TODO(olha): we should keep panelType for smooth animation
      panelType: null,
      isExpanded: false,
    });
  }, [setRightSidePanelData]);

  return useMemo(
    () => ({
      setRightSidePanelData,
      resetAndCloseRightSidePanelData,
      toggleRightSidePanelExpanded,
      toggleRightSidePanelFullWidth,
    }),
    [
      setRightSidePanelData,
      resetAndCloseRightSidePanelData,
      toggleRightSidePanelExpanded,
      toggleRightSidePanelFullWidth,
    ],
  );
};
