import { lazy, Suspense, useMemo, useContext } from 'react';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import {
  atomOneDarkReasonable,
  atomOneLight,
} from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { useColorTheme } from 'src/hooks';
import { MoveToEditorTool } from 'src/v2/components/ThreadMessage/components/MoveToEditorTool';
import { isBetaEnv } from 'src/utils';
import { EditorType } from 'src/types';
import { ProgrammingLanguageOptions } from 'src/v2/components/Editor/plugins/NinjaCanvasPlugin/shared/types';
import SessionContext from 'src/contexts/SessionContext';
import { CopyCodeButton } from 'src/v2/components/CopyCodeButton';

const SyntaxHighlighter = lazy(() => import('react-syntax-highlighter'));

export const CodeMarkdown = ({
  node,
  inline,
  className = 'language-code',
  style,
  children,
  ...props
}: CodeProps) => {
  const { isDarkTheme } = useColorTheme();
  const { isVisibleTaskViewModal } = useContext(SessionContext);

  const syntaxHighlighterLanguage = /language-(\w+)/.exec(className || '');

  const sanitizedText = useMemo(
    () =>
      String(children)
        .replace(/\u00A0/g, ' ')
        .replace(/&nbsp;/g, ' ')
        .replace(/\r\n|\n|\r/g, '\n'),
    [children],
  );

  if (!inline && syntaxHighlighterLanguage) {
    return (
      <div className="nj-markdown-block-wrapper">
        <div className="nj-markdown-copy-block-wrapper">
          <h6>{syntaxHighlighterLanguage[1]}</h6>

          <div className="nj-markdown-copy-block-wrapper-actions">
            <CopyCodeButton content={sanitizedText} color="link" />

            {isBetaEnv && !isVisibleTaskViewModal && (
              <MoveToEditorTool
                componentType={EditorType.CODE}
                codeContent={sanitizedText}
                language={
                  syntaxHighlighterLanguage[1] as ProgrammingLanguageOptions
                }
              />
            )}
          </div>
        </div>
        <hr className="divider" />
        <Suspense fallback={<pre>{String(children).replace(/\n$/, '')}</pre>}>
          <SyntaxHighlighter
            style={isDarkTheme ? atomOneDarkReasonable : atomOneLight}
            language={syntaxHighlighterLanguage[1]}
            PreTag="div"
            // attn: if "showLineNumbers" is true, "wrapLongLines" must be false. Issue: https://github.com/react-syntax-highlighter/react-syntax-highlighter/issues/376
            wrapLongLines={true}
            showLineNumbers={false}
            {...props}
          >
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
        </Suspense>
      </div>
    );
  }

  return (
    <code className={className} {...props}>
      {children}
    </code>
  );
};
