import { useMemo, useRef } from 'react';
import { ControlledMenu } from '@szhsin/react-menu';
import { DotsThreeOutlineVertical } from '@phosphor-icons/react';
import styles from './DimensionMoreSelector.module.scss';
import {
  ImageSettingsAspectRatioSet,
  ImageSettingsIconsSet,
  ImageSettingsPrimaryGroup,
  SVG_SIZE_M,
} from 'src/constants';
import classNames from 'classnames';
import { useVisible, useBreakpoint } from 'src/hooks';
import { BottomDrawer } from 'src/components/BottomDrawer';
import { DimensionMoreOptions } from 'src/components/ImageGenSettings/components/DimensionMoreOptions';

interface DimensionMoreSelectorProps {
  onSelect: (value: string) => void;
  selectedValue: string;
  isMoreDisabled: boolean;
}

export const DimensionMoreSelector = ({
  onSelect,
  selectedValue = '',
  isMoreDisabled,
}: DimensionMoreSelectorProps) => {
  const { isVisible, onVisibilityRemove, onVisibilityToggle } = useVisible();

  const { isMobileOrTablet } = useBreakpoint();

  const buttonRef = useRef(null);

  const isPrimaryGroupSelected = !!ImageSettingsPrimaryGroup[selectedValue];

  const toggleButtonContent = useMemo(() => {
    if (isPrimaryGroupSelected) {
      return null;
    }

    const aspect_ratio = ImageSettingsAspectRatioSet[selectedValue];

    const CurrentIcon = ImageSettingsIconsSet[selectedValue];

    return (
      <button
        className={classNames(styles.selectedToggleContent, {
          [styles.active]: !isPrimaryGroupSelected,
        })}
      >
        <>
          <span className={styles.icon}>
            {!!CurrentIcon && <CurrentIcon size={SVG_SIZE_M} />}
          </span>
          <span className={styles.subtitle}>{aspect_ratio}</span>
        </>
      </button>
    );
  }, [isPrimaryGroupSelected, selectedValue]);

  const handleItemClick = (value: string) => {
    onVisibilityRemove();
    onSelect(value);
  };

  return (
    <div className={styles.root}>
      {toggleButtonContent}

      <button
        ref={buttonRef}
        className={classNames(styles.toggleButton, {
          [styles.disabled]: isMoreDisabled,
        })}
        disabled={isMoreDisabled}
        onClick={onVisibilityToggle}
        data-e2e="image-dimension-more-button"
      >
        <DotsThreeOutlineVertical size={SVG_SIZE_M} />
      </button>

      {isMobileOrTablet ? (
        <BottomDrawer isOpen={isVisible} onClose={onVisibilityRemove}>
          <DimensionMoreOptions
            selectedValue={selectedValue}
            onClick={handleItemClick}
          />
        </BottomDrawer>
      ) : (
        <ControlledMenu
          gap={8}
          menuClassName={styles.menu}
          state={isVisible ? 'open' : 'closed'}
          anchorRef={buttonRef}
          onClose={onVisibilityRemove}
          transition
        >
          <DimensionMoreOptions
            selectedValue={selectedValue}
            onClick={handleItemClick}
          />
        </ControlledMenu>
      )}
    </div>
  );
};
