import { useState } from 'react';
import {
  useVisible,
  useSession,
  useLogout,
  useTeamInfo,
  useSubscriptionInfo,
} from 'src/hooks';
import { DeleteAccountModal } from 'src/pages/ManageTasksChatPage/components/AccessAndDataSettings/components/DeleteAccountModal';
import { Modal } from 'src/components/Modal';
import { UserCircleMinus } from '@phosphor-icons/react';
import styles from 'src/pages/ManageAccountPage/components/General/General.module.scss';
import {
  useDeleteUserAccountMutation,
  useAddFeedbackToUserDeletionMutation,
} from 'src/store/services/usersApi';
import { toast } from 'react-toastify';
import { DeleteAccountFormData } from 'src/types';
import { ContactNinjaWhenTeamUserDeleteAccount } from 'src/v2/components/modals/ContactNinjaWhenTeamUserDeleteAccount';
import { Button } from 'src/v2/commonComponents/Button';

const DELETED_TEXT = 'Account successfully deleted';
const ERROR_DELETING_TEXT = 'Error deleting account';
const APPLE_PAYMENT_DELETING_ERROR_TEXT =
  'Please cancel your subscription through the mobile app or the Amazon Partner Network before deleting your account"';

export const DeleteAccount = () => {
  const [isDeleting, setIsDeleting] = useState(false);

  const { isAppleOrAmpPayment } = useSubscriptionInfo();

  const { isVisible, onVisibilitySet, onVisibilityRemove } = useVisible();
  const {
    isVisible: isOpenContactNinjaModal,
    onVisibilitySet: onOpenContactNinjaModal,
    onVisibilityRemove: onCloseContactNinjaModal,
  } = useVisible();
  const { appUser, isTeamsTier } = useSession();
  const { logout } = useLogout();
  const { isOwner } = useTeamInfo();

  const [deleteUserAccount] = useDeleteUserAccountMutation();
  const [addFeedbackToUserDeletion] = useAddFeedbackToUserDeletionMutation();

  const handleDeleteAccountButtonClick = () => {
    onVisibilitySet();
  };

  const handleDeleteAccount = async (formValues: DeleteAccountFormData) => {
    setIsDeleting(true);
    try {
      // Attempt to delete the account
      await deleteUserAccount(appUser.user_id).unwrap();
      await addFeedbackToUserDeletion({
        user_id: appUser.user_id,
        email: appUser.email,
        feedback_details: {
          reason: formValues.selectedReason, // Use selected reason
          note: formValues.reasonText || 'undefined', // Use reason text
        },
      }).unwrap();
      logout(); // Log out the user after deletion
      toast(DELETED_TEXT); // Show a success message
      // todo: it is temporary to fix failed build, replace any in the future
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (isTeamsTier && isOwner && error?.status === 400) {
        onOpenContactNinjaModal();
        setIsDeleting(false);
        return;
      } else {
        const errorMessage = error?.data?.detail || ERROR_DELETING_TEXT;
        toast(`${ERROR_DELETING_TEXT}: ${errorMessage}`);
      }
    } finally {
      // Ensure the deleting state is reset
      setIsDeleting(false);
    }
  };

  const handleOnClose = () => {
    setIsDeleting(false);
  };

  return (
    <div className={styles.dataControlContentRed}>
      <p className={styles.dataControlSubtitleRed}>Delete account</p>

      <Button
        color="transparent"
        onClick={handleDeleteAccountButtonClick}
        className="nj-settings-tab-content--delete-account-button"
        tooltipContent={
          isAppleOrAmpPayment ? APPLE_PAYMENT_DELETING_ERROR_TEXT : ''
        }
        disabled={isAppleOrAmpPayment}
      >
        <UserCircleMinus size={24} />
      </Button>

      <Modal
        open={isDeleting}
        container={document.body}
        classNames={{
          root: 'nj-modal--root',
          overlay: 'nj-modal--overlay',
          modal: 'nj-modal--delete-account',
        }}
        showCloseIcon={false}
        center
        closeOnOverlayClick={false}
        onClose={handleOnClose}
        blockScroll={false}
      >
        <div className="nj-delete--executing">
          <span className="nj-delete--progress-icon" />
          <span className="nj-delete--executing-title">Deleting account</span>
        </div>
      </Modal>

      <DeleteAccountModal
        open={isVisible}
        title="Delete account"
        submitTitle="Delete account"
        onSubmit={handleDeleteAccount}
        onClose={onVisibilityRemove}
      >
        <ul>
          <li>
            Deleting your account is permanent, this action cannot be undone.
          </li>
          <li>
            Your data will be deleted within 60 days, except we may retain a
            limited set of data for longer where required or permitted by law.
          </li>
          <li>Any active subscriptions will be cancelled.</li>
        </ul>
      </DeleteAccountModal>
      <ContactNinjaWhenTeamUserDeleteAccount
        isOpen={isOpenContactNinjaModal}
        onClose={onCloseContactNinjaModal}
      />
    </div>
  );
};
