import { Icon } from 'src/components/Icon';
import { SVG_SIZE_M } from 'src/constants';
import {
  CalendarDots,
  CodeBlock,
  FileMagnifyingGlass,
  Images,
  ListMagnifyingGlass,
} from '@phosphor-icons/react';
import { NinjaAgentsOutlinedIcon } from 'src/images/icons/ninjaAgentsOutlined';
import { ChatGPTLogo } from 'src/images/logos/chat-gpt';
import { UserTier } from 'src/types';

export const BASIC_TIER_MODELS_AND_SKILLS = [
  {
    title: 'Amazon Nova Pro',
    icon: <Icon type="nova" size={SVG_SIZE_M} />,
  },
  {
    title: 'Research',
    icon: <ListMagnifyingGlass size={SVG_SIZE_M} />,
  },
  {
    title: 'Claude 3.7 Sonnet',
    icon: <Icon type="claudeLogo" size={SVG_SIZE_M} />,
  },
  {
    title: 'FLUX 1.1 Pro',
    icon: <Icon type="flux" size={SVG_SIZE_M} />,
  },
  {
    title: 'Scheduler',
    icon: <CalendarDots size={SVG_SIZE_M} />,
  },
  {
    title: 'Stable Diffusion XL 1.0',
    icon: <Icon type="bedrockStableLogo" size={SVG_SIZE_M} />,
  },
  {
    title: 'Image Generation',
    icon: <Images size={SVG_SIZE_M} />,
  },
  {
    title: 'DeepSeek R1',
    icon: <Icon type="deepSeekLogo" size={SVG_SIZE_M} />,
  },
  {
    title: 'Coding',
    icon: <CodeBlock size={SVG_SIZE_M} />,
  },
  {
    title: 'Gemini 1.5 Pro',
    icon: <Icon type="googleGeminiProLogo" size={SVG_SIZE_M} />,
  },
  {
    title: 'File Analysis',
    icon: <FileMagnifyingGlass size={SVG_SIZE_M} />,
  },
  {
    title: 'GPT–4o Mini',
    icon: <Icon type="chatGPTLogo" size={SVG_SIZE_M} />,
  },
];

const STANDARD_FEATURES = [
  {
    id: '1',
    title: <span>Custom image settings</span>,
  },
  {
    id: '2',
    title: <span>SuperAgent–Turbo</span>,
  },
  {
    id: '3',
    title: (
      <span className="nj-feature-bold-item">
        <span className="colored">Unlimited</span> access to 3 AI models
      </span>
    ),
  },
];

const ULTRA_FEATURES = [
  {
    id: '1',
    title: <span>2K image generation</span>,
  },
  {
    id: '2',
    title: <span>Video generation</span>,
  },
  {
    id: '3',
    title: <span>File upload analysis</span>,
  },
  {
    id: '4',
    title: <span>SuperAgent–Apex</span>,
  },
  {
    id: '5',
    title: <span>SuperAgent-R 2.0 (Reasoning model)</span>,
  },
  {
    id: '6',
    title: (
      <span className="nj-feature-bold-item">
        <span className="colored">Unlimited</span> access to 20+ AI models
      </span>
    ),
  },
];

const STANDARD_MODELS = [
  {
    id: '1',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
    text: <span>Fine-tuned Llama 3.3 70B</span>,
  },
  {
    id: '2',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
    text: <span>Ninja Pix (PixArt-Sigma)</span>,
  },
  {
    id: '3',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
    text: <span>Ninja Flux (FLUX.1)</span>,
  },
];

const ULTRA_MODELS = [
  {
    id: '1',
    icon: <Icon type="deepSeekLogo" size={SVG_SIZE_M} />,
    text: <span>DeepSeek R1</span>,
    isShowImageIcon: false,
  },
  {
    id: '2',
    icon: <ChatGPTLogo size={SVG_SIZE_M} />,
    text: <span>OpenAI GPT–4o</span>,
    isShowImageIcon: false,
  },
  {
    id: '3',
    icon: <Icon type="claudeLogo" size={SVG_SIZE_M} />,
    text: <span>Anthropic Claude 3.7 Sonnet</span>,
    isShowImageIcon: false,
  },
];

export const BASIC_WELCOME_UPSELL_MODAL_PLANS = [
  {
    id: UserTier.FREE,
    title: 'Basic',
    price: 0,
    features: [],
    models: [],
    description: <span>6 tasks per day with Ninja AI models</span>,
  },
  {
    id: UserTier.STANDARD,
    title: 'Standard',
    price: 5,
    features: STANDARD_FEATURES,
    models: STANDARD_MODELS,
    description: (
      <span>
        <span className="colored">Unlimited tasks</span> with Ninja AI models
      </span>
    ),
  },
  {
    id: UserTier.ULTRA,
    title: 'Ultra',
    price: 15,
    features: ULTRA_FEATURES,
    models: ULTRA_MODELS,
    description: (
      <span>
        <span className="colored">Unlimited tasks</span> with 20+ AI models
      </span>
    ),
  },
];
