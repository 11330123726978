import { createApi } from '@reduxjs/toolkit/query/react';
import { baseTasksQuery } from './config';
import {
  GetUserSettingsResponse,
  SettingKeys,
  UpdateSettingValueRequest,
} from 'src/types/settings';
import { settingKeyToRootKey } from 'src/utils/settings';

export enum UserSettingsTags {
  UserSettings = 'UserSettings',
}

export const userSettingsApi = createApi({
  reducerPath: 'UserSettingsApi',
  tagTypes: [UserSettingsTags.UserSettings],
  baseQuery: baseTasksQuery,
  endpoints: (builder) => ({
    getSettings: builder.query<
      GetUserSettingsResponse,
      { user_id: string; settingKey: SettingKeys | string }
    >({
      query: ({ user_id, settingKey }) =>
        `users/${user_id}/settings/${settingKey}`,
      providesTags: () => [UserSettingsTags.UserSettings],
    }),
    updateSetting: builder.mutation<void, UpdateSettingValueRequest>({
      query: ({ user_id, setting_key, body }) => ({
        url: `users/${user_id}/settings/${setting_key}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted(
        { user_id, setting_key, body },
        { dispatch, queryFulfilled },
      ) {
        // Find the cache for the "root" setting (e.g. for "researcher:") and update it with the setting that we about to update
        const patchResult = dispatch(
          userSettingsApi.util.updateQueryData(
            'getSettings',
            { user_id, settingKey: settingKeyToRootKey(setting_key) },
            (draft) => {
              const setting = draft.settings[setting_key];
              if (setting) {
                setting.value = body.new_value;
              }
              return draft;
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const { useGetSettingsQuery, useUpdateSettingMutation } =
  userSettingsApi;
