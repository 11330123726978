import styles from './ThinkingSection.module.scss';
import { useCollapse } from 'react-collapsed';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { ThinkingStep } from 'src/v2/components/ThinkingSection/components/ThinkingStep';
import { CotReasoning } from 'src/types/models/CotReasoning';
import { useEffect } from 'react';
import cn from 'classnames';

type ThinkingSectionProps = {
  reasoning: CotReasoning;
  defaultExpanded?: boolean;
  isDone?: boolean;
  isDeepResearch?: boolean;
};

export const ThinkingSection = ({
  reasoning,
  defaultExpanded,
  isDone,
  isDeepResearch,
}: ThinkingSectionProps) => {
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse({
      defaultExpanded,
    });

  useEffect(() => {
    if (isDone) {
      setExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDone]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <button {...getToggleProps()} className={styles.collapseButton}>
          <span
            className={cn(styles.buttonText, { [styles.blinking]: !isDone })}
          >
            {isDeepResearch ? 'Deep Research' : 'Thinking'}{' '}
            {isDone ? 'is done' : 'in progress...'}{' '}
            {reasoning?.time_secs && reasoning.time_secs > 0
              ? `in ${reasoning.time_secs} seconds`
              : ''}
          </span>
          {isExpanded ? (
            <CaretUp size={SVG_SIZE_M} />
          ) : (
            <CaretDown size={SVG_SIZE_M} />
          )}
        </button>
      </div>

      <div {...getCollapseProps()}>
        <div className={styles.collapseItemsBlock}>
          {Object.keys(reasoning.steps || {}).map((item, index, array) => {
            return (
              <ThinkingStep
                step={(reasoning?.steps || {})[item]}
                key={item}
                isStreaming={index === array.length - 1 && !isDone}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
