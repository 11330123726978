import { createApi } from '@reduxjs/toolkit/query/react';
import { baseTokenQuery } from './config';
import { addSourcesToGTMDataLayer } from 'src/utils';
import { CALENDAR_URL, CONTACTS_URL } from 'src/constants';
import log from 'src/utils/logger';
import { GetProvidersResponse } from 'src/types';

export enum TokenTags {
  Token = 'Token',
}

export const tokenApi = createApi({
  reducerPath: 'tokenApi',
  tagTypes: [TokenTags.Token],
  baseQuery: baseTokenQuery,
  endpoints: (builder) => ({
    getGoogleResourcesAccess: builder.query<GetProvidersResponse, string>({
      query: (userId) => `/providers?user_id=${userId}`,
      providesTags: () => [TokenTags.Token],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.providers && data.providers.length > 0) {
            const currentProvider = data.providers[0];
            const calendar_access =
              currentProvider.scopes.includes(CALENDAR_URL);
            const contacts_access =
              currentProvider.scopes.includes(CONTACTS_URL);

            addSourcesToGTMDataLayer({
              calendar_access,
              contacts_access,
            });
          }
        } catch (error) {
          log.error('Failed to fetch Google resources access:', error);
        }
      },
    }),
    revokeGoogleResourcesAccess: builder.mutation<
      void,
      { userId: string; providerId: string; email: string }
    >({
      query: ({ userId, providerId, email }) => ({
        url: `/revoke?user_id=${userId}&provider_id=${providerId}&email=${email}`,
        method: `GET`,
      }),
      invalidatesTags: (result, error, arg) => [TokenTags.Token],
    }),
    getGoogleToken: builder.query<
      {
        access_token: string;
        expired: string;
      },
      { userId: string; email: string }
    >({
      query: ({ userId, email }) =>
        `/?user_id=${userId}&provider_id=Google&email=${email}`,
      // Google token is valid for 60 minutes. Also, TokenAPI will always return a cached version if expired time > 5 minutes from now.
      // We set up a callback that will invalidate the cache 4 minutes before expired time so any data consumers will trigger Token refetch.
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          const timeUntilExpiry =
            Date.parse(data.expired) - Date.now() - 4 * 60 * 1000;

          setTimeout(() => {
            dispatch(tokenApi.util.invalidateTags([TokenTags.Token]));
          }, timeUntilExpiry);
        } catch (err) {
          log.error('Failed to fetch Token', err);
        }
      },
      providesTags: () => [TokenTags.Token],
    }),
  }),
});

export const {
  useGetGoogleResourcesAccessQuery,
  useRevokeGoogleResourcesAccessMutation,
  useGetGoogleTokenQuery,
  usePrefetch,
} = tokenApi;
