import styles from './ChatThreadModelSelector.module.scss';
import { useRef, useState } from 'react';
import { Button } from 'src/v2/commonComponents/Button';
import { newExternalModelsList, SVG_SIZE_M, SVG_SIZE_XL } from 'src/constants';
import { Icon } from 'src/components/Icon';
import { useBreakpoint, useOutsideClick, useVisible } from 'src/hooks';
import { CaretDown } from '@phosphor-icons/react';
import cn from 'classnames';
import { SuperAgentSelect } from './components/SuperAgentSelect';
import { ReactTooltip } from 'src/v2/commonComponents/ReactTooltip';
import { BottomDrawer } from 'src/components/BottomDrawer';
import { OtherModelsButton } from './components/OtherModelsButton';
import { TextDivider } from 'src/v2/commonComponents/TextDivider';
import { useActiveModel } from 'src/hooks/userSettingsHooks/useActiveModel';
import { useUserSettings } from 'src/hooks';
import { SettingKeys, SingleSelectSetting } from 'src/types/settings';

const buttonId = 'chat-model-selector';

export const ChatThreadModelSelector = () => {
  const { isMobileOrTablet } = useBreakpoint();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isVisible, onVisibilityRemove, onVisibilityToggle } = useVisible();
  const { activeModel } = useActiveModel();
  const { otherLLMModels, superAgentModels } =
    useUserSettings<SingleSelectSetting>(
      SettingKeys.WRITER_CODER_PRIMARY_MODEL,
    );

  const [isExternalModelsMenuOpened, setIsExternalModelsMenuOpened] =
    useState(false);

  const handleOutsideClick = () => {
    if (!isMobileOrTablet) {
      onVisibilityRemove();
    }
  };

  useOutsideClick(containerRef, handleOutsideClick);

  const selectedModelName = !!activeModel
    ? newExternalModelsList[activeModel || '']?.display_name
    : 'Ninja Mode';

  const selectedModelIconName = !!activeModel
    ? newExternalModelsList[activeModel || '']?.icon_name
    : 'ninjaMainIcon';

  const handleCloseExternalModels = () => {
    setIsExternalModelsMenuOpened(false);
  };

  const handleOpenExternalModels = () => {
    setIsExternalModelsMenuOpened(true);
  };

  const handleMobileDrawerModelsClose = () => {
    onVisibilityRemove();
    setIsExternalModelsMenuOpened(false);
  };

  return (
    <div className={styles.root} ref={containerRef}>
      <Button
        color="transparent"
        onClick={onVisibilityToggle}
        id={buttonId}
        className={cn(styles.button, { [styles.buttonSelected]: isVisible })}
      >
        <div className={styles.buttonContent}>
          <Icon type={selectedModelIconName} size={SVG_SIZE_XL} />
          <span className={styles.buttonTitle}>{selectedModelName}</span>
          <CaretDown size={SVG_SIZE_M} />
        </div>
      </Button>

      {!isMobileOrTablet ? (
        <ReactTooltip
          id={buttonId}
          className={styles.modelsTooltip}
          isOpen={isVisible}
          place={isMobileOrTablet ? 'bottom' : 'bottom-start'}
        >
          <SuperAgentSelect
            onClose={onVisibilityRemove}
            models={superAgentModels}
          />
          <TextDivider withMargin={false} />
          <OtherModelsButton
            onClose={onVisibilityRemove}
            handleOpenExternalModels={handleOpenExternalModels}
            handleCloseExternalModels={handleCloseExternalModels}
            isExternalModelsMenuOpened={isExternalModelsMenuOpened}
            models={otherLLMModels}
          />
        </ReactTooltip>
      ) : (
        <BottomDrawer
          isOpen={isVisible}
          onClose={handleMobileDrawerModelsClose}
        >
          {!isExternalModelsMenuOpened && (
            <SuperAgentSelect
              onClose={onVisibilityRemove}
              models={superAgentModels}
            />
          )}
          <TextDivider />
          <OtherModelsButton
            onClose={onVisibilityRemove}
            handleOpenExternalModels={handleOpenExternalModels}
            handleCloseExternalModels={handleCloseExternalModels}
            isExternalModelsMenuOpened={isExternalModelsMenuOpened}
            models={otherLLMModels}
          />
        </BottomDrawer>
      )}
    </div>
  );
};
