import { useMemo } from 'react';
import { Thread } from '@langchain/langgraph-sdk';
import { getTaskDisplayDate } from 'src/utils';
import { FileListItem } from '../FileListItem';
import styles from './FileListGroupedByDate.module.scss';
import { DocumentValuesType, CodeValuesType } from 'src/types';

interface FileListGroupedByDateProps {
  date: string;
  groupedDocuments: Thread<DocumentValuesType | CodeValuesType>[];
  selectedConversationId?: string;
}

export const FileListGroupedByDate = ({
  date,
  groupedDocuments,
}: FileListGroupedByDateProps) => {
  const documentDate = useMemo(() => getTaskDisplayDate(date), [date]);

  return (
    <div className={styles.root}>
      <div className={styles.date}>
        <span>{documentDate}</span>
      </div>

      {groupedDocuments.map(
        (document: Thread<DocumentValuesType | CodeValuesType>) => (
          <FileListItem
            key={`document-${document.thread_id}`}
            data={document}
          />
        ),
      )}
    </div>
  );
};
