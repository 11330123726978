import { useContext } from 'react';
import { ArrowCounterClockwise } from '@phosphor-icons/react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { Message } from 'src/types';
import { SVG_SIZE_M } from 'src/constants';
import { useDeleteAttachment } from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';

interface RephraseMessageButtonProps {
  message: Message | undefined;
  disabled?: boolean;
}

export const RephraseMessageButton = ({
  message,
  disabled,
}: RephraseMessageButtonProps) => {
  const { setThreadInputBoxValue } = useContext(ThreadInputBoxContext);
  const { onDeleteAllAttachments } = useDeleteAttachment();

  const userInput = message?.original_query || '';

  const handleClick = () => {
    setThreadInputBoxValue(userInput);
    onDeleteAllAttachments();
  };

  return (
    <Button
      color="transparent"
      disabled={!userInput || disabled}
      onClick={handleClick}
      data-e2e="rephrase-button"
      tooltipContent="Rephrase"
      tooltipPlace="left"
    >
      <ArrowCounterClockwise size={SVG_SIZE_M} />
    </Button>
  );
};
