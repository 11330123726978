import { useMemo } from 'react';
import { AppRoutes } from 'src/types';
import { convertConversationIdToSlug } from 'src/utils';
import { useQueryParams } from '../useQueryParams';
import { useConversationParams } from './useConversationParams';
import { DEFAULT_CHAT_ID } from 'src/constants';

interface UseConversationLinkParams {
  conversation_id?: string | null;
  document?: string;
}

// (irina) if we pass conversation_id or document, it will use that value, otherwise it will use the value from the searchParams
export const useConversationLink = ({
  conversation_id,
  document,
}: UseConversationLinkParams) => {
  const { currentConversationId } = useConversationParams();
  const { searchParams } = useQueryParams();

  const { document: documentFromSearchParams } = searchParams;

  const conversationSearchParam = conversation_id
    ? convertConversationIdToSlug(conversation_id)
    : currentConversationId !== DEFAULT_CHAT_ID
      ? convertConversationIdToSlug(currentConversationId)
      : null;

  const documentSearchParam = document || documentFromSearchParams;

  const queryParamsString = [
    conversationSearchParam ? `conv=${conversationSearchParam}` : '',
    documentSearchParam ? `document=${documentSearchParam}` : '',
  ]
    .filter((item) => item)
    .join('&');

  const linkTo = useMemo(
    () =>
      `${AppRoutes.HOME}${queryParamsString ? `?${queryParamsString}` : ''}`,
    [queryParamsString],
  );

  return useMemo(
    () => ({
      linkTo,
    }),
    [linkTo],
  );
};
