import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface DeepResearchState {
  isDeepResearchEnabled: boolean;
}

const initialState = {
  isDeepResearchEnabled: false,
} as DeepResearchState;

export const deepResearchSlice = createSlice({
  name: 'deepResearchPersist',
  initialState,
  reducers: {
    setIsDeepResearchEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeepResearchEnabled = payload;
    },
  },
});

export const deepResearchState = (state: RootState) =>
  state.deepResearchPersist as DeepResearchState;

export const { setIsDeepResearchEnabled } = deepResearchSlice.actions;

export default deepResearchSlice.reducer;
