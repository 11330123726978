import { Thread } from '@langchain/langgraph-sdk';
import { CodeValuesType, DocumentValuesType, FileListType } from 'src/types';

export const getDocumentTypeFromArtifactValues = (
  values: DocumentValuesType | CodeValuesType,
) => {
  return values?.artifact?.contents?.[0]?.type === 'code'
    ? FileListType.CODE
    : FileListType.DOCUMENT;
};

export const getDocumentTitleFromArtifactValues = (
  data: Thread<DocumentValuesType | CodeValuesType>,
) => {
  return (data?.metadata?.thread_title as string) || 'Untitled document';
};
