import classNames from 'classnames';
import { Icon } from 'src/components/Icon';
import { SVG_SIZE_L, SVG_SIZE_S } from 'src/constants';
import styles from './DimensionPrimaryItem.module.scss';
import { ImageGenNewSettingsDimensionOptionTypes } from 'src/types/settings';

interface DimensionPrimaryItemProps {
  data: {
    aspect_ratio: string;
    name: ImageGenNewSettingsDimensionOptionTypes | string;
    isDisabled: boolean;
  };
  isSelected: boolean;
  onClick: () => void;
}

const IconsSet: Record<string, React.ReactElement> = {
  [ImageGenNewSettingsDimensionOptionTypes.SQUARE]: (
    <Icon type="square" size={SVG_SIZE_L} />
  ),
  [ImageGenNewSettingsDimensionOptionTypes.PORTRAIT]: (
    <Icon type="portrait" size={SVG_SIZE_S} />
  ),
  [ImageGenNewSettingsDimensionOptionTypes.LANDSCAPE]: (
    <Icon type="landscape" size={SVG_SIZE_L} />
  ),
};

export const DimensionPrimaryItem = ({
  data,
  isSelected,
  onClick,
}: DimensionPrimaryItemProps) => {
  const { aspect_ratio, name, isDisabled } = data;
  const CurrentIcon = IconsSet[name.toLowerCase()];

  return (
    <button
      onClick={onClick}
      className={classNames(styles.root, {
        [styles.active]: isSelected,
        [styles.disabled]: isDisabled,
      })}
      disabled={isDisabled}
      data-e2e={`image-dimension-${name}-button`}
    >
      <span className={styles.icon}>{CurrentIcon}</span>
      <span className={styles.subtitle}>{aspect_ratio}</span>
    </button>
  );
};
