import { useCallback } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';

export const useClearEditor = () => {
  const [editor] = useLexicalComposerContext();

  const clearEditor = useCallback(() => {
    editor.update(() => {
      const root = $getRoot();
      root.getChildren().forEach((child) => child.remove());
    });
  }, [editor]);

  return { clearEditor };
};
