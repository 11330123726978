/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FeedbackType {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  FEEDBACK_V2 = 'feedback_v2',
}
