/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserTier {
  OPEN = 'open',
  FREE = 'free',
  ENTERPRISE_TRIAL = 'enterprise_trial',
  ENTERPRISE = 'enterprise',
  PRO = 'pro',
  ULTRA = 'ultra',
  STANDARD = 'standard',
  TEAMS_STANDARD = 'teams_standard',
  TEAMS_PRO = 'teams_pro',
  TEAMS_ULTRA = 'teams_ultra',
  TESTING = 'testing',
  TEAM = 'team',
  PAID = 'paid',
  PRO_TRIAL = 'pro_trial',
}
