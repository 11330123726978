/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SuperAgentModels {
  TURBO = 'turbo',
  NEXUS = 'nexus',
  APEX = 'apex',
  REASONING = 'reasoning',
}
