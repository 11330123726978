import { useSession, useUserSettings } from 'src/hooks';
import { ImageStyleSelect } from 'src/components/ImageGenSettings/components/ImageStyleSelect';
import { Dimensions } from 'src/components/ImageGenSettings/components/Dimensions';
import { UpsellMessage } from 'src/components/UpsellMessage';
import { TextDivider } from 'src/v2/commonComponents/TextDivider';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './ImageSettings.module.scss';
import { ResolutionToggle } from 'src/components/ImageGenSettings/components/ResolutionToggle';
import {
  ImageGenNewSettingsDimensionOptionTypes,
  SettingKeys,
  SingleSelectSetting,
} from 'src/types/settings';
import {
  ImageGenSettingsDimensionResolutionTypes,
  ImageGenStyles,
} from 'src/types';
import log from 'src/utils/logger';
import { SAVED_TEXT } from 'src/constants';
import { toast } from 'react-toastify';

export const ImageSettings = () => {
  const { isFreeTier, isOpenTier, isStandardTier } = useSession();
  const { updateUserSetting: updateDimention } =
    useUserSettings<SingleSelectSetting>(SettingKeys.IMAGE_DIMENTIONS);
  const { updateUserSetting: updateResolution } =
    useUserSettings<SingleSelectSetting>(SettingKeys.IMAGE_SIZES);
  const { updateUserSetting: updateStyle } =
    useUserSettings<SingleSelectSetting>(SettingKeys.IMAGE_STYLES);

  const handleResetClick = () => {
    try {
      updateDimention(ImageGenNewSettingsDimensionOptionTypes.SQUARE, true);
      updateResolution(ImageGenSettingsDimensionResolutionTypes.SMALL, true);
      updateStyle(ImageGenStyles.NONE, true);
    } catch (e) {
      log.error(e);
    } finally {
      toast.success(SAVED_TEXT);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {(isOpenTier || isFreeTier || isStandardTier) && (
          <UpsellMessage
            title="Customize your images"
            description="Unlock high-resolution images with 11 aspect ratios and the ability to select from multiple image styles"
            dataGTMNinjaTier="Customize-images-upgrade"
            dataGTMOpenTier="Customize-images-signup"
            buttonStyle="with-background"
            buttonLabel="Upgrade to unlock"
            className={styles.upsellMessage}
          />
        )}

        <Dimensions />

        <TextDivider />

        <ResolutionToggle />

        <TextDivider />

        <ImageStyleSelect />
      </div>

      <div className={styles.resetButtonWrapper}>
        <Button
          className={styles.resetButton}
          type="button"
          onClick={handleResetClick}
          color="link"
        >
          Reset
        </Button>
      </div>
    </div>
  );
};
