import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface ActiveModelState {
  activeModel?: string;
}

const initialState = {
  activeModel: undefined,
} as ActiveModelState;

export const activeModelSlice = createSlice({
  name: 'activeModelPersist',
  initialState,
  reducers: {
    setActiveModel: (state, { payload }: PayloadAction<string | undefined>) => {
      state.activeModel = payload;
    },
  },
});

export const activeModelState = (state: RootState) =>
  state.activeModelPersist as ActiveModelState;

export const { setActiveModel } = activeModelSlice.actions;

export default activeModelSlice.reducer;
