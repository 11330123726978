import React, { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './IconButton.module.scss';
import { IconProps } from '@phosphor-icons/react';

interface IconButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'content'> {
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  icon?: React.ComponentType<IconProps>;
  customContent?: React.ReactNode;
  size?: number;
  isMobile?: boolean;
  label?: string;
}

/**
 * @deprecated
 */
export const IconButton: React.FC<IconButtonProps> = ({
  className,
  type = 'button',
  onClick,
  disabled = false,
  icon: Icon,
  customContent,
  size = 24,
  isMobile = false,
  label,
  ...props
}) => {
  return (
    <button
      className={cn(styles.iconButton, className, {
        [styles.mobile]: isMobile,
        [styles.disabled]: disabled,
      })}
      type={type}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      <div className={styles.content}>
        {Icon && <Icon size={size} className={styles.icon} />}
        <div className={styles.textContent}>
          {customContent}
          {!isMobile && label && <span className={styles.label}>{label}</span>}
        </div>
      </div>
    </button>
  );
};
