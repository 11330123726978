import { useContext, useRef, useCallback } from 'react';
import { ReactTooltip } from 'src/v2/commonComponents/ReactTooltip';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { PromptLoadingReason } from 'src/constants';
import {
  useBreakpoint,
  useSession,
  useOutsideClick,
  useConversationParams,
} from 'src/hooks';
import log from 'loglevel';
import { useGetTranslationLanguagesJSONQuery } from 'src/store/services';
import { getBaseHeaders } from 'src/store/services/config';
import { bffTasksBaseUrl } from 'src/store/constants';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import styles from './TranslatePromptModal.module.scss';
import { TranslatePromptLanguageSelector } from '../TranslatePromptButton/components/TranslatePromptLanguageSelector';
import { BottomDrawer } from 'src/components/BottomDrawer';

type TranslatePromptModalProps = {
  onClose?: () => void;
  undoTriggerRef: React.MutableRefObject<boolean>;
  setTranslatedText: (text: string) => void;
  selectedLanguage: string;
  setSelectedLanguage: (id: string) => void;
  isModalOpen: boolean;
  removeModalVisibility: () => void;
};

const TOOLTIP_ANCHOR_ID = 'prompt-translate-trigger';
const textDecoder = new TextDecoder();

export const TranslatePromptModal = ({
  onClose,
  undoTriggerRef,
  setTranslatedText,
  selectedLanguage,
  setSelectedLanguage,
  isModalOpen,
  removeModalVisibility,
}: TranslatePromptModalProps) => {
  const { threadInputBoxRef } = useContext(ForwardRefContext);

  const { appUser } = useSession();
  const { isMobileOrTablet } = useBreakpoint();
  const { isLandingPage } = useConversationParams();

  const { data: languagesData = {}, isLoading } =
    useGetTranslationLanguagesJSONQuery();

  const containerRef = useRef(null);

  const {
    threadInputBoxValue,
    setTemporaryInputValue,
    setPromptLoading,
    setPromptModificationReason,
    temporaryInputValue,
    setThreadInputBoxValue,
  } = useContext(ThreadInputBoxContext);

  const handleReturnInputToFocus = useCallback(() => {
    threadInputBoxRef?.current?.focus();
  }, [threadInputBoxRef]);

  const handleOutsideClick = () => {
    if (!isMobileOrTablet) {
      removeModalVisibility();
    }
  };

  useOutsideClick(containerRef, handleOutsideClick);

  const updatePrompt = useCallback(
    async (response: Response, stream: boolean) => {
      let value = '';
      if (stream) {
        if (response.body) {
          const reader = response.body.getReader();
          while (true) {
            const { done, value: chunk } = await reader.read();
            if (done) break;
            value += textDecoder.decode(chunk, { stream: true });
            if (undoTriggerRef.current) {
              undoTriggerRef.current = false;
              return;
            }
            setThreadInputBoxValue(value);
          }
          setTranslatedText(value);
        }
      } else {
        const result = await response.json();
        setThreadInputBoxValue(result.translated_prompt);
        setTranslatedText(result.translated_prompt);
      }
    },
    [setThreadInputBoxValue, setTranslatedText, undoTriggerRef],
  );

  const handleSendPrompt = useCallback(
    async (language = selectedLanguage) => {
      try {
        setPromptLoading(true);
        setPromptModificationReason(PromptLoadingReason.TRANSLATING);
        setTemporaryInputValue(threadInputBoxValue);
        setThreadInputBoxValue('');

        await fetch(
          `${bffTasksBaseUrl}/users/${appUser.user_id}/prompts/translate`,
          {
            method: 'POST',
            headers: await getBaseHeaders(
              new Headers({ 'Content-Type': 'application/json' }),
            ),
            body: JSON.stringify({
              prompt: threadInputBoxValue,
              target_language: language,
              stream: true,
            }),
          },
        )
          .then((response) => updatePrompt(response, true))
          .catch((err) => log.error(err));

        setPromptLoading(false);
      } catch (e) {
        setPromptLoading(false);
        setThreadInputBoxValue(temporaryInputValue);
        setTemporaryInputValue('');
        log.error(e);
      }
      handleReturnInputToFocus();
    },
    [
      appUser.user_id,
      selectedLanguage,
      threadInputBoxValue,
      setPromptLoading,
      setPromptModificationReason,
      setTemporaryInputValue,
      setThreadInputBoxValue,
      temporaryInputValue,
      handleReturnInputToFocus,
      updatePrompt,
    ],
  );

  const handleLanguageSelect = (id: string) => {
    setSelectedLanguage(id);
  };

  return (
    <div className={styles.root} ref={containerRef}>
      {!isMobileOrTablet ? (
        <ReactTooltip
          id={TOOLTIP_ANCHOR_ID}
          isOpen={isModalOpen}
          place={isLandingPage ? 'bottom-end' : 'left'}
          className={styles.tooltip}
          offset={isLandingPage ? -250 : -50}
        >
          <TranslatePromptLanguageSelector
            isModalOpen={isModalOpen}
            isLoading={isLoading}
            languages={languagesData}
            selectedLanguage={selectedLanguage}
            defaultLanguage={selectedLanguage}
            handleLanguageSelect={handleLanguageSelect}
            handleSendPrompt={handleSendPrompt}
            removeModalVisibility={removeModalVisibility}
          />
        </ReactTooltip>
      ) : (
        <BottomDrawer
          isOpen={isModalOpen}
          onClose={removeModalVisibility}
          className={styles.drawer}
        >
          <TranslatePromptLanguageSelector
            isModalOpen={isModalOpen}
            isLoading={isLoading}
            languages={languagesData}
            selectedLanguage={selectedLanguage}
            defaultLanguage={selectedLanguage}
            handleLanguageSelect={handleLanguageSelect}
            handleSendPrompt={handleSendPrompt}
            removeModalVisibility={removeModalVisibility}
          />
        </BottomDrawer>
      )}
    </div>
  );
};
